export default {
  title: {
    en: 'Don\'t have a wallet?',
    ru: 'Нет кошелька?',
    fr: 'Pas de portefeuille ?',
    es: 'No tiene una cartera?',
    pt: 'Não tem carteira?',
    de: 'Du hast keine Wallet?',
    zh: '沒有錢包嗎?',
  },
}
