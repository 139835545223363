import { useState, useEffect } from 'react'


const loadedScripts: Record<string, boolean | Promise<void>> = {}

const loadScript = (src: string, isAsync: boolean = true): Promise<void> => {
  if (loadedScripts[src] === true) {
    return Promise.resolve()
  }

  if (loadedScripts[src]) {
    return loadedScripts[src] as Promise<void>
  }

  loadedScripts[src] = new Promise<void>((resolve, reject) => {
    const script = document.createElement('script')

    script.async = isAsync
    script.type = 'text/javascript'
    script.src = src

    script.onerror = (err) => {
      console.error(`Script "${src}" load error: ${err}`)

      if (script.parentNode) {
        script.parentNode.removeChild(script)
      }

      reject(err)
    }

    script.onload = () => {
      resolve()

      // required for setting correct status of "isLoaded" when the hook is rendered first time.
      loadedScripts[src] = true
    }

    document.head.appendChild(script)
  })

  return loadedScripts[src] as Promise<void>
}

type State = {
  isLoading: boolean
  error: Error | null
}

type Opts = {
  async?: boolean
  skip?: boolean
}

type Result = {
  isScriptLoading: boolean
  scriptLoadingError: State['error']
  isScriptLoaded: boolean
}

const useScriptsLoader = (srcs: string[], opts?: Opts): Result => {
  const { async, skip } = opts || {}

  const isLoaded = srcs.every((src) => loadedScripts[src] === true)

  const [ state, setState ] = useState<State>({
    isLoading: !isLoaded,
    error: null,
  })

  const { isLoading, error } = state

  useEffect(() => {
    if (isLoaded || skip) {
      return
    }

    let isMounted = true
    let promise

    promise = Promise.all(srcs.map((src) => loadScript(src, async)))

    promise
      .then(() => {
        if (isMounted) {
          setState({ isLoading: false, error: null })
        }
      })
      .catch((error) => {
        if (isMounted) {
          setState({ isLoading: false, error })
        }
      })

    return () => {
      isMounted = false
    }
  }, [])

  return {
    isScriptLoading: isLoading,
    scriptLoadingError: error,
    isScriptLoaded: !isLoading && !error,
  }
}


export default useScriptsLoader
