import { BigNumber } from '@ethersproject/bignumber'
import { analytics, constants } from 'helpers'
import { getRatesContracts } from 'contracts'
import localStorage from 'local-storage'
import cacheStorage from 'cache-storage'


const cache = cacheStorage.get(UNIQUE_FILE_ID)

// Regardless of which network is selected by the user, we must request fiat currency data from the mainnet 1 time
const fetchFiatValues = async (): Promise<InitialData.System.FiatRates> => {
  const cachedData = cache.getData<InitialData.System.FiatRates>()

  if (cachedData) {
    return cachedData
  }

  try {
    const rateContracts = getRatesContracts()

    const [ ethUsdRateBN, eurUsdRateBN, gbpUsdRateBN, gnoUsdRateBN, ethXdaiRateBN ] = await Promise.all([
      rateContracts.USD.ETH,
      rateContracts.USD.EUR,
      rateContracts.USD.GBP,
      rateContracts.USD.GNO,
      rateContracts.ETH.XDAI,
    ].map(((contract) => contract.latestAnswer())))

    const result = {
      ethUsdRateBN,
      eurUsdRateBN,
      gbpUsdRateBN,
      gnoUsdRateBN,
      ethXdaiRateBN,
    }

    cache.setData(result)

    localStorage.setItem(constants.localStorageNames.mocks.fiatValues, {
      ethUsdRate: ethUsdRateBN.toString(),
      eurUsdRate: eurUsdRateBN.toString(),
      gbpUsdRate: gbpUsdRateBN.toString(),
      gnoUsdRate: gnoUsdRateBN.toString(),
      ethXdaiRate: ethXdaiRateBN.toString(),
      timestamp: new Date().getTime(),
    })

    return result
  }
  catch (error) {
    analytics.sentry.exception('Fetch fiat values error', error as Error)

    try {
      const savedValues = localStorage.getItem<{
        timestamp: number,
        ethUsdRate: string,
        eurUsdRate: string,
        gbpUsdRate: string,
        usdGnoRate: string,
        ethXdaiRate: string,
      }>(constants.localStorageNames.mocks.fiatValues)

      if (savedValues) {
        const { timestamp, ethUsdRate, eurUsdRate, gbpUsdRate, usdGnoRate, ethXdaiRate } = savedValues

        const lastSavedDay = (new Date().getTime() - new Date(timestamp).getTime()) / 1000 / 60 / 60 / 24

        if (lastSavedDay > 3) {
          // It is not safe to keep too old fiat values
          return Promise.reject(error)
        }

        return {
          ethUsdRateBN: BigNumber.from(ethUsdRate),
          eurUsdRateBN: BigNumber.from(eurUsdRate),
          gbpUsdRateBN: BigNumber.from(gbpUsdRate),
          gnoUsdRateBN: BigNumber.from(usdGnoRate),
          ethXdaiRateBN: BigNumber.from(ethXdaiRate),
        }
      }
    }
    catch {
      localStorage.removeItem(constants.localStorageNames.mocks.fiatValues)
    }

    return Promise.reject(error)
  }
}


export default fetchFiatValues
