const getHarbourGoerliData = (): GetNodeOperators.Data => {
  const operators = {
    'Blockdaemon': [ '0xca445ecf3c53e1f8bc0dcfec6ff1e9544b3dc5b8' ],
  }

  const europe = [
    `${operators['Blockdaemon'][0]}-1`,
  ]

  return {
    operators,
    europe,
  }
}


export default getHarbourGoerliData
