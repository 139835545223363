import { WalletLinkCoinbaseConnector } from 'custom-connectors'
import { constants } from 'helpers'
import supportedNetworks from 'config/helpers/supportedNetworks'
import supportedChainIds from 'config/helpers/supportedChainIds'

import messages from './messages'


const getSpecialErrors: InitConnectors.Data['getSpecialErrors'] = (error) => {
  const errorMessage = (error as Error)?.message

  if (/unsupported chain id/i.test(errorMessage)) {
    return messages.errors.unsupportedNetwork
  }
  else if (errorMessage === 'User denied account authorization') {
    return messages.errors.authorize
  }

  return null
}

const initCoinbase: InitConnectors.CreateConnectorHandler<WalletIds> = () => {
  const connector = new WalletLinkCoinbaseConnector({
    appLogoUrl: 'https://app.stakewise.io/logo512.png',
    url: supportedNetworks.mainnet.url,
    appName: 'StakeWise',
    supportedChainIds,
  })

  return {
    [constants.walletNames.coinbase]: {
      connector,
      name: messages.name,
      activationMessage: messages.activationMessage,
      getSpecialErrors,
    },
  }
}


export default initCoinbase
