import React, { useRef } from 'react'

import useMenu from './util/useMenu'
import Content from './Content/Content'
import ActionIcon from './ActionIcon/ActionIcon'

import useSticky from './util/useSticky'

import s from './Menu.module.scss'


const buttonId = 'menuButton'
const controlsId = 'menuControls'

const Menu: React.FC = () => {
  const { isOpened, isDeviceCalculated, onClick } = useMenu()

  const rootContainerRef = useRef<HTMLDivElement>(null)

  useSticky({
    fixedClassName: s.fixed,
    rootContainerRef,
  })

  return (
    <div
      ref={rootContainerRef}
      className={s.menu}
    >
      <ActionIcon
        id={buttonId}
        controlsId={controlsId}
        isOpened={isOpened}
        onClick={onClick}
        dataTestId="header-menu-button"
      />
      {
        isDeviceCalculated && (
          <Content
            id={controlsId}
            buttonId={buttonId}
            isOpened={isOpened}
            onClick={onClick}
          />
        )
      }
    </div>
  )
}


export default React.memo(Menu)
