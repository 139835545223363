import React from 'react'
import cx from 'classnames'
import Image from 'next/image'
import { constants, modifiers } from 'helpers'
import { useStore, useFiatValues } from 'hooks'

import { Text, CountUp } from 'components/ui'
import { Box, Flex } from 'components/layout'
import { Tooltip } from 'components/feedback'

import s from './SwiseBalance.module.scss'


type SwiseBalanceProps = {
  isCompact?: boolean
}

const storeSelector = (store: Store) => ({
  currencySymbol: store.account.currencySymbol,
  isInitialRequestLoading: store.ui.initialRequestLoading,
  swiseTokenBalance: store.account.balances.swiseTokenBalance,
})

const SwiseBalance: React.FC<SwiseBalanceProps> = (props) => {
  const { isCompact } = props

  const {
    currencySymbol,
    swiseTokenBalance,
    isInitialRequestLoading,
  } = useStore(storeSelector)

  const { fiatSwiseBalance } = useFiatValues({
    fiatSwiseBalance: {
      value: swiseTokenBalance,
      token: constants.tokens.swise,
    },
  })

  const formattedSwiseBalance = modifiers.formatTokenValue({
    withSeparator: false,
    value: swiseTokenBalance,
  })

  const isLargeAmount = Number(formattedSwiseBalance) > 1000

  const mainClassName = cx({
    [s.compact]: isCompact,
    [s.loading]: isInitialRequestLoading,
  })

  const content = (
    <Box className={s.container} ph={12} noWrapper>
      <Flex className={s.content} justify="start">
        <Box className={s.imageContainer} mr={8}>
          <Image
            src={constants.tokensImages[constants.tokens.swise]}
            width={16}
            height={16}
            alt=""
          />
        </Box>
        <Text
          className={s.text}
          color={isCompact ? 'black' : 'default'}
          size={isCompact ? 14 : 16}
          dataTestId="header-swise-balance"
        >
          <CountUp
            suffix={` ${constants.tokens.swise}`}
            end={parseFloat(formattedSwiseBalance)}
            duration={isCompact ? 0 : 1.5}
            separator=","
            decimals={isLargeAmount ? 0 : 2}
          />
        </Text>
      </Flex>
    </Box>
  )

  return (
    <div className={mainClassName}>
      {
        fiatSwiseBalance.value ? (
          <Tooltip content={`${fiatSwiseBalance.formattedValue} ${currencySymbol}`} center>
            {content}
          </Tooltip>
        ) : (
          content
        )
      }
    </div>
  )
}


export default React.memo(SwiseBalance)
