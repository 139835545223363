export default {
  name: {
    en: 'ZenGo',
    ru: 'ZenGo',
    fr: 'ZenGo',
    es: 'ZenGo',
    pt: 'ZenGo',
    de: 'ZenGo',
    zh: 'ZenGo',
  },
  activationMessage: {
    en: 'Waiting for QR code to be scanned by ZenGo application',
    ru: 'Ожидаем сканирования QR-кода приложением ZenGo',
    fr: 'Attente du scan du QR Code par l\'application ZenGo',
    es: 'Esperando que el código QR sea escaneado por el app de ZenGo',
    pt: 'Aguardando a leitura do código QR pelo aplicativo ZenGo',
    de: 'Warte auf das Scannen des QR Codes in der ZenGo App',
    zh: '等待 ZenGo 應用程式掃描 QR Code',
  },
  errors: {
    unsupportedNetwork: {
      en: 'ZenGo is connected to an unsupported network',
      ru: 'ZenGo подключен к не поддерживаемой сети',
      fr: 'ZenGo est connecté à un réseau non supporté',
      es: 'ZenGo está conectado a una red no soportada',
      pt: 'ZenGo está conectado a uma rede não suportada',
      de: 'ZenGo ist mit einem nicht unterstützten Netzwerk verbunden',
      zh: 'ZenGo 連接到一個不支援的網路',
    },
    authorize: {
      en: 'Please authorize access to your ZenGo account',
      ru: 'Пожалуйста, разрешите доступ к Вашему аккаунту ZenGo',
      fr: 'Merci d\'autoriser l\'accès à votre compte ZenGo',
      es: 'Favor permitir acceso a su cuenta de ZenGo',
      pt: 'Por favor autorize o acesso à sua conta ZenGo',
      de: 'Bitte autorisiere den Zugriff auf Dein ZenGo Konto',
      zh: '請允許存取您的 ZenGo',
    },
  },
}
