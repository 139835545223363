export default {
  switchError: {
    en: 'Your wallet does not support switching the network',
    ru: 'Ваш кошелек не поддерживает смену сети',
    fr: 'Votre portefeuille ne prend pas en charge le changement de réseau',
    es: 'Tu monedero no admite el cambio de red',
    pt: 'A sua carteira não suporta a mudança de rede',
    de: 'Ihr Wallet unterstützt das Wechseln des Netzwerks nicht',
    zh: '您的钱包不支持切换网络',
  },
  chainError: {
    en: 'Your wallet does not support this network',
    ru: 'Ваш кошелек не поддерживает эту сеть',
    fr: 'Votre portefeuille ne prend pas en charge ce réseau',
    es: 'Tu monedero no admite esta red',
    pt: 'A sua carteira não suporta esta rede',
    de: 'Ihr Wallet unterstützt dieses Netzwerk nicht',
    zh: '您的钱包不支持此网络',
  },
}
