import { getters } from 'helpers'


export type Input = {
  trigger: HTMLDivElement
  tooltip: HTMLDivElement
  center: boolean | undefined
}

const setPosition = (values: Input) => {
  const { trigger, tooltip, center } = values

  const offset = 16
  const viewportWidth = document.documentElement.clientWidth
  const triggerPosition = getters.getElementPosition(trigger)
  const triggerBounds = trigger.getBoundingClientRect()
  let tooltipBounds = tooltip.getBoundingClientRect()

  let top
  let left
  let right

  // if distance between viewport's left side and trigger is less than tooltip width
  // then the tooltip should be placed to the right of the trigger
  if (triggerBounds.left < tooltipBounds.width + offset) {
    left = triggerPosition.left + triggerBounds.width - tooltipBounds.width

    // if tooltip is off-screened
    if (left < 0) {
      if (triggerPosition.left < offset) {
        left = triggerPosition.left
      }
      else {
        left = offset
      }
    }

    // if tooltip doesn't fit the space between left and right sides of the viewport
    if (tooltipBounds.width + left > viewportWidth - offset) {
      if (viewportWidth - triggerPosition.left - triggerBounds.width < offset) {
        right = viewportWidth - triggerPosition.left - triggerBounds.width
      }
      else {
        right = offset
      }
    }
  }
  else {
    const leftPositionForCenter = triggerPosition.left + (triggerBounds.width / 2) - (tooltipBounds.width / 2)

    const supportCenterPosition = (
      center
      && (viewportWidth - leftPositionForCenter) > tooltipBounds.width
    )

    left = supportCenterPosition
      ? leftPositionForCenter
      : triggerPosition.left + triggerBounds.width - tooltipBounds.width
  }

  tooltip.style.left = `${left}px`
  tooltip.style.right = right ? `${right}px` : 'auto'

  tooltipBounds = tooltip.getBoundingClientRect()

  // if distance between viewport's top side and trigger is less than tooltip height
  // then the tooltip should be placed to the bottom of the trigger
  if (triggerBounds.top - tooltipBounds.height < offset) {
    top = triggerPosition.top + triggerBounds.height + 4
  }
  else {
    top = triggerPosition.top - tooltipBounds.height - 4
  }

  tooltip.style.top = `${top}px`
}


export default setPosition
