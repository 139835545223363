import localStorage from 'local-storage'
import { constants } from 'helpers'
import { getConnectors } from 'connectors'
import { createContracts } from 'contracts'
import { isAddress } from '@ethersproject/address'
import { ExternalProvider, Web3Provider } from '@ethersproject/providers'

import { getConfig, supportedNetworkIds } from '../helpers'
import getQueryNetworkId from '../helpers/getQueryNetworkId'

import getInitialContext from './getInitialContext'


const getSavedNetworkId = (): NetworkIds | null => {
  const localStorageName = constants.localStorageNames.savedNetwork
  const savedNetwork = localStorage.getItem<LocalStorageData.SavedNetwork>(localStorageName)

  if (savedNetwork?.id && supportedNetworkIds.includes(savedNetwork.id)) {
    return savedNetwork.id
  }

  return null
}

const getSavedActiveWallet = (): WalletIds | null => {
  const localStorageName = constants.localStorageNames.walletName
  const savedActiveWallet = localStorage.getItem<WalletIds>(localStorageName)

  if (savedActiveWallet && Object.values(constants.walletNames).includes(savedActiveWallet)) {
    return savedActiveWallet
  }
  else {
    localStorage.removeItem(localStorageName)
  }

  return null
}

const getSavedReadOnlyAddress = (): string | null => {
  const localStorageName = constants.localStorageNames.savedReadOnlyAddress
  const savedReadOnlyAddress = localStorage.getItem<string>(localStorageName)

  if (savedReadOnlyAddress && isAddress(savedReadOnlyAddress)) {
    return savedReadOnlyAddress
  }
  else {
    localStorage.removeItem(localStorageName)
  }

  return null
}

const getSavedContext = (): ConfigProvider.State => {
  const queryNetworkId = getQueryNetworkId()
  const initialContext = getInitialContext(queryNetworkId)

  const savedContext = {
    ...initialContext,
    autoConnectChecked: true,
  }

  const savedNetworkId = getSavedNetworkId()
  const savedActiveWallet = getSavedActiveWallet()
  const savedReadOnlyAddress = getSavedReadOnlyAddress()

  const networkId = queryNetworkId || savedActiveWallet && savedNetworkId

  if (networkId) {
    savedContext.config = getConfig(networkId)
    savedContext.connectors = getConnectors(savedContext.config)

    savedContext.networkId = networkId
    savedContext.chainId = savedContext.config.network.chainId as ChainIds

    savedContext.connector.changeChainId?.(savedContext.chainId)

    savedContext.library = new Web3Provider(savedContext.connector as ExternalProvider)
    savedContext.contracts = createContracts(savedContext.library, savedContext.config)
  }

  if (savedActiveWallet) {
    savedContext.activeWallet = savedActiveWallet

    const isMonitorAddress = savedActiveWallet === constants.walletNames.monitorAddress

    if (isMonitorAddress) {
      if (savedReadOnlyAddress) {
        savedContext.connector.setAccount?.(savedReadOnlyAddress)
        savedContext.address = savedReadOnlyAddress
      }
      else {
        savedContext.activeWallet = null
        localStorage.removeItem(constants.localStorageNames.walletName)
      }
    }
  }

  return savedContext
}


export default getSavedContext
