import React from 'react'
import { useIntl } from 'intl'
import { useRouter } from 'next/router'
import { commonMessages } from 'helpers'

import { Box, Flex } from 'components/layout'
import { Text, QuestionIcon } from 'components/ui'

import messages from './messages'


type Titles = Record<string, {
  title: Intl.Message | string
  questionIconLink?: string
  questionIconLabel?: Intl.Message
}>

const titles: Titles = {
  '/': {
    title: messages.staking,
    questionIconLink: 'https://docs.stakewise.io/stakewise-pool',
    questionIconLabel: messages.questionIconLabel,
  },
  '/faq': {
    title: messages.faq,
  },
  '/farm': {
    title: messages.farms,
  },
  '/solo': {
    title: messages.solo,
  },
  '/payments': {
    title: messages.payments,
  },
  '/vestings': {
    title: messages.vesting,
  },
  '/settings': {
    title: commonMessages.settings,
  },
  '/blockdaemon': {
    title: 'Blockdaemon',
  },
  '/update-validators': {
    title: messages.updateValidators,
  },
}

const Title: React.FC = () => {
  const intl = useIntl()
  const { pathname } = useRouter()

  const { title, questionIconLink, questionIconLabel } = titles[pathname] || {}

  if (!title) {
    console.error('Incorrect title in header!')
  }

  return (
    <Flex>
      <Text
        message={title}
        color="default"
        dataTestId="header-text"
        uppercase
      />
      {
        questionIconLink && (
          <Box ml={8} noWrapper>
            <a
              href={questionIconLink}
              target="_blank"
              rel="noreferrer nofollow"
              aria-label={intl.formatMessage(questionIconLabel as Intl.Message)}
              data-testid="header-text-help"
            >
              <QuestionIcon background="blue" withHover />
            </a>
          </Box>
        )
      }
    </Flex>
  )
}


export default Title
