import { useCallback, useState, useMemo, useEffect } from 'react'
import { useForm, Form } from 'formular'
import { openNotification } from 'notifications'
import { parseEther } from '@ethersproject/units'
import { useActions, useConfig, useBalances } from 'hooks'
import { constants, requests, getters, validators, commonMessages } from 'helpers'
import type { ContractTransaction } from '@ethersproject/contracts'

import type { SendingFormProps } from '../SendingForm'

import messages from './messages'


type Input = {
  closeModal: () => void
  whiteListedOnly: boolean
  token: SendingFormProps['token']
  balance: BigNumber
}

type Output = {
  form: Form<Fields>
  isLoading: boolean
  isReadOnlyWallet: boolean
  onSubmit: () => Promise<ContractTransaction | void>
}

type Fields = {
  amount: string
  recipient: string
}

const { required, ethAddress, numberWithDot, sufficientBalance, exclude } = validators

const useSendingForm = (values: Input): Output => {
  const { token, balance, whiteListedOnly, closeModal } = values

  const actions = useActions()
  const [ isLoading, setLoading ] = useState(false)
  const { config, contracts, library, address, activeWallet } = useConfig()

  const {
    fetchAndSetRewardTokenBalance,
    fetchAndSetStakedTokenBalance,
    fetchAndSetSwiseTokenBalance,
  } = useBalances()

  const isReadOnlyWallet = activeWallet === constants.walletNames.monitorAddress

  const form = useForm<Fields>({
    fields: {
      amount: [],
      recipient: [ required, ethAddress, exclude([ address as string ]) ],
    },
  })

  useEffect(() => {
    if (!address) {
      return closeModal()
    }

    // reset form if user change address
    form.unsetValues()
    form.fields.amount.validators = [ required, numberWithDot, sufficientBalance(balance) ]
  }, [ address, balance.toString() ])

  const tokenContract = useMemo(() => {
    if (!contracts) {
      return null
    }

    const tokenContrats = {
      [config.tokens.stakedToken]: contracts.tokens.default.stakedTokenContract,
      [config.tokens.rewardToken]: contracts.tokens.default.rewardTokenContract,
      [constants.tokens.swise]: contracts.tokens.default.swiseTokenContract,
    }

    return tokenContrats[token]
  }, [ config, contracts, token ])

  const fetchers = useMemo(() => ({
    [config.tokens.stakedToken]: fetchAndSetStakedTokenBalance,
    [config.tokens.rewardToken]: fetchAndSetRewardTokenBalance,
    [constants.tokens.swise]: fetchAndSetSwiseTokenBalance,
  }), [ config, fetchAndSetRewardTokenBalance, fetchAndSetStakedTokenBalance, fetchAndSetSwiseTokenBalance ])

  const handleTransaction = useCallback((txHash: string) => {
    openNotification({
      type: 'success',
      text: commonMessages.notifications.sent,
    })

    actions.ui.setBottomLoaderTransaction(`${config.pages.etherscan}/${txHash}`)

    if (library) {
      library.waitForTransaction(txHash)
        .then(() => {
          const fetchAndSetBalance = fetchers[token]

          actions.ui.resetBottomLoader()

          openNotification({
            type: 'success',
            text: commonMessages.notifications.success,
          })

          if (typeof fetchAndSetBalance === 'function') {
            fetchAndSetBalance()
          }
        })
    }

    closeModal()
  }, [ library, config, token, fetchers ])

  const handleSubmit = useCallback(async () => {
    const amount = form.fields.amount.state.value
    const recipient = form.fields.recipient.state.value

    const isValidAmount = getters.getIsInputTokenAmountValid(amount)

    if (!library || !address || !contracts || !tokenContract || !isValidAmount) {
      form.fields.amount.set('0')

      return
    }

    if (whiteListedOnly) {
      const isWhitelisted = await requests.fetchWhitelistedAccounts({
        address: form.fields.recipient.state.value,
        contracts,
      })

      if (!isWhitelisted) {
        form.fields.recipient.setError(commonMessages.disabledMessages.notWhitelisted)

        return
      }
    }

    setLoading(true)

    openNotification({
      type: 'info',
      text: messages.waiting,
    })

    const signer = library.getUncheckedSigner(address)
    const signedContract = tokenContract.connect(signer)

    const value = parseEther(amount)

    try {
      const estimatedGas = await signedContract.estimateGas.transfer(recipient, value)
      const gasLimit = getters.getGasMargin(estimatedGas)

      const { hash } = await signedContract.transfer(recipient, value, { gasLimit })

      handleTransaction(hash)
    }
    catch (_) {
      openNotification({
        type: 'error',
        text: commonMessages.notifications.failed,
      })

      setLoading(false)
    }
  }, [ address, contracts, library, tokenContract ])

  return {
    form,
    isLoading,
    isReadOnlyWallet,
    onSubmit: handleSubmit,
  }
}


export default useSendingForm
