import mixpanel from 'mixpanel-browser'
import localStorage from 'local-storage'

import getPositionsData from '../getters/getPositionsData'
import formatTokenValue from '../modifiers/formatTokenValue'
import protectAnalyticsData from './utils/protectAnalyticsData'
import constants from '../constants'


let isInit = false
const savedCalls: Array<Function> = []
const mixpanelKey = process.env.NEXT_PUBLIC_MIXPANEL_KEY

const getPreventAnalyticsStatus = () => localStorage.getItem<boolean>(constants.localStorageNames.isPreventAnalytics)

const _wrapMethod = (method: Function) => {
  const isPreventAnalytics = getPreventAnalyticsStatus()

  const isBlockAnalytics = (
    typeof window === 'undefined'
    || !mixpanelKey
    || !IS_PROD
    || window.isE2E
    || isPreventAnalytics
  )

  if (isBlockAnalytics) {
    return
  }

  if (!isInit) {
    savedCalls.push(method)
    return
  }

  if (savedCalls.length) {
    savedCalls.forEach((call) => call())
    savedCalls.length = 0
  }

  method()
}

const removeMixpanelUser = () => mixpanel.people?.delete_user()

const _formatToken = (value: string) => {
  const result = formatTokenValue({ value })

  return Number(result.replace(',', ''))
}

type CreateGetPoolPropInput = Pick<Store['farms']['pools'][string], 'token0' | 'token1' | 'projectName'>

const _createGetPoolProp = (values: CreateGetPoolPropInput) => (propName: string) => {
  const { projectName, token0, token1 } = values

  const shortProjectNames: { [key: string]: string } = {
    'Uniswap V3': 'Uni',
  }

  const project = shortProjectNames[projectName] || ''

  return (
    `${project.slice(0, 3)}-${token0}-${token1}-${propName}`
  )
}

type GetPositionsStatusInput = {
  isPartially: boolean
  isParticipating: boolean
}

const _getPositionsStatus = ({ isPartially, isParticipating }: GetPositionsStatusInput) => {
  if (isParticipating) {
    return 'Yes'
  }
  else if (isPartially) {
    return 'Partially'
  }

  return 'No'
}

// -- Methods

const init = () => {
  if (typeof window === 'undefined' || !mixpanelKey) {
    return
  }

  mixpanel?.init(mixpanelKey as string, { 'ip': false })

  isInit = true
}

const setBalances = (balances: Store['account']['balances']) => _wrapMethod(() => {
  const { nativeTokenBalance, stakedTokenBalance, rewardTokenBalance, swiseTokenBalance, swiseDrop } = balances

  mixpanel?.people.set({
    hasSwiseDrop: Boolean(swiseDrop.index),
    ethBalance: _formatToken(nativeTokenBalance),
    rewardEthBalance: _formatToken(rewardTokenBalance),
    stakedEthBalance: _formatToken(stakedTokenBalance),
    swiseTokenBalanceBalance: _formatToken(swiseTokenBalance),
  })
})

type SetUserType = Pick<Store['account'], 'currencySymbol' | 'newsletter'> &{
  address: string
  hasSoloValidators: boolean
  activeWallet: WalletIds | null
}

const setUser = (values: SetUserType) => _wrapMethod(() => {
  const {
    address,
    newsletter,
    activeWallet,
    currencySymbol,
    hasSoloValidators,
  } = protectAnalyticsData(values)

  mixpanel?.identify(address)

  mixpanel?.people.set({
    address,
    newsletter,
    activeWallet,
    currencySymbol,
    hasSoloValidators,
  })
})

type SetFarmsDataInput = {
  config: Config
  pools: Store['farms']['pools']
}

const setFarmsData = (values: SetFarmsDataInput) => _wrapMethod(() => {
  const { pools, config } = values

  Object.keys(pools).forEach((poolAddress) => {
    const { token0, token1, projectName, positions, deposit, rewards, fees } = pools[poolAddress]

    const getPoolProp = _createGetPoolProp({ token0, token1, projectName })

    const proprAPR = getPoolProp('APR')
    const propFeesT0 = getPoolProp(`Fees ${token0}`)
    const propFeesT1 = getPoolProp(`Fees ${token1}`)
    const propDepositT0 = getPoolProp(`Deposit ${token0}`)
    const propDepositT1 = getPoolProp(`Deposit ${token1}`)
    const proprParticipating = getPoolProp('Participating')
    const propRewardsT0 = getPoolProp(`Rewards ${rewards.token0.name}`)
    const propRewardsT1 = getPoolProp(`Rewards ${rewards.token1.name}`)

    const { apr, isPartially, isParticipating } = getPositionsData({
      fiatDeposit: deposit.fiatDeposit,
      poolAddress,
      positions,
      config,
    })

    mixpanel?.people.set({
      [proprAPR]: apr.toFixed(2),
      [propFeesT0]: _formatToken(fees.token0Fees),
      [propFeesT1]: _formatToken(fees.token1Fees),
      [propRewardsT0]: _formatToken(rewards.token0.value),
      [propRewardsT1]: _formatToken(rewards.token1.value),
      [propDepositT0]: _formatToken(deposit.token0Deposit),
      [propDepositT1]: _formatToken(deposit.token1Deposit),
      [proprParticipating]: _getPositionsStatus({ isPartially, isParticipating }),
    })
  })
})

const sendEvent = (message: string, params?: object) => _wrapMethod(() => {
  mixpanel?.track(message, params)
})

const registerProperty = (params: object) => _wrapMethod(() => {
  mixpanel?.register(params)
})


export default {
  init,
  setUser,
  sendEvent,
  setBalances,
  setFarmsData,
  registerProperty,
  removeMixpanelUser,
  getPreventAnalyticsStatus,
}
