export default {
  error: {
    en: 'Failed to fetch your account data. Please try later.',
    ru: 'Не удалось получить данные Вашего аккаунта. Пожалуйста, попробуйте позже.',
    fr: 'Impossible de récupérer les données de votre compte, merci de réessayer plus tard.',
    es: 'Ha habido un error al obtener la data de su cuenta. Intente de nuevo más tarde.',
    pt: 'Falha ao buscar os dados da sua conta. Por favor tente mais tarde',
    de: 'Fehler beim Abruf Deiner Kontodaten. Bitte später nochmal probieren',
    zh: '讀取帳號資料失敗。請稍後再試。',
  },
}
