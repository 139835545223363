export default {
  expired: {
    en: 'Updating',
    ru: 'Загрузка',
    fr: 'Mise à jour',
    es: 'Actualizando',
    pt: 'Atualizando',
    de: 'Aktualisierung',
    zh: '更新中…',
  },
}
