import { useCallback } from 'react'
import { useObjectState } from 'hooks'

import type { PaginationProps } from '../Pagination'


type Input = Pick<PaginationProps, 'items' | 'count'>

type Output = {
  maxPages: number
  currentPage: number
  isDisabledNext: boolean
  isDisabledPrev: boolean
  croppedItems: PaginationProps['items']
  onNextPage: () => void
  onPrevPage: () => void
  onNewPage: (page: number) => void
}

const usePagination = (values: Input): Output => {
const { items, count } = values

  const maxItems = items.length
  const proportion = maxItems / count
  const maxPages = proportion < 1 ? 0 : Math.ceil(maxItems / count)

  const [ { croppedItems, currentPage }, setState ] = useObjectState<{
    croppedItems: PaginationProps['items']
    currentPage: number
  }>({
    croppedItems: [ ...items ].splice(0, count),
    currentPage: 1,
  })

  const handleNextPage = useCallback(() => {
    if (maxPages === currentPage) {
      return
    }

    const nextPage = currentPage + 1
    const nextIndex = currentPage * count
    const nextItems = [ ...items ].splice(nextIndex, count)

    setState({
      croppedItems: nextItems,
      currentPage: nextPage,
    })
  }, [ currentPage ])

  const handlePrevPage = useCallback(() => {
    if (currentPage === 1) {
      return
    }

    const prevPage = currentPage - 1
    const prevIndex = prevPage === 1 ? 0 : prevPage * count - count
    const prevItems = [ ...items ].splice(prevIndex, count)

    setState({
      croppedItems: prevItems,
      currentPage: currentPage - 1,
    })
  }, [ currentPage ])

  const handleNewPage = useCallback((newPage: number) => {
    if (newPage < 0 || newPage > maxPages) {
      return
    }

    const newIndex = newPage === 1 ? 0 : newPage * count - count
    const newItems = [ ...items ].splice(newIndex, count)

    setState({
      croppedItems: newItems,
      currentPage: newPage,
    })
  }, [])

  const isDisabledNext = maxPages === currentPage
  const isDisabledPrev = currentPage === 1

  return {
    maxPages,
    currentPage,
    croppedItems,
    isDisabledNext,
    isDisabledPrev,
    onNewPage: handleNewPage,
    onNextPage: handleNextPage,
    onPrevPage: handlePrevPage,
  }
}


export default usePagination
