export default {
  text: {
    en: 'Add <span>{symbol}</span> to wallet',
    ru: 'Добавить <span>{symbol}</span> в кошелёк',
    fr: 'Ajouter <span>{symbol}</span> au portefeuille',
    es: 'Agregar <span>{symbol}</span> a la cartera',
    pt: 'Adicionar <span>{symbol}</span> a carteira',
    de: 'Füge <span>{symbol}</span> zur Wallet hinzu',
    zh: '增加 <span>{symbol}</span> 到錢包',
  },
  notifications: {
    error: {
      communicating: {
        en: 'Having trouble communicating with your wallet. Please try again.',
        ru: 'Проблема с подключением к Вашему кошельку. Пожалуйста, попробуйте снова.',
        fr: 'Problème lors de la communication avec le portefeuille. Merci de réessayer.',
        es: 'Hubo un error al comunicarse con su cartera. Por favor intente de nuevo.',
        pt: 'Tendo problemas para se comunicar con sua carteira. Por favor, tente novamente',
        de: 'Probleme bei der Kommunikation mit Deiner Wallet. Bitte versuche es erneut',
        zh: '與您的錢包通訊失敗。請重新嘗試。',
      },
      adding: {
        en: 'Having trouble adding {symbol} token to your wallet. Please try again.',
        ru: 'Проблема с добавлением {symbol} токена в Ваш кошелёк. Пожалуйста, попробуйте снова.',
        fr: 'Problème lors de l\'ajout du jeton {symbol} au portefeuille. Merci de réessayer.',
        es: 'Hubo un error al agregar el token {symbol} a su cartera. Por favor intente de nuevo.',
        pt: 'Tendo problemas para adicionar {symbol} token a sua carteira. Por favor, tente novamente.',
        de: 'Probleme beim Hinzufügen des {symbol} Tokens in Deine Wallet. Bitte versuche es erneut',
        zh: '新增{symbol}代幣至您的錢包失敗。請重新嘗試。',
      },
    },
    success: {
      en: 'Added {symbol} token to your wallet',
      ru: 'Токен {symbol} добавлен в Ваш кошелёк',
      fr: 'Jeton {symbol} ajouté au portefeuille',
      es: 'El token {symbol} se ha agregado a tu cartera',
      pt: '{symbol} token adicionado a sua carteira',
      de: '{symbol} Token erfolgreich zur Wallet hinzugefügt',
      zh: '{symbol}代幣已新增到您的錢包。',
    },
    waiting: {
      en: 'Waiting for a token approval in your wallet',
      ru: 'Ожидаем одобрения токена в Вашем кошельке',
      fr: 'Attente de l\'autorisation du jeton par votre portefeuille',
      es: 'Esperando la aprobación de un token en su cartera',
      pt: 'Aguardando uma aprovação de token em sua carteira',
      de: 'Warten auf eine Token Zustimmung in Deiner Wallet',
      zh: '等待您的錢包允許代幣使用授權',
    },
  },
}
