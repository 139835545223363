export default {
  // gnosis chain
  gno: 'GNO',
  mgno: 'mGNO',
  sgno: 'sGNO',
  rgno: 'rGNO',
  xdai: 'xDAI',

  // Harbour
  'seth-h': 'sETH-h',
  'reth-h': 'rETH-h',

  // default
  eth: 'ETH',
  weth: 'WETH',
  seth2: 'sETH2',
  reth2: 'rETH2',
  swise: 'SWISE',

  // extra
  dai: 'DAI',
  steth: 'stETH',
} as const
