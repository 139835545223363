import React from 'react'

import { Box } from 'components/layout'

import MetaMaskDownloadLink from './MetaMaskDownloadLink/MetaMaskDownloadLink'
import MonitorAddressLink from './MonitorAddressLink/MonitorAddressLink'
import WalletConnectLink from './WalletConnectLink/WalletConnectLink'
import MetaMaskLink from './MetaMaskLink/MetaMaskLink'
import CoinbaseLink from './CoinbaseLink/CoinbaseLink'
import PortisLink from './PortisLink/PortisLink'
import LedgerLink from './LedgerLink/LedgerLink'
import TallyLink from './TallyLink/TallyLink'
import ZenGoLink from './ZenGoLink/ZenGoLink'


const List: React.FC = () => {
  const hasInjectedProvider = Boolean(typeof window !== 'undefined' && (window?.web3 || window?.ethereum))

  return (
    <Box relative>
      <MetaMaskLink />
      <ZenGoLink />
      <WalletConnectLink />
      <CoinbaseLink />
      <LedgerLink />
      <TallyLink />
      <PortisLink />
      <MonitorAddressLink />
      {
        !hasInjectedProvider && (
          <MetaMaskDownloadLink />
        )
      }
    </Box>
  )
}


export default React.memo(List)
