// eslint-disable-next-line no-restricted-imports
import { FixedNumber, BigNumber } from '@ethersproject/bignumber'


type Input = InitialData.System.FiatRates & {
  swiseUsdRateBN: InitialData.System.RestData['swiseUsdRateBN']
}

const _formatPrice = (valueFN: FixedNumber, rateFN: FixedNumber): number => {
  if (!valueFN || !rateFN) {
    return 0
  }

  const result = valueFN
    .divUnsafe(rateFN)
    .toUnsafeFloat()
    .toFixed(3)

  return Number(result)
}

type CreateGetPricesInput = {
  usdRateFN: FixedNumber
  eurUsdRateFN: FixedNumber
  gbpUsdRateFN: FixedNumber
}

const _createGetPrices = ({ usdRateFN, eurUsdRateFN, gbpUsdRateFN }: CreateGetPricesInput) => (
  (value: FixedNumber | undefined) => {
    if (!value) {
      return {
        USD: 0,
        EUR: 0,
        GBP: 0,
      }
    }

    return {
      USD: _formatPrice(value, usdRateFN),
      EUR: _formatPrice(value, eurUsdRateFN),
      GBP: _formatPrice(value, gbpUsdRateFN),
    }
  }
)

const _converEthRateToUsd = (valueBN: BigNumber, ethUsdRateBN: BigNumber) => {
  if (!valueBN || !ethUsdRateBN) {
    return
  }

  const decimal8 = valueBN.div(String(1e+10)) // convert 18 decimals to 8 decimals
  const ratio = decimal8.mul(ethUsdRateBN)

  return FixedNumber.from(ratio.div(String(1e+8))) // Remove extra decimals after multiplication
}

const getFiatPrices = (values: Input): Store['system']['fiatPrices'] => {
  const {
    // x 8 decimals rate
    ethUsdRateBN,
    eurUsdRateBN,
    gbpUsdRateBN,
    gnoUsdRateBN,
    swiseUsdRateBN,

    // x 18 decimals rate
    ethXdaiRateBN,
  } = values

  const getPrices = _createGetPrices({
    usdRateFN: FixedNumber.from(100_000_000),
    eurUsdRateFN: eurUsdRateBN && FixedNumber.from(eurUsdRateBN),
    gbpUsdRateFN: gbpUsdRateBN && FixedNumber.from(gbpUsdRateBN),
  })

  const ethUsdRateFN = ethUsdRateBN && FixedNumber.from(ethUsdRateBN)
  const gnoUsdRateFN = gnoUsdRateBN && FixedNumber.from(gnoUsdRateBN)
  const swiseUsdRateFN = swiseUsdRateBN && FixedNumber.from(swiseUsdRateBN)
  const xdaiUsdRateFN = _converEthRateToUsd(ethXdaiRateBN, ethUsdRateBN)

  const ethPrices = getPrices(ethUsdRateFN)
  const gnoPrices = getPrices(gnoUsdRateFN)
  const xdaiPrices = getPrices(xdaiUsdRateFN)
  const swisePrices = getPrices(swiseUsdRateFN)
  const mgnoPrices = getPrices(gnoUsdRateFN && gnoUsdRateFN.divUnsafe(FixedNumber.from(32))) // GNO = mGNO x 32

  return {
    GNO: gnoPrices,
    ETH: ethPrices,
    DAI: xdaiPrices,
    mGNO: mgnoPrices,
    SWISE: swisePrices,
    isCalculated: true,
  }
}


export default getFiatPrices
