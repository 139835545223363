import { formatEther } from '@ethersproject/units'
import { analytics, constants } from 'helpers'
import { supportedNetworks } from 'config'
import localStorage from 'local-storage'
import request from 'request-module'

import initialRequestQuery from 'graphql/main/initialRequest.graphql'


type Output = InitialData.System.GqlData

const _getNextUpdate = (time: number, config: Config) => {
  // oraclesNextUpdate are seconds, you need to get milliseconds and add 12 hours for get next update (1 hour in testnet).
  const hours = config.network.id === supportedNetworks.goerli.id ? 1 : 12
  const lastUpdateTime = Number((time || 0) * 1000)
  const additionalTime = hours * 60 * 60 * 1000

  return lastUpdateTime + additionalTime
}

const fetchSystemGqlData = async (config: Config): Promise<Output> => {
  const localStorageName = `${constants.localStorageNames.mocks.totalStakingAmount}-${config.network.id}`

  try {
    const response = await request.gql(config.api.mainSubgraph, initialRequestQuery, {
      poolAddress: config.addresses.base.pool.toLowerCase(),
    })

    const operatorAllocations = response.operatorAllocations
    const totalStakingAmount = formatEther(response.pool?.totalStaked || '0')
    const oraclesNextUpdate = _getNextUpdate(response.rewardEthTokens[0]?.updatedAtTimestamp, config)

    if (Number(totalStakingAmount)) {
      localStorage.setItem(localStorageName, totalStakingAmount)
    }

    return {
      operatorAllocations,
      totalStakingAmount,
      oraclesNextUpdate,
    }
  }
  catch (error) {
    analytics.sentry.exception('Fetch system gql data error', error as Error)

    const totalStakingAmount = localStorage.getItem<string>(localStorageName) || '0'

    return {
      totalStakingAmount,
      oraclesNextUpdate: 0,
      operatorAllocations: [],
    }
  }
}


export default fetchSystemGqlData
