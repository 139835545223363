import request from 'request-module'
import { analytics } from 'helpers'


const fetchApy = async (id: string) => {
  let apy = 0

  if (id === 'mainnet') {
    const response = await request.post<{ data: { v2Pools: { apy: string }[] } }>({
      url: 'https://mainnet-graph.stakewise.io/subgraphs/name/stakewise/stakewise',
      data: {
        query: `{
          v2Pools {
            apy
          }
        }`,
      },
    })

    apy = Number(response?.data?.v2Pools?.find((item) => item['apy'])?.apy || 0)
  }
  else {
    const response = await request.post<{ data: { vault: { apy: string } } }>({
      url: 'https://gnosis-graph.stakewise.io/subgraphs/name/stakewise/stakewise',
      data: {
        query: `{
          vault(id: "0x4b4406ed8659d03423490d8b62a1639206da0a7a") {
            apy
          }
        }`,
      },
    })

    apy = Number(response?.data?.vault?.apy || 0)
  }

  return apy
}

const fetchValidatorsAPR = async (config: Config): Promise<InitialData.System.RestData['validatorsAPR']> => {
  try {
    const apy = await fetchApy(config.network.id)

    return apy
  }
  catch (error) {
    analytics.sentry.exception('REST API: fetch validators apy', error as Error)

    return Promise.reject(error)
  }
}


export default fetchValidatorsAPR
