import React from 'react'
import Link from 'next/link'
import { useDevice } from 'device'

import { Text, Icon } from 'components/ui'
import { Box, Flex } from 'components/layout'

import messages from './messages'
import s from './Links.module.scss'


const year = new Date().getFullYear()
const termsDataTestId = 'footer-terms-link'
const gitHubDataTestId = 'footer-github-link'
const stakeWiseDataTestId = 'footer-stakewise-link'

const Links: React.FC = () => {
  const { isMobile } = useDevice()

  if (isMobile) {
    return (
      <Box className={s.container} alignRight>
        <Box pt={8}>
          <Flex justify="end">
            <Icon name="fa-brands fa-github" color="default" size={14} />
            <Box ml={8}>
              <a
                href="https://github.com/stakewise"
                target="_blank"
                data-testid={gitHubDataTestId}
                rel="noreferrer nofollow"
              >
                <Text message="GitHub" size={12} />
              </a>
            </Box>
          </Flex>
        </Box>
        <Box mt={8} pb={8}>
          <a
            href="https://stakewise.io/terms-and-conditions"
            data-testid={termsDataTestId}
            rel="noreferrer nofollow"
            target="_blank"
          >
            <Text message={messages.terms} size={12} />
          </a>
        </Box>
        <Flex justify="end">
          <span>© {year} &nbsp;</span>
          <Link href="/" passHref>
            <a data-testid={stakeWiseDataTestId}>
              <Text message="StakeWise Labs" size={12} />
            </a>
          </Link>
        </Flex>
      </Box>
    )
  }

  return (
    <Flex className={s.container} justify="end">
        <a
          href="https://stakewise.io/terms-and-conditions"
          data-testid={termsDataTestId}
          rel="noreferrer nofollow"
          target="_blank"
        >
          <Text message={messages.terms} size={14} />
        </a>
      <Box ph={8}>
        <span>|</span>
      </Box>
      <Flex justify="start">
        <Icon name="fa-brands fa-github" color="default" size={14} />
        <Box ml={8}>
          <a
            href="https://github.com/stakewise"
            target="_blank"
            rel="noreferrer nofollow"
            data-testid={gitHubDataTestId}
          >
            <Text message="GitHub" size={14} />
          </a>
        </Box>
      </Flex>
      <Box ph={8}>
        <span>|</span>
      </Box>
      <span>© {year} &nbsp;</span>
      <Link href="/" passHref>
        <a data-testid={stakeWiseDataTestId}>
          <Text message="StakeWise Labs" size={14} />
        </a>
      </Link>
    </Flex>
  )
}

export default React.memo(Links)
