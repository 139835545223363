import supportedNetworks from 'config/helpers/supportedNetworks'
import type { ContractInterface } from '@ethersproject/contracts'

import { PoolAbi, GnosisPoolAbi } from './abis'
import createContract from './createContract'
import type {
  GnosisPoolAbi as GnosisPoolType,
  PoolAbi as PoolType,
} from './types'


const gnosisId = supportedNetworks.gnosis.id
const goerliId = supportedNetworks.goerli.id
const mainnetId = supportedNetworks.mainnet.id
const harbourGoerliId = supportedNetworks.harbourGoerli.id
const harbourMainnetId = supportedNetworks.harbourMainnet.id

const _createContractCreator = (config: Config, library: Library) => (
  <T extends unknown>(networkId: NetworkIds, abi: ContractInterface): T | null => {
    if (networkId === config.network.id) {
      return createContract<T>(config.addresses.base.pool, abi, library)
    }

    return null
  }
)

const createPoolContracts = (library: Library, config: Config) => {
  const create = _createContractCreator(config, library)

  const gnosisContract = create<GnosisPoolType>(gnosisId, GnosisPoolAbi)
  const goerliContract = create<PoolType>(goerliId, PoolAbi)
  const mainnetContract = create<PoolType>(mainnetId, PoolAbi)
  const harbourGoerliContract = create<PoolType>(harbourGoerliId, PoolAbi)
  const harbourMainnetContract = create<PoolType>(harbourMainnetId, PoolAbi)

  return {
    [gnosisId]: gnosisContract,
    [goerliId]: goerliContract,
    [mainnetId]: mainnetContract,
    [harbourGoerliId]: harbourGoerliContract,
    [harbourMainnetId]: harbourMainnetContract,
  }
}


export default createPoolContracts
