import React from 'react'
import cx from 'classnames'
import Image from 'next/image'
import { constants } from 'helpers'

import { Flex } from 'components/layout'

import s from './RoundIcon.module.scss'


const sizes = [ 52, 70, 75 ] as const

export type RoundIconProps = {
  className?: string
  shadow?: boolean
  token?: Tokens
  size?: typeof sizes[number]
}

const RoundIcon: React.FC<RoundIconProps> = (props) => {
  const { className, children, token, shadow, size = 70 } = props

  const containerClassName = cx(className, s.container, {
    [s[`size-${size}`]]: size,
    [s.shadow]: shadow,
  })

  return (
    <Flex className={containerClassName} align="center" justify="center">
      {
        token ? (
          <Image src={constants.tokensImages[token]} alt={token} />
        ) : (
          children
        )
      }
    </Flex>
  )
}


export default RoundIcon
