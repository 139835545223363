import React from 'react'
import cx from 'classnames'
import Image from 'next/image'
import { constants } from 'helpers'

import { Text } from 'components/ui'
import { Box, Flex } from 'components/layout'

import s from './Button.module.scss'


type ButtonType = {
  className?: string
  title: string
  disabled?: boolean
  onClick: () => void
}

const Button: React.FC<ButtonType> = (props) => {
  const { className, title, disabled, onClick } = props

  const buttonClassNames = cx(className, s.button, {
    [s.disabled]: disabled,
  })

  const image = title === 'Gnosis'
    ? constants.tokensImages.GNO
    : constants.tokensImages.ETH

  return (
    <button
      className={buttonClassNames}
      data-testid={title}
      type="button"
      onClick={disabled ? undefined : onClick }
    >
      <Flex justify="start">
        <Flex
          className={s.imageContainer}
          justify="center"
          align="center"
        >
          <Image
            src={image}
            height={16}
            width={16}
            alt=""
          />
        </Flex>
        <Box className={s.textContainer}>
          <Text
            message={title}
            color="default"
            size={16}
          />
        </Box>
      </Flex>
    </button>
  )
}


export default React.memo(Button)
