import React from 'react'
import { useIntl } from 'intl'

import useLanguage from './util/useLanguage'
import Select from '../components/Select/Select'

import messages from './messages'
import s from './LanguageSelect.module.scss'


const LanguageSelect: React.FC = () => {
  const intl = useIntl()
  const { selectedOption, languageOptions, handleChange } = useLanguage()

  if (!languageOptions.length || !selectedOption) {
    return null
  }

  const label = intl.formatMessage(messages.language)

  return (
    <Select
      className={s.select}
      label={label}
      iconSize={22}
      options={languageOptions}
      dataTestId="footer-language-switcher"
      selectedOption={selectedOption}
      onChange={handleChange}
    />
  )
}


export default React.memo(LanguageSelect)
