import { openNotification } from 'notifications'
import { Contract } from '@ethersproject/contracts'
import type { ContractInterface } from '@ethersproject/contracts'

import messages from './messages'


const createContract = <T extends unknown>(
  address: string,
  abi: ContractInterface,
  library: Library
) : T | null => {
  if (!address) {
    return null
  }

  const contract = new Contract(address, abi, library) as T

  // In some cases, we can block all transactions where a signer is needed
  const isEmergencyMode = process.env.NEXT_PUBLIC_EMERGENCY_MODE === 'true'

  if (isEmergencyMode) {
    // @ts-ignore
    contract.connect = () => {
      openNotification({
        type: 'warning',
        text: messages.transactionsStopped,
      })

      return contract
    }
  }

  return contract
}


export default createContract
