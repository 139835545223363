import React from 'react'
import { modalWrapper } from 'modal'

import { Modal } from 'components/feedback'
import { Box, Flex } from 'components/layout'
import { Text, StayNotifiedLink } from 'components/ui'

import TransactionsList from './TransactionsList/TransactionsList'
import useTransactions from './util/useTransactions'

import messages from './messages'
import s from './TransactionsModal.module.scss'


export const [ TransactionsModal, openTransactionsModal ] = (
  modalWrapper(UNIQUE_FILE_ID, (props) => {
    const { closeModal } = props

    const { transactions, isLoading } = useTransactions(closeModal)

    return (
      <Modal className={s.modal} analyticsName="transactions" closeModal={closeModal}>
        <Box pv={16}>
          <Text
            message={messages.title}
            color="default"
            align="center"
            size={24}
          />
        </Box>
        <Box className={s.transactions} mt={24}>
          {
            isLoading ? (
              <Box className={s.loader} p={80} />
            ) : (
              <TransactionsList items={transactions} />
            )
          }
        </Box>
        <Box mt={32} noWrapper>
          <Flex justify="center">
            <StayNotifiedLink callback={closeModal} />
          </Flex>
        </Box>
      </Modal>
    )
  })
)
