import { SafeAppProvider } from '@gnosis.pm/safe-apps-provider'
import SafeAppsSDK, { Opts, SafeInfo } from '@gnosis.pm/safe-apps-sdk'
import { AbstractConnectorArguments } from 'modules/custom-connectors/types'

import AbstractConnector from './AbstractConnector'


class InjectedConnector extends AbstractConnector {

  private readonly sdk: SafeAppsSDK
  private safe: SafeInfo | undefined
  private provider: SafeAppProvider | undefined

  constructor(props: Opts & AbstractConnectorArguments) {
    super(props)

    this.sdk = new SafeAppsSDK(props)
  }

  async isSafeApp(): Promise<boolean> {
    // check if we're in an iframe
    if (window?.parent === window) {
      return false
    }

    const safe = await Promise.race([
      this.getSafeInfo(),
      new Promise<undefined>((resolve) => setTimeout(resolve, 300)),
    ])

    return Boolean(safe)
  }

  async getSafeInfo(): Promise<SafeInfo> {
    if (!this.safe) {
      this.safe = await this.sdk.safe.getInfo()
    }

    return this.safe
  }

  async getProvider(): Promise<SafeAppProvider> {
    if (!this.provider) {
      const safe = await this.getSafeInfo()
      this.provider = new SafeAppProvider(safe, this.sdk)
    }

    return this.provider
  }

  async activate() {
    const runningAsSafeApp = await this.isSafeApp()

    if (!runningAsSafeApp) {
      throw new Error('The app is loaded outside safe context')
    }

    const [ provider, chainId, account ] = await Promise.all([
      this.getProvider(),
      this.getChainId(),
      this.getAccount(),
    ])

    return {
      provider,
      chainId,
      account,
    }
  }

  async getChainId() {
    const provider = await this.getProvider()

    return provider.chainId
  }

  async getAccount() {
    const safe = await this.getSafeInfo()

    return safe.safeAddress
  }

  deactivate() {
    return
  }
}


export default InjectedConnector
