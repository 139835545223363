import React from 'react'
import { useConfig } from 'hooks'
import { constants } from 'helpers'

import { Icon } from 'components/ui'
import { openMonitorAddressModal } from '../../../MonitorAddressModal/MonitorAddressModal'

import ListItem from '../components/ListItem/ListItem'


const walletName = constants.walletNames.monitorAddress

const MonitorAddressLink: React.FC = (props) => {
  const { connectors } = useConfig()

  const { name } = connectors[walletName]

  const imageNode = (
    <Icon name="fa-solid fa-eye" size={20} color="info" />
  )

  return (
    <ListItem
      title={name}
      leftNode={imageNode}
      onClick={openMonitorAddressModal}
    />
  )
}


export default React.memo(MonitorAddressLink)
