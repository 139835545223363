/* eslint-disable max-len */
import { WETH9 } from '@uniswap/sdk-core'
import constants from 'helpers/constants'


const chainId = 1
const isTheGraph = process.env.NEXT_PUBLIC_MAINNET_RUN_THEGRAPH === 'true'

// ATTN Addresses must be in check sum format!

export default {
  api: {
    rest: IS_PROD
      ? 'https://api.stakewise.io'
      : 'https://stage-api.stakewise.io',
    mainSubgraph: isTheGraph
      ? 'https://gateway-arbitrum.network.thegraph.com/api/007fa8d2e10d5b2394388e4ed945d8ab/subgraphs/id/6N88GLujsJMFbimxUu787nUAv4jxP6uqFXYfeMUPMsDV'
      : 'https://graph.stakewise.io/subgraphs/name/stakewise/stakewise',
    uniswapSubgraph: isTheGraph
      ? 'https://gateway-arbitrum.network.thegraph.com/api/007fa8d2e10d5b2394388e4ed945d8ab/subgraphs/id/EAPBWjcN1MM5ryJc1yi3EhntUR8EHfkuyoYSsr9J2ze1'
      : 'https://graph.stakewise.io/subgraphs/name/stakewise/uniswap-v3',
  },
  network: {
    chainId,
    isMain: true,
    id: 'mainnet',
    name: 'Ethereum',
    hexadecimalChainId: '0x1',
    blockExplorerUrls: 'https://etherscan.io',
    url: process.env.NEXT_PUBLIC_MAINNET_NETWORK_URL || '',
    nativeCurrency: {
      symbol: constants.tokens.eth,
      name: 'Ethereum',
      decimals: 18,
    },
  },
  pages: {
    etherscan: 'https://etherscan.io/tx',
    uniWethToStakedEthPoolNewPosition: 'https://app.uniswap.org/#/add/ETH/0xFe2e637202056d30016725477c5da089Ab0A043A/3000',
    uniSwiseToStakedEthPoolNewPosition: 'https://app.uniswap.org/#/add/0x48C3399719B582dD63eB5AADf12A40B4C3f52FA2/0xFe2e637202056d30016725477c5da089Ab0A043A/3000',
    uniRewardEthToStakedEthPoolNewPosition: 'https://app.uniswap.org/#/add/0x20BC832ca081b91433ff6c17f85701B6e92486c5/0xFe2e637202056d30016725477c5da089Ab0A043A/500',
    uniEthToSwisePoolNewPosition: 'https://app.uniswap.org/add/0x48c3399719b582dd63eb5aadf12a40b4c3f52fa2/ETH/3000?minPrice=0.0000000000000000000000000000000000000029543&maxPrice=338490000000000000000000000000000000000',
  },
  settings: {
    minimalAPR: 3.5,
    maintainerFee: 1000,
    rewardUniswapFee: 500,
  },
  addresses: {
    tokens: {
      default: {
        swise: '0x48C3399719B582dD63eB5AADf12A40B4C3f52FA2',
        staked: '0xFe2e637202056d30016725477c5da089Ab0A043A',
        reward: '0x20BC832ca081b91433ff6c17f85701B6e92486c5',
        deposit: WETH9[chainId].address,
        alternativeDeposit: '',
      },
      special: {
        dai: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
        steth: '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84',
      },
    },
    base: {
      pool: '0xC874b064f465bdD6411D45734b56fac750Cda29A',
      oracles: '0x2f1C5E86B13a74f5A6E7B4b35DD77fe29Aa47514',
      multicall: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
      merkleDrop: '0x2AAB6822a1a9f982fd7b0Fe35A5A5b6148eCf4d5',
      whiteListManager: '',
      merkleDistributor: '0xA3F21010e8b9a3930996C8849Df38f9Ca3647c20',
      vestingEscrowFactory: '0x7B910cc3D4B42FEFF056218bD56d7700E4ea7dD5',
      soloPaymentsRecipient: '0x51a5b4aD57255ebC52d7377e288998C16B3c3C04',
    },
    pools: {
      uniswap: {
        stakedTokenNativeToken: '0x7379e81228514a1D2a6Cf7559203998E20598346',
        stakedTokenRewardToken: '0xa9ffb27d36901F87f1D0F20773f7072e38C5bfbA',
        stakedTokenSwiseToken: '0x992f534fcc87864875224d142d6Bf054B1882160',
        ethSwiseToken: '0x2aF41D838763E3c5cad6AdC111af9c5dA19B9AFa',
      },
      curve: {
        stakedTokenRewardToken: '',
        gaugeStakedTokenRewardToken: '',
        stakedTokenDepositToken: '',
        gaugeStakedTokenDepositToken: '',
        stakedTokenLidoStakedToken: '0xE95E4c2dAC312F31Dc605533D5A4d0aF42579308',
        gaugeStakedTokenLidoStakedToken: '0xeCb860e54E33FEA8fAb5B076734e2591D1A9ebA4',
      },
    },
    helpers: {
      uniswap: {
        quoter:  '0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6',
        router:  '0xE592427A0AEce92De3Edee1F18E0157C05861564',
        positionManager: '0xC36442b4a4522E871399CD717aBDD847Ab11FE88',
      },
    },
  },
  tokens: {
    nativeToken: constants.tokens.eth,
    depositToken: constants.tokens.eth,
    rewardToken: constants.tokens.reth2,
    stakedToken: constants.tokens.seth2,
    alternativeDepositToken: constants.tokens.eth,
  },
} as const
