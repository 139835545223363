import { useEffect, useState } from 'react'

import useScriptsLoader from './useScriptsLoader'


type State = {
  token: string | null
  error: string | null
  isFetching: boolean
}

const key = IS_PROD
  ? '6Lex4jcaAAAAAGAsEdB0EFC5b4jnLZADmTE5GqXk'
  : '6LcM9KsdAAAAAI_NjqGhWIEv5rLB-hk2MJeJ5Caz'

const useRecaptcha = (action: string) => {
  const [ { token, error, isFetching }, setState ] = useState<State>({
    token: null,
    error: null,
    isFetching: true,
  })

  const { isScriptLoaded } = useScriptsLoader([ `https://www.google.com/recaptcha/api.js?render=${key}` ], {
    async: true,
  })

  useEffect(() => {
    if (isScriptLoaded) {
      window.grecaptcha.ready(async () => {
        try {
          const token = await window.grecaptcha.execute(key, { action })

          setState({ token, error: null, isFetching: false })
        }
        catch (error) {
          const errorMessage = (error as Error)?.message || 'Failed to fetch reCAPTCHA token'

          console.error(errorMessage)

          setState({
            token: null,
            error: errorMessage,
            isFetching: false,
          })
        }
      })
    }
  }, [ isScriptLoaded, action ])

  return {
    error,
    reCaptchaToken: token,
    isReCaptchaFetching: isFetching,
  }
}


export default useRecaptcha
