import React from 'react'
import cx from 'classnames'

import Cell from './Cell/Cell'
import type { GridCellProps } from './Cell/Cell'

import s from './Grid.module.scss'


export const aligns = [ 'center', 'start', 'end', 'baseline', 'stretch' ] as const
export const justifies = [ 'between', 'center', 'start', 'end' ] as const
export const columnsSizes = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ] as const
export const gutterSizes = [ 4, 8, 12, 16, 24, 32, 48 ] as const

export type ColumnsSize = typeof columnsSizes[number]
export type GutterSize = typeof gutterSizes[number]
export type Justify = typeof justifies[number]
export type Align = typeof aligns[number]

export type GridProps = {
  className?: string
  columns?: ColumnsSize
  gutter?: GutterSize
  justify?: Justify
  noWrap?: boolean
  align?: Align
}

type GridComponent = React.FC<GridProps> & {
  Cell: React.FC<GridCellProps>
}

const Grid: GridComponent = (props) => {
  const {
    children, className, columns = 1, gutter,
    align = 'start', justify = 'start', noWrap,
  } = props

  const rootClassName = cx(className, s.grid, {
    [s[`justify-${align}`]]: justify,
    [s[`gutter-${gutter}`]]: gutter,
    [s[`align-${align}`]]: align,
    [s.flexWrap]: !noWrap,
  })

  return (
    <div className={rootClassName} role="list">
      {
        React.Children.map<any, React.ReactNode>(children, (child) => {
          if (child) {
            const cellProps = { columns, gutter } as { columns: ColumnsSize, gutter: GutterSize }

            if (React.isValidElement(child) && child.type === Cell) {
              return React.cloneElement(child, cellProps)
            }

            return (
              <Grid.Cell {...cellProps}>
                {child}
              </Grid.Cell>
            )
          }
        })
      }
    </div>
  )
}

Grid.Cell = Cell


export default Grid
