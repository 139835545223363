import { useCallback, useMemo, useRef } from 'react'
import { commonMessages } from 'helpers'
import { openNotification } from 'notifications'
import { useConfig, useScriptsLoader, useStore, useBalances } from 'hooks'


const storeSelector = (store: Store) => ({
  currency: store.account.currency,
})

const useBuyNativeToken = () => {
  const { address, isMainnet } = useConfig()

  const { isScriptLoaded } = useScriptsLoader([ 'https://packages.gem.co/gem-init.js' ], {
    async: true,
    skip: !isMainnet,
  })

  const gem = useRef<any>()
  const { currency } = useStore(storeSelector)
  const { fetchAndSetNativeTokenBalance } = useBalances()

  const onrampConfig = useMemo(() => ({
    intent: 'buy',
    partnerName: 'StakeWise',
    defaultFiat: currency.toLowerCase(),
    environment: IS_PROD ? 'production' : 'sandbox',
    partnerIconUrl: 'https://app.stakewise.io/logo512.png',
    apiKey: '52f5a6217f1596454da563a1b5986324016e4bc15557c13d4fb2e9e30f6ab33f',
    wallets: [
      {
        asset: 'eth',
        address: address,
      },
    ],
    onEvent: (event: any) => {
      // @ts-ignore
      if (event.eventType === Gem.events.SUCCESS) {
        openNotification({
          type: 'success',
          text: commonMessages.notifications.success,
        })

        fetchAndSetNativeTokenBalance()
      }
    },
  }), [ address, currency ])

  const buyNativeToken = useCallback(() => {
    if (isScriptLoaded) {
      // @ts-ignore
      gem.current = new Gem.Onramp(onrampConfig)
      gem.current.open()
    }
  }, [ isScriptLoaded, onrampConfig ])

  // TODO remove blocker (wait answer from Jordan)
  return null // isScriptLoaded ? buyNativeToken : null
}


export default useBuyNativeToken
