import type { Log } from '@ethersproject/abstract-provider'


const sortEvents = (firstLog: Log, secondLog: Log) => {
  if (firstLog.blockNumber > secondLog.blockNumber) {
    return 1
  }

  if (firstLog.blockNumber < secondLog.blockNumber) {
    return -1
  }

  if (firstLog.transactionIndex > secondLog.transactionIndex) {
    return 1
  }

  if (firstLog.transactionIndex < secondLog.transactionIndex) {
    return -1
  }

  if (firstLog.logIndex > secondLog.logIndex) {
    return 1
  }

  if (firstLog.logIndex < secondLog.logIndex) {
    return -1
  }

  return 0
}


export default sortEvents
