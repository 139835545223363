import React from 'react'

import { Dropdown } from 'components/ui'

import List from './List/List'
import Button from './Button/Button'

import s from './ConnectButton.module.scss'


const ConnectButton: React.FC = () => (
  <Dropdown
    className={s.dropdown}
    controlNode={(isActive) => (
      <Button isActive={isActive} />
    )}
  >
    <List />
  </Dropdown>
)


export default React.memo(ConnectButton)
