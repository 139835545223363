export default {
  transactionsStopped: {
    en: 'Transactions are temporarily stopped. Technical work is underway. Please try later.',
    ru: 'Транзакции временно остановлены. Ведутся технические работы. Пожалуйста, попробуйте позже.',
    fr: 'Les transactions sont temporairement désactivées. Intervention en cours. Merci de réessayer plus tard.',
    es: 'Las transacciones están temporalmente paradas. Estamos llevando a cabo unos cambios técnicos. Pruebe mas tarde.',
    pt: 'As transações são interrompidas temporariamente. Trabalho técnico está em andamento. Por favor tente mais tarde',
    de: 'Momentan keine Transaktionen möglich. Wir arbeiten bereits dran. Versuche es später nochmal.',
  },
}
