const getMainnetData = (): GetNodeOperators.Data => {
  const operators = {
    'Finoa': [ '0xfe26832d3580e0ade4813f9e60e7c17b45e92cba' ],
    'StakeWise Labs': [
      '0x102f792028a56f13d6d99ed4ec8a6125de98582a',
      '0x5fc60576b92c5ce5c341c43e3b2866eb9e0cddd1',
      '0xf91aa4a655b6f43243ed4c2853f3508314daa2ab',
    ],
    'CryptoManufaktur': [ '0x59ecf48345a221e0731e785ed79ed40d0a94e2a5' ],
    'Deutsche Telekom': [ '0x01f26d7f195a37d368cb772ed75ef70dd29700f5' ],
  }

  const australia = [
    `${operators['CryptoManufaktur'][0]}-1`,
    `${operators['CryptoManufaktur'][0]}-2`,
  ]

  const europe: string[] = [
    `${operators['StakeWise Labs'][1]}-0`,
    `${operators['StakeWise Labs'][1]}-1`,
    `${operators['StakeWise Labs'][1]}-2`,
    `${operators['StakeWise Labs'][2]}-1`,
    `${operators['Deutsche Telekom'][0]}-1`,
  ]

  const asia = [
    `${operators['Finoa']}-1`,
  ]

  const northAmerica: string[] = [
    `${operators['StakeWise Labs'][0]}-0`,
  ]

  return {
    operators,
    asia,
    europe,
    australia,
    northAmerica,
  }
}


export default getMainnetData
