import React, { useState, useEffect, useRef } from 'react'
import { useStore } from 'hooks'

import { Box, Flex } from 'components/layout'
import { SmallLoader, Icon, Text } from 'components/ui'

import s from './BottomLoader.module.scss'


const storeSelector = (store: Store) => store.ui.bottomLoader

const BottomLoader: React.FC= () => {
  const bottomLoader = useStore(storeSelector)
  const [ data, setData ] = useState<Store['ui']['bottomLoader']>(null)

  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (bottomLoader?.content) {
      setData(bottomLoader)
    }
    else if (!bottomLoader?.content && data && containerRef.current) {
      containerRef.current.classList.add(s.closed)
      setTimeout(() => setData(null), 300) // For animation
    }
  }, [ data, bottomLoader?.content ])

  if (!data) {
    return null
  }

  const { content, iconLink, iconName } = data || {}

  return (
    <Box
      className={s.container}
      ref={containerRef}
      overflow="hidden"
      radius={4}
      pv={12}
      ph={16}
    >
      <Flex>
        <SmallLoader className={s.loader} />
        {
          Boolean(content) && (
            <Box ml={16}>
              <Text message={content as Intl.Message} color="white" size={14} html />
            </Box>
          )
        }
        {
          Boolean(iconName && iconLink) && (
            <Box ml={16}>
              <a href={iconLink as string} target="_blank" rel="noreferrer nofollow">
                <Icon name={iconName as string} color="white" size={16} />
              </a>
            </Box>
          )
        }
      </Flex>
    </Box>
  )
}


export default BottomLoader
