import { useMemo } from 'react'
import { uiMethods } from 'redux/ui'
import { useDispatch } from 'react-redux'
import { farmsMethods } from 'redux/farms'
import { systemMethods } from 'redux/system'
import { accountMethods } from 'redux/account'


const wrapMethods = <T extends {}>(methods: T, dispatch: ReturnType<typeof useDispatch>): T => {
  return Object.keys(methods).reduce((acc, key) => {
    const method = methods[key as keyof T]
    // @ts-ignore
    acc[key] = (...args) => dispatch(method(...args))

    return acc as T
  }, {} as T)
}

const useActions = () => {
  const dispatch = useDispatch()

  return useMemo(() => ({
    ui: wrapMethods(uiMethods, dispatch),
    farms: wrapMethods(farmsMethods, dispatch),
    system: wrapMethods(systemMethods, dispatch),
    account: wrapMethods(accountMethods, dispatch),
  }), [])
}


export default useActions
