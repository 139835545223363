export default {
  connectErrors: {
    unknown: {
      en: 'An unknown error occurred. Check the console for more details.',
      ru: 'Произошла неизвестная ошибка. Проверьте консоль для получения более подробной информации',
      fr: 'Une erreur inconnue s\'est produite. Merci de vérifier la console pour plus de détails',
      es: 'Un error desconocido ha ocurrido. Revise la consola para más detalles.',
      pt: 'Ocorreu um erro desconhecido. Verifique o console para obter mais detalhes.',
      de: 'Ein unbekannter Fehler ist aufgetreten. Prüfe die Konsole für mehr Details',
      zh: '發生未知錯誤。請由主控台確認詳細資訊。',
    },
    networkError: {
      en: 'The selected network is not supported',
      ru: 'Выбранная сеть не поддерживается',
      fr: 'Le réseau sélectionné n\'est pas supporté',
      es: 'La red seleccionada no está soportada',
      pt: 'A rede selecionada não é compatível',
      de: 'Das ausgewählte Netzwerk wird nicht unterstützt',
      zh: '不支援選取的網路',
    },
    metaMaskLogin: {
      en: 'Please open the MetaMask extension and login to your account',
      ru: 'Откройте расширение MetaMask и введите данные для входа в аккаунт',
      fr: 'Merci d\'ouvrir MetaMask et de vous connecter à votre compte',
      es: 'Por favor abra la extensión de MetaMask e ingrese a su cuenta',
      pt: 'Por favor, abra a extensão MetaMask e faça login na sua conta',
      de: 'Bitte MetaMask Extension öffnen und anmelden',
      zh: '請開啟Metamask擴充套件並登入您的帳戶',
    },
  },
  successConnect: {
    en: 'Successfully connected with {wallet}',
    ru: 'Успешно подключено к {wallet}',
    fr: 'Succès de la connexion avec {wallet}',
    es: 'Se ha conectado con {wallet} exitosamente',
    pt: 'Conectado com sucesso {wallet}',
    de: 'Erfolgreich mit {wallet} verbunden',
    zh: '成功連接 {wallet}',
  },
  successDisconnect: {
    en: 'Successfully disconnected from {wallet}',
    ru: 'Успешно отключено от {wallet}',
    fr: 'Succès de la déconnexion de {wallet}',
    es: 'Se ha desconectado de {wallet} exitosamente',
    pt: 'Desconectado com sucesso {wallet}',
    de: 'Verbindung mit {wallet} erfolgreich getrennt',
    zh: '成功斷開與 {wallet} 的連接',
  },
  notification: {
    en: 'Please confirm via WalletConnect to switch network',
    ru: 'Пожалуйста, подтвердите смену сети в Вашем приложении WalletConnect',
    fr: 'Veuillez confirmer le changement de réseau dans votre application WalletConnect',
    es: 'Confirme el cambio de red en su aplicación WalletConnect',
    pt: 'Confirme a alteração de rede em seu aplicativo WalletConnect',
    de: 'Bitte bestätigen Sie die Netzwerkänderung in Ihrer WalletConnect-App',
    zh: '請在您的 WalletConnect 應用中確認網絡更改',
  },
}
