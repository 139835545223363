import React, { useState } from 'react'
import { actions } from 'helpers'
import { useIsomorphicLayoutEffect } from 'hooks'


const initialValues = {
  isMobile: false,
  isDesktop: false,
  isTablet: false,
  isTabletPortrait: false,
  isTabletLandscape: false,
  isCalculated: false,
}

export const Context = React.createContext<Device.Context>(initialValues)

const DeviceProvider: React.FC = ({ children }) => {
  const [ media, setMedia ] = useState<Device.Context>(initialValues)

  useIsomorphicLayoutEffect(() => {
    const handleSetMedia = () => {
      const width = window.innerWidth

      setMedia({
        isMobile: width <= 568,
        isDesktop: width >= 1200,
        isTablet: width > 568 && width <= 1199,
        isTabletPortrait: width > 568 && width <= 768,
        isTabletLandscape: width > 768 && width <= 1199,
        isCalculated: true,
      })
    }

    handleSetMedia()

    const handleResize = actions.throttle(handleSetMedia, 200)

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <Context.Provider value={media}>
      {children}
    </Context.Provider>
  )
}


export default DeviceProvider
