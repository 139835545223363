import { useEffect } from 'react'
import { useIntlRef } from 'hooks'
import { openNotification } from 'notifications'
import { actions as actionsHelpers, analytics } from 'helpers'

import messages from './messages'


type UseConnectChangeProps = Pick<
  ConfigProvider.State,
  'activeWallet' | 'autoConnectChecked'
> & {
  contextRef: Record<'current', ConfigProvider.State>
  isGnosis: boolean
}

const useConnectChange = ({ activeWallet, contextRef, autoConnectChecked, isGnosis }: UseConnectChangeProps) => {
  const intlRef = useIntlRef()

  // Send analytic events on connect
  useEffect(() => {
    if (autoConnectChecked && !activeWallet) {
      return () => {
        const walletConnectorPeerMeta = contextRef.current.connector.provider?.connector?.peerMeta

        analytics.statistics.sendEvent('connect', {
          walletName: contextRef.current.activeWallet,
          isMetaMask: Boolean(window?.ethereum?.isMetaMask), // check if dApp is MetaMask
          walletConnectorName: walletConnectorPeerMeta?.name,
          walletConnectorUrl: walletConnectorPeerMeta?.url,
        })
      }
    }
  }, [ activeWallet, contextRef, autoConnectChecked ])

  // Notifications on connect or disconnect
  useEffect(() => {
    if (autoConnectChecked) {
      if (activeWallet) {
        const { name } = contextRef.current.connectors[activeWallet]

        return () => {
          const wallet = intlRef.current.formatMessage(name)

          openNotification({
            type: 'success',
            text: { ...messages.successDisconnect, values: { wallet } },
          })
        }
      }
      else {
        return () => {
          const currentActiveWallet = contextRef.current.activeWallet

          if (currentActiveWallet) {
            const { name } = contextRef.current.connectors[currentActiveWallet]

            const wallet = intlRef.current.formatMessage(name)

            openNotification({
              type: 'success',
              text: { ...messages.successConnect, values: { wallet } },
            })
          }
        }
      }
    }
  }, [ intlRef, activeWallet, contextRef, autoConnectChecked ])

  // Set gnosis safe light theme on network change
  useEffect(() => {
    if (isGnosis) {
      const connector = contextRef.current.connector

      connector.isSafeApp?.()
        .then((isSafeApp) => {
          if (isSafeApp) {
            const savedTheme = actionsHelpers.theme.getStorageTheme()

            if (!savedTheme) {
              actionsHelpers.theme.setLightTheme()
            }
          }
        })
    }
  }, [ isGnosis, contextRef ])
}


export default useConnectChange
