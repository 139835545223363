import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useRouter } from 'next/router'
import localStorage from 'local-storage'
import { constants } from 'helpers'


const useLocale = () => {
  const router = useRouter()
  const { asPath, locale: routerLocale, locales = [], push, replace } = router

  const pathRef = useRef(asPath)
  pathRef.current = asPath

  const storageLocale = useMemo(() => {
    const locale = localStorage.getItem<string>(constants.localStorageNames.locale)

    if (locale && locales.includes(locale)) {
      return locale
    }

    return null
  }, [ locales ])

  const browserLocale = useMemo(() => {
    if (typeof window !== 'undefined') {
      const language = window.navigator?.language
      const languages = window.navigator?.languages || []

      if (language && locales.includes(language)) {
        return language
      }

      return languages.find((language) => locales.includes(language))
    }

    return null
  }, [ locales ])

  const userLocale = storageLocale || browserLocale

  // To avoid the error of hydration between server and client code, we should first give
  // the server locale and then change it on the client if necessary.
  const locale = routerLocale || 'en'

  const setLocale = useCallback((value: string) => {
    localStorage.setItem(constants.localStorageNames.locale, value)
    push(pathRef.current, undefined, { locale: value === 'en' ? false : value, scroll: false })
  }, [ push ])

  useEffect(() => {
    if (userLocale && userLocale !== routerLocale) {
      replace(pathRef.current, undefined, { locale: userLocale === 'en' ? false : userLocale, scroll: false })
    }
  }, [ userLocale, routerLocale, replace ])

  return {
    locale,
    setLocale,
  }
}


export default useLocale
