import { constants } from 'helpers'
import { LedgerConnector } from 'custom-connectors'
import supportedUrls from 'config/helpers/supportedUrls'
import supportedNetworks from 'config/helpers/supportedNetworks'

import messages from './messages'


const getSpecialErrors: InitConnectors.Data['getSpecialErrors'] = (error) => {
  if (error && typeof error === 'object') {
    const errorMessage = (error as Error)?.message

    if (/unsupported chain id/i.test(errorMessage)) {
      return messages.errors.unsupportedNetwork
    }

    if (error.name === 'EthAppPleaseEnableContractData') {
      return messages.errors.settings
    }

    if (error.name === 'TransportStatusError') {
      const statusCode = error.statusCode
      const isNotOpened = statusCode === 0x650f || statusCode === 0x6511

      return isNotOpened ? messages.errors.notOpened : messages.errors.lock
    }

    const isNotConnected = (
      errorMessage.indexOf('0x6804') !== -1
      || errorMessage.indexOf('U2F DEVICE_INELIGIBLE') !== -1
    )

    if (isNotConnected) {
      return messages.errors.notConnected
    }
  }

  return null
}

const initLedger: InitConnectors.CreateConnectorHandler<WalletIds> = () => {
  const connector = new LedgerConnector({
    pollingInterval: 12000,
    requestTimeoutMs: 180000,
    urls: supportedUrls,
    defaultChainId: supportedNetworks.mainnet.chainId,
  })

  return {
    [constants.walletNames.ledger]: {
      connector,
      name: messages.name,
      activationMessage: messages.activationMessage,
      getSpecialErrors,
    },
  }
}


export default initLedger
