import React from 'react'
import { modalWrapper } from 'modal'

import { Text } from 'components/ui'
import { Box } from 'components/layout'
import { Modal } from 'components/feedback'
import AddTokensToWallet from 'compositions/AddTokensToWallet/AddTokensToWallet'

import messages from './messages'
import s from './AddTokensModal.module.scss'


export const [ AddTokensModal, openAddTokensModal ] = (
  modalWrapper(UNIQUE_FILE_ID, (props) => {
    const { closeModal } = props

    return (
      <Modal className={s.modal} analyticsName="add tokens" closeModal={closeModal}>
        <Box pv={16}>
          <Text
            message={messages.title}
            color="default"
            align="center"
            size={24}
          />
        </Box>
        <Box mt={24}>
          <AddTokensToWallet />
        </Box>
      </Modal>
    )
  })
)
