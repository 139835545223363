const isStringValueGreaterZero = (value: string): boolean => {
  if (typeof value !== 'string') {
    return false
  }

  return Number(value.replace(/(\.|,)/g, '')) > 0
}


export default isStringValueGreaterZero
