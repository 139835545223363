import React, { useEffect } from 'react'
import Head from 'next/head'
import Script from 'next/script'
import { IntlProvider } from 'intl'
import { store } from 'redux/store'
import { analytics } from 'helpers'
import { ConfigProvider } from 'config'
import { DeviceProvider } from 'device'
import type { AppProps } from 'next/app'
import { promiseAllSettled } from 'polyfills'
import { Provider as ReduxProvider } from 'react-redux'

import AppLayout from 'layouts/AppLayout/AppLayout'

import 'sanitize.css'
import '../scss/globals.scss'


promiseAllSettled()

const App = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    analytics.statistics.init()
  }, [])

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=2.0"
        />
        <meta
          name="description"
          content={`
            Reliable & secure Eth2 staking provider. We employ innovative solutions to maximize your yield.
            Unlock your earnings potential with StakeWise.
          `}
        />
      </Head>
      <Script
        src="https://kit.fontawesome.com/79eb5f3471.js"
        strategy="lazyOnload"
      />
      <Script
        src="/static/js/initDevice.js"
        strategy="beforeInteractive"
      />
      <Script
        src="/static/js/initTheme.js"
        strategy="beforeInteractive"
      />
      <IntlProvider>
        <DeviceProvider>
          <ReduxProvider store={store}>
            <ConfigProvider>
              <AppLayout>
                <Component {...pageProps} />
              </AppLayout>
            </ConfigProvider>
          </ReduxProvider>
        </DeviceProvider>
      </IntlProvider>
    </>
  )
}


export default App
