export default {
  sender: {
    en: 'Sender',
    ru: 'Отправитель',
    fr: 'Expéditeur',
    es: 'Remitente',
    pt: 'Remetente',
    de: 'Absender',
    zh: '發送者',
  },
  recipient: {
    en: 'Recipient',
    ru: 'Получатель',
    fr: 'Destinataire',
    es: 'Destinatario',
    pt: 'Recipiente',
    de: 'Empfänger',
    zh: '收件者',
  },
  amount: {
    en: 'Amount',
    ru: 'Сумма',
    fr: 'Montant',
    es: 'Cantidad',
    pt: 'Valor',
    de: 'Betrag',
    zh: '數量',
  },
  txHash: {
    en: 'Tx Hash',
    ru: 'Хеш Транз.',
    fr: 'Hash de la transaction',
    es: 'Hash de la Tx',
    pt: 'Tx Hash',
    de: 'Tx Hash',
    zh: '交易 Hash',
  },
}
