import constants from '../constants'


type Input = {
  token: Tokens
  value: string
  currency: Store['account']['currency']
  fiatPrices: Store['system']['fiatPrices']
}

const currencyMap = {
  DAI: [
    constants.tokens.dai,
    constants.tokens.xdai,
  ] as Tokens[],
  mGNO: [
    constants.tokens.mgno,
  ] as Tokens[],
  GNO: [
    constants.tokens.gno,
    constants.tokens.sgno,
    constants.tokens.rgno,
  ] as Tokens[],
  ETH: [
    constants.tokens.eth,
    constants.tokens.weth,
    constants.tokens.steth,
    constants.tokens.seth2,
    constants.tokens.reth2,
    constants.tokens['reth-h'],
    constants.tokens['seth-h'],
  ] as Tokens[],
  SWISE: [
    constants.tokens.swise,
  ] as Tokens[],
} as const

const getFiatValue = (values: Input): number => {
  const { token, value, fiatPrices, currency } = values

  if (!fiatPrices.isCalculated) {
    return 0
  }

  const tokenName = Object.keys(currencyMap).find((name) => (
    currencyMap[name as keyof typeof currencyMap].includes(token)
  )) as keyof typeof currencyMap

  if (!tokenName) {
    console.error(`Incorrect token value in getFiatValue: ${token}`)
    return 0
  }

  const rate = fiatPrices[tokenName][currency]

  const result = (rate * Number(value)).toFixed(2)

  return Number(result)
}


export default getFiatValue
