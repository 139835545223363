/* eslint-disable max-len */
export default {
  // TODO - need a translation
  metaMaskError: {
    en: `
      We have detected that you already have MetaMask installed. <br /><br />
      If you would prefer to use Tally instead, ensure that it is downloaded and set to default (Tally App -> Menu).
    `,
    ru:  `
      Мы обнаружили, что у вас уже установлен MetaMask. <br /><br />
      Если вы предпочитаете использовать Tally, убедитесь, что он загружен и установлен по умолчанию (Tally App -> Меню).
    `,
    fr:  `
      We have detected that you already have MetaMask installed. <br /><br />
      If you would prefer to use Tally instead, ensure that it is downloaded and set to default (Tally App -> Menu).
    `,
    es:  `
      We have detected that you already have MetaMask installed. <br /><br />
      If you would prefer to use Tally instead, ensure that it is downloaded and set to default (Tally App -> Menu).
    `,
    pt:  `
      We have detected that you already have MetaMask installed. <br /><br />
      If you would prefer to use Tally instead, ensure that it is downloaded and set to default (Tally App -> Menu).
    `,
    de:  `
      We have detected that you already have MetaMask installed. <br /><br />
      If you would prefer to use Tally instead, ensure that it is downloaded and set to default (Tally App -> Menu).
    `,
    zh:  `
      We have detected that you already have MetaMask installed. <br /><br />
      If you would prefer to use Tally instead, ensure that it is downloaded and set to default (Tally App -> Menu).
    `,
  },
}
