import React from 'react'
import { Notifications as NotificationsConductor } from 'notifications'

import Notification from './Notification/Notification'

import s from './Notifications.module.scss'


const Notifications = () => (
  <NotificationsConductor
    className={s.notifications}
    component={Notification}
  />
)


export default React.memo(Notifications)
