import { constants, analytics } from 'helpers'


type MulticallReturnType = {
  isPoolPaused: boolean
  maintainerFee: BigNumber
}

const fetchSystemContractsData = async (contracts: Contracts, config: Config): Promise<InitialData.System.ContractsData> => {
  const poolContract = contracts.base.poolContract[config.network.id]

  const params: Parameters<typeof contracts.helpers.createMulticall>[0] = [
    {
      returnName: 'maintainerFee',
      contract: contracts.tokens.default.rewardTokenContract,
      methodName: 'protocolFee',
      defaultValue: constants.blockchain.amount0,
      args: [],
    },
    {
      returnName: 'isPoolPaused',
      contract: poolContract,
      methodName: 'paused',
      defaultValue: false,
      args: [],
    },
  ]

  try {
    const handleMulticall = contracts.helpers.createMulticall<MulticallReturnType>(params)

    const {
      isPoolPaused,
      maintainerFee,
    } = await handleMulticall()

    return {
      isPoolPaused,
      maintainerFee: maintainerFee.toNumber(),
    }
  }
  catch (error) {
    analytics.sentry.exception('Fetch system contracts data error', error as Error)

    return {
      isPoolPaused: false,
      maintainerFee: config.settings.maintainerFee,
    }
  }
}


export default fetchSystemContractsData
