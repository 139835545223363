export default {
  name: {
    en: 'Coinbase Wallet',
    ru: 'Кошелёк Coinbase',
    fr: 'Portefeuille Coinbase',
    es: 'Coinbase Wallet',
    pt: 'Coinbase Wallet',
    de: 'Coinbase Wallet',
    zh: 'Coinbase 錢包',
  },
  activationMessage: {
    en: 'Waiting for QR code to be scanned by Coinbase Wallet',
    ru: 'Ожидаем сканирования QR кода кошельком Coinbase',
    fr: 'Attente du scan du QR code par le portefeuille Coinbase',
    es: 'Esperando a que el código QR sea escaneado por Coinbase Wallet',
    pt: 'Esperando que o código QR seja digitalizado pela Coinbase Wallet',
    de: 'Warten auf das QR Code Scannen der Coinbase Wallet',
    zh: '等待Coinbase錢包掃描QR Code',
  },
  errors: {
    unsupportedNetwork: {
      en: 'Coinbase Wallet is connected to an unsupported network',
      ru: 'Кошелёк Coinbase подключен к не поддерживаемой сети',
      fr: 'Le portefeuille Coinbase est connecté à un réseau non supporté',
      es: 'Coinbase Wallet está conectado a una red no soportada',
      pt: 'Coinbase Wallet está conectada a uma rede não suportada',
      de: 'Coinbase Wallet ist mit einem nicht unterstützten Netzwerk verbunden',
      zh: 'Coinbase錢包連接到一個不支援的網路',
    },
    authorize: {
      en: 'Please authorize access to your Coinbase Wallet account',
      ru: 'Пожалуйста, разрешите доступ к Вашему кошельку Coinbase',
      fr: 'Merci d\'autoriser l\'accès au compte de votre portefeuille Coinbase',
      es: 'Favor autorizar acceso a su cuenta de Coinbase Wallet',
      pt: 'Por favor autorize o acesso à sua conta de Coinbase Wallet',
      de: 'Bitte autorisiere den Zugriff auf Dein Coinbase Wallet Konto',
      zh: '請允許存取您的Coinbase錢包',
    },
  },
}
