export default {
  title: {
    en: '<b>StakeWise V3 is out!</b> Learn more about our major release',
    ru: '<b>StakeWise V3 вышел!</b> Узнайте больше о нашем главном релизе',
    fr: '<b>StakeWise V3 est sorti!</b> En savoir plus sur notre version majeure',
    es: '<b>¡StakeWise V3 está fuera!</b> Más información sobre nuestro principal lanzamiento',
    pt: '<b>O StakeWise V3 saiu!</b> Saiba mais sobre o nosso grande lançamento',
    de: '<b>StakeWise V3 ist da!</b> Erfahren Sie mehr über unsere Hauptversion',
    zh: '<b>StakeWise V3已推出！</b>详细了解我们的主要版本',
  },
}
