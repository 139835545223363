type Input = {
  address: string
  contracts: Contracts
}

const fetchWhitelistedAccounts = (values: Input) => {
  const { address, contracts } = values

  if (!contracts.base.whiteListManagerContract) {
    return true
  }

  return contracts.base.whiteListManagerContract.whitelistedAccounts(address)
}


export default fetchWhitelistedAccounts
