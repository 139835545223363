import { useCallback, useEffect, useState } from 'react'
import { openNotification } from 'notifications'
import { constants, getters } from 'helpers'
import { useConfig, useStore } from 'hooks'

import { openSwiseDropModal } from '../SettingsModals/SwiseDropModal/SwiseDropModal'

import messages from './messages'


type Output = {
  hasSwiseDrop: boolean
  openSwiseDropModal?: () => void
}

const storeSelector = (store: Store) => ({
  amount: store.account.balances.swiseDrop.amount,
  swiseDropIndex: store.account.balances.swiseDrop.index,
})

const useSwiseDrop = (): Output => {
  const [ withModal, setWithModal ] = useState(false)
  const { swiseDropIndex, amount } = useStore(storeSelector)
  const { contracts, activeWallet, address } = useConfig()

  useEffect(() => {
    const canFetchClaimedStatus = (
      Boolean(address)
      && contracts
      && getters.isStringValueGreaterZero(amount)
      && activeWallet !== constants.walletNames.monitorAddress
    )

    if (canFetchClaimedStatus && contracts.base.merkleDropContract) {
      contracts.base.merkleDropContract.isClaimed(swiseDropIndex)
        .then((isClaimed) => {
          if (!isClaimed) {
            setWithModal(true)
            openNotification({
              type: 'info',
              text: messages.notification,
            })
          }
        })
    }
  }, [ amount ])

  const handleClick = useCallback(() => {
    if (withModal) {
      openSwiseDropModal({
        amount,
        destroy: () => setWithModal(false),
      })
    }
  }, [ withModal, amount ])

  return {
    hasSwiseDrop: withModal,
    openSwiseDropModal: withModal ? handleClick : undefined,
  }
}


export default useSwiseDrop
