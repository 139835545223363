/* eslint-disable max-len */
export default {
  // TODO - need a translation
  tallyError: {
    en: 'Tally is set as your default wallet. If you would prefer to connect with MetaMask instead, remove Tally as default via Tally App -> Menu.',
    ru: 'Tally установлен в качестве кошелька по умолчанию. Если вы предпочитаете подключиться к MetaMask, удалите Tally как кошелек по умолчанию через Tally App -> Меню.',
    fr: 'Tally is set as your default wallet. If you would prefer to connect with MetaMask instead, remove Tally as default via Tally App -> Menu.',
    es: 'Tally is set as your default wallet. If you would prefer to connect with MetaMask instead, remove Tally as default via Tally App -> Menu.',
    pt: 'Tally is set as your default wallet. If you would prefer to connect with MetaMask instead, remove Tally as default via Tally App -> Menu.',
    de: 'Tally is set as your default wallet. If you would prefer to connect with MetaMask instead, remove Tally as default via Tally App -> Menu.',
    zh: 'Tally is set as your default wallet. If you would prefer to connect with MetaMask instead, remove Tally as default via Tally App -> Menu.',
  },
}
