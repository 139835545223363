import React from 'react'
import cx from 'classnames'
import Image from 'next/image'
import { useConfig } from 'hooks'
import { constants, modifiers } from 'helpers'

import { Text } from 'components/ui'
import { Box, Flex } from 'components/layout'

import zenGoIcon from 'public/static/images/logos/zen-go.svg'
import tallyIcon from 'public/static/images/logos/tally-logo.png'
import ledgerIcon from 'public/static/images/logos/ledger-logo.png'
import portisIcon from 'public/static/images/logos/portis-logo.png'
import metaMaskIcon from 'public/static/images/logos/metamask-logo.svg'
import coinbaseIcon from 'public/static/images/logos/coinbase-logo.svg'
import gnosisIcon from 'public/static/images/logos/gnosis-safe-icon.png'
import dAppBrowserIcon from 'public/static/images/logos/magnifying-glass.svg'
import monitorAddressIcon from 'public/static/images/logos/monitor-address.svg'
import walletConnectIcon from 'public/static/images/logos/walletconnect-logo.svg'

import s from './Address.module.scss'


type AddressProps = {
  isCompact?: boolean
}

const walletImages = {
  [constants.walletNames.tally]: tallyIcon,
  [constants.walletNames.zenGo]: zenGoIcon,
  [constants.walletNames.gnosis]: gnosisIcon,
  [constants.walletNames.ledger]: ledgerIcon,
  [constants.walletNames.portis]: portisIcon,
  [constants.walletNames.coinbase]: coinbaseIcon,
  [constants.walletNames.metaMask]: metaMaskIcon,
  [constants.walletNames.ledgerLive]: ledgerIcon,
  [constants.walletNames.dAppBrowser]: dAppBrowserIcon,
  [constants.walletNames.walletConnect]: walletConnectIcon,
  [constants.walletNames.monitorAddress]: monitorAddressIcon,
}

const Address: React.FC<AddressProps> = (props) => {
  const { isCompact } = props

  const { address, accountName, activeWallet } = useConfig()

  const imageSize = isCompact ? 14 : 20
  const addressSize = isCompact ? -8 : -4
  const walletIcon = walletImages[activeWallet as NonNullable<WalletIds>]

  const containerClassName = cx(s.container, {
    [s.compact]: isCompact,
  })

  return (
    <Box className={containerClassName} ph={12} pv={4} radius={8} noWrapper>
      <Flex align="center">
        <Text
          className={s.text}
          message={accountName || modifiers.shortenAddress(address, addressSize)}
          color={isCompact ? 'black' : 'default'}
          dataTestId="header-address"
        />
        {
          Boolean(walletIcon) && (
            <Box ml={8} noWrapper>
              <Flex>
                <Image
                  src={walletIcon}
                  width={imageSize}
                  height={imageSize}
                  alt={activeWallet as NonNullable<WalletIds>}
                />
              </Flex>
            </Box>
          )
        }
      </Flex>
    </Box>
  )
}


export default React.memo(Address)
