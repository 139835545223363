import { useCallback } from 'react'
import { constants } from 'helpers'
import request from 'request-module'
import localStorage from 'modules/local-storage'
import { formatEther } from '@ethersproject/units'

import useConfig from './useConfig'
import useActions from '../store/useActions'

import totalStakedAmountQuery from 'graphql/main/totalStakedAmount.graphql'


const useNewTotalStakingAmount = () => {
  const actions = useActions()
  const { config } = useConfig()

  return useCallback(async () => {
    try {
      const response = await request.gql(config.api.mainSubgraph, totalStakedAmountQuery, {
        poolAddress: config.addresses.base.pool.toLowerCase(),
      })

      const result = formatEther(response.pool?.totalStaked || '0')

      localStorage.setItem(`${constants.localStorageNames.mocks.totalStakingAmount}-${config.network.id}`, result)

      actions.system.setTotalStakingAmount(result)
    }
    catch {}
  }, [ config ])
}


export default useNewTotalStakingAmount
