const getGoerliData = (): GetNodeOperators.Data => {
  const operators = {
    'StakeWise Labs': [
      '0x6a5b7d2e9cfcc174e072d3e64d6676619d62cae3',
      '0x77765445d70fc0341a36b980d746ee4d1360fba3',
    ],
    'Stakin': [ '0x1f1b26d761a884147ca7d09c07c4b41140e2d1a1' ],
    'VeriHash': [ '0x5b0df4ab7905f7e5098865900819188fa153dd0d' ],
    'ChainLayer': [ '0x1a49a54bb22e8d799a3861ae8d3b401bb2d9ea00' ],
    'CryptoManufaktur': [ '0x59ecf48345a221e0731e785ed79ed40d0a94e2a5' ],
  }

  const europe = [
    `${operators['ChainLayer'][0]}-1`,
    `${operators['StakeWise Labs'][0]}-0`,
    `${operators['StakeWise Labs'][0]}-1`,
  ]

  const northAmerica = [
    `${operators['Stakin'][0]}-0`,
    `${operators['Stakin'][0]}-1`,
    `${operators['VeriHash'][0]}-1`,
    `${operators['StakeWise Labs'][1]}-0`,
    `${operators['StakeWise Labs'][1]}-1`,
    `${operators['CryptoManufaktur'][0]}-1`,
  ]

  return {
    operators,
    europe,
    northAmerica,
  }
}


export default getGoerliData
