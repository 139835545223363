import data from '../data'
import getChainIdByNetworkIdE2E from './getChainIdByNetworkIdE2E'


const {
  harbourMainnet,
  mainnet,
  gnosis,

  // testnets
  goerli,
  harbourGoerli,
} = data

const configs = {
  [goerli.network.id]: goerli,
  [gnosis.network.id]: gnosis,
  [mainnet.network.id]: mainnet,
  [harbourGoerli.network.id]: harbourGoerli,
  [harbourMainnet.network.id]: harbourMainnet,
} as const

// TODO update useQueryParams, this is a temporary solution
// getConfig is called before useQueryParams updates sessionStorage
// because of it we can't use localStorage.getSessionItem
// Here 'chainIdByNetworkIdE2E' will get initial 'e2e' query and store its value
const chainIdByNetworkIdE2E = getChainIdByNetworkIdE2E()

const getConfig = (networkId: keyof typeof configs) => {
  const config = configs[networkId]

  if (!config) {
    throw new Error('Wrong network passed to config')
  }

  const chainIdE2E = chainIdByNetworkIdE2E[networkId]

  const network = chainIdE2E
    ? {
      ...config.network,
      chainId: chainIdE2E,
      originalChainId: config.network.chainId,
    }
    : config.network

  return {
    api: config.api,
    tokens: config.tokens,
    network,
    addresses: config.addresses,
    pages: {
      ...config.pages,
      uniswap: 'https://app.uniswap.org/',
      website: 'https://app.stakewise.io/',
      uniPosition: 'https://app.uniswap.org/#/pool',
    },
    settings: {
      ...config.settings,
      swiseUniswapFee: 3000,
      stakedUniswapFee: 3000,
      unpaidValidatorsExitDelay: 864000,
    },
  }
}


export default getConfig
