import React from 'react'
import ReactCountUp from 'react-countup'
import { CountUpProps } from 'react-countup/build/CountUp'


const toFixedSafe = (number: number, decimals?: number) => {
  const stringNumber = String(number)
  const isWithDot = /\./.test(stringNumber)

  if (decimals && isWithDot) {
    const [ integer, decimal ] = stringNumber.split('.')

    const formattedDecimal = decimal.slice(0, decimals)

    return Number(`${integer}.${formattedDecimal}`)
  }

  return number
}

const CountUp: React.FC<CountUpProps> = (props) => {
  const { end, decimals, ...rest } = props

  return (
    <ReactCountUp
      {...rest}
      end={toFixedSafe(end, decimals)}
      decimals={decimals}
    />
  )
}


export default CountUp
