import type Portis from '@portis/web3'

import { PortisConnectorArguments } from 'modules/custom-connectors/types'

import supportedNetworks from '../../config/helpers/supportedNetworks'

import AbstractConnector from './AbstractConnector'


const chainIdToNetwork: Record<number, string> = {
  1: 'mainnet',
  3: 'ropsten',
  4: 'rinkeby',
  5: 'goerli',
  42: 'kovan',
  100: 'xdai',
  30: 'orchid',
  31: 'orchidTestnet',
  99: 'core',
  77: 'sokol',
  61: 'classic',
  8: 'ubiq',
  108: 'thundercore',
  18: 'thundercoreTestnet',
  163: 'lightstreams',
  122: 'fuse',
  15001: 'maticTestnet',
}

const validateChainIds = (chainIds: number[]) => (
  chainIds.every((chainId) => Boolean(chainIdToNetwork[chainId]))
)

class PortisConnector extends AbstractConnector {

  private dAppId: string
  private chainId: number
  private portis?: Portis
  private isListenersAdded: boolean

  private config: PortisConnectorArguments['config']

  constructor(props: PortisConnectorArguments) {
    const { dAppId, supportedChainIds, config } = props

    const isValidChainIds = validateChainIds(supportedChainIds)

    if (!isValidChainIds) {
      throw new Error('Not valid chain ids provided')
    }

    super({ supportedChainIds })

    this.dAppId = dAppId
    this.config = config
    this.chainId = supportedChainIds[0]
    this.isListenersAdded = false

    this.handleClose = this.handleClose.bind(this)
    this.handleError = this.handleError.bind(this)
    this.handleAccountsChanged = this.handleAccountsChanged.bind(this)
  }

  private handleClose() {
    this.emitDeactivate()
  }

  private handleError(error: any) {
    this.emit(error)
  }

  private handleAccountsChanged(account: string) {
    if (!account) {
      this.emitDeactivate()
    }
    else {
      this.emitUpdate({
        account,
      })
    }
  }

  private async getPortis(): Promise<Portis> {
    if (this.portis) {
      return this.portis
    }

    const portisPackage = await import('@portis/web3')

    if (portisPackage) {
      const Portis = portisPackage.default || portisPackage
      const network = chainIdToNetwork[this.chainId]

      this.portis = new Portis(
        this.dAppId,
        network,
        this.config
      )

      return this.portis
    }
    else {
      throw new Error('No Portis provider was imported from @portis/web3')
    }
  }

  async getProvider(): Promise<Portis['provider']> {
    const portis = await this.getPortis()

    return portis.provider
  }

  private async addListeners() {
    if (!this.isListenersAdded) {
      const porits = await this.getPortis()

      porits.onError(this.handleError)
      porits.onLogout(this.handleClose)
      porits.onActiveWalletChanged(this.handleAccountsChanged)

      this.isListenersAdded = true
    }
  }

  async activate() {
    const provider = await this.getProvider()

    await this.addListeners()

    try {
      const accounts = await provider.enable()

      return {
        account: accounts[0],
        provider,
      }
    }
    catch (error: any) {
      if (error.code === 4001) {
        throw new Error('The user rejected the request')
      }
      else {
        return Promise.reject(error)
      }
    }
  }

  async changeChainId(chainId: number): Promise<void> {
    const provider = await this.getProvider()

    await this.addListeners()

    if (provider && this.portis) {
      if (!chainIdToNetwork[chainId]) {
        return Promise.reject(`Chain id ${chainId} is not supported`)
      }

      try {
        const supportedNetwork = Object.values(supportedNetworks)
          .find((supportedNetwork) => (
            supportedNetwork.chainId === chainId
          ))

        // We need to provide nodeUrl from our config because Portis has wrong url for gnosis
        if (supportedNetwork?.url) {
          this.portis.changeNetwork({
            chainId: String(chainId),
            nodeUrl: supportedNetwork.url,
          })
        }
        else {
          this.portis.changeNetwork(chainIdToNetwork[chainId])
        }
      }
      catch (error: any) {
        return Promise.reject(error)
      }
    }
    else {
      return Promise.reject('Provider is not exist')
    }
  }

  private async sendRequest(params: string | Record<string, string>) {
    const provider = await this.getProvider()

    const data = await provider.send(params)

    return data?.result || data
  }

  async getChainId(): Promise<number> {
    return this.sendRequest('eth_chainId')
  }

  async getAccount(): Promise<null | string> {
    const accounts = await this.sendRequest('eth_accounts')

    return accounts?.[0]
  }

  async deactivate() {
    const porits = await this.getPortis()

    const callback = () => {}

    porits.onError(callback)
    porits.onLogout(callback)
    porits.onActiveWalletChanged(callback)

    this.isListenersAdded = false
  }
}


export default PortisConnector
