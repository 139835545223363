import React from 'react'
import { useConfig } from 'hooks'
import { constants } from 'helpers'

import { SwiseDropModal } from './SwiseDropModal/SwiseDropModal'
import { AddTokensModal } from './AddTokensModal/AddTokensModal'
import { SendTokensModal } from './SendTokensModal/SendTokensModal'
import { TransactionsModal } from './TransactionsModal/TransactionsModal'
import { SwitchAccountModal } from './SwitchAccountModal/SwitchAccountModal'


type SettingsModalsProps = {
  hasSwiseDrop: boolean
}

const SettingsModals: React.FC<SettingsModalsProps> = (props) => {
  const { hasSwiseDrop } = props
  const { activeWallet } = useConfig()
  const isLedger = activeWallet === constants.walletNames.ledger

  return (
    <>
      <AddTokensModal />
      <SendTokensModal />
      <TransactionsModal />
      {
        hasSwiseDrop && (
          <SwiseDropModal />
        )
      }
      {
        isLedger && (
          <SwitchAccountModal />
        )
      }
    </>
  )
}


export default React.memo(SettingsModals)
