import React from 'react'

import ConnectButton from './ConnectButton/ConnectButton'
import { MonitorAddressModal } from './MonitorAddressModal/MonitorAddressModal'

import s from './Connect.module.scss'


const Connect: React.FC = () => (
  <div className={s.connect}>
    <ConnectButton />
    <MonitorAddressModal />
  </div>
)


export default React.memo(Connect)
