import React from 'react'

import useMenu from './util/useMenu'
import MenuItem from './MenuItem/MenuItem'

import s from './Menu.module.scss'


type MenuProps = {
  buyNativeToken: (() => void) | null
}

const Menu: React.FC<MenuProps> = (props) => {
  const { buyNativeToken } = props

  const { items } = useMenu({ buyNativeToken })

  return (
    <>
      {
        items.map((item, index) => {
          if (item === 'line') {
            return (
              <div key={index} className={s.line} />
            )
          }

          return (
            <MenuItem
              key={index}
              title={item.title}
              iconName={item.iconName}
              onClick={item.onClick}
            />
          )
        })
      }
    </>
  )
}


export default React.memo(Menu)
