import React from 'react'
import Image from 'next/image'
import { constants } from 'helpers'
import { useDevice } from 'device'
import { useConfig } from 'hooks'

import { Box } from 'components/layout'

import ListItem from '../components/ListItem/ListItem'
import waitForSelector from './util/waitForSelector'

import zenGoLogo from 'public/static/images/logos/zen-go.svg'


const walletName = constants.walletNames.zenGo

const ZenGoLink: React.FC = () => {
  const { isDesktop } = useDevice()
  const { connectors, activate } = useConfig()

  const title = connectors[walletName].name

  const handleClick = async () => {
    activate(walletName)

    const closeBtn = await waitForSelector<HTMLButtonElement>('.walletconnect-modal__close__wrapper')

    if (closeBtn) {
      // Need to reload page, since the modal in connector cannot be opened a second time
      closeBtn.addEventListener('click', () => {
        window.location.reload()
      })
    }
  }

  const handleMobileClick = async () => {
    handleClick()

    const style = document.createElement('style')
    style.innerHTML = '#walletconnect-wrapper {display: none !important;}'
    document.head.appendChild(style)

    const input = await waitForSelector<HTMLInputElement>('.walletconnect-search__input')

    if (input) {
      input.value = 'ZenGo'
      input.dispatchEvent(new Event('input'))

      const zengoLink = await waitForSelector<HTMLLinkElement>('a[href^="https://get.zengo.com/wc"]')

      if (zengoLink) {
        zengoLink.click()
      }
    }

    document.head.removeChild(style)
  }

  const imageNode = (
    <Image src={zenGoLogo} width={25} height={25} alt="ZenGo" />
  )

  return (
    <Box relative>
      <ListItem
        title={title}
        leftNode={imageNode}
        onClick={isDesktop ? handleClick : handleMobileClick}
      />
    </Box>
  )
}


export default React.memo(ZenGoLink)
