import { WalletLinkConnector } from 'custom-connectors'
import supportedNetworks from 'config/helpers/supportedNetworks'
import supportedChainIds from 'config/helpers/supportedChainIds'

import initDefaultWalletConnect from './default/init'
import initZenGo from './zenGo/init'


const initWalletConnect: InitConnectors.CreateConnectorHandler<WalletIds> = () => {
  const connector = new WalletLinkConnector({
    supportedChainIds,
    config: {
      projectId: process.env.NEXT_PUBLIC_WALLET_CONNECT,
      chains: [ supportedNetworks.mainnet.chainId ],
      optionalChains: [
        supportedNetworks.gnosis.chainId,
        supportedNetworks.goerli.chainId,
      ],
      showQrModal: true,
      qrModalOptions: {
        themeVariables: {
          "--w3m-z-index": '999',
        },
      },
    },
  })

  const createWalletsHandlers = [ initDefaultWalletConnect, initZenGo ]

  return createWalletsHandlers.reduce((result, initWallet) => {
    // @ts-ignore
    const walletData = initWallet(connector)

    return {
      ...result,
      [walletData.id]: walletData,
    }
  }, {})
}


export default initWalletConnect
