import { useCallback, useEffect, useRef } from 'react'
import localStorage from 'local-storage'
import { createContracts } from 'contracts'
import { analytics, constants, getters } from 'helpers'
import { openNotification } from 'notifications'
import { getAddress } from '@ethersproject/address'
import { ExternalProvider, Web3Provider } from '@ethersproject/providers'
import { useActions, useNotificationTimeout, useObjectState } from 'hooks'

import { getConfig, supportedNetworks, supportedChainIds } from '../helpers'

import useAutoConnect from './useAutoConnect'
import useConnectChange from './useConnectChange'
import useStorageUpdate from './useStorageUpdate'
import useCanChangeChain from './useCanChangeChain'
import useNetworkClassName from './useNetworkClassName'
import useSupportedNetworks from './useSupportedNetworks'

import getMockContext from './getMockContext'
import getInitialContext from './getInitialContext'

import messages from './messages'


const useConfigContext = (): ConfigProvider.Context => {
  const actions = useActions()
  const { chainIdByNetworkId, networkIdByChainId } = useSupportedNetworks()

  const [ context, setContext ] = useObjectState<ConfigProvider.State>(
    typeof window === 'undefined' ? getMockContext() : getInitialContext()
  )

  const { setNotificationTimeout, clearNotificationTimeout } = useNotificationTimeout({
    handlersOnly: true,
  })

  const { config, address, networkId, library, connector, activeWallet, autoConnectChecked } = context

  const {
    isGnosis,
    isGoerli,
    isMainnet,
    isHarbour,
    isHarbourGoerli,
    isHarbourMainnet,
  } = getters.getNetworkType(config)

  context.library.pollingInterval = 15000 // by default 4000

  const contextRef = useRef<ConfigProvider.State>(context)
  contextRef.current = context

  // Prevent 'changeChain' listener callback for chain changing
  const waitForChainId = useRef<null | ChainIds>(null)

  const resetStore = useCallback(() => {
    actions.system.resetData()
    actions.account.resetData()
  }, [ actions ])

  const deactivate = useCallback(async (walletName?: WalletIds) => {
    const connector = walletName
      ? contextRef.current.connectors[walletName].connector
      : contextRef.current.connector

    await connector.deactivate()

    const queryNetworkId = localStorage.getSessionItem<NetworkIds>(constants.queryNames.networkId)
    const initialContext = getInitialContext(queryNetworkId)

    setContext({
      ...initialContext,
      autoConnectChecked: true,
    })

    actions.account.resetData()
  }, [ actions, setContext ])

  const handleChangeChain = useCallback(async (networkId: NetworkIds, walletName?: WalletIds): Promise<void> => {
    const chainId = chainIdByNetworkId[networkId] as ChainIds

    if (!chainId) {
      return Promise.reject(`Network ${networkId} is not supported`)
    }

    const appConnectorNames: WalletIds[] = [
      constants.walletNames.walletConnect,
      constants.walletNames.coinbase,
      constants.walletNames.zenGo,
    ]

    const needAppConfirmation = walletName
      ? appConnectorNames.includes(walletName)
      : appConnectorNames.includes(contextRef.current.activeWallet as WalletIds)

    waitForChainId.current = chainId

    if (needAppConfirmation) {
      setNotificationTimeout({
        text: messages.notification,
        time: 4000,
      })
    }

    const connector = walletName
      ? contextRef.current.connectors[walletName].connector
      : contextRef.current.connector

    try {
      await connector.changeChainId?.(chainId)
    }
    catch (error: any) {
      const errorCode = error?.data?.originalError?.code || error?.code
      const isUnrecognizedChain = /unrecognized chain id/i.test(error) || errorCode === 4902

      // TODO move to connectors
      if (isUnrecognizedChain) {
        const provider = await connector.getProvider()
        const network = Object.values(supportedNetworks).find((supportedNetwork) => (
          supportedNetwork.chainId === chainId
        ))

        if (provider && network) {
          try {
            await provider.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  blockExplorerUrls: [ network.blockExplorerUrls ],
                  nativeCurrency: network.nativeCurrency,
                  chainId: network.hexadecimalChainId,
                  rpcUrls: [ network.url ],
                  chainName: network.name,
                },
              ],
            })

            return handleChangeChain(networkId)
          }
          catch (error) {
            analytics.sentry.exception('Add chain error', error as Error)
          }
        }
      }

      return Promise.reject(error)
    }
    finally {
      waitForChainId.current = null
      clearNotificationTimeout()
    }
  }, [ chainIdByNetworkId, setNotificationTimeout, clearNotificationTimeout ])

  const changeChain = useCallback(async (networkId: NetworkIds): Promise<void> => {
    const chainId = chainIdByNetworkId[networkId] as ChainIds

    if (!chainId) {
      return Promise.reject(`Network ${networkId} is not supported`)
    }

    const config = getConfig(networkId)

    if (chainId === contextRef.current.chainId) {
      // Harbour chain switching
      resetStore()

      setContext({
        config,
        networkId,
        contracts: createContracts(contextRef.current.library, config),
      })
    }
    else {
      // Default chain switching
      await handleChangeChain(networkId)

      const provider = await contextRef.current.connector.getProvider()
      const library = new Web3Provider(provider as ExternalProvider)
      const contracts = createContracts(library, config)

      resetStore()

      setContext({
        config,

        chainId,
        networkId,

        library,
        contracts,
      })
    }
  }, [ chainIdByNetworkId, handleChangeChain, setContext, resetStore ])

  const handleWrongNetwork = useCallback(async (walletName?: WalletIds) => {
    openNotification({
      type: 'warning',
      text: messages.connectErrors.networkError,
    })

    await deactivate(walletName)
  }, [ deactivate ])

  const activateCountRef = useRef(0)

  const activate = useCallback(async (walletName: WalletIds, autoConnectNetworkId?: NetworkIds): Promise<void> => {
    activateCountRef.current += 1

    const count = activateCountRef.current
    const { connector, activationMessage, getSpecialErrors } = contextRef.current.connectors[walletName]

    try {
      actions.ui.setBottomLoader({ content: activationMessage })

      // @ts-ignore
      const data = await connector.activate(contextRef.current.config)
      const unFormattedChainId = data.chainId || await connector.getChainId()

      // Check there were no subsequent `activate` calls while activating,
      // since we should change context only for the last activated connector
      if (count === activateCountRef.current) {
        const queryNetworkId = localStorage.getSessionItem<NetworkIds>(constants.queryNames.networkId)

        const connectorChainId = Number(unFormattedChainId) as ChainIds
        const queryChainId = queryNetworkId ? chainIdByNetworkId[queryNetworkId] : null
        const autoConnectChainId = autoConnectNetworkId ? chainIdByNetworkId[autoConnectNetworkId] : null

        let connectorNetworkId

        if (queryChainId === connectorChainId) {
          connectorNetworkId = queryNetworkId
        }
        else if (autoConnectChainId === connectorChainId) {
          connectorNetworkId = autoConnectNetworkId
        }
        else {
          connectorNetworkId = networkIdByChainId[connectorChainId]
        }

        // We should check chain ids rather than network ids
        // if we provide E2E hardhat networks but there is 'mainnet' selected in connector
        // networkId would be the same, but chainId would be different
        const isUnsupportedChainId = !supportedChainIds.includes(connectorChainId)

        // Show wrong network notification. And deactivate current connector if this is autoConnect activation
        if (isUnsupportedChainId || !connectorNetworkId) {
          await handleWrongNetwork(walletName)
        }

        let networkIdToChange

        if (queryNetworkId && queryChainId !== connectorChainId) {
          // If queryNetworkId is not equal to currentNetworkId
          networkIdToChange = queryNetworkId
        }
        else if (autoConnectNetworkId && autoConnectChainId !== connectorChainId) {
          // Change networkId to autoConnectNetworkId from localStorage
          networkIdToChange = autoConnectNetworkId
        }
        else if (isUnsupportedChainId || !connectorNetworkId) {
          // Change unsupported network to default
          networkIdToChange = contextRef.current.networkId
        }

        if (networkIdToChange) {
          await handleChangeChain(networkIdToChange, walletName)

          connectorNetworkId = networkIdToChange
        }

        const isNetworkChanged = connectorNetworkId !== contextRef.current.networkId

        const config = isNetworkChanged
          ? getConfig(connectorNetworkId as NetworkIds)
          : contextRef.current.config

        const [ unFormattedAddress, provider ] = await Promise.all([
          data.account || connector.getAccount(),
          connector.getProvider(),
        ])

        const address = unFormattedAddress && getAddress(unFormattedAddress)
        const library = new Web3Provider(provider as ExternalProvider)
        const contracts = createContracts(library, config)
        const chainId = config.network.chainId as ChainIds
        const networkId = config.network.id

        setContext({
          config,

          address,
          chainId,
          networkId,

          library,
          contracts,
          connector,

          activeWallet: walletName,
          autoConnectChecked: true,
        })
      }
    }
    catch (error: any) {
      // TODO move error to InjectedConnector
      const isMetaMask = walletName === constants.walletNames.metaMask
      const isUnsupportedChain = /unsupported chain id/i.test(error?.message)

      if (isMetaMask && isUnsupportedChain) {
        await handleChangeChain(contextRef.current.config.network.id, walletName)

        return activate(walletName)
      }

      const specialError = typeof getSpecialErrors === 'function' ? getSpecialErrors(error) : null
      const errorMessage = specialError || messages.connectErrors.unknown
      const needLoginToMetaMask = isMetaMask && error?.code === -32002

      openNotification({
        type: 'error',
        text: needLoginToMetaMask
          ? messages.connectErrors.metaMaskLogin
          : errorMessage,
      })

      // Reset all data that may be the cause of the error
      localStorage.clear()

      // Reset saved context to the initial, in case if saved connector that couldn't be activated
      // For example if we have 'ledger' wallet in the localStorage, we'll see an error after page reload
      if (autoConnectNetworkId) {
        deactivate()
      }
    }

    actions.ui.resetBottomLoader()
  }, [ actions, networkIdByChainId, chainIdByNetworkId, handleWrongNetwork, handleChangeChain, setContext, deactivate ])

  // Get account name
  useEffect(() => {
    const hasAccountName = Boolean(contextRef.current.accountName)

    if (address) {
      library.lookupAddress(address)
        .then((accountName) => setContext({ accountName }))
        .catch(() => hasAccountName ? setContext({ accountName: null }) : null)
    }
    else if (hasAccountName) {
      setContext({ accountName: null })
    }
  }, [ address, library, autoConnectChecked, setContext ])

  // Update network if it was changed in metamask
  useEffect(() => {
    if (!autoConnectChecked) {
      return
    }

    const handleNetworkChanged = (chainId: ChainIds) => {
      if (waitForChainId.current === chainId) {
        return
      }

      const isSupported = supportedChainIds.includes(chainId)

      if (isSupported) {
        if (chainId !== contextRef.current.config.network.chainId) {
          const networkId = networkIdByChainId[chainId]

          changeChain(networkId)
        }
      }
      else {
        handleWrongNetwork()
      }
    }

    const handleAccountsChanged = (accounts: string[]) => {
      const [ address ] = accounts

      if (address) {
        setContext({
          address: getAddress(address),
          accountName: null,
        })
      }
      else {
        deactivate()
      }
    }

    const handleUpdate = async (data: { account?: string, chainId?: ChainIds }) => {
      const { account, chainId } = data

      if (account) {
        handleAccountsChanged([ account ])
      }

      if (chainId) {
        handleNetworkChanged(chainId)
      }
    }

    const handleDeactivate = () => deactivate()

    const connector = contextRef.current.connector

    connector.on?.('AbstractConnectorUpdate', handleUpdate)
    connector.on?.('AbstractConnectorDeactivate', handleDeactivate)

    return () => {
      connector.removeListener?.('AbstractConnectorUpdate', handleUpdate)
      connector.removeListener?.('AbstractConnectorDeactivate', handleDeactivate)
    }
  }, [ library, networkIdByChainId, activate, deactivate, autoConnectChecked, setContext, changeChain, handleWrongNetwork ])

  // Handle auto connect
  useAutoConnect({ activate, contextRef, setContext })

  // Send analytic events on connect
  // Notifications on connect or disconnect
  // Set gnosis safe light theme on network change
  useConnectChange({
    autoConnectChecked,
    activeWallet,
    contextRef,
    isGnosis,
  })

  // Update localStorage network, wallet name, readonly address
  useStorageUpdate({
    config,
    address,
    activeWallet,
    autoConnectChecked,
  })

  // Change body class for network
  useNetworkClassName(networkId)

  const canChangeChain = useCanChangeChain({ activeWallet })

  return {
    ...context,
    canChangeChain,

    isGnosis,
    isGoerli,
    isMainnet,
    isHarbour,
    isHarbourGoerli,
    isHarbourMainnet,

    activate,
    deactivate,
    changeChain,
  }
}


export default useConfigContext
