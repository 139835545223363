import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { supportedNetworks } from 'config'
import { useStore, useConfig } from 'hooks'
import { analytics, modifiers } from 'helpers'


const storeSelector = (store: Store) => ({
  email: store.account.email,
  balances: store.account.balances,
  newsletter: store.account.newsletter,
  currencySymbol: store.account.currencySymbol,
  hasSoloValidators: Boolean(store.account.soloValidators.list.length),
})

const useAnalytics = () => {
  const { locale } = useRouter()
  const { chainId, activeWallet, address, config } = useConfig()
  const { balances, currencySymbol, newsletter, hasSoloValidators } = useStore(storeSelector)

  const {
    swiseTokenBalance,
    rewardTokenBalance,
    stakedTokenBalance,
    nativeTokenBalance,
  } = balances

  const isMainnet = chainId === supportedNetworks.mainnet.chainId

  useEffect(() => {
    if (address) {
      analytics.sentry.context('connect', {
        chainId,
        address,
        activeWallet,
      })

      analytics.statistics.registerProperty({ chainId })

      analytics.statistics.setUser({
        hasSoloValidators,
        currencySymbol,
        activeWallet,
        newsletter,
        address,
      })
    }
  }, [ address, chainId, activeWallet, isMainnet ])

  useEffect(() => {
    if (address && isMainnet) {
      analytics.statistics.setBalances(balances)
    }
  }, [ address, isMainnet, rewardTokenBalance, stakedTokenBalance, nativeTokenBalance, swiseTokenBalance ])

  useEffect(() => {
    analytics.statistics.registerProperty({ language: locale })
  }, [ locale ])

  useEffect(() => {
    window.networkConfig = modifiers.deepFreeze(config)
  }, [ config ])
}


export default useAnalytics
