export default {
  title: {
    en: 'Select Account',
    ru: 'Выберите Аккаунт',
    fr: 'Choisir un compte',
    es: 'Seleccionar Cuenta',
    pt: 'Selecionar conta',
    de: 'Konto auswählen',
    zh: '選取帳號',
  },
  loading: {
    en: 'Loading...',
    ru: 'Загрузка...',
    fr: 'Chargement...',
    es: 'Cargando...',
    pt: 'Carregando...',
    de: 'Lade...',
    zh: '載入中...',
  },
  note: {
    en: 'Don\'t see your account? Try switching to',
    ru: 'Не видите свой аккаунт? Попробуйте переключиться на',
    fr: 'Vous ne voyez pas votre compte ? Essayez de le choisir',
    es: 'No ve su cuenta? Pruebe cambiar a',
    pt: 'Não vê sua conta? Tente mudar',
    de: 'Deine Konto ist nicht dabei? Versuche umzuschalten auf',
    zh: '沒有看到您的帳號? 請切換到',
  },
  successNotification: {
    en: 'The address has been switched',
    ru: 'Адрес был переключен',
    fr: 'L\'adresse a été modifiée',
    es: 'La dirección ha sido cambiada',
    pt: 'O endereço foi trocado',
    de: 'Adresse wurde umgeschaltet',
    zh: '地址已經切換完畢',
  },
  types: {
    bip44: {
      en: 'BIP44 Standard',
      ru: 'Стандарт BIP44',
      fr: 'BIP44 Standard',
      es: 'Estándar BIP44',
      pt: 'BIP44 Standard',
      de: 'BIP44 Standard',
      zh: 'BIP44 標準',
    },
    live: {
      en: 'Ledger Live',
      ru: 'Ledger Live',
      fr: 'Ledger Live',
      es: 'Ledger Live',
      pt: 'Ledger Live',
      de: 'Ledger Live',
      zh: 'Ledger Live',
    },
    legacy: {
      en: 'Legacy',
      ru: 'Legacy',
      fr: 'Legacy',
      es: 'Legacy',
      pt: 'Legacy',
      de: 'Legacy',
      zh: '傳統',
    },
  },
}
