export class Cache {
  private cache: {
    data: any
    changed: boolean
    time: number | null
  }
  private timeout: NodeJS.Timeout | null
  private listeners: Array<() => void>

  constructor() {
    this.cache = {
      data: null,
      time: null,
      changed: false,
    }

    this.timeout = null
    this.listeners = []
  }

  callTimer(time: number) {
    clearTimeout(this.timeout as NodeJS.Timeout)
    // Need to save "this", so don't do this - setTimeout(this.resetData, time)
    this.timeout = setTimeout(() => this.resetData(), time)
  }

  resetData() {
    this.cache = {
      data: null,
      time: null,
      changed: false,
    }

    clearTimeout(this.timeout as NodeJS.Timeout)
    this.callListeners()
  }

  setData<T = any>(data: T, time: number = 1000 * 60 * 60 * 4) {
    const cacheTime = Date.now() + time

    this.cache = {
      data,
      time: cacheTime,
      changed: true,
    }

    this.callTimer(time)
    this.callListeners()
  }

  getData<T = any>(): T | null {
    return this.cache.data
  }

  get isChanged() {
    return this.cache.changed
  }

  callListeners() {
    this.listeners.forEach((handler) => handler())
  }

  addListener(handler: Cache['listeners'][number]) {
    this.listeners.push(handler)
  }

  removeListener(handler: Cache['listeners'][number]) {
    this.listeners = this.listeners.filter((_handler) => _handler !== handler)
  }
}

class CacheStorage {
  caches: {
    [key: string]: Cache
  }

  constructor() {
    this.caches = {}
  }

  get(id: string) {
    if (this.caches[id]) {
      return this.caches[id]
    }

    return this.caches[id] = new Cache()
  }
}


export default new CacheStorage
