import constants from '../constants'


type Input = {
  address: string
  contracts: Contracts
}

type MulticallReturnType = {
  swiseTokenBalance: BigNumber
  rewardTokenBalance: BigNumber
  stakedTokenBalance: BigNumber
  nativeTokenBalance: BigNumber
}

const multicallUserBalances = (values: Input): Promise<MulticallReturnType> => {
  const { contracts, address } = values

  const multicall = contracts.helpers.createMulticall<MulticallReturnType>([
    {
      returnName: 'stakedTokenBalance',
      contract: contracts.tokens.default.stakedTokenContract,
      methodName: 'balanceOf',
      args: [ address ],
      defaultValue: constants.blockchain.amount0,
    },
    {
      returnName: 'rewardTokenBalance',
      contract: contracts.tokens.default.rewardTokenContract,
      methodName: 'balanceOf',
      args: [ address ],
      defaultValue: constants.blockchain.amount0,
    },
    {
      returnName: 'swiseTokenBalance',
      contract: contracts.tokens.default.swiseTokenContract,
      methodName: 'balanceOf',
      args: [ address ],
      defaultValue: constants.blockchain.amount0,
    },
    {
      returnName: 'nativeTokenBalance',
      contract: contracts.helpers.multicallContract,
      methodName: 'getEthBalance',
      args: [ address ],
      defaultValue: constants.blockchain.amount0,
    },
  ])

  return multicall()
}


export default multicallUserBalances
