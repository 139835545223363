import React from 'react'
import Image from 'next/image'
import { modalWrapper } from 'modal'
import { commonMessages, constants, modifiers } from 'helpers'

import { Text } from 'components/ui'
import { Button } from 'components/inputs'
import { Modal } from 'components/feedback'
import { Box, Flex } from 'components/layout'

import useClaim from './util/useClaim'

import messages from './messages'
import s from './SwiseDropModal.module.scss'


type SwiseDropModalProps = {
  amount: string
  destroy: () => void
}

export const [ SwiseDropModal, openSwiseDropModal ] = (
  modalWrapper<SwiseDropModalProps>(UNIQUE_FILE_ID, (props) => {
    const { amount, closeModal, destroy } = props

    const { onButtonClick, canClaim } = useClaim({ closeModal, destroy })

    const croppedValue = modifiers.formatTokenValue({ value: amount })

    return (
      <Modal className={s.modal} analyticsName="swise drop" closeModal={closeModal}>
        <Box className={s.container} overflow="hidden" relative>
          <div className={s.animation}>
            <span />
            <span />
          </div>
          <Box className={s.content} p={32}>
            <Text
              message={messages.title}
              color="default"
              align="center"
              size={24}
            />
            <Box mt={24} noWrapper>
              <Flex justify="center">
                <Image
                  src={constants.tokensImages[constants.tokens.swise]}
                  alt="SWISE"
                  height={40}
                  width={40}
                />
                <Box ml={8} noWrapper>
                  <Text
                    message={croppedValue}
                    color="default"
                    size={40}
                  />
                </Box>
              </Flex>
            </Box>
            <Box mt={24} noWrapper>
              <Text
                className={s.text}
                message={messages.text}
                color="dark-gray"
                align="center"
                size={16}
                html
              />
            </Box>
            <Box mt={40} alignCenter>
              {
                !canClaim && (
                  <Box mb={24} noWrapper>
                    <Text
                      message={commonMessages.disabledMessages.readOnly}
                      color="danger"
                      size={16}
                    />
                  </Box>
                )
              }
              <Button
                message={messages.buttonText}
                disabled={!canClaim}
                color="orange"
                analyticsEventName="claim swise drop"
                onClick={onButtonClick}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    )
  })
)
