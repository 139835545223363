import React from 'react'
import { useFieldState } from 'formular'
import { commonMessages } from 'helpers'
import type { Form } from 'formular'

import { Button } from 'components/inputs'


type ConfirmButtonProps = {
  form: Form<{
    amount: string
    recipient: string
  }>
  loading?: boolean
  isReadOnlyWallet: boolean
  onClick: () => void
}

const ConfirmButton: React.FC<ConfirmButtonProps> = (props) => {
  const { form, loading, isReadOnlyWallet, onClick } = props

  // TODO - use const { values, errors } = useFormState after being repaired in the library
  const { value: amount, error: amountError } = useFieldState(form.fields.amount)
  const { value: recipient, error: recipientError } = useFieldState(form.fields.recipient)

  const isDisabled = !amount || !recipient || amountError || recipientError || isReadOnlyWallet

  return (
    <Button
      message={commonMessages.buttons.confirm}
      color="orange"
      loading={loading}
      disabled={isDisabled}
      dataTestId="send tokens modal submit"
      analyticsEventName="send tokens modal submit"
      onClick={onClick}
    />
  )
}


export default React.memo(ConfirmButton)
