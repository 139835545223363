import React from 'react'
import cx from 'classnames'

import s from './Icon.module.scss'


const allowedSizes = [ 14, 16, 20, 24, 28, 40 ] as const
const colors = [
  'info',
  'white',
  'black',
  'green',
  'success',
  'inverse',
  'default',
  'warning',
  'primary',
  'dark-gray',
  'green-states',
  'danger-states',
] as const

export type IconProps = {
  className?: string
  containerClassName?: string
  opacity?: 5
  name: string
  size?: typeof allowedSizes[number]
  color?: typeof colors[number]
}

// Find icons here - https://fontawesome.com/v6.0/icons?d=gallery&p=1&s=solid&m=free

const Icon: React.FC<IconProps> = (props) => {
  const { className, containerClassName, name, size = 20, color, opacity } = props

  const iconClassName = cx(name, s.icon, className, {
    [s[`opacity-${opacity}`]]: opacity,
    [s[`color-${color}`]]: color,
    [s[`size-${size}`]]: size,
  })

  return (
    <div key={name} className={cx(s.container, containerClassName)}>
      <i className={iconClassName} />
    </div>
  )
}


export default React.memo(Icon)
