import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'

import { Box } from 'components/layout'

import List from './List/List'
import Title from './Title/Title'
import useContent from './util/useContent'

import s from './Content.module.scss'


type ContentProps = {
  id: string
  buttonId: string
  isOpened: boolean
  onClick: () => void
}

const Content: React.FC<ContentProps> = (props) => {
  const { id, buttonId, isOpened, onClick } = props

  const { isDesktop } = useDevice()
  const items = useContent()

  const rootClassName = cx(s.root, {
    [s.compact]: !isOpened && isDesktop,
    [s.hide]: !isOpened && !isDesktop,
  })

  return (
    <Box className={rootClassName} relative onClick={isDesktop ? undefined : onClick}>
      <Box className={s.container} overflow="hidden" relative>
        <div
          className={s.content}
          id={id}
          aria-labelledby={buttonId}
          role="menu"
          tabIndex={0}
        >
          <Title className={s.title} />
          <Box mt={16}>
            <List items={items} />
          </Box>
        </div>
      </Box>
    </Box>
  )
}


export default React.memo(Content)
