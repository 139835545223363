import { useRouter } from 'next/router'


const useSelectedLanguage = () => {
  const { locale } = useRouter()

  return {
    isFrench: locale === 'fr',
    isGerman: locale === 'de',
    isEnglish: locale === 'en',
    isSpanish: locale === 'es',
    isChinese: locale === 'zh',
    isRussian: locale === 'ru',
    isPortuguese: locale === 'pt',
  }
}


export default useSelectedLanguage
