import AbstractConnector from './AbstractConnector'


class LedgerLiveConnector extends AbstractConnector {
  private _timeoutMilliseconds: number
  private _isLedgerLive: boolean
  private _targetOrigin: string
  private _chainId: number
  private _provider: any

  constructor(values: {
    chainId: LedgerLiveConnector['_chainId']
    targetOrigin?: LedgerLiveConnector['_targetOrigin']
    timeoutMilliseconds?: LedgerLiveConnector['_timeoutMilliseconds']
  }) {
    super({ supportedChainIds: [ values.chainId ] })

    const { chainId, timeoutMilliseconds = 60000, targetOrigin = '*' } = values

    this._timeoutMilliseconds = timeoutMilliseconds
    this._targetOrigin = targetOrigin
    this._isLedgerLive = false
    this._chainId = chainId

    this.handleClose = this.handleClose.bind(this)
    this.handleChainChanged = this.handleChainChanged.bind(this)
    this.handleNetworkChanged = this.handleNetworkChanged.bind(this)
    this.handleAccountsChanged = this.handleAccountsChanged.bind(this)
  }

  handleNetworkChanged(networkId: number) {
    this.emitUpdate({ provider: this._provider, chainId: networkId })
  }

  handleChainChanged(chainId: number) {
    this.emitUpdate({ chainId })
  }

  handleAccountsChanged(accounts: string[]) {
    this.emitUpdate({ account: accounts.length === 0 ? null : accounts[0] })
  }

  handleClose() {
    this.emitDeactivate()
  }

  async isLedgerLive() {
    if (this._isLedgerLive) {
      return this._isLedgerLive
    }

    if (typeof window === 'undefined') {
      return false
    }

    try {
      this._isLedgerLive = window.self !== window.top
    } catch (error) {
      this._isLedgerLive = false
    }

    return this._isLedgerLive
  }

  async activate() {
    if (!this._provider) {
      const { IFrameEthereumProvider } = await import(
        '@ledgerhq/iframe-provider'
      )

      this._provider = new IFrameEthereumProvider({
        timeoutMilliseconds: this._timeoutMilliseconds,
        targetOrigin: this._targetOrigin,
      })

      this._provider.on('disconnect', this.emitDeactivate)
      this._provider.on('networkChanged', this.handleNetworkChanged)
      this._provider.on('chainChanged', this.handleChainChanged)
      this._provider.on('accountsChanged', this.handleAccountsChanged)
      this._provider.on('close', this.handleClose)
    }

    const accounts = await this._provider.enable()

    return {
      provider: this._provider,
      chainId: this._chainId,
      account: accounts[0],
    }
  }

  deactivate() {
    if (this._provider) {
      this._provider.removeListener('close', this.handleClose)
      this._provider.removeListener('chainChanged', this.handleChainChanged)
      this._provider.removeListener('networkChanged', this.handleNetworkChanged)
      this._provider.removeListener('accountsChanged', this.handleAccountsChanged)
    }
    this.emitDeactivate()
  }

  async getProvider() {
    return this._provider
  }

  async getChainId() {
    return this._provider.send('eth_chainId')
  }

  async getAccount() {
    const accounts = await this._provider.send('eth_accounts')

    return accounts[0]
  }
}


export default LedgerLiveConnector
