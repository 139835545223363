import {
  Erc20Abi,
  OraclesAbi,
  UniPoolAbi,
  CurvePoolAbi,
  MulticallAbi,
  UniQuoterAbi,
  UniRouterAbi,
  SwiseTokenAbi,
  MerkleDropAbi,
  CurveGaugesAbi,
  VestingEscrowAbi,
  StakedEthTokenAbi,
  RewardEthTokenAbi,
  WhiteListManagerAbi,
  MerkleDistributorAbi,
  UniPositionManagerAbi,
  VestingEscrowFactoryAbi,
} from './abis'

import multicall from './multicall'

import type {
  VestingEscrowFactoryAbi as VestingEscrowFactoryType,
  UniPositionManagerAbi as UniPositionManagerType,
  MerkleDistributorAbi as MerkleDistributorType,
  WhiteListManagerAbi as WhiteListManagerType,
  StakedEthTokenAbi as StakedEthTokenType,
  RewardEthTokenAbi as RewardEthTokenType,
  VestingEscrowAbi as VestingEscrowType,
  CurveGaugesAbi as CurveGaugesType,
  SwiseTokenAbi as SwiseTokenType,
  MerkleDropAbi as MerkleDropType,
  CurvePoolAbi as CurvePoolType,
  UniRouterAbi as UniRouterType,
  UniQuoterAbi as UniQuoterType,
  MulticallAbi as MulticallType,
  UniPoolAbi as UniPoolType,
  OraclesAbi as OraclesType,
  Erc20Abi as Erc20Type,
} from './types'

import createContract from './createContract'
import createPoolContracts from './createPoolContracts'


const getStakedEthTokenContract = (library: Library, config: Config) => createContract<StakedEthTokenType>(
  config.addresses.tokens.default.staked,
  StakedEthTokenAbi,
  library
)

const getRewardEthTokenContract = (library: Library, config: Config) => createContract<RewardEthTokenType>(
  config.addresses.tokens.default.reward,
  RewardEthTokenAbi,
  library
)

const getSwiseTokenContract = (library: Library, config: Config) => createContract<SwiseTokenType>(
  config.addresses.tokens.default.swise,
  SwiseTokenAbi,
  library
)

const getDepositTokenContract = (library: Library, config: Config) => createContract<Erc20Type>(
  config.addresses.tokens.default.deposit,
  Erc20Abi,
  library
)

const getAlternativeDepositTokenContract = (library: Library, config: Config) => createContract<Erc20Type>(
  config.addresses.tokens.default.alternativeDeposit,
  Erc20Abi,
  library
)

const getMulticallContract = (library: Library, config: Config) => createContract<MulticallType>(
  config.addresses.base.multicall,
  MulticallAbi,
  library
)

const getWhiteListManagerContract = (library: Library, config: Config) => createContract<WhiteListManagerType>(
  config.addresses.base.whiteListManager,
  WhiteListManagerAbi,
  library
)

const getMerkleDropContract = (library: Library, config: Config) => createContract<MerkleDropType>(
  config.addresses.base.merkleDrop,
  MerkleDropAbi,
  library
)

const getStakedTokenNativeTokenUniPoolContract = (library: Library, config: Config) => createContract<UniPoolType>(
  config.addresses.pools.uniswap.stakedTokenNativeToken,
  UniPoolAbi,
  library
)

const getStakedTokenRewardTokenUniPoolContract = (library: Library, config: Config) => createContract<UniPoolType>(
  config.addresses.pools.uniswap.stakedTokenRewardToken,
  UniPoolAbi,
  library
)

const getStakedTokenSwiseTokenUniPoolContract = (library: Library, config: Config) => createContract<UniPoolType>(
  config.addresses.pools.uniswap.stakedTokenSwiseToken,
  UniPoolAbi,
  library
)

const getEthSwiseTokenUniPoolContract = (library: Library, config: Config) => createContract<UniPoolType>(
  config.addresses.pools.uniswap.ethSwiseToken,
  UniPoolAbi,
  library
)

const getUniQuoterContract = (library: Library, config: Config) => createContract<UniQuoterType>(
  config.addresses.helpers.uniswap.quoter,
  UniQuoterAbi,
  library
)

const getUniRouterContract = (library: Library, config: Config) => createContract<UniRouterType>(
  config.addresses.helpers.uniswap.router,
  UniRouterAbi,
  library
)

const getUniPositionManagerContract = (library: Library, config: Config) => createContract<UniPositionManagerType>(
  config.addresses.helpers.uniswap.positionManager,
  UniPositionManagerAbi,
  library
)

const getMerkleDistributorContract = (library: Library, config: Config) => createContract<MerkleDistributorType>(
  config.addresses.base.merkleDistributor,
  MerkleDistributorAbi,
  library
)

const getOraclesContract = (library: Library, config: Config) => createContract<OraclesType>(
  config.addresses.base.oracles,
  OraclesAbi,
  library
)

const getVestingEscrowFactoryContract = (library: Library, config: Config) => createContract<VestingEscrowFactoryType>(
  config.addresses.base.vestingEscrowFactory,
  VestingEscrowFactoryAbi,
  library
)

const getDaiTokenContract = (library: Library, config: Config) => createContract<Erc20Type>(
  config.addresses.tokens.special.dai,
  Erc20Abi,
  library
)

const getStethTokenContract = (library: Library, config: Config) => createContract<Erc20Type>(
  config.addresses.tokens.special.steth,
  Erc20Abi,
  library
)

const getCurveGaugeStakedTokenRewardTokenContract = (library: Library, config: Config) => createContract<CurveGaugesType>(
  config.addresses.pools.curve.gaugeStakedTokenRewardToken,
  CurveGaugesAbi,
  library
)

const getCurveGaugeStakedTokenDepositTokenContract = (library: Library, config: Config) => createContract<CurveGaugesType>(
  config.addresses.pools.curve.gaugeStakedTokenDepositToken,
  CurveGaugesAbi,
  library
)

const getCurveGaugeStakedTokenLidoStakedTokenContract = (library: Library, config: Config) => createContract<CurveGaugesType>(
  config.addresses.pools.curve.gaugeStakedTokenLidoStakedToken,
  CurveGaugesAbi,
  library
)

const getCurvePoolStakedTokenDepositTokenContract = (library: Library, config: Config) => createContract<CurvePoolType>(
  config.addresses.pools.curve.stakedTokenDepositToken,
  CurvePoolAbi,
  library
)
const getCurvePoolStakedTokenRewardTokenContract = (library: Library, config: Config) => createContract<CurvePoolType>(
  config.addresses.pools.curve.stakedTokenRewardToken,
  CurvePoolAbi,
  library
)

export const createContracts = (library: Library, config: Config) => {
  const multicallContract = getMulticallContract(library, config)

  return {
    helpers: {
      multicallContract,
      createMulticall: multicall(multicallContract as MulticallType),
      createErc20: (address: string) => createContract<Erc20Type>(address, Erc20Abi, library),
      createVestingEscrowDirect: (address: string) => createContract<VestingEscrowType>(address, VestingEscrowAbi, library),

      uniswap: {
        quoterContract: getUniQuoterContract(library, config),
        routerContract: getUniRouterContract(library, config),
        positionManagerContract: getUniPositionManagerContract(library, config),
      },
    },
    base: {
      poolContract: createPoolContracts(library, config),
      oraclesContract: getOraclesContract(library, config),
      merkleDropContract: getMerkleDropContract(library, config),
      whiteListManagerContract: getWhiteListManagerContract(library, config),
      merkleDistributorContract: getMerkleDistributorContract(library, config),
      vestingEscrowFactoryContract: getVestingEscrowFactoryContract(library, config),
    },
    tokens: {
      default: {
        swiseTokenContract: getSwiseTokenContract(library, config),
        depositTokenContract: getDepositTokenContract(library, config),
        stakedTokenContract: getStakedEthTokenContract(library, config),
        rewardTokenContract: getRewardEthTokenContract(library, config),
        alternativeDepositTokenContract: getAlternativeDepositTokenContract(library, config),
      },
      special: {
        daiTokenContract: getDaiTokenContract(library, config),
        stethTokenContract: getStethTokenContract(library, config),
      },
    },
    pools: {
      uniswap: {
        stakedTokenRewardTokenContract: getStakedTokenRewardTokenUniPoolContract(library, config),
        stakedTokenNativeTokenContract: getStakedTokenNativeTokenUniPoolContract(library, config),
        stakedTokenSwiseTokenContract: getStakedTokenSwiseTokenUniPoolContract(library, config),
        ethSwiseTokenContract: getEthSwiseTokenUniPoolContract(library, config),
      },
      curve: {
        poolStakedTokenRewardTokenContract: getCurvePoolStakedTokenRewardTokenContract(library, config),
        poolStakedTokenDepositTokenContract: getCurvePoolStakedTokenDepositTokenContract(library, config),
        gaugeStakedTokenRewardTokenContract: getCurveGaugeStakedTokenRewardTokenContract(library, config),
        gaugeStakedTokenDepositTokenContract: getCurveGaugeStakedTokenDepositTokenContract(library, config),
        gaugeStakedTokenLidoStakedTokenContract: getCurveGaugeStakedTokenLidoStakedTokenContract(library, config),
      },
    },
  }
}


export default createContracts
