import { useActions } from 'hooks'
import localStorage from 'local-storage'
import { getters, constants } from 'helpers'
import { formatEther } from '@ethersproject/units'


type Input = [
  InitialData.Account.ContractsData,
  InitialData.Account.RestData,
  boolean
]

type Output = Parameters<ReturnType<typeof useActions>['account']['setInitialData']>[0]

const getFormattedResult = async (values: Input): Promise<Output> => {
  const [ contractsData, restData, isWhitelisted ] = values

  const {
    swiseDrop,
    soloValidators,
    user: { email, newsletter, currency, isRegistered },
  } = restData

  const {
    swiseTokenBalance,
    rewardTokenBalance,
    stakedTokenBalance,
    nativeTokenBalance,
  } = contractsData

  const { soloStakedEth, soloRewardEth, soloStakingAPR } = getters.getSoloValidatorsBalance(soloValidators)

  const savedCurrency = localStorage.getItem<Currencies>(constants.localStorageNames.currency)

  return {
    email,
    newsletter,
    currency: savedCurrency || currency,
    soloValidators: {
      list: soloValidators,
      stakingAPR: soloStakingAPR,
    },
    balances: {
      swiseDrop: {
        index: swiseDrop.index,
        proof: swiseDrop.proof,
        amount: swiseDrop.amount,
      },
      soloStakedEth: formatEther(soloStakedEth),
      soloRewardEth: formatEther(soloRewardEth),
      swiseTokenBalance: formatEther(swiseTokenBalance),
      rewardTokenBalance: formatEther(rewardTokenBalance),
      stakedTokenBalance: formatEther(stakedTokenBalance),
      nativeTokenBalance: formatEther(nativeTokenBalance),
    },
    isWhitelisted,
    isRegistered,
    isFetched: true,
  }
}


export default getFormattedResult
