import React from 'react'
import cx from 'classnames'

import { Icon, Text } from 'components/ui'
import { Box, Flex } from 'components/layout'

import s from './Row.module.scss'


export type RowProps = {
  text: Intl.Message
  iconName: string
  isActive?: boolean
}

const Row: React.FC<RowProps> = (props) => {
  const { text, iconName, isActive } = props

  const rootClassName = cx(s.root, {
    [s.active]: isActive,
  })

  return (
    <Box className={rootClassName} pr={16} pv={12} overflow="hidden" relative>
      <Flex className={s.content} justify="start">
        <Icon className={s.icon} name={iconName} color="white" size={20} />
        <Box id="mainMenuItemText" ml={12} relative>
          <Text message={text} size={14} color="white" />
        </Box>
      </Flex>
    </Box>
  )
}


export default React.memo(Row)
