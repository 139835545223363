export default {
  title: {
    en: 'Transactions',
    ru: 'Транзакции',
    fr: 'Transactions',
    es: 'Transacciones',
    pt: 'Transações',
    de: 'Transaktionen',
    zh: '交易記錄',
  },
}
