import React from 'react'
import { analytics } from 'helpers'

import { Text, Icon } from 'components/ui'
import { Box, Flex } from 'components/layout'

import s from './MenuItem.module.scss'


type MenuItemProps = {
  title: Intl.Message
  iconName: string
  onClick: () => void
}

const MenuItem: React.FC<MenuItemProps> = (props) => {
  const { title, iconName, onClick } = props

  const handleClick = () => {
    if (typeof onClick === 'function') {
      analytics.statistics.sendEvent('settings menu link', {
        title: title.en,
      })

      onClick()
    }
  }

  return (
    <Box
      className={s.item}
      pv={16}
      pl={16}
      pr={8}
      dataTestId={`header settings menu link: ${title.en}`}
      onClick={handleClick}
    >
      <Flex justify="start">
        <Icon name={iconName} size={16} color="black" />
        <Box ml={8} noWrapper>
          <Text message={title} size={12} color="black" />
        </Box>
      </Flex>
    </Box>
  )
}


export default React.memo(MenuItem)
