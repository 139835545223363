import React from 'react'
import cx from 'classnames'

import Text from 'components/ui/Text/Text'
import Box from 'components/layout/Box/Box'
import Flex from 'components/layout/Flex/Flex'

import s from './PageButton.module.scss'


export type PageButtonProps = {
  page: number
  isActive: boolean
  onClick: (page: PageButtonProps['page']) => void
}

const PageButton: React.FC<PageButtonProps> = (props) => {
  const { page, isActive, onClick } = props

  const handleClick = () => {
    if (typeof onClick === 'function') {
      onClick(page)
    }
  }

  const boxClassName = cx(s.box, {
    [s.active]: isActive,
  })

  return (
    <button type="button" onClick={handleClick}>
      <Box className={boxClassName} radius={4} mh={4} alignCenter noWrapper>
        <Flex justify="center">
          <Text
            className={s.text}
            color={isActive ? 'white' : 'default'}
            message={String(page)}
            tag="span"
            size={14}
          />
        </Flex>
      </Box>
    </button>
  )
}


export default React.memo(PageButton)
