import React from 'react'
import { modalWrapper } from 'modal'

import { Text } from 'components/ui'
import { Modal } from 'components/feedback'
import { Box, Grid, Bone } from 'components/layout'

import AccountItem from './AccountItem/AccountItem'
import useSwitchAccount from './util/useSwitchAccount'

import messages from './messages'
import s from './SwitchAccountModal.module.scss'


export const [ SwitchAccountModal, openSwitchAccountModal ] = (
  modalWrapper(UNIQUE_FILE_ID, (props) => {
    const { closeModal } = props

    const {
      address,
      accounts,
      isFetching,
      nextPathType,
      onSelectAddress,
      onChangePathType,
    } = useSwitchAccount(closeModal)

    return (
      <Modal analyticsName="switch account" closeModal={closeModal}>
        <Box pv={16}>
          <Text
            message={messages.title}
            color="default"
            align="center"
            size={24}
          />
          <Box mt={40}>
            <Grid gutter={16} columns={2}>
              {
                isFetching ? (
                  [ ...new Array(10) ].map((_, index) => (
                    <Grid.Cell key={index}>
                      <Bone h={75} pw={100} radius={8} />
                    </Grid.Cell>
                  ))
                ) : (
                  accounts.map((account) => (
                    <Grid.Cell key={account}>
                      <AccountItem
                        address={account}
                        isActive={account === address}
                        onClick={onSelectAddress}
                      />
                    </Grid.Cell>
                  ))
                )
              }
            </Grid>
          </Box>
          <Box mt={24} alignCenter>
            {
              isFetching ? (
                <Text
                  message={messages.loading}
                  color="info"
                  size={14}
                />
              ) : (
                <>
                  <Text
                    message={messages.note}
                    color="dark-gray"
                    tag="span"
                    size={14}
                  />
                  &nbsp;
                  <button
                    type="button"
                    onClick={() => onChangePathType(nextPathType.type)}
                  >
                    <Text
                      className={s.buttonText}
                      message={nextPathType.title}
                      color="info"
                      tag="span"
                      size={14}
                    />
                  </button>
                </>
              )
            }
          </Box>
        </Box>
      </Modal>
    )
  })
)
