const getHarbourMainnetData = (): GetNodeOperators.Data => {
  const operators = {
    'Blockdaemon': [ '0xa11c358c20b2baa682956ea2c7a659f6e9583f96' ],
  }

  return {
    operators,
    multipurpose: [
      {
        id: `${operators['Blockdaemon'][0]}-1`,
        continent: 'asia',
        percent: 20,
      },
      {
        id: `${operators['Blockdaemon'][0]}-1`,
        continent: 'europe',
        percent: 40,
      },
      {
        id: `${operators['Blockdaemon'][0]}-1`,
        continent: 'australia',
        percent: 20,
      },
      {
        id: `${operators['Blockdaemon'][0]}-1`,
        continent: 'northAmerica',
        percent: 20,
      },
    ],
  }
}


export default getHarbourMainnetData
