import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import messages from './messages'


export interface UiState {
  initialRequestLoading: boolean
  bottomLoader: {
    content: Intl.Message | string | null
    iconLink?: string | null
    iconName?: string | null
  } | null
}

export const initialState: UiState = {
  initialRequestLoading: true,
  bottomLoader: null,
}

export const loadersSlice = createSlice({
  name: 'loaders',
  initialState,
  reducers: {
    setInitialRequestLoading: (state, action: PayloadAction<UiState['initialRequestLoading']>) => {
      state.initialRequestLoading = action.payload
    },
    setBottomLoader: (state, action: PayloadAction<UiState['bottomLoader']>) => {
      state.bottomLoader = action.payload
    },
    setBottomLoaderTransaction: (state, action: PayloadAction<string>) => {
      state.bottomLoader = {
        iconLink: action.payload,
        iconName: 'fa-light fa-up-right-from-square',
        content: messages.processingTransaction,
      }
    },
    resetBottomLoader: (state) => {
      state.bottomLoader = null
    },
  },
})


export const uiMethods = loadersSlice.actions

export default loadersSlice.reducer
