export default {
  language: {
    en: 'Choose language',
    ru: 'Выберите язык',
    fr: 'Choix de langue',
    es: 'Elegir idioma',
    pt: 'Escolha o seu idioma',
    de: 'Sprache wählen',
    zh: '選擇語言',
  },
}
