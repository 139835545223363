import React from 'react'
import Link from 'next/link'

import Text from 'components/ui/Text/Text'
import Box from 'components/layout/Box/Box'
import Flex from 'components/layout/Flex/Flex'
import Icon from 'components/ui/icons/Icon/Icon'

import messages from './messages'
import s from './StayNotifiedLink.module.scss'


type StayNotifiedLinkProps = {
  callback?: () => void
}

const StayNotifiedLink: React.FC<StayNotifiedLinkProps> = ({ callback }) => (
  <Link href="/settings" passHref>
    <a onClick={callback}>
      <Flex className={s.notifyLink} justify="start">
        <Box mr={8} noWrapper>
          <Icon
            name="fa-light fa-bell"
            color="info"
            size={16}
          />
        </Box>
        <Text
          className={s.text}
          message={messages.link}
          color="info"
          size={16}
        />
      </Flex>
    </a>
  </Link>
)


export default React.memo(StayNotifiedLink)
