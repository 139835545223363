import { analytics } from 'helpers'


type Input = Pick<InitialData.System.ContractsData, 'maintainerFee'> & {
  validatorsAPR: InitialData.System.RestData['validatorsAPR']
}

type Output = Pick<Store['system']['validators'], 'stakingAPR' | 'protocolFee'>

const getStakingData = (values: Input): Output => {
  const { maintainerFee, validatorsAPR } = values

  try {
    const protocolFee = maintainerFee / 100

    return {
      protocolFee,
      stakingAPR: validatorsAPR,
    }
  }
  catch (error) {
    analytics.sentry.exception('Method getStakingData has crashed', error as Error)

    return {
      stakingAPR: 0,
      protocolFee: 0,
    }
  }
}


export default getStakingData
