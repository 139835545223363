import React, { useRef, useEffect, useState, CSSProperties } from 'react'
import cx from 'classnames'

import Text from 'components/ui/Text/Text'
import Box from 'components/layout/Box/Box'

import TooltipContent from './TooltipContent/TooltipContent'
import createTooltip from './util/createTooltip'

import s from './Tooltip.module.scss'


type TooltipProps = {
  children?: React.ReactNode
  className?: string
  wrapperClassName?: string
  style?: CSSProperties
  content: React.ReactNode | Intl.Message
  inModal?: boolean
  center?: boolean
  dataTestId?: string
}

const TooltipWrapper: React.FC<TooltipProps> = (props) => {
  const { children, className, wrapperClassName, style, content, center, inModal, dataTestId } = props

  const [ mounted, setMounted ] = useState(false)

  const triggerRef = useRef<HTMLDivElement>(null)
  const tooltipRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (mounted) {
      return createTooltip({
        trigger: triggerRef.current as HTMLDivElement,
        tooltip: tooltipRef.current as HTMLDivElement,
        styles: s,
        center,
      })
    }
    else {
      setMounted(true)
    }
  }, [ center, mounted ])

  if (!mounted) {
    // ATTN We need to render only on the client, since we are using the portal
    return (
      <div
        className={wrapperClassName}
        style={style}
        data-testid={dataTestId}
      >
        {children}
      </div>
    )
  }

  const tooltipClassName = cx(s.tooltip, className, {
    [s.zIndex]: inModal,
  })

  return (
    <>
      <div
        ref={triggerRef}
        className={wrapperClassName}
        style={style}
        data-testid={dataTestId}
      >
        {children}
      </div>
      <TooltipContent className={tooltipClassName} ref={tooltipRef}>
        {
          React.isValidElement(content) ? (
            content
          ) : (
            <Box pv={8} ph={16}>
              <Text
                message={content as Intl.Message}
                color="default"
                size={14}
                html
              />
            </Box>
          )
        }
      </TooltipContent>
    </>
  )
}


export default TooltipWrapper
