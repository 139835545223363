import Head from 'next/head'
import { useIntl } from 'intl'
import { constants } from 'helpers'

import { Text } from 'components/ui'
import { Box, Flex } from 'components/layout'

import messages from './messages'
import s from './ErrorContent.module.scss'


const ErrorContent: React.FC = () => (
  <Flex className={s.container} justify="center">
    <div>
      <Text
        className={s.title}
        message={messages.title}
        color="default"
        align="center"
      />
      <Box mt={24}>
        <Text
          message={messages.text}
          color="default"
          align="center"
          size={40}
        />
      </Box>
      <Box mt={24}>
        <Text
          message={messages.note}
          color="default"
          align="center"
          size={24}
        />
      </Box>
    </div>
  </Flex>
)


export default ErrorContent
