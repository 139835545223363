export default {
  title: {
    en: 'Send tokens',
    ru: 'Отправить токены',
    fr: 'Envoyer des jetons',
    es: 'Enviar tokens',
    pt: 'Enviar tokens',
    de: 'Sende Tokens',
    zh: '發送代幣',
  },
}
