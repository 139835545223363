import { Contract } from '@ethersproject/contracts'
import { JsonRpcProvider } from '@ethersproject/providers'
import supportedNetworks from 'config/helpers/supportedNetworks'

import { UsdRateAbi, EthRateAbi } from './abis'
import type { UsdRateAbi as UsdRateType, EthRateAbi as EthRateType } from './types'


const createUsdRateContract = (provider: JsonRpcProvider, address: string) => {
  const result = new Contract(address, UsdRateAbi, provider) as unknown

  return result as UsdRateType
}

const createEthRateContract = (provider: JsonRpcProvider, address: string) => {
  const result = new Contract(address, EthRateAbi, provider) as unknown

  return result as EthRateType
}

let usdRatesCache: {
  USD: {
    ETH: UsdRateType
    EUR: UsdRateType
    GBP: UsdRateType
    GNO: UsdRateType
  }
  ETH: {
    XDAI: EthRateType
  }
} | null = null

export const getRatesContracts = () => {
  if (usdRatesCache) {
    return usdRatesCache
  }

  const mainnetProvider = new JsonRpcProvider(supportedNetworks.mainnet.url)
  const gnosisProvider = new JsonRpcProvider(supportedNetworks.gnosis.url)

  const ETH = createUsdRateContract(mainnetProvider, '0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419')
  const EUR = createUsdRateContract(mainnetProvider, '0xb49f677943BC038e9857d61E7d053CaA2C1734C1')
  const GBP = createUsdRateContract(mainnetProvider, '0x5c0Ab2d9b5a7ed9f470386e82BB36A3613cDd4b5')
  const GNO = createUsdRateContract(gnosisProvider, '0x22441d81416430a54336ab28765abd31a792ad37')

  const XDAI = createEthRateContract(mainnetProvider, '0x773616E4d11A78F511299002da57A0a94577F1f4')

  const result = {
    USD: { ETH, EUR, GBP, GNO },
    ETH: { XDAI },
  }

  usdRatesCache = result

  return result
}
