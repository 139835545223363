import { useEffect, useCallback, useRef } from 'react'
import { useObjectState, useConfig } from 'hooks'
import { formatEther } from '@ethersproject/units'
import { constants, actions, modifiers } from 'helpers'

import type { ListItemProps } from '../TransactionsList/ListItem/ListItem'

import messages from './messages'


type Output = {
  isLoading: boolean
  transactions: Array<ListItemProps>
}

const zeroAddress = '0x0000000000000000000000000000000000000000'

const useTransactions = (closeModal: () => void): Output => {
  const { config, address, library, contracts } = useConfig()

  const [ state, setState ] = useObjectState<Output>({
    isLoading: true,
    transactions: [],
  })

  const savedAddress = useRef(address)

  const fetchData = useCallback(async () => {
    if (
      !contracts
      || !library
      || !contracts.tokens.default.stakedTokenContract
      || !contracts.tokens.default.rewardTokenContract
      || !contracts.tokens.default.swiseTokenContract
    ) {
      setState({ isLoading: false })
      return
    }

    const commonParams = {
      toBlock: 'latest',
      fromBlock: 0,
    }

    const events = await Promise.all([
      contracts.tokens.default.stakedTokenContract.filters,
      contracts.tokens.default.rewardTokenContract.filters,
      contracts.tokens.default.swiseTokenContract.filters,
    ].reduce<Promise<any>[]>((acc, filters) => {
      const incoming = library.getLogs({
        ...filters.Transfer(address),
        ...commonParams,
      })

      const outgoing = library.getLogs({
        ...filters.Transfer(null, address),
        ...commonParams,
      })

      return [ ...acc, incoming, outgoing ]
    }, []))

    // reverse events to get them from newest to oldest
    const transactions = events
      .flat()
      .sort(actions.sortEvents)
      .map((event) => {
        let token, sender, recipient, parsedLog

        if (contracts.tokens.default.stakedTokenContract && event.address === config.addresses.tokens.default.staked) {
          parsedLog = contracts.tokens.default.stakedTokenContract.interface.parseLog(event)
          token = config.tokens.stakedToken
        }
        else if (contracts.tokens.default.swiseTokenContract && event.address === config.addresses.tokens.default.swise) {
          parsedLog = contracts.tokens.default.swiseTokenContract.interface.parseLog(event)
          token = constants.tokens.swise
        }
        else {
          // @ts-ignore
          parsedLog = contracts.tokens.default.rewardTokenContract.interface.parseLog(event)
          token = config.tokens.rewardToken
        }

        const { value, from, to } = parsedLog.args

        if (from === zeroAddress || from === config.addresses.base.merkleDrop) {
          sender = { ...messages.contract, values: { token } }
        }
        else if (from === address) {
          sender = messages.you
        }
        else {
          sender = modifiers.shortenAddress(from, -4)
        }

        if (to === zeroAddress) {
          recipient = { ...messages.contract, values: { token } }
        }
        else if (to === address) {
          recipient = messages.you
        }
        else {
          recipient = modifiers.shortenAddress(from, -4)
        }

        const formattedAmount = modifiers.formatTokenValue({
          value: formatEther(value),
        })

        return {
          amount: {
            token,
            value: formattedAmount,
            isExpenses: to !== address,
          },
          hash: {
            text: modifiers.shortenAddress(event.transactionHash, -4),
            link: `${config.pages.etherscan}/${event.transactionHash}`,
          },
          sender,
          recipient,
        }
      })
      .reverse()

    setState({ transactions, isLoading: false })
  }, [ address, contracts, library, config ])

  useEffect(() => {
    fetchData()
  }, [ fetchData ])

  useEffect(() => {
    if (savedAddress.current !== address) {
      closeModal()
    }
    else {
      fetchData()
    }
  }, [ fetchData ])

  return state
}


export default useTransactions
