import { requests } from 'helpers'
import { useCallback, useEffect } from 'react'
import { useConfig, useBalances } from 'hooks'


let skipRequest = false // If the request is too slow, you need to block the next request
let timer: NodeJS.Timeout
const timeout = 1000 * 15

const useAutoFetchBalances = () => {
  const { contracts, address } = useConfig()

  const {
    setRewardTokenBalance,
    setStakedTokenBalance,
    setNativeTokenBalance,
    setSwiseTokenBalance,
  } = useBalances()

  const fetchBalances = useCallback(async () => {
    if (contracts && address && !skipRequest) {
      skipRequest = true

      try {
        const {
          swiseTokenBalance,
          rewardTokenBalance,
          stakedTokenBalance,
          nativeTokenBalance,
        } = await requests.multicallUserBalances({ contracts, address })

        setNativeTokenBalance(nativeTokenBalance)
        setRewardTokenBalance(rewardTokenBalance)
        setStakedTokenBalance(stakedTokenBalance)
        setSwiseTokenBalance(swiseTokenBalance)
      }
      catch (_) {}

      skipRequest = false
    }
  }, [
    address,
    contracts,
    setStakedTokenBalance,
    setRewardTokenBalance,
    setNativeTokenBalance,
    setSwiseTokenBalance,
  ])

  useEffect(() => {
    if (timer) {
      clearInterval(timer)
    }

    timer = setInterval(fetchBalances, timeout)
  }, [ fetchBalances ])
}


export default useAutoFetchBalances
