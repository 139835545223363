export default {
  // TODO - need a translation
  error: {
    en: 'Failed to fetch system data. Please try later.',
    ru: 'Не удалось получить данные системы. Пожалуйста, попробуйте позже.',
    fr: 'Failed to fetch system data. Please try later.',
    es: 'Failed to fetch system data. Please try later.',
    pt: 'Failed to fetch system data. Please try later.',
    de: 'Failed to fetch system data. Please try later.',
    zh: 'Failed to fetch system data. Please try later.',
  },
}
