import { NodeOperator } from 'redux/system'


const sortOperators = (operator1: NodeOperator, operator2: NodeOperator) => {
  // sort based on the validators count in descending order
  return operator2.validators - operator1.validators
}


export default sortOperators
