import React from 'react'
import Image from 'next/image'
import { constants } from 'helpers'
import { useConfig } from 'hooks'

import ListItem from '../components/ListItem/ListItem'

import walletConnectIcon from 'public/static/images/logos/walletconnect-logo.svg'


const walletName = constants.walletNames.walletConnect

const WalletConnectLink: React.FC = () => {
  const { connectors, activate } = useConfig()

  const title = connectors[walletName].name

  const handleClick = () => {
    activate(walletName)

    setTimeout(() => {
      const closeBtn = document.getElementsByClassName('walletconnect-modal__close__wrapper')

      if (closeBtn?.length) {
        // Need to reload page, since the modal in connector cannot be opened a second time
        closeBtn[0].addEventListener('click', () => {
          window.location.reload()
        })
      }
    }, 300)
  }

  const imageNode = (
    <Image src={walletConnectIcon} width={25} height={25} alt="WalletConnect" />
  )

  return (
    <ListItem
      title={title}
      leftNode={imageNode}
      onClick={handleClick}
    />
  )
}


export default React.memo(WalletConnectLink)
