import { constants } from 'helpers'


type UseCanChangeChainProps = {
  activeWallet: ConfigProvider.Context['activeWallet']
}

const useCanChangeChain = ({ activeWallet }: UseCanChangeChainProps) => {
  const isSupportedDAppBrowser = (
    activeWallet === constants.walletNames.dAppBrowser
    && typeof window !== 'undefined'
    && Boolean(window.ethereum?.isMetaMask)
  )

  return (
    activeWallet === constants.walletNames.ledger
    || activeWallet === constants.walletNames.portis
    || activeWallet === constants.walletNames.metaMask
    || activeWallet === constants.walletNames.coinbase
    || activeWallet === constants.walletNames.walletConnect
    || activeWallet === constants.walletNames.monitorAddress
    || isSupportedDAppBrowser
    || !activeWallet
  )
}


export default useCanChangeChain
