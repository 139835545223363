import React from 'react'
import cx from 'classnames'
import { useConfig } from 'hooks'
import { commonMessages } from 'helpers'

import { Text } from 'components/ui'
import { Box } from 'components/layout'

import useAccountItem from './util/useAccountItem'

import s from './AccountItem.module.scss'


export type AccountItemProps = {
  address: string
  isActive: boolean
  onClick: (value: AccountItemProps['address']) => void
}

const AccountItem: React.FC<AccountItemProps> = (props) => {
  const { isActive } = props

  const { config } = useConfig()
  const { address, balance, onClick } = useAccountItem(props)

  const containerClassName = cx(s.container, { [s.active]: isActive })

  return (
    <Box
      className={containerClassName}
      alignCenter
      radius={8}
      relative
      pv={12}
      onClick={onClick}
    >
      {
        isActive && (
          <Box className={s.selectedTextContainer} alignCenter>
            <Text
              className={s.selectedText}
              message={commonMessages.selected}
              color="info"
              tag="span"
              size={12}
            />
          </Box>
        )
      }
      <Text
        message={address}
        color="default"
        size={16}
      />
      {
        Boolean(balance) && (
          <Box mt={8}>
            <Text
              message={`${balance} ${config.tokens.nativeToken}`}
              color="dark-gray"
              size={14}
            />
          </Box>
        )
      }
    </Box>
  )
}


export default React.memo(AccountItem)
