import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'

import { Text } from 'components/ui'
import { Box } from 'components/layout'

import messages from './messages'
import s from './Button.module.scss'


type ButtonProps = {
  isActive: boolean
}

const Button: React.FC<ButtonProps> = (props) => {
  const { isActive } = props

  const { isMobile } = useDevice()

  const className = cx(s.button, {
    [s.active]: isActive,
  })

  const { close, connect } = isMobile ? messages.mobile : messages
  const title = isActive ? close : connect

  return (
    <Box className={className} radius={8} dataTestId="connect button">
      <Text message={title} size={16} color="white" />
    </Box>
  )
}


export default React.memo(Button)
