import React from 'react'

import { Box } from 'components/layout'

import Token from './Token/Token'


const AddTokensToWallet: React.FC = () => (
  <>
    <Token type="staked" />
    <Box mt={16} noWrapper>
      <Token type="reward" />
    </Box>
    <Box mt={16} noWrapper>
      <Token type="swise" />
    </Box>
  </>
)


export default AddTokensToWallet
