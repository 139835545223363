import { createSlice, PayloadAction } from '@reduxjs/toolkit'


const currencySymbols = {
  'USD': '$',
  'EUR': '€',
  'GBP': '£',
}

const defaultCurrency = 'USD'

const defaultCurrencySymbol = currencySymbols[defaultCurrency]

export interface AccountState {
  newsletter: boolean
  email: string | null
  currency: Currencies
  currencySymbol: string
  soloValidators: {
    list: Array<{
      status: number
      balance: string
      publicKey: string
      createdAt: string
      currentAPR: number
      includedAt: string
      activatedAt: string
      isPenalised: boolean
      exitedAt: string | null
      withdrawalCredentials: string
    }>
    stakingAPR: number
  }
  balances: {
    soloStakedEth: string
    soloRewardEth: string
    swiseTokenBalance: string
    stakedTokenBalance: string
    rewardTokenBalance: string
    nativeTokenBalance: string
    swiseDrop: {
      index: string
      amount: string
      proof: string[]
    }
  }
  activatingDeposits: Array<{
    amount: string
    creationTime: number
    validatorIndex: string
    activationTime: number
  }>
  isWhitelisted: boolean
  isRegistered: boolean
  isFetched: boolean
}

export const initialState: AccountState = {
  email: null,
  newsletter: false,
  soloValidators: {
    list: [],
    stakingAPR: 0,
  },
  currency: defaultCurrency,
  currencySymbol: defaultCurrencySymbol,
  balances: {
    nativeTokenBalance: '0',
    stakedTokenBalance: '0',
    rewardTokenBalance: '0',
    swiseTokenBalance: '0',
    soloStakedEth: '0',
    soloRewardEth: '0',
    swiseDrop: {
      index: '',
      amount: '0',
      proof: [ '' ],
    },
  },
  activatingDeposits: [],
  isWhitelisted: true,
  isRegistered: false,
  isFetched: false,
}

type UpdateUserDataPayload = Partial<
  Pick<AccountState, 'email' | 'currency' | 'newsletter' | 'isRegistered' | 'balances'>
>

type UpdateSoloDataPayload = {
  balances: Pick<AccountState['balances'], 'soloStakedEth' | 'soloRewardEth'>
  soloValidators: AccountState['soloValidators'],
}

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setInitialData: (state, action: PayloadAction<Omit<AccountState, 'currencySymbol' | 'activatingDeposits'>>) => ({
      ...state,
      ...action.payload,
      currencySymbol: currencySymbols[action.payload.currency],
    }),
    updateUserData: (state, action: PayloadAction<UpdateUserDataPayload>) => ({
      ...state,
      ...action.payload,
      currencySymbol: action.payload.currency ? currencySymbols[action.payload.currency] : state.currencySymbol,
    }),
    updateSoloData: (state, action: PayloadAction<UpdateSoloDataPayload>) => ({
      ...state,
      balances: {
        ...state.balances,
        ...action.payload.balances,
      },
      soloValidators: action.payload.soloValidators,
    }),
    setStakedTokenBalance: (state, action: PayloadAction<AccountState['balances']['stakedTokenBalance']>) => {
      state.balances.stakedTokenBalance = action.payload
    },
    setRewardTokenBalance: (state, action: PayloadAction<AccountState['balances']['rewardTokenBalance']>) => {
      state.balances.rewardTokenBalance = action.payload
    },
    setSwiseTokenBalance: (state, action: PayloadAction<AccountState['balances']['swiseTokenBalance']>) => {
      state.balances.swiseTokenBalance = action.payload
    },
    setNativeTokenBalance: (state, action: PayloadAction<AccountState['balances']['nativeTokenBalance']>) => {
      state.balances.nativeTokenBalance = action.payload
    },
    setCurrency: (state, action: PayloadAction<AccountState['currency']>) => {
      state.currency = action.payload
      state.currencySymbol = currencySymbols[action.payload]
    },
    setSwiseDrop: (state, action: PayloadAction<Partial<AccountState['balances']['swiseDrop']>>) => {
      state.balances.swiseDrop = {
        ...state.balances.swiseDrop,
        ...action.payload,
      }
    },
    setActivatingDeposits: (state, action: PayloadAction<AccountState['activatingDeposits']>) => {
      state.activatingDeposits = action.payload
    },
    resetData: () => initialState,
  },
})


export const accountMethods = accountSlice.actions

export default accountSlice.reducer
