import React, { useMemo } from 'react'
import { Field, useFieldState } from 'formular'
import { useIntl } from 'intl'
import cx from 'classnames'
import Image, { StaticImageData } from 'next/image'

import Text from 'components/ui/Text/Text'
import Box from 'components/layout/Box/Box'
import Flex from 'components/layout/Flex/Flex'
import Icon from 'components/ui/icons/Icon/Icon'
import Dropdown from 'components/ui/Dropdown/Dropdown'

import s from './Select.module.scss'


const sizes = [ 38, 50 ] as const

type SelectSize = typeof sizes[number]

type Option = {
  title: string | Intl.Message
  value: string | number
  image?: string | StaticImageData
  imageSize?: number
}

export type SelectProps = {
  className?: string
  field: Field<Option['value']>
  size?: SelectSize
  options: Option[]
  placeholder?: Intl.Message | string
  dataTestId?: string
  direction?: 'top' | 'bottom'
  disabled?: boolean
}

const Select: React.FC<SelectProps> = (props) => {
  const { className, field, placeholder, options, dataTestId, size = 38, direction, disabled = false } = props

  const intl = useIntl()
  const { value } = useFieldState(field)

  const rootClassName = cx(s.root, className, {
    [s[`size-${size}`]]: size,
    [s.disabled]: disabled,
  })

  const placeholderText = typeof placeholder === 'object'
    ? intl.formatMessage(placeholder)
    : placeholder

  const { title, image } = useMemo<{ title?: string, image?: string }>(() => {
    if (value) {
      return options.find((option) => option.value === value) || {}
    }

    return {}
  }, [ options, value ])

  return (
    <Dropdown
      className={s.wFull}
      buttonClassName={cx(s.block, s.wFull)}
      direction={direction}
      disabled={disabled}
      controlNode={(isOpened) => (
        <Flex className={rootClassName} align="center" justify="start" dataTestId={dataTestId}>
          {
            Boolean(image) && (
              <Box className={s.rootImage} pl={12}>
                <Image src={image as string} width={32} height={32} alt="" />
              </Box>
            )
          }
          <Box className={s.select} pl={16} pr={32} noWrapper>
            <Text
              message={title || placeholderText}
              align="left"
              color="default"
            />
          </Box>
          <div className={cx(s.rightIcon, isOpened ? 'fa-rotate-180' : null)}>
            <Icon name="fa-light fa-chevron-down" size={16} color="default" />
          </div>
        </Flex>
      )}
    >
      {
        options.map(({ title, value, image, imageSize = 32 }, index) => (
          <button
            key={index}
            className={cx(s.option, s.wFull)}
            data-testid={`${dataTestId}: ${value}`}
            onClick={() => field.set(value)}
          >
            <Box p={8} noWrapper>
              <Flex align="center" justify="start">
                {
                  Boolean(image) && (
                    <Image
                      src={image as string}
                      height={imageSize}
                      width={imageSize}
                      alt=""
                    />
                  )
                }
                <Box pl={16} noWrapper>
                  <Text
                    message={title}
                    size={14}
                    align="left"
                    color="black"
                  />
                </Box>
              </Flex>
            </Box>
          </button>
        ))
      }
    </Dropdown>
  )
}


export default React.memo(Select)
