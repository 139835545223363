import initMonitorAddress from './monitorAddress/init'
import initWalletConnect from './walletConnect/init'
import initCoinbase from './coinbase/init'
import initInjected from './injected/init'
import initPortis from './portis/init'
import initLedger from './ledger/init'
import initGnosis from './gnosis/init'


const createConnectorsHandlers: InitConnectors.CreateConnectorHandler<WalletIds>[] = [
  initMonitorAddress,
  initWalletConnect,
  initCoinbase,
  initInjected,
  initLedger,
  initPortis,
  initGnosis,
]

const savedConnectors: { [chainId: number]: InitConnectors.Result } = {}

const getConnectors = (config: Config) => {
  let result: InitConnectors.Result = {} as InitConnectors.Result

  if (savedConnectors[config.network.chainId]) {
    return savedConnectors[config.network.chainId]
  }

  createConnectorsHandlers.forEach((initConnector) => {
    result = {
      ...result,
      ...initConnector({ config }),
    } as InitConnectors.Result
  })

  savedConnectors[config.network.chainId] = result

  return result
}


export {
  getConnectors,
}
