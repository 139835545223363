export default {
  name: {
    en: 'DApp Browser',
    ru: 'DApp Браузер',
    fr: 'Navigateur DApp',
    es: 'DApp Browser',
    pt: 'DApp Browser',
    de: 'DApp Browser',
    zh: 'DApp 瀏覽器',
  },
  activationMessage: {
    en: 'Waiting for authorisation request to be approved in DApp Browser',
    ru: 'Ожидаем подтверждения запроса на авторизацию в DApp Браузере',
    fr: 'Attente de l\'approbation de la requête d\'autorisation dans le navigateur DApp',
    es: 'Esperando a que la petición de autorización sea aprobada en DApp Browser',
    pt: 'Aguardando a aprovação da solicitação de autorização no DApp Browser',
    de: 'Warten auf die Bestätigung der DApp Browser Autorisierung',
    zh: '等待 DApp瀏覽器 允許存取',
  },
  errors: {
    noProvider: {
      en: 'No Ethereum browser extension detected, visit from a DApp Browser on mobile',
      ru: 'Расширение браузера для Ethereum не обнаружено, зайдите из DApp Браузера на мобильном устройстве',
      fr: 'Aucune extension compatible Ethereum detectée, merci de d\'utiliser un navigateur DApp mobile',
      es: 'No se ha detectado la cartera de Ethereum del navegador, visite desde DApp Browser móvil',
      pt: 'Nenhuma extensão de navegador Ethereum detectada, visite de um navegador DApp no celular',
      de: 'Keine Ethereum Browser Extension gefunden, probiere es mit dem mobilen DApp Browser',
      zh: '沒有偵測到Ethereum覽器擴充套件，請使用行動版DApp瀏覽器瀏覽',
    },
    unsupportedNetwork: {
      en: 'DApp Browser is connected to an unsupported network',
      ru: 'DApp Браузер подключен к не поддерживаемой сети',
      fr: 'Le navigateur DAPP est connecté à un réseau non supporté',
      es: 'DApp Browser está conectado a una red no soportada',
      pt: 'DApp Browser está conectada a uma rede não suportada',
      de: 'DApp Browser ist mit einem nicht unterstützten Netzwerk verbunden',
      zh: 'DApp瀏覽器 連接到一個不支援的網路',
    },
    authorize: {
      en: 'Please authorize access to your DApp Browser account',
      ru: 'Пожалуйста, разрешите доступ к Вашему аккаунту DApp Браузера',
      fr: 'Merci d\'autoriser l\'accès au compte de votre navigateur DApp',
      es: 'Favor autorizar acceso a su cuenta de DApp Browser',
      pt: 'Por favor autorize o acesso à sua conta do DApp Browser',
      de: 'Bitte autorisiere den Zugriff auf Dein DApp Browser Konto',
      zh: '請允許存取您的 DApp瀏覽器',
    },
  },
}
