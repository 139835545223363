import React from 'react'
import { useDevice } from 'device'

import { Dropdown } from 'components/ui'
import { Box, Flex } from 'components/layout'
import SwiseBalance from 'compositions/SwiseBalance/SwiseBalance'

import Menu from './Menu/Menu'
import Address from './Address/Address'
import ActionIcon from './ActionIcon/ActionIcon'
import useBuyNativeToken from './util/useBuyNativeToken'
import SwiseDropButton from './SwiseDropButton/SwiseDropButton'
import NativeTokenBalance from './NativeTokenBalance/NativeTokenBalance'
import AnalyticsNotify from './AnalyticsNotify/AnalyticsNotify'

import useSwiseDrop from './util/useSwiseDrop'
import SettingsModals from './SettingsModals/SettingsModals'

import s from './Settings.module.scss'


const settingsDataTestId = 'header-settings-button'
const dropdownDataTestId = 'settings-dropdown'

const Settings: React.FC = () => {
  const buyNativeToken = useBuyNativeToken()
  const { isTablet, isMobile } = useDevice()
  const { hasSwiseDrop, openSwiseDropModal } = useSwiseDrop()

  return (
    <Box pv={24}>
      {
        (isTablet || isMobile) ? (
          <Flex justify="start">
            {
              hasSwiseDrop && (
                <Box mr={16}>
                  <SwiseDropButton onClick={openSwiseDropModal as () => void} />
                </Box>
              )
            }
            <Dropdown
              className={s.mobileMenu}
              dataTestId={settingsDataTestId}
              controlNode={(isActive: boolean) => (
                <ActionIcon isActive={isActive} />
              )}
            >
              <div data-testid={dropdownDataTestId}>
                <Box p={8}>
                  <SwiseBalance isCompact />
                  <Box mt={8}>
                    <NativeTokenBalance buyNativeToken={buyNativeToken} isCompact />
                  </Box>
                  <Box mt={8}>
                    <Address isCompact />
                  </Box>
                </Box>
                <Menu buyNativeToken={buyNativeToken} />
              </div>
            </Dropdown>
          </Flex>
        ) : (
          <Flex justify="end">
            <Box ml={16}>
              <SwiseBalance />
            </Box>
            <Box ml={16}>
              <NativeTokenBalance buyNativeToken={buyNativeToken} />
            </Box>
            <Box ml={16}>
              <Address />
            </Box>
            {
              hasSwiseDrop && (
                <Box ml={16}>
                  <SwiseDropButton onClick={openSwiseDropModal as () => void} />
                </Box>
              )
            }
            <Box className={s.dropdownContainer} ml={16} relative>
              <Dropdown
                className={s.menu}
                dataTestId={settingsDataTestId}
                controlNode={(isActive: boolean) => (
                  <ActionIcon isActive={isActive} />
                )}
              >
                <div data-testid={dropdownDataTestId}>
                  <Menu buyNativeToken={buyNativeToken} />
                </div>
              </Dropdown>
            </Box>
          </Flex>
        )
      }
      <SettingsModals hasSwiseDrop={hasSwiseDrop} />
      <AnalyticsNotify />
    </Box>
  )
}


export default React.memo(Settings)
