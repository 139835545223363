import { useEffect } from 'react'
import { constants } from 'helpers'
import { useRouter } from 'next/router'
import localStorage from 'local-storage'


// We can lose query parameters as we go through the pages. It is better to save them for the
// whole session and use them from anywhere in the application
const useQueryParams = () => {
  const { query, isReady } = useRouter()

  useEffect(() => {
    if (isReady) {
      const supportedQueryNames = Object.values(constants.queryNames)

      supportedQueryNames.forEach((name) => {
        const value = query[name]
        const savedValue = localStorage.getSessionItem(name)

        if (value) {
          localStorage.setSessionItem(name, value)
        }
        else if (savedValue) {
          localStorage.removeSessionItem(name)
        }
      })
    }
  }, [ query, isReady ])
}


export default useQueryParams
