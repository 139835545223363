import localStorage from 'local-storage'


const resetWalletConnect = () => {
  const modal = document.getElementById('walletconnect-wrapper')

  if (modal) {
    document.body.removeChild(modal)
  }

  // WalletConnect saves the current connection in LocalStorage
  // and if there is an error in this connection, then you will no longer be able to use the WalletConnect
  localStorage.removeItem('walletconnect')

  setTimeout(() => {
    window.location.reload()
  }, 1000)
}


export default resetWalletConnect
