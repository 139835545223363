/* eslint-disable max-len */
export default {
  name: {
    en: 'Tally',
    ru: 'Tally',
    fr: 'Tally',
    es: 'Tally',
    pt: 'Tally',
    de: 'Tally',
    zh: 'Tally',
  },
  activationMessage: {
    en: 'Waiting for authorisation request to be approved in Tally',
    ru: 'Ожидаем подтверждения запроса на авторизацию в Tally',
    fr: 'Attente de l\'approbation de la requête d\'autorisation dans Tally',
    es: 'Esperando la aprobación a la petición de autorización en Tally',
    pt: 'Aguardando a aprovação da solicitação de autorização no Tally',
    de: 'Warte auf die Autorisierungsbestätigung von Tally',
    zh: '等待 Tally 允許存取',
  },
  errors: {
    noProvider: {
      en: 'No Ethereum browser extension detected, install Tally on desktop or visit from a Tally browser on mobile',
      ru: 'Расширение браузера для Ethereum не обнаружено, установите Tally на компьютере или зайдите из браузера Tally на мобильном.',
      fr: 'Aucune extension compatible Ethereum detectée, merci d\'installer l\'extension Tally ou d\'utiliser Tally browser',
      es: 'No se ha detectado la cartera de Ethereum del navegador, instale Metamask en escritorio o visite desde el app Tally para móvil',
      pt: 'Nenhuma extensão do navegador Ethereum detectada, instale o Tally no desktop ou visite de um navegador Tally no celular',
      de: 'Keine Ethereum Browser Extension gefunden. Installiere Tally auf dem Desktop oder probiere es mit einem mobilen Tally browser',
      zh: '沒有偵測到 Ethereum 瀏覽器擴充套件，請安裝 Tally 桌面版應用程式或改以行動版 Tally 瀏覽器訪問',
    },
    unsupportedNetwork: {
      en: 'Tally is connected to an unsupported network',
      ru: 'Tally подключен к не поддерживаемой сети',
      fr: 'Tally est connecté à un réseau non supporté',
      es: 'Tally está conectado a una red no compatible',
      pt: 'Tally está conectada a uma rede não suportada',
      de: 'Tally ist mit einem nicht unterstützten Netzwerk verbunden',
      zh: 'Tally連接到一個不支援的網路',
    },
    authorize: {
      en: 'Please authorize access to your Tally account',
      ru: 'Пожалуйста, разрешите доступ к Вашему Tally аккаунту',
      fr: 'Merci d\'autoriser l\'accès à votre compte Tally',
      es: 'Favor autorizar acceso a su cuenta de Tally',
      pt: 'Por favor autorize o acesso à sua conta Tally',
      de: 'Bitte autorisiere den Zugriff auf Dein Tally Konto',
      zh: '請允許存取您的 Tally 錢包',
    },
  },
}
