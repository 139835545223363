import React from 'react'
import Image from 'next/image'
import { constants } from 'helpers'
import { useConfig } from 'hooks'

import ListItem from '../components/ListItem/ListItem'

import ledgerIcon from 'public/static/images/logos/ledger-logo.png'


const walletName = constants.walletNames.ledger

const LedgerLink: React.FC = () => {
  const { connectors, activate } = useConfig()

  const title = connectors[walletName].name

  const handleClick = () => activate(walletName)

  const imageNode = (
    <Image src={ledgerIcon} width={25} height={25} alt="Ledger" />
  )

  return (
    <ListItem
      title={title}
      leftNode={imageNode}
      onClick={handleClick}
    />
  )
}


export default React.memo(LedgerLink)
