import messages from './messages'


type Input = {
  time: number
  intl: Intl.Data
  options?: {
    withSeconds?: boolean
  }
}

const formatTime = (time: number) => time < 10 ? `0${time}` : time

const getTime = ({ time, intl, options = {} }: Input): string => {
  if (!time) {
    return ''
  }

  const { withSeconds = true } = options || {}

  const secondsPrefix = intl.formatMessage(messages.seconds)
  const minutesPrefix = intl.formatMessage(messages.minutes)
  const hoursPrefix = intl.formatMessage(messages.hours)
  const daysPrefix = intl.formatMessage(messages.days)

  const values = {
    days: Math.floor(time / 60 / 60 / 1000 / 24),
    hours: formatTime(Math.floor((time / 60 / 60 / 1000) % 24)),
    minutes: formatTime(Math.floor((time / 60 / 1000) % 60)),
    seconds: formatTime(Math.floor((time / 1000) % 60)),
  }

  const days = `${values.days}${daysPrefix}`
  const hours = `${values.hours}${hoursPrefix}`
  const minutes = `${values.minutes}${minutesPrefix}`
  const seconds = withSeconds ? ` ${values.seconds}${secondsPrefix}` : ''

  if (Number(values.days)) {
    return `${days} ${hours} ${minutes}${seconds}`
  }

  if (Number(values.hours)) {
    return `${hours} ${minutes}${seconds}`
  }

  if (Number(values.minutes)) {
    return `${minutes}${seconds}`
  }

  return seconds
}


export default getTime
