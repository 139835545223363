import getChainIdByNetworkIdE2E from './getChainIdByNetworkIdE2E'
import supportedNetworks from './supportedNetworks'


let ids = Object.values(supportedNetworks).map(({ chainId }) => chainId)


let chainIdByNetworkIdE2E = {} as Record<NetworkIds, number>

if (!IS_PROD || IS_PREVIEW) {
  chainIdByNetworkIdE2E = getChainIdByNetworkIdE2E()

  const chainIdsE2E = Object.values(chainIdByNetworkIdE2E)
  const originalChainIds: number[] = []

  Object.keys(chainIdByNetworkIdE2E).forEach((networkId) => {
    const originalChainId = Object.values(supportedNetworks).find(({ id }) => id === networkId)?.chainId

    if (originalChainId) {
      originalChainIds.push(originalChainId)
    }
  })

  ids = ids.filter((id) => !originalChainIds.includes(id))

  // @ts-ignore
  ids.push(...chainIdsE2E)
}


export { chainIdByNetworkIdE2E }

export default Array.from(new Set(ids))
