import { useMemo, useCallback } from 'react'
import { analytics, constants } from 'helpers'
import { useActions, useStore } from 'hooks'
import localStorage from 'local-storage'


const currencyOptions = [
  {
    value: 'USD',
    title: 'USD',
    icon: 'fa-solid fa-dollar-sign',
  },
  {
    value: 'EUR',
    title: 'EUR',
    icon: 'fa-solid fa-euro-sign',
  },
  {
    value: 'GBP',
    title: 'GBP',
    icon: 'fa-solid fa-sterling-sign',
  },
]

const storeSelector = (store: Store) => ({
  currency: store.account.currency,
})

const useCurrency = () => {
  const actions = useActions()
  const { currency } = useStore(storeSelector)

  const handleChange = useCallback((value) => {
    actions.account.setCurrency(value)
    localStorage.setItem(constants.localStorageNames.currency, value)
    analytics.statistics.sendEvent('update currency', {
      oldCurrency: currency,
      currency: value,
    })
  }, [ currency ])

  const [ selectedOption, options ] = useMemo(() => {
    const options = currencyOptions.filter(({ value }) => currency !== value)
    const selectedOption = currencyOptions.find(({ value }) => currency === value)

    return [ selectedOption, options ]
  }, [ currency ])

  return {
    options,
    selectedOption,
    handleChange,
  }
}


export default useCurrency
