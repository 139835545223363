import React from 'react'
import cx from 'classnames'
import { useIntl } from 'intl'

import Text from 'components/ui/Text/Text'
import Box from 'components/layout/Box/Box'
import Tooltip from 'components/feedback/Tooltip/Tooltip'

import s from './BorderBlockWithTitle.module.scss'


export type BorderBlockWithTitleProps = {
  title: Intl.Message | string
  value?: Intl.Message | string
  content?: React.ReactNode
  tooltipContent?: React.ReactNode
  dataTestIdPrefix?: string
}

const BorderBlockWithTitle: React.FC<BorderBlockWithTitleProps> = (props) => {
  const { title, value, content, tooltipContent, dataTestIdPrefix } = props

  const intl = useIntl()

  const containerClassName = cx(s.container, {
    [s.withHover]: tooltipContent,
  })

  const titleText = typeof title === 'object' ? intl.formatMessage(title) : title
  const isSmall = titleText.length <= 15

  const structure = (
    <Box className={containerClassName} ph={8} pv={16} radius={8} relative alignCenter>
      <Box className={s.titleContainer}>
        <Text
          className={s.title}
          message={title}
          tag="span"
          size={isSmall ? 12 : 10}
          dataTestId={dataTestIdPrefix ? `${dataTestIdPrefix}-header` : ''}
        />
      </Box>
      {
        value && (
          <Text
            message={value}
            color="default"
            size={14}
            dataTestId={dataTestIdPrefix ? `${dataTestIdPrefix}-value` : ''}
          />
        )
      }
      {
        Boolean(content) && (
          content
        )
      }
    </Box>
  )

  if (tooltipContent) {
    return (
      <Tooltip content={tooltipContent} center inModal>
        {structure}
      </Tooltip>
    )
  }

  return structure
}


export default React.memo(BorderBlockWithTitle)
