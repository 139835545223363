import { useCallback } from 'react'
import { useSelector as useStore } from 'react-redux'
import { parseEther, formatEther } from '@ethersproject/units'

import useConfig from '../data/useConfig'
import useActions from '../store/useActions'


type Output = {
  fetchAndSetStakedTokenBalance: () => Promise<void>
  fetchAndSetRewardTokenBalance: () => Promise<void>
  fetchAndSetSwiseTokenBalance: () => Promise<void>
  fetchAndSetNativeTokenBalance: () => Promise<void>
  setSwiseTokenBalance: (balance: BigNumber) => void
  setStakedTokenBalance: (balance: BigNumber) => void
  setRewardTokenBalance: (balance: BigNumber) => void
  setNativeTokenBalance: (balance: BigNumber) => void
}

const storeSelector = (store: Store) => ({
  swiseTokenBalance: store.account.balances.swiseTokenBalance,
  rewardTokenBalance: store.account.balances.rewardTokenBalance,
  stakedTokenBalance: store.account.balances.stakedTokenBalance,
  nativeTokenBalance: store.account.balances.nativeTokenBalance,
})

const useBalances = (): Output => {
  const actions = useActions()
  const { address, contracts } = useConfig()

  const {
    swiseTokenBalance,
    stakedTokenBalance,
    rewardTokenBalance,
    nativeTokenBalance,
  } = useStore(storeSelector)

  const setStakedTokenBalance = useCallback((newBalance: BigNumber) => {
    const stakedTokenBalanceBN = parseEther(stakedTokenBalance)
    const isChanged = !stakedTokenBalanceBN.eq(newBalance)

    if (isChanged) {
      const value = formatEther(newBalance)
      actions.account.setStakedTokenBalance(value)
    }
  }, [ stakedTokenBalance ])

  const setRewardTokenBalance = useCallback((newBalance: BigNumber) => {
    const rewardTokenBalanceBN = parseEther(rewardTokenBalance)
    const isChanged = !rewardTokenBalanceBN.eq(newBalance)

    if (isChanged) {
      const value = formatEther(newBalance)
      actions.account.setRewardTokenBalance(value)
    }
  }, [ rewardTokenBalance ])

  const setSwiseTokenBalance = useCallback((newBalance: BigNumber) => {
    const swiseBN = parseEther(swiseTokenBalance)
    const isChanged = !swiseBN.eq(newBalance)

    if (isChanged) {
      const value = formatEther(newBalance)
      actions.account.setSwiseTokenBalance(value)
    }
  }, [ swiseTokenBalance ])

  const setNativeTokenBalance = useCallback((newBalance: BigNumber) => {
    const nativeTokenBalanceBN = parseEther(nativeTokenBalance)
    const isChanged = !nativeTokenBalanceBN.eq(newBalance)

    if (isChanged) {
      const value = formatEther(newBalance)
      actions.account.setNativeTokenBalance(value)
    }
  }, [ nativeTokenBalance ])

  const fetchAndSetStakedTokenBalance = useCallback(async () => {
    if (contracts && address && contracts.tokens.default.stakedTokenContract) {
      const stakedTokenBalance = await contracts.tokens.default.stakedTokenContract.balanceOf(address)

      setStakedTokenBalance(stakedTokenBalance)
    }
  }, [ address, contracts, setStakedTokenBalance ])

  const fetchAndSetRewardTokenBalance = useCallback(async () => {
    if (contracts && address && contracts.tokens.default.rewardTokenContract) {
      const rewardTokenBalance = await contracts.tokens.default.rewardTokenContract.balanceOf(address)

      setRewardTokenBalance(rewardTokenBalance)
    }
  }, [ address, contracts, setRewardTokenBalance ])

  const fetchAndSetSwiseTokenBalance = useCallback(async () => {
    if (contracts && address && contracts.tokens.default.swiseTokenContract) {
      const swise = await contracts.tokens.default.swiseTokenContract.balanceOf(address)

      setSwiseTokenBalance(swise)
    }
  }, [ address, contracts, setSwiseTokenBalance ])

  const fetchAndSetNativeTokenBalance = useCallback(async () => {
    if (contracts && address && contracts.helpers.multicallContract) {
      const eth = await contracts.helpers.multicallContract.getEthBalance(address)

      setNativeTokenBalance(eth)
    }
  }, [ address, contracts, setNativeTokenBalance ])

  return {
    fetchAndSetNativeTokenBalance,
    fetchAndSetRewardTokenBalance,
    fetchAndSetStakedTokenBalance,
    fetchAndSetSwiseTokenBalance,
    setNativeTokenBalance,
    setRewardTokenBalance,
    setStakedTokenBalance,
    setSwiseTokenBalance,
  }
}


export default useBalances
