import localStorageNames from './localStorageNames'
import specialAddresses from './specialAddresses'
import tokensImages from './tokensImages'
import walletNames from './walletNames'
import blockchain from './blockchain'
import queryNames from './queryNames'
import poolTypes from './poolTypes'
import tokens from './tokens'


export default {
  localStorageNames,
  specialAddresses,
  tokensImages,
  walletNames,
  blockchain,
  queryNames,
  poolTypes,
  tokens,
}
