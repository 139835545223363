import { BigNumber } from '@ethersproject/bignumber'
import { parseEther } from '@ethersproject/units'

import getGasMargin from '../getters/getGasMargin'


type Input = {
  to: string
  from: string
  amount?: string
  tokenAddress: string
  contracts: Contracts
  library: Library
}

const approvedBalance = BigNumber.from(2).pow(256).sub(1)

const approve = async (values: Input) => {
  const { from, to, amount, tokenAddress, library, contracts } = values

  const tokenContract = contracts.helpers.createErc20(tokenAddress)

  if (!tokenContract) {
    return
  }

  const signer = library.getUncheckedSigner(from)
  const signedContract = tokenContract.connect(signer)
  const value = amount ? parseEther(amount) : approvedBalance

  const [ gasCost, feeData ] = await Promise.all([
    signedContract.estimateGas.approve(to, value),
    library.getFeeData(),
  ])

  const { maxFeePerGas, maxPriorityFeePerGas } = feeData

  const gasLimit = getGasMargin(gasCost)
  const overrides: Parameters<typeof signedContract.approve>[2] = {
    gasLimit,
  }

  if (maxFeePerGas) {
    overrides.maxFeePerGas = maxFeePerGas
  }

  if (maxPriorityFeePerGas) {
    overrides.maxPriorityFeePerGas = maxPriorityFeePerGas
  }

  return signedContract.approve(to, value, overrides)
}


export default approve
