import { useState, useEffect } from 'react'
import { formatEther } from '@ethersproject/units'
import cacheStorage from 'cache-storage'
import { modifiers } from 'helpers'
import { useConfig } from 'hooks'

import type { AccountItemProps } from '../AccountItem'


type Output = {
  address: string
  balance: string | undefined
  onClick: () => void
}

type Cache = { [address: string]: string }

const cache = cacheStorage.get(UNIQUE_FILE_ID)
cache.setData<Cache>({})

const useAccountItem = (values: AccountItemProps): Output => {
  const { address, isActive, onClick } = values

  const cachedData = cache.getData<Cache>() || {}
  const cachedValue = cachedData[address]

  const { library } = useConfig()
  const [ balance, setBalance ] = useState<string>(cachedValue)

  useEffect(() => {
    if (!cachedValue && library?.getBalance) {
      library.getBalance(address)
        .then((value) => {
          const formattedValue = modifiers.formatTokenValue({
            value: formatEther(value),
          })

          setBalance(formattedValue)

          cache.setData({
            ...cachedData,
            [address]: formattedValue,
          })
        })
    }
  }, [])

  const handleClick = () => {
    if (!isActive) {
      onClick(address)
    }
  }

  return {
    balance,
    address: modifiers.shortenAddress(address, -4),
    onClick: handleClick,
  }
}


export default useAccountItem
