export default {
  title: {
    en: 'Tokens',
    ru: 'Токены',
    fr: 'Jetons',
    es: 'Tokens',
    pt: 'Tokens',
    de: 'Tokens',
    zh: '代幣',
  },
}
