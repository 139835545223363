import { createSlice, PayloadAction } from '@reduxjs/toolkit'


export interface FarmsState {
  isPaused: boolean
  isClaimed: boolean
  isUpdating: boolean
  isFetching: boolean
  collectData: null | {
    index: number
    proof: string[]
    tokens: string[]
    amounts: string[]
  }
  breakpoints: Array<{
    note: 'swise' | 'operator' | 'partner' | string
    token: RewardsTokens
    fiatAmount: number
    amount: string
    from: string
  }>
  pools: {
    [key: string]: {
      type: string
      token0: Tokens
      token1: Tokens
      projectName: string
      gaugeAddress: string
      analyticsPage: string
      earnedAssets: Array<Tokens>
      positions: Array<{
        id: number
        APR: number
        inRange: boolean
        liquidity: string
        fiatDeposit: number
        inFullRange: boolean
        token0Amount: string
        token1Amount: string
        token0FiatDeposit: number
        token1FiatDeposit: number
      }>
      balances: Array<{
        token: Tokens,
        value: string,
      }>,
      deposit: {
        fiatDeposit: number
        token0Deposit: string
        token1Deposit: string
        hideTokensAmount: boolean
      }
      rewards: {
        fiatRewards: number
        token0: {
          name: RewardsTokens | null
          fiatValue: number
          value: string
        }
        token1: {
          name: RewardsTokens | null
          fiatValue: number
          value: string
        }
      }
      fees: {
        fiatFees: number
        token0Fees: string
        token1Fees: string
      }
      total: {
        token0Total: string
        token1Total: string
        fiatTotal: number
      }
      aprItems: {
        fees: number
        staking: number
        allocations: Array<{
          time: number
          token: Tokens
          value: number
        }>
      }
    }
  }
}

export const initialState: FarmsState = {
  isPaused: false,
  isClaimed: false,
  isUpdating: false,
  isFetching: false,
  collectData: null,
  breakpoints: [],
  pools: {},
}

export const connectSlice = createSlice({
  name: 'connect',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<Partial<FarmsState>>) => ({
      ...state,
      ...action.payload,
    }),
    setFetching: (state, action: PayloadAction<FarmsState['isFetching']>) => ({
      ...state,
      isFetching: action.payload,
    }),
    resetData: () => initialState,
  },
})


export const farmsMethods = connectSlice.actions

export default connectSlice.reducer
