import React, { useState, useEffect, useRef, useCallback } from 'react'
import cx from 'classnames'

import Box from 'components/layout/Box/Box'

import s from './Dropdown.module.scss'


type DropdownProps = {
  className?: string
  buttonClassName?: string
  children: React.ReactNode | ((onClose: () => void) => React.ReactNode)
  controlNode: React.ReactNode | ((isOpened: boolean) => React.ReactNode)
  direction?: 'top' | 'bottom'
  dataTestId?: string
  disabled?: boolean
}

const Dropdown: React.FC<DropdownProps> = (props) => {
  const { children, className, buttonClassName, controlNode, direction = 'bottom', dataTestId, disabled = false } = props

  const dropdownRef = useRef<HTMLDivElement>(null)
  const [ isOpened, setOpened ] = useState(false)

  const handleButtonClick = useCallback(() => {
    if (!disabled) {
      setOpened((prev) => !prev)
    }
  }, [ disabled ])

  const handleClose = useCallback(() => {
    setOpened(false)
  }, [])

  const handleOutsideClick = useCallback((event) => {
    if (!dropdownRef.current?.contains(event.target)) {
      setOpened(false)
    }
  }, [])

  useEffect(() => {
    if (isOpened) {
      document.addEventListener('mousedown', handleOutsideClick)
    }
    else {
      document.removeEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [ isOpened, handleOutsideClick ])

  return (
    <Box ref={dropdownRef} relative>
      <button
        className={buttonClassName}
        type="button"
        data-testid={dataTestId}
        onClick={handleButtonClick}
      >
        {
          typeof controlNode === 'function' ? (
            controlNode(isOpened)
          ) : (
            controlNode
          )
        }
      </button>
      {
        isOpened && !disabled && (
          <div className={cx(s.container, s[direction], className)}>
            <Box
              className={s.dropdown}
              radius={4}
              overflow="hidden"
              dataTestId={dataTestId ? `${dataTestId}-dropdown` : ''}
              onClick={handleClose}
            >
              {
                typeof children === 'function' ? (
                  children(handleClose)
                ) : (
                  children
                )
              }
            </Box>
          </div>
        )
      }
    </Box>
  )
}


export default Dropdown
