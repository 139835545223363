import { Mutex } from 'async-mutex'
import AppEth from '@ledgerhq/hw-app-eth'
import { Transaction } from 'ethereumjs-tx'
// @ts-ignore
import TransportU2F from '@ledgerhq/hw-transport-u2f'
import TransportHID from '@ledgerhq/hw-transport-webhid'
import { stripHexPrefix } from 'ethereumjs-util'
import { WalletSubproviderErrors } from '@0x/subproviders/lib/src/types'
import { BaseWalletSubprovider } from '@0x/subproviders/lib/src/subproviders/base_wallet_subprovider'

import { structHash } from './helpers/eip712'


export enum PathTypes {
  LEGACY = 'legacy',
  BIP44 = 'bip44',
  LIVE = 'live',
}

declare global {

  type LedgerPathTypes = PathTypes
}

const transport = {
  lock: new Mutex(),
  create: () => {
    if ('hid' in navigator) {
      return TransportHID.create()
    }
    return TransportU2F.create()
  },
}

class LedgerSubprovider extends BaseWalletSubprovider {
  private _networkId: number
  private _accountIndex: number
  private _pathType: PathTypes
  private _paths: { [type in LedgerSubprovider['_pathType']]: string }

  constructor({ networkId, accountIndex = 0 }: any = {}) {
    super()
    this._networkId = networkId
    this._pathType = PathTypes.LIVE
    this._accountIndex = accountIndex

    this._paths = {
      [PathTypes.LIVE]: "m/44'/60'/index'/0/0",
      [PathTypes.BIP44]: "m/44'/60'/0'/0/index",
      [PathTypes.LEGACY]: "m/44'/60'/0'/index",
    }
  }

  get accountIndex() {
    return this._accountIndex
  }

  set accountIndex(value) {
    this._accountIndex = value ?? 0
  }

  get pathType() {
    return this._pathType
  }

  set pathType(type: PathTypes) {
    this._pathType = type
  }

  getBaseDerivationPath(accountIndex: number) {
    return this._paths[this._pathType].replace('index', `${accountIndex}`)
  }

  async signTransactionAsync(txParams: any) {
    return this._runApp(async (app: any) => {
      const tx = new Transaction(txParams, { chain: this._networkId })

      // Set the EIP155 bits
      tx.raw[6] = Buffer.from([ this._networkId ]) // v
      tx.raw[7] = Buffer.from([]) // r
      tx.raw[8] = Buffer.from([]) // s

      // Pass hex-rlp to ledger for signing
      const result = await app.signTransaction(
        this.getBaseDerivationPath(this._accountIndex),
        tx.serialize().toString('hex')
      )

      // Store signature in transaction
      tx.v = Buffer.from(result.v, 'hex')
      tx.r = Buffer.from(result.r, 'hex')
      tx.s = Buffer.from(result.s, 'hex')

      // EIP155: v should be chain_id * 2 + {35, 36}
      const signedChainId = Math.floor((tx.v[0] - 35) / 2)
      const validChainId = this._networkId & 0xff // FIXME this is to fixed a current workaround that app don't support > 0xff

      if (signedChainId !== validChainId) {
        throw LedgerSubprovider.makeError(
          'Invalid networkId signature returned. Expected: ' +
            this._networkId +
            ', Got: ' +
            signedChainId,
          'InvalidNetworkId'
        )
      }

      return `0x${tx.serialize().toString('hex')}`
    })
  }

  async signPersonalMessageAsync(data: any, _address: any) {
    return this._runApp(async (app: any) => {
      const path = this.getBaseDerivationPath(this._accountIndex)
      const result = await app.signPersonalMessage(path, stripHexPrefix(data))

      return this._makeSignature(result)
    })
  }

  // @ts-ignore
  async signTypedDataAsync() {
    throw new Error(WalletSubproviderErrors.MethodNotSupported)
  }

  static makeError(msg: any, id: any) {
    const err: any = new Error(msg)
    err.id = id

    return err
  }

  async getAccountsAsync({ from, limit }: any = { from: 0, limit: 1 }) {
    return this._runApp(async (app: any) => {
      const addresses = []

      for (let i = from; i < from + limit; i++) {
        const path = this.getBaseDerivationPath(i)
        const info = await app.getAddress(path, false, true)

        addresses.push(info.address)
      }

      return addresses
    })
  }

  async handleRequest(payload: any, next: any, end: any) {
    switch (payload.method) {
      case 'eth_signTypedData_v4':
        return this._handleEthSignTypedDataV4(payload, end)
      default:
        return super.handleRequest(payload, next, end)
    }
  }

  async _handleEthSignTypedDataV4(payload: any, end: any) {
    const typedData = JSON.parse(payload.params[1])

    return this._signEIP712MessageAsync(typedData)
      .then((result) => end(null, result))
      .catch((reason) => end(reason, null))
  }

  async _signEIP712MessageAsync(typedData: any) {
    return this._runApp(async (app: any) => {
      const path = this.getBaseDerivationPath(this._accountIndex)

      const domain = structHash(typedData, 'EIP712Domain', typedData.domain)
      const message = structHash(
        typedData,
        typedData.primaryType,
        typedData.message
      )

      const result = await app.signEIP712HashedMessage(path, domain, message)
      return this._makeSignature(result)
    })
  }

  async _runApp(block: any) {
    const release = await transport.lock.acquire()
    try {
      const connection = await transport.create()

      try {
        const eth = new AppEth(connection)
        return await block(eth)
      }
      finally {
        await connection.close()
      }
    }
    finally {
      release()
    }
  }

  _makeSignature({ r, s, v }: any) {
    v = parseInt(v.toString(), 10) - 27
    v = v.toString(16)

    if (v.length < 2) {
      v = `0${v}`
    }

    return `0x${r}${s}${v}`
  }
}


export default LedgerSubprovider
