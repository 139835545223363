import tokens from './tokens'

import ethImage from 'public/static/images/currencies/eth.png'
import reth2Image from 'public/static/images/currencies/reth2.png'
import seth2Image from 'public/static/images/currencies/seth2.png'
import swiseImage from 'public/static/images/currencies/swise.png'

import rethhImage from 'public/static/images/currencies/reth-h.png'
import sethhImage from 'public/static/images/currencies/seth-h.png'

import gnoImage from 'public/static/images/currencies/gno.png'
import xdaiImage from 'public/static/images/currencies/xdai.png'
import rgnoImage from 'public/static/images/currencies/rgno.png'
import sgnoImage from 'public/static/images/currencies/sgno.png'
import mgnoImage from 'public/static/images/currencies/mgno.png'

import daiImage from 'public/static/images/currencies/dai.png'
import stethImage from 'public/static/images/currencies/steth.png'


export default {
  // gnosis
  [tokens.gno]: gnoImage,
  [tokens.xdai]: xdaiImage,
  [tokens.mgno]: mgnoImage,
  [tokens.sgno]: sgnoImage,
  [tokens.rgno]: rgnoImage,

  // permission
  [tokens['reth-h']]: rethhImage,
  [tokens['seth-h']]: sethhImage,

  // default
  [tokens.eth]: ethImage,
  [tokens.weth]: ethImage,
  [tokens.reth2]: reth2Image,
  [tokens.seth2]: seth2Image,
  [tokens.swise]: swiseImage,

  // extra
  [tokens.dai]: daiImage,
  [tokens.steth]: stethImage,
} as const
