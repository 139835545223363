import constants from 'helpers/constants'
import localStorage from 'local-storage'


const darkTheme = 'dark'
const lightTheme = 'light'

const getStorageTheme = () => localStorage.getItem<string>(constants.localStorageNames.themeColor)

const getCurrentTheme = () => {
  const isDark = document.body.classList.contains('body-dark-theme')

  return isDark ? darkTheme : lightTheme
}

const setDarkTheme = () => {
  document.body.classList.add('body-dark-theme')
  document.body.classList.remove('body-light-theme')
  localStorage.setItem(constants.localStorageNames.themeColor, darkTheme)
}

const setLightTheme = () => {
  document.body.classList.add('body-light-theme')
  document.body.classList.remove('body-dark-theme')
  localStorage.setItem(constants.localStorageNames.themeColor, lightTheme)
}

export default {
  darkTheme,
  lightTheme,
  setDarkTheme,
  setLightTheme,
  getStorageTheme,
  getCurrentTheme,
}
