import { supportedNetworks } from 'config'

import { sortOperators, createCollectOperator, multiCollectOperator } from './helpers'
import data from './data'


type Input = {
  config: Config
  operatorAllocations: InitialData.System.GqlData['operatorAllocations']
}

const getNodeOperators = (values: Input): Store['system']['nodeOperators'] => {
  let { config, operatorAllocations } = values

  const params = data[config.network.id]

  if (!params) {
    return {
      asia: [],
      europe: [],
      australia: [],
      northAmerica: [],
    }
  }

  const {
    asia,
    europe,
    australia,
    operators,
    northAmerica,
    multipurpose,
  } = params

  const isGnosis = config.network.id === supportedNetworks.gnosis.id
  const isMainnet = config.network.id === supportedNetworks.mainnet.id

  if (isGnosis) {
    // A crutch for spoofing the actual state of the validators
    operatorAllocations = [
      ...operatorAllocations,
      {
        id: '0x59ecf48345a221e0731e785ed79ed40d0a94e2a5-1',
        validatorsCount: '4971',
      },
      {
        id: '0xf37c8f35fc820354b402054699610c098559ae44-1',
        validatorsCount: '4971',
      },
    ]
  }

  if (isMainnet) {
    operatorAllocations = [
      ...operatorAllocations,
      {
        id: '0xfe26832d3580e0ade4813f9e60e7c17b45e92cba-1',
        validatorsCount: '64',
      },
      {
        id: '0x59ecf48345a221e0731e785ed79ed40d0a94e2a5-1',
        validatorsCount: '63',
      },
      {
        id: '0x01f26d7f195a37d368cb772ed75ef70dd29700f5-1',
        validatorsCount: '64',
      },
    ]
  }

  const temporaryResult = operatorAllocations.reduce<GetNodeOperators.TemporaryResult>((result, item) => {
    const { id, validatorsCount } = item

    // Allocations ID = {operatorAddress-id}
    const [ operatorAddress ] = id.split('-')

    const operatorName = Object.keys(operators).find((operatorName) => {
      const addresses = operators[operatorName]

      return addresses.includes(operatorAddress)
    })

    if (!operatorName) {
      return result
    }

    if (multipurpose?.find((item) => item.id === id)) {
      multiCollectOperator({
        result,
        multipurpose,
        operatorName,
        validatorsCount,
      })
    }

    const collectOperator = createCollectOperator({ result, operatorName, validatorsCount })

    if (asia?.includes(id)) {
      collectOperator('asia')
    }

    if (europe?.includes(id)) {
      collectOperator('europe')
    }

    if (northAmerica?.includes(id)) {
      collectOperator('northAmerica')
    }

    if (australia?.includes(id)) {
      collectOperator('australia')
    }

    return result
  }, {
    asia: {},
    europe: {},
    australia: {},
    northAmerica: {},
  })

  return {
    asia: Object.values(temporaryResult.asia).sort(sortOperators),
    europe: Object.values(temporaryResult.europe).sort(sortOperators),
    australia: Object.values(temporaryResult.australia).sort(sortOperators),
    northAmerica: Object.values(temporaryResult.northAmerica).sort(sortOperators),
  }
}


export default getNodeOperators
