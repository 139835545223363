import { useState } from 'react'
import { useField, Field } from 'formular'
import { isAddress } from '@ethersproject/address'
import { useConfig } from 'hooks'
import { constants, validators, analytics } from 'helpers'

import messages from '../messages'


type Output = {
  field: Field<string>
  cameraError?: Intl.Message | string
  onSetAddress: (value?: string | null) => void
  onSetCameraError: (error: Intl.Message | string) => void
}

const { required, ethOrEnsAddress } = validators

const useMonitorAddress = (closeModal: () => void): Output => {
  const { connectors, library, activate } = useConfig()
  const [ cameraError, setCameraError ] = useState<Intl.Message | string>('')

  const field = useField<string>({
    validate: [ required, ethOrEnsAddress ],
  })

  const handleConnect = (address: string, type: string) => {
    if (connectors.monitorAddress.connector?.setAccount) {
      connectors.monitorAddress.connector.setAccount(address)
      analytics.statistics.sendEvent('monitor address connect success', { type })

      activate(constants.walletNames.monitorAddress)
    }
  }

  const handleSetAddress = (value?: string | null) => {
    if (value) {
      field.set(value)
    }

    const type = value ? 'qr code' : 'input'

    const address = field.state.value
    const error = ethOrEnsAddress(address)

    if (error) {
      field.setError(error)
    }
    else {
      field.setError('')

      if (isAddress(address)) {
        handleConnect(address, type)
        closeModal()

        return
      }

      library?.resolveName(address)
        .then((result: string | null) => {
          if (result) {
            handleConnect(result, type)

            closeModal()
          }
          else {
            field.setError(messages.customEnsError)
          }
        })
    }
  }

  return {
    field,
    cameraError,
    onSetAddress: handleSetAddress,
    onSetCameraError: () => setCameraError,
  }
}


export default useMonitorAddress
