import React, { useState, useCallback, useEffect, useRef } from 'react'
import { supportedNetworks } from 'config'
import { useConfig, useStore } from 'hooks'

import { Box } from 'components/layout'

import Menu from './Menu/Menu'
import NetworkButton from './NetworkButton/NetworkButton'

import s from './Network.module.scss'


const harbourMainnetId = supportedNetworks.harbourMainnet.id
const harbourGoerliId = supportedNetworks.harbourGoerli.id
const mainnetId = supportedNetworks.mainnet.id
const goerliId = supportedNetworks.goerli.id
const gnosisId = supportedNetworks.gnosis.id

const titles = {
  [harbourMainnetId]: 'Portara ETH',
  [harbourGoerliId]: 'Goerli P',
  [mainnetId]: 'Ethereum',
  [goerliId]: 'Goerli',
  [gnosisId]: 'Gnosis',
} as const

const storeSelector = (store: Store) => ({
  isInitialRequestLoading: store.ui.initialRequestLoading,
})

const Network: React.FC = () => {
  const { networkId } = useConfig()
  const containerRef = useRef<HTMLDivElement>(null)
  const [ showMenu, setShowMenu ] = useState(false)
  const { isInitialRequestLoading } = useStore(storeSelector)

  const handleClick = () => {
    if (isInitialRequestLoading) {
      return
    }

    setShowMenu((value) => !value)
  }

  const handleOutsideClick = useCallback((event) => {
    if (!containerRef.current?.contains(event.target)) {
      setShowMenu(false)
    }
  }, [])

  useEffect(() => {
    if (showMenu) {
      document.addEventListener('mousedown', handleOutsideClick)
    }
    else {
      document.removeEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [ showMenu, handleOutsideClick ])

  return (
    <Box ref={containerRef} relative>
      <NetworkButton
        className={s.button}
        title={titles[networkId]}
        dataTestId="header-network-name"
        opened={showMenu}
        onClick={handleClick}
      />
      <Menu
        opened={showMenu}
        onClick={() => setShowMenu(false)}
      />
    </Box>
  )
}


export default React.memo(Network)
