export default {
  name: {
    en: 'Gnosis Safe',
    ru: 'Gnosis Safe',
    fr: 'Gnosis Safe',
    es: 'Gnosis Safe',
    pt: 'Gnosis Safe',
    de: 'Gnosis Safe',
    zh: 'Gnosis Safe',
  },
  activationMessage: {
    en: 'Waiting for authorization to be completed in Gnosis Safe',
    ru: 'Ожидаем завершения авторизации в Gnosis Safe',
    fr: 'Attente de l\'autorisation Gnosis Safe',
    es: 'Esperada a que se complete la autorización en Gnosis Safe',
    pt: 'Aguardando autorização ser concluída no Gnosis Safe',
    de: 'Warte auf die Autorisierung im Gnosis Safe',
    zh: '等待Gnosis Safe錢包允許存取',
  },
  errors: {
    unsupportedNetwork: {
      en: 'Gnosis Safe is connected to an unsupported network',
      ru: 'Gnosis Safe подключен к не поддерживаемой сети',
      fr: 'Gnosis Safe est connecté à un réseau non supporté',
      es: 'Gnosis Safe está conectado a una red no soportada',
      pt: 'Gnosis Safe está conectada a uma rede não suportada',
      de: 'Gnosis Safe ist mit einem nicht unterstützten Netzwerk verbunden',
      zh: 'Gnosis Safe錢包連接到一個不支援的網路',
    },
  },
}
