/* eslint-disable max-len */
export default {
  name: {
    en: 'MetaMask',
    ru: 'MetaMask',
    fr: 'MetaMask',
    es: 'MetaMask',
    pt: 'MetaMask',
    de: 'MetaMask',
    zh: 'MetaMask',
  },
  activationMessage: {
    en: 'Waiting for authorisation request to be approved in MetaMask',
    ru: 'Ожидаем подтверждения запроса на авторизацию в MetaMask',
    fr: 'Attente de l\'approbation de la requête d\'autorisation dans MetaMask',
    es: 'Esperando la aprobación a la petición de autorización en MetaMask',
    pt: 'Aguardando a aprovação da solicitação de autorização no MetaMask',
    de: 'Warte auf die Autorisierungsbestätigung von MetaMask',
    zh: '等待 MetaMask 允許存取',
  },
  errors: {
    noProvider: {
      en: 'No Ethereum browser extension detected, install MetaMask on desktop or visit from a MetaMask browser on mobile',
      ru: 'Расширение браузера для Ethereum не обнаружено, установите MetaMask на компьютере или зайдите из браузера MetaMask на мобильном.',
      fr: 'Aucune extension compatible Ethereum detectée, merci d\'installer l\'extension MetaMask ou d\'utiliser MetaMask browser',
      es: 'No se ha detectado la cartera de Ethereum del navegador, instale Metamask en escritorio o visite desde el app MetaMask para móvil',
      pt: 'Nenhuma extensão do navegador Ethereum detectada, instale o MetaMask no desktop ou visite de um navegador MetaMask no celular',
      de: 'Keine Ethereum Browser Extension gefunden. Installiere MetaMask auf dem Desktop oder probiere es mit einem mobilen MetaMask browser',
      zh: '沒有偵測到 Ethereum 瀏覽器擴充套件，請安裝 MetaMask 桌面版應用程式或改以行動版 MetaMask 瀏覽器訪問',
    },
    unsupportedNetwork: {
      en: 'MetaMask is connected to an unsupported network',
      ru: 'MetaMask подключен к не поддерживаемой сети',
      fr: 'MetaMask est connecté à un réseau non supporté',
      es: 'MetaMask está conectado a una red no compatible',
      pt: 'MetaMask está conectada a uma rede não suportada',
      de: 'MetaMask ist mit einem nicht unterstützten Netzwerk verbunden',
      zh: 'MetaMask連接到一個不支援的網路',
    },
    authorize: {
      en: 'Please authorize access to your MetaMask account',
      ru: 'Пожалуйста, разрешите доступ к Вашему MetaMask аккаунту',
      fr: 'Merci d\'autoriser l\'accès à votre compte MetaMask',
      es: 'Favor autorizar acceso a su cuenta de MetaMask',
      pt: 'Por favor autorize o acesso à sua conta MetaMask',
      de: 'Bitte autorisiere den Zugriff auf Dein MetaMask Konto',
      zh: '請允許存取您的 MetaMask 錢包',
    },
  },
}
