import { useMemo } from 'react'

import { supportedNetworks } from '../helpers'
import { chainIdByNetworkIdE2E } from '../helpers/supportedChainIds'


const useSupportedNetworks = () => {
  return useMemo(() => {
    const networkIdByChainId = {} as Record<number, NetworkIds>
    const chainIdByNetworkId = {} as Record<NetworkIds, number>

    Object.values(supportedNetworks).forEach((network) => {
      const { id } = network

      const chainIdE2E = chainIdByNetworkIdE2E[id]
      const chainId = chainIdE2E || network.chainId

      networkIdByChainId[chainId] = id
      chainIdByNetworkId[id] = chainId
    })

    return {
      networkIdByChainId,
      chainIdByNetworkId,
    }
  }, [])
}


export default useSupportedNetworks
