import { store } from 'redux/store'
import { formatEther } from '@ethersproject/units'

import getFiatValue from './getFiatValue'


type Input<T extends string> = Record<T, {
  token: Tokens
  value: BigNumber
}>

type Output<T extends string> = Record<T, number>

const getFiatAmountWithStore = <T extends string>(values: Input<T>): Output<T> => {
  const { system: { fiatPrices }, account: { currency } } = store.getState()

  return Object.keys(values).reduce<Output<T>>((acc, key) => {
    const { token, value } = values[key as keyof typeof values]

    const result = getFiatValue({
      token,
      currency,
      fiatPrices,
      value: value ? formatEther(value) : '0',
    })

    acc[key as keyof typeof values] = Number(result)

    return acc
  }, {} as Output<T>)
}


export default getFiatAmountWithStore
