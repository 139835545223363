export default {
  title: {
    en: 'Ooooups!',
    ru: 'Ууууупс!',
    fr: 'Ooooups!',
    es: '¡Ups!',
    pt: 'Ooooups!',
    de: 'Ooooups!',
    zh: '哦噢!',
  },
  text: {
    en: 'Something went wrong',
    ru: 'Что-то пошло не так',
    fr: 'Quelque chose s\'est mal passé',
    es: 'Algo ha salido mal',
    pt: 'Algo deu errado',
    de: 'Etwas ist schiefgelaufen',
    zh: '有些東西出錯了',
  },
  note: {
    en: 'We see this error in our logs and will fix it soon',
    ru: 'Мы видим эту ошибку в наших логах и скоро исправим её',
    fr: 'L\'erreur nous a été remontée et nous la corrigerons bientôt',
    es: 'Vemos este error en nuestros logs y lo corregiremos enseguida',
    pt: 'Vemos esse erro em nossos logs e o corrigiremos em breve',
    de: 'Wir sehen diesen Fehler in unseren Logs und werden ihn bald fixen',
    zh: '我們已經看到這個錯誤並且會盡快修正',
  },
}
