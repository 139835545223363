import request from 'request-module'

import sentry from '../analytics/sentry'


type ApiResponse = {
  balance: string
  status: number
  public_key: string
  current_apr: number
  exited_at: string | null
  created_at: string
  included_at: string
  activated_at: string
  is_penalised: boolean
  withdrawal_credentials: string
}[]

const fetchSoloValidators = async (address: string, config: Config): Promise<InitialData.Account.RestData['soloValidators']> => {
  try {
    const response = await request.get<ApiResponse>({
      url: `${config.api.rest}/solo-validators/${address}/`,
      params: {
        network: config.network.id,
      },
    })

    const soloValidators = response.data || []

    return soloValidators.map((soloValidator) => {
      const {
        balance,
        status,
        public_key,
        current_apr,
        exited_at,
        created_at,
        included_at,
        activated_at,
        is_penalised,
        withdrawal_credentials,
      } = soloValidator

      return {
        balance,
        status,
        publicKey: public_key,
        currentAPR: current_apr,
        exitedAt: exited_at,
        createdAt: created_at,
        includedAt: included_at,
        activatedAt: activated_at,
        isPenalised: is_penalised,
        withdrawalCredentials: withdrawal_credentials,
      }
    })
  }
  catch (error) {
    sentry.exception('REST API: solo-validators', error as Error)

    return Promise.reject(error)
  }
}


export default fetchSoloValidators
