import React from 'react'
import cx from 'classnames'
import { useConfig } from 'hooks'
import { constants } from 'helpers'
import localStorage from 'local-storage'
import { supportedNetworks } from 'config'

import { Box } from 'components/layout'

import Button from './Button/Button'

import s from './Menu.module.scss'


const harbourMainnetId = supportedNetworks.harbourMainnet.id
const harbourGoerliId = supportedNetworks.harbourGoerli.id
const mainnetId = supportedNetworks.mainnet.id
const gnosisId = supportedNetworks.gnosis.id
const goerliId = supportedNetworks.goerli.id


type MenuProps = {
  onClick: () => void
  opened: boolean
}

const Menu: React.FC<MenuProps> = (props) => {
  const { opened, onClick } = props
  const { config, canChangeChain, changeChain } = useConfig()

  const showTestnets = Boolean(localStorage.getSessionItem(constants.queryNames.showTestnets)) || !IS_PROD

  const handleSubmit = (id: NetworkIds) => {
    changeChain(id)

    if (typeof onClick === 'function') {
      onClick()
    }
  }

  const containerClassName = cx(s.container, {
    [s.opened]: opened,
  })

  return (
    <Box className={containerClassName} ph={8} pb={8} overflow="hidden" radius={8}>
      {
        config.network.id !== mainnetId && (
          <Box mt={8} fullWidth>
            <Button
              title="Ethereum"
              onClick={() => handleSubmit(mainnetId)}
              />
          </Box>
        )
      }
      {
        config.network.id !== gnosisId && (
          <Box mt={8} fullWidth>
            <Button
              title="Gnosis"
              disabled={!canChangeChain}
              onClick={() => handleSubmit(gnosisId)}
            />
          </Box>
        )
      }
      {
        showTestnets && (
          <>
            {
              config.network.id !== goerliId && (
                <Box mt={8} fullWidth>
                  <Button
                    title="Goerli"
                    disabled={!canChangeChain}
                    onClick={() => handleSubmit(goerliId)}
                  />
                </Box>
              )
            }
            {
              config.network.id !== harbourGoerliId && (
                <Box mt={8} fullWidth>
                  <Button
                    title="Goerli H"
                    disabled={!canChangeChain}
                    onClick={() => handleSubmit(harbourGoerliId)}
                  />
                </Box>
              )
            }
          </>
        )
      }
    </Box>
  )
}


export default React.memo(Menu)
