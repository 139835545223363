import { useEffect } from 'react'
import { analytics } from 'helpers'
import { openNotification } from 'notifications'
import { useActions, useConfig, useInitialState } from 'hooks'

import fetchFiatValues from './fetching/fetchFiatValues'
import getFormattedResult from './getters/getFormattedResult'
import fetchSystemGqlData from './fetching/fetchSystemGqlData'
import fetchSystemRestData from './fetching/fetchSystemRestData'
import fetchSystemContractsData from './fetching/fetchSystemContractsData'

import messages from './messages'


const useSystem = () => {
  const actions = useActions()
  const initialState = useInitialState()
  const { config, contracts, autoConnectChecked } = useConfig()

  useEffect(() => {
    if (autoConnectChecked) {
      actions.ui.setInitialRequestLoading(true)

      Promise.all([
        fetchSystemContractsData(contracts, config),
        fetchSystemRestData(config),
        fetchSystemGqlData(config),
        fetchFiatValues(),
      ])
        .then(async (result) => {
          const system = getFormattedResult(result, config)
          actions.system.setInitialData(system)
        })
        .catch((error) => {
          openNotification({
            type: 'error',
            text: messages.error,
          })

          analytics.sentry.exception('Fetch system state error', error)
        })
        .finally(() => {
          actions.ui.setInitialRequestLoading(false)
        })
    }
    else {
      actions.system.setInitialData(initialState.system)
    }
  }, [ config, actions, contracts, initialState, autoConnectChecked ])
}


export default useSystem
