import React from 'react'


export type TabsContentProps = {
  id: string
  title: Intl.Message | string
  dataTestId?: string
}

const Content: React.FC<TabsContentProps> = (props) => {
  const { children } = props

  return (
    <>
      {children}
    </>
  )
}


export default Content
