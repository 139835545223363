import React from 'react'

import useCurrency from './util/useCurrency'
import Select from '../components/Select/Select'


const CurrencySelect: React.FC = () => {
  const { selectedOption, options, handleChange } = useCurrency()

  if (!options.length || !selectedOption) {
    return null
  }

  return (
    <Select
      iconSize={24}
      options={options}
      dataTestId="footer-currencies-switcher"
      selectedOption={selectedOption}
      onChange={handleChange}
    />
  )
}


export default React.memo(CurrencySelect)
