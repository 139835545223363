import { useCallback, useEffect, useRef } from 'react'


let skipRequest = false

type UseAutoFetchProps = {
  action: () => Promise<void>
  skip: boolean
  callOnMount?: boolean
}

const useAutoFetch = ({ action, skip, callOnMount = false }: UseAutoFetchProps) => {
  const interval = useRef<NodeJS.Timer>()
  const savedAction = useRef(action)

  const handleFetch = useCallback(async () => {
    if (!skipRequest) {
      skipRequest = true

      await action()

      skipRequest = false
    }
  }, [ action ])

  useEffect(() => {
    if (!skip) {
      if (interval.current && savedAction.current !== action) {
        clearInterval(interval.current)
      }

      if (callOnMount) {
        handleFetch()
      }

      savedAction.current = action
      interval.current = setInterval(handleFetch, 10000)

      return () => {
        if (interval.current) {
          clearInterval(interval.current)
        }
      }
    }
  }, [ skip, callOnMount, action, handleFetch ])
}


export default useAutoFetch
