import getFiatPrices from './getFiatPrices'
import getNodeOperators from './getNodeOperators'
import getValidatorsData from './getValidatorsData'


type Values = [
  InitialData.System.ContractsData,
  InitialData.System.RestData,
  InitialData.System.GqlData,
  InitialData.System.FiatRates,
]

const getFormattedResult = (values: Values, config: Config): Omit<Store['system'], 'chainId'> => {
  const [ contractsData, restData, gqlData, fiatRates ] = values

  const { validatorsAPR, swiseUsdRateBN } = restData
  const { isPoolPaused, maintainerFee } = contractsData
  const { oraclesNextUpdate, operatorAllocations, totalStakingAmount } = gqlData
  const { ethUsdRateBN, eurUsdRateBN, gbpUsdRateBN, gnoUsdRateBN, ethXdaiRateBN } = fiatRates

  const { stakingAPR, protocolFee } = getValidatorsData({ validatorsAPR, maintainerFee })
  const nodeOperators = getNodeOperators({ operatorAllocations, config })
  const fiatPrices = getFiatPrices({
    ethUsdRateBN,
    eurUsdRateBN,
    gbpUsdRateBN,
    gnoUsdRateBN,
    ethXdaiRateBN,
    swiseUsdRateBN,
  })

  return {
    isPoolPaused,
    nodeOperators,
    oraclesNextUpdate,
    totalStakingAmount,
    validators: {
      stakingAPR,
      protocolFee,
      maintainerFee,
    },
    fiatPrices,
    isFetched: true,
  }
}


export default getFormattedResult
