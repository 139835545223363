import React, { useCallback } from 'react'
import { useDevice } from 'device'

import { Box } from 'components/layout'
import { Text, Pagination } from 'components/ui'

import Header from './Header/Header'
import ListItem from './ListItem/ListItem'
import type { ListItemProps } from './ListItem/ListItem'

import messages from './messages'


export type TransactionsListProps = {
  items: Array<ListItemProps>
}

const TransactionsList: React.FC<TransactionsListProps> = (props) => {
  const { items } = props

  const { isMobile } = useDevice()

  const handleRender = useCallback((items: Array<ListItemProps>) => (
    items.map(({ sender, recipient, hash, amount }, index) => (
      <ListItem
        key={index}
        hash={hash}
        amount={amount}
        sender={sender}
        recipient={recipient}
      />
    )
  )), [])

  return (
    <>
      <Header />
      <div>
        {
          Boolean(items?.length) ? (
            <Pagination
              items={items}
              count={isMobile ? 7 : 10}
              render={handleRender}
            />
          ) : (
            <Box pv={56}>
              <Text
                message={messages.noItems}
                color="dark-gray"
                align="center"
                size={16}
              />
            </Box>
          )
        }
      </div>
    </>
  )
}


export default React.memo(TransactionsList)
