import React from 'react'
import cx from 'classnames'
import Image from 'next/image'
import { constants } from 'helpers'

import { Text, Icon } from 'components/ui'
import { Box, Flex } from 'components/layout'

import s from './NetworkButton.module.scss'


type NetworkButtonType = {
  className?: string
  title: 'Portara ETH' | 'Ethereum' | 'Gnosis' | 'Goerli' | 'Goerli P'
  opened: boolean
  dataTestId?: string
  onClick: () => void
}

const NetworkButton: React.FC<NetworkButtonType> = (props) => {
  const { className, title, opened, dataTestId, onClick } = props

  const buttonClassNames = cx(s.button, className, {
    [s.harbourMainnet]: title === 'Portara ETH',
    [s.testnet]: title.includes('Goerli'),
    [s.mainnet]: title === 'Ethereum',
    [s.gnosis]: title === 'Gnosis',
  })

  const iconClassName = cx(s.icon, {
    [s.rotate]: opened,
  })

  const image = title === 'Gnosis'
    ? constants.tokensImages.mGNO
    : constants.tokensImages.ETH

  return (
    <button
      className={buttonClassNames}
      data-testid={dataTestId || title}
      type="button"
      onClick={onClick}
    >
      <Flex justify="start">
        <Flex
          className={s.imageContainer}
          justify="center"
          align="center"
        >
          <Image
            src={image}
            height={16}
            width={16}
            alt=""
          />
        </Flex>
        <Box className={s.textContainer}>
          <Text
            message={title}
            color="white"
            size={16}
          />
        </Box>
      </Flex>
      <Icon
        containerClassName={iconClassName}
        name="fa-solid fa-angle-down"
        color="white"
        size={14}
      />
    </button>
  )
}


export default React.memo(NetworkButton)
