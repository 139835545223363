import React, { useEffect, useRef } from 'react'
import Onboarding from '@metamask/onboarding'

import ListItem from '../components/ListItem/ListItem'

import messages from './messages'
import s from './MetaMaskDownloadLink.module.scss'


const MetaMaskDownloadLink: React.FC = () => {
  const onboarding = useRef<Onboarding>()

  useEffect(() => {
    onboarding.current = new Onboarding()
  }, [])

  return (
    <ListItem
      className={s.link}
      title={messages.title}
      onClick={() => onboarding.current?.startOnboarding()}
    />
  )
}


export default React.memo(MetaMaskDownloadLink)
