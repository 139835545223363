import useSystem from './system/useSystem'
import useAccount from './account/useAccount'


const useInitialRequests = () => {
  // This is the main request, where we populate data about our application. It should be the first among all other requests.
  useSystem()

  // User data, pulled up after the connection ()
  useAccount()
}


export default useInitialRequests
