import React from 'react'
import cx from 'classnames'
import Image from 'next/image'
import { constants } from 'helpers'

import { Text } from 'components/ui'
import { Box, Flex } from 'components/layout'

import s from './Title.module.scss'


type TitleProps = {
  className?: string
}

const Title: React.FC<TitleProps> = ({ className }) => (
  <Box className={cx(s.container, className)} pv={32} overflow="hidden" relative>
    <Flex className={s.content} justify="start">
      <Image
        src={constants.tokensImages[constants.tokens.swise]}
        width={30}
        height={30}
        data-testid="menu-logo"
        alt=""
      />
      <Box id="mainMenuItemText" ml={12} relative>
        <Text message="STAKEWISE" size={14} color="white" />
      </Box>
    </Flex>
  </Box>
)


export default React.memo(Title)
