import { Pool } from '@uniswap/v3-sdk'
import cacheStorage from 'cache-storage'
import type { UniPoolAbi } from 'contracts'

import constants from '../constants'
import getUniTokens from './getUniTokens'


type Output = Record<string, Pool>

type Input = {
  config: Config
  contracts: Contracts
}

type MulticallReturnType = {
  stakedEthLiquidity?: BigNumber
  rewardEthLiquidity?: BigNumber
  swiseEthLiquidity?: BigNumber
  ethSwiseLiquidity?: BigNumber
  ethSwiseSlot?: Unpromise<ReturnType<UniPoolAbi['slot0']>>
  swiseEthSlot?: Unpromise<ReturnType<UniPoolAbi['slot0']>>
  stakedEthSlot?: Unpromise<ReturnType<UniPoolAbi['slot0']>>
  rewardEthSlot?: Unpromise<ReturnType<UniPoolAbi['slot0']>>
}

const cache = cacheStorage.get(UNIQUE_FILE_ID)

const defaultValueSlot = {
  sqrtPriceX96: constants.blockchain.amount0,
  tick: 0,
}

const _fetchData = (contracts: Contracts) => (
  contracts?.helpers.createMulticall<MulticallReturnType>([
    {
      returnName: 'stakedEthLiquidity',
      contract: contracts.pools.uniswap.stakedTokenNativeTokenContract,
      defaultValue: constants.blockchain.amount0,
      methodName: 'liquidity',
      args: [],
    },
    {
      returnName: 'stakedEthSlot',
      contract: contracts.pools.uniswap.stakedTokenNativeTokenContract,
      defaultValue: defaultValueSlot,
      methodName: 'slot0',
      args: [],
    },
    {
      returnName: 'rewardEthLiquidity',
      contract: contracts.pools.uniswap.stakedTokenRewardTokenContract,
      defaultValue: constants.blockchain.amount0,
      methodName: 'liquidity',
      args: [],
    },
    {
      returnName: 'rewardEthSlot',
      contract: contracts.pools.uniswap.stakedTokenRewardTokenContract,
      defaultValue: defaultValueSlot,
      methodName: 'slot0',
      args: [],
    },
    {
      returnName: 'swiseEthLiquidity',
      contract: contracts.pools.uniswap.stakedTokenSwiseTokenContract,
      defaultValue: constants.blockchain.amount0,
      methodName: 'liquidity',
      args: [],
    },
    {
      returnName: 'swiseEthSlot',
      contract: contracts.pools.uniswap.stakedTokenSwiseTokenContract,
      defaultValue: defaultValueSlot,
      methodName: 'slot0',
      args: [],
    },
    {
      returnName: 'ethSwiseLiquidity',
      contract: contracts.pools.uniswap.ethSwiseTokenContract,
      defaultValue: constants.blockchain.amount0,
      methodName: 'liquidity',
      args: [],
    },
    {
      returnName: 'ethSwiseSlot',
      contract: contracts.pools.uniswap.ethSwiseTokenContract,
      defaultValue: defaultValueSlot,
      methodName: 'slot0',
      args: [],
    },
  ])()
)

const getUniPools = async (values: Input): Promise<Output> => {
  const { contracts, config } = values

  const isNotSupported = (
    !config.addresses.pools.uniswap.stakedTokenNativeToken
    || !config.addresses.pools.uniswap.stakedTokenRewardToken
    || !config.addresses.pools.uniswap.stakedTokenSwiseToken
  )

  if (isNotSupported) {
    return {}
  }

  const cachedData = cache.getData<Record<string, Record<string, Pool>>>()

  if (cachedData && cachedData[config.network.id]) {
    return cachedData[config.network.id]
  }

  try {
    const response = await _fetchData(contracts)

    const {
      stakedEthSlot,
      stakedEthLiquidity,

      rewardEthSlot,
      rewardEthLiquidity,

      swiseEthSlot,
      swiseEthLiquidity,

      ethSwiseSlot,
      ethSwiseLiquidity,
    } = response

    const result = {} as Record<string, Pool>

    const {
      uniSwiseToken,
      uniRewardToken,
      uniStakedToken,
      uniWrappedEthToken,
    } = getUniTokens(config)

    if (stakedEthLiquidity && stakedEthSlot) {
      result[config.addresses.pools.uniswap.stakedTokenNativeToken] = new Pool(
        uniWrappedEthToken,
        uniStakedToken,
        config.settings.stakedUniswapFee,
        // @ts-ignore
        stakedEthSlot.sqrtPriceX96,
        stakedEthLiquidity,
        stakedEthSlot.tick
      )
    }

    if (rewardEthLiquidity && rewardEthSlot) {
      result[config.addresses.pools.uniswap.stakedTokenRewardToken] = new Pool(
        uniRewardToken,
        uniStakedToken,
        // @ts-ignore
        config.settings.rewardUniswapFee,
        // @ts-ignore
        rewardEthSlot.sqrtPriceX96,
        rewardEthLiquidity,
        rewardEthSlot.tick
      )
    }

    if (swiseEthLiquidity && swiseEthSlot) {
      result[config.addresses.pools.uniswap.stakedTokenSwiseToken] = new Pool(
        uniSwiseToken,
        uniStakedToken,
        config.settings.swiseUniswapFee,
        // @ts-ignore
        swiseEthSlot.sqrtPriceX96,
        swiseEthLiquidity,
        swiseEthSlot.tick
      )
    }

    if (ethSwiseLiquidity && ethSwiseSlot) {
      result[config.addresses.pools.uniswap.ethSwiseToken] = new Pool(
        uniSwiseToken,
        uniWrappedEthToken,
        config.settings.swiseUniswapFee,
        // @ts-ignore
        ethSwiseSlot.sqrtPriceX96,
        ethSwiseLiquidity,
        ethSwiseSlot.tick
      )
    }

    const hasData = Object.keys(result).length

    if (hasData) {
      cache.setData({
        ...(cachedData || {}),
        [config.network.id]: result,
      })
    }

    return result
  }
  catch {
    return {}
  }
}


export default getUniPools
