const waitForSelector = <T extends HTMLElement>(selector: string, attempt: number = 0): Promise<T | null> => (
  new Promise((resolve) => {
    setTimeout(() => {
      const element = document.querySelector(selector)

      if (element) {
        resolve(element as T)
      }
      else if (attempt < 5) {
        resolve(waitForSelector(selector, attempt + 1))
      }
      else {
        resolve(null)
      }
    }, attempt * 100)
  })
)


export default waitForSelector
