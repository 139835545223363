export default {
  name: {
    en: 'Ledger',
    ru: 'Ledger',
    fr: 'Ledger',
    es: 'Ledger',
    pt: 'Ledger',
    de: 'Ledger',
    zh: 'Ledger',
  },
  activationMessage: {
    en: 'Connect your Ledger and open Ethereum App',
    ru: 'Подключите свой Ledger и откройте приложение Ethereum',
    fr: 'Connectez votre Ledger et ouvrez Ethereum App',
    es: 'Conecte su Ledger y abra el App de Ethereum',
    pt: 'Conecte seu Ledger e abra o aplicativo Ethereum',
    de: 'Verbinde Deinen Ledger und öffne die Ethereum App',
    zh: '連接您的Ledger錢包並打開Ethereum應用程式',
  },
  errors: {
    unsupportedNetwork: {
      en: 'Ledger is connected to an unsupported network',
      ru: 'Ledger подключен к не поддерживаемой сети',
      fr: 'Ledger est connecté à un réseau non supporté',
      es: 'Ledger está conectado a una red no soportada',
      pt: 'O Ledger está conectado a uma rede não suportada',
      de: 'Ledger ist mit einem nicht unterstützten Netzwerk verbunden',
      zh: 'Ledger連接到一個不支援的網路',
    },
    notOpened: {
      en: 'Ledger Ethereum app is not opened',
      ru: 'Приложение Ledger Ethereum не открыто',
      fr: 'Ledger Ethereum App n\'est pas ouverte',
      es: 'El app de Ethereum no está abierto en el Ledger',
      pt: 'O aplicativo Ledger Ethereum não está aberto',
      de: 'Die Ledger Ethereum App ist nicht geöffnet',
      zh: 'Ledger Ethereum 應用程式沒有開啟',
    },
    lock: {
      en: 'Please unlock the Ledger',
      ru: 'Пожалуйста, разблокируйте Ledger',
      fr: 'Merci de déverouiller le Ledger',
      es: 'Favor desbloquear el Ledger',
      pt: 'Por favor desbloqueie o Ledger',
      de: 'Bitte entsperre Deinen Ledger',
      zh: '請解鎖 Ledger',
    },
    settings: {
      en: 'Please enable contract data in the Ethereum app settings',
      ru: 'Включите данные контракта в настройках приложения Ethereum',
      fr: 'Merci d\'activer les données de contrat dans les préférences d\'Ethereum App',
      es: 'Favor habilitar \'contract data\' en la configuración del app de Ethereum',
      pt: 'Por favor ative os dados do contrato nas configurações do aplicativo Ethereum',
      de: 'Bitte schalte Contract Data in den Ethereum App Einstellungen an',
      zh: '請在 Ethereum app settings 內將 contract data 設定改為 enable',
    },
    notConnected: {
      en: 'Ledger is not connected or Ethereum App is not opened',
      ru: 'Ledger не подключен или приложение Ethereum не открыто',
      fr: 'Ledger n\'est pas connecté ou Ethereum App n\'est pas ouverte',
      es: 'El Ledger no está conectado o el App de Ethereum no está abierto',
      pt: 'Ledger não está conectado ou o aplicativo Ethereum não está aberto',
      de: 'Ledger ist nicht verbunden oder die Ethereum App läuft nicht',
      zh: '沒有連接 Ledger 或尚未開啟 Ethereum 應用程式',
    },
  },
}
