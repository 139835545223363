import { BigNumber } from '@ethersproject/bignumber'

import constants from '../constants'


const depositAmount = constants.blockchain.amount32

type Output = {
  soloStakedEth: BigNumber
  soloRewardEth: BigNumber
  soloStakingAPR: number
}

const getSoloValidatorsBalance = (soloValidators: InitialData.Account.RestData['soloValidators']): Output => {
  const soloStakedEth = depositAmount.mul(soloValidators.length)

  let soloRewardEth = constants.blockchain.amount0
  let totalAPR = 0

  soloValidators.forEach(({ balance, currentAPR }) => {
    const validatorReward = BigNumber.from(balance).sub(depositAmount)

    soloRewardEth = soloRewardEth.add(validatorReward)

    if (currentAPR !== 0) {
      totalAPR += currentAPR
    }
  })

  const soloStakingAPR = parseFloat((totalAPR / soloValidators.length).toFixed(2))

  return {
    soloStakedEth,
    soloRewardEth,
    soloStakingAPR,
  }
}


export default getSoloValidatorsBalance
