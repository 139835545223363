import request from 'request-module'
import { analytics } from 'helpers'
import { AxiosError } from 'axios'


type ApiResponse = {
  account: string
  newsletter: boolean
  email_address: string | null
  display_currency: Currencies
}

const fetchAccountSettings = async (address: string, config: Config): Promise<InitialData.Account.RestData['user']> => {
  try {
    const response = await request.get<ApiResponse>({
      url: `${config.api.rest}/profiles/${address}/`,
      params: {
        network: config.network.id,
      },
    })

    const { newsletter, email_address, display_currency } = response.data

    return {
      newsletter,
      email: email_address,
      currency: display_currency || 'USD',
      isRegistered: Boolean(Object.keys(response.data).length),
    }
  }
  catch (error) {
    if ((error as AxiosError)?.response?.status !== 404) {
      analytics.sentry.exception('REST API: profiles/{address}', error as Error)
    }

    return Promise.reject(error)
  }
}


export default fetchAccountSettings
