import { BigNumber, BigNumberish } from '@ethersproject/bignumber'
import constants from 'helpers/constants'

// We decided not to use FixedNumber, so when we work with decimals, we must constantly do multiplication,
// which will move the point to the right. This is difficult to read. That's what this class is made for

// Supported count of zeros
const zeroTypes = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ] as const
type Decimals = typeof zeroTypes[number]

const zeros = zeroTypes.reduce<Record<Decimals, string>>((acc, number) => ({
  ...acc,
  [number]: [ ...new Array(number) ].reduce((acc) => `0${acc}`, ''),
}), {} as Record<Decimals, string>)

// Helpers

const _getZeros = (decimals: typeof zeroTypes[number] = 4) => {
  if (!zeros[decimals]) {
    throw new Error('Incorrect argument "decimals" in _getZeros()')
  }

  return zeros[decimals]
}

const _getMultiplier = (zeros: string) => {
  if (Number(zeros) !== 0) {
    throw new Error('Incorrect argument "zeros" in _getMultiplier()')
  }

  return Number(`1${zeros}`)
}

const _formatPercentValue = (value: number, decimals?: Decimals) => {
  const zeros = _getZeros(decimals)
  const multiplier = _getMultiplier(zeros)
  const formattedValue = value * multiplier

  return formattedValue
}


class DecimalsBN {
  private bn: BigNumber

  constructor(bn: BigNumber = constants.blockchain.amount0) {
    this.bn = bn
  }

  // Base BN methods

  eq(value: BigNumberish) {
    return this.bn.eq(value)
  }

  lt(value: BigNumberish) {
    return this.bn.lt(value)
  }

  lte(value: BigNumberish) {
    return this.bn.lte(value)
  }

  gt(value: BigNumberish) {
    return this.bn.gt(value)
  }

  gte(value: BigNumberish) {
    return this.bn.gte(value)
  }

  mul(value: BigNumberish) {
    this.bn = this.bn.mul(value)

    return this
  }

  div(value: BigNumberish) {
    this.bn = this.bn.div(value)

    return this
  }

  sub(value: BigNumberish) {
    this.bn = this.bn.sub(value)

    return this
  }

  add(value: BigNumberish) {
    this.bn = this.bn.add(value)

    return this
  }

  toString() {
    return this.bn.toString()
  }

  toNumber() {
    return this.bn.toNumber()
  }

  // Special methods

  toBN() {
    return this.bn
  }

  setMultiplier(decimals?: Decimals) {
    const zeros = _getZeros(decimals)
    const multiplier = _getMultiplier(zeros)

    this.bn = this.bn.mul(multiplier)

    return this
  }

  removeMultiplier(decimals?: Decimals) {
    const zeros = _getZeros(decimals)
    const multiplier = _getMultiplier(zeros)

    this.bn = this.bn.div(multiplier)

    return this
  }

  mulPercent(value: number) {
    const [ _, decimals ] = String(value).split('.')

    if (!decimals) {
      throw new Error('DecimalsBN.mulPercent() can only take argument with a dot, otherwise use the base mul()')
    }

    const zeros = _getZeros(decimals.length as Decimals)
    const multiplier = _getMultiplier(zeros)
    const formatterPercent = value * multiplier

    this.bn = this.bn.mul(formatterPercent).div(multiplier)

    return this
  }

  addPercent(value: number, decimals?: Decimals) {
    const formattedValue = _formatPercentValue(value, decimals)

    this.bn = this.bn.add(formattedValue)

    return this
  }

  subPercent(value: number, decimals?: Decimals) {
    const formattedValue = _formatPercentValue(value, decimals)

    this.bn = this.bn.sub(formattedValue)

    return this
  }

  getPercent(value: number, decimals?: Decimals) {
    const formattedValue = _formatPercentValue(value, decimals)

    this.bn = BigNumber.from(formattedValue)

    return this
  }
}


export default DecimalsBN
