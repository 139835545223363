import Web3ProviderEngine from 'web3-provider-engine'
import localStorage from 'local-storage'
import { constants } from 'helpers'
// @ts-ignore
import CacheSubprovider from 'web3-provider-engine/subproviders/cache.js'
import { RPCSubprovider } from '@0x/subproviders/lib/src/subproviders/rpc_subprovider'

import AbstractConnector from './AbstractConnector'

import LedgerSubprovider, { PathTypes } from './LedgerSubprovider'


class LedgerConnector extends AbstractConnector {
  private url: string
  private pollingInterval: number
  private requestTimeoutMs: number

  chainId: number
  urls: Record<number, string>
  account: string | null = null
  provider: Web3ProviderEngine
  ledgerSubprovider: LedgerSubprovider

  constructor(values: {
    urls: LedgerConnector['urls']
    defaultChainId: LedgerConnector['chainId']
    pollingInterval: LedgerConnector['pollingInterval']
    requestTimeoutMs: LedgerConnector['requestTimeoutMs']
  }) {
    super({ supportedChainIds: Object.keys(values.urls).map(Number) })

    this.urls = values.urls
    this.chainId = values.defaultChainId
    this.url = values.urls[values.defaultChainId]
    this.pollingInterval = values.pollingInterval
    this.requestTimeoutMs = values.requestTimeoutMs

    this.provider = new Web3ProviderEngine({
      pollingInterval: this.pollingInterval,
    })

    this.ledgerSubprovider = new LedgerSubprovider({
      networkId: this.chainId,
    })

    this.provider.addProvider(this.ledgerSubprovider)
    this.provider.addProvider(new CacheSubprovider())
    this.provider.addProvider(new RPCSubprovider(this.url, this.requestTimeoutMs))

    this.updateActiveAccount()
  }

  private async updateActiveAccount() {
    const localStorageName = constants.localStorageNames.ledgerSelectedAccount
    const savedLedgerAccount = localStorage.getItem<LocalStorageData.LedgerSelectedAccount>(localStorageName)

    if (savedLedgerAccount) {
      const { index, pathType } = savedLedgerAccount

      await Promise.all([
        this.setActiveAccount(index),
        pathType ? this.setPathType(pathType) : Promise.resolve(),
      ])
    }
  }

  async activate() {
    this.provider.start()
    const account = await this.getAccount()

    return {
      provider: this.provider,
      chainId: this.chainId,
      account,
    }
  }

  deactivate() {
    this.provider?.stop()
  }

  async getProvider() {
    return this.provider
  }

  async getChainId() {
    return this.chainId
  }

  get multipleAccounts() {
    return true
  }

  get pathType(): PathTypes {
    if (this.ledgerSubprovider) {
      return this.ledgerSubprovider.pathType
    }

    return PathTypes.LIVE
  }

  async changeChainId(chainId: number) {
    const newUrl = this.urls[chainId]

    if (!newUrl) {
      return
    }

    if (this.provider) {
      this.provider.stop()
    }

    this.url = newUrl
    this.chainId = chainId

    this.provider = new Web3ProviderEngine({
      pollingInterval: this.pollingInterval,
    })

    this.ledgerSubprovider = new LedgerSubprovider({
      networkId: chainId,
    })

    this.provider.addProvider(this.ledgerSubprovider)
    this.provider.addProvider(new CacheSubprovider())
    this.provider.addProvider(new RPCSubprovider(newUrl, this.requestTimeoutMs))

    this.provider.start()
  }

  async getAccount(force = false) {
    if (!this.account || force) {
      const accounts = await this.getAccounts({
        from: this.ledgerSubprovider?.accountIndex,
        limit: 1,
      })

      this.account = accounts[0]
    }

    return this.account
  }

  async setActiveAccount(index: number) {
    if (this.ledgerSubprovider && this.ledgerSubprovider.accountIndex !== index) {
      this.ledgerSubprovider.accountIndex = index
      const account = await this.getAccount(true)

      this.emitUpdate({
        provider: this.provider,
        chainId: this.chainId,
        account,
      })
    }
  }

  async getAccounts({ from = 0, limit = 5 } = {}) {
    return this.ledgerSubprovider?.getAccountsAsync({ from, limit })
  }

  async setPathType(type: PathTypes) {
    if (this.ledgerSubprovider) {
      const pathType = this.ledgerSubprovider.pathType

      if (pathType === type) {
        return
      }

      this.ledgerSubprovider.pathType = type
      const account = await this.getAccount(true)

      this.emitUpdate({
        provider: this.provider,
        chainId: this.chainId,
        account,
      })
    }
  }
}


export default LedgerConnector
