export default {
  title: {
    en: 'Your SWISE Drop',
    ru: 'Ваш SWISE Бонус',
    fr: 'Votre dépôt de SWISE',
    es: 'Su drop de SWISE',
    pt: 'Sua SWISE Drop',
    de: 'Dein SWISE Drop',
    zh: '您的 SWISE 空投',
  },
  text: {
    en: `
      Congratulations! You're one of the StakeWise early adopters and DAO members.
      Claim your 
      <a href="https://docs.stakewise.io/governance/stakewise-dao#tokenomics" target="_blank" rel="noreferrer nofollow">
        <span>SWISE</span>
        <i class="fa-light fa-up-right-from-square"></i> 
      </a>
      tokens now!
    `,
    ru: `
      Поздравляем! Вы один из первых пользователей StakeWise и членов DAO.
      Получите Ваши 
      <a href="https://docs.stakewise.io/governance/stakewise-dao#tokenomics" target="_blank" rel="noreferrer nofollow">
        <span>SWISE</span>
        <i class="fa-light fa-up-right-from-square"></i> 
      </a>
      токены сейчас!
    `,
    fr: `
      Félicitations! Vous êtes un utilisateur précoce ou membre de la DAO StakeWise.
      Réclamez vos jetons 
      <a href="https://docs.stakewise.io/governance/stakewise-dao#tokenomics" target="_blank" rel="noreferrer nofollow">
        <span>SWISE</span>
        <i class="fa-light fa-up-right-from-square"></i> 
      </a>
      now!
    `,
    es: `
      Felicidades! Eres uno de los primeros usuarios de StakeWise y miembros del DAO.
      Reclama tus 
      <a href="https://docs.stakewise.io/governance/stakewise-dao#tokenomics" target="_blank" rel="noreferrer nofollow">
        <span>tokens SWISE</span>
        <i class="fa-light fa-up-right-from-square"></i> 
      </a>
      ahora!
    `,
    pt: `
      Parabéns! Você é um dos pioneiros do StakeWise e membros do DAO.
      Reclame seus 
      <a href="https://docs.stakewise.io/governance/stakewise-dao#tokenomics" target="_blank" rel="noreferrer nofollow">
        <span>SWISE</span>
        <i class="fa-light fa-up-right-from-square"></i> 
      </a>
      tokens agora!
    `,
    de: `
      Glückwunsch! Du bist ein StakeWise Early Adopter und DAO Mitglied.
      Hol Dir Deine 
      <a href="https://docs.stakewise.io/governance/stakewise-dao#tokenomics" target="_blank" rel="noreferrer nofollow">
        <span>SWISE</span>
        <i class="fa-light fa-up-right-from-square"></i> 
      </a>
      tokens jetzt ab!
    `,
    zh: `
      恭喜! 您已經成為 StakeWise 早期質押者和 DAO 成員。
      趕快索取您的
      <a href="https://docs.stakewise.io/governance/stakewise-dao#tokenomics" target="_blank" rel="noreferrer nofollow">
        <span>SWISE</span>
        <i class="fa-light fa-up-right-from-square"></i> 
      </a>
      代幣!
    `,
  },
  buttonText: {
    en: 'Claim',
    ru: 'Получить',
    fr: 'Réclamez',
    es: 'Reclamar',
    pt: 'Reclamar',
    de: 'Abholen',
    zh: '索取',
  },
}
