import supportedNetworks from 'config/helpers/supportedNetworks'
import supportedUrls from 'config/helpers/supportedUrls'
import { ReadOnlyConnector } from 'custom-connectors'
import { constants } from 'helpers'

import messages from './messages'


const initMonitorAddress: InitConnectors.CreateConnectorHandler<WalletIds> = () => {
  const connector = new ReadOnlyConnector({
    urls: supportedUrls,
    defaultChainId: supportedNetworks.mainnet.chainId,
  })

  return {
    [constants.walletNames.monitorAddress]: {
      connector,
      name: messages.name,
      activationMessage: messages.activationMessage,
    },
  }
}


export default initMonitorAddress
