import React from 'react'
import Image from 'next/image'
import { useConfig } from 'hooks'
import { constants } from 'helpers'

import { Tooltip } from 'components/feedback'

import ListItem from '../components/ListItem/ListItem'

import messages from './messages'
import metaMaskIcon from 'public/static/images/logos/metamask-logo.svg'


const walletName = constants.walletNames.metaMask

const MetaMaskLink: React.FC = () => {
  const { connectors, activate } = useConfig()

  // @ts-ignore
  const isTally = typeof window !== 'undefined' && window?.ethereum?.isTally
  const isMetaMask = typeof window !== 'undefined' && window?.ethereum?.isMetaMask
  const hasInjectedProvider = Boolean(typeof window !== 'undefined' && (window?.web3 || window?.ethereum))

  const title = connectors[walletName].name

  const handleClick = () => {
    if (hasInjectedProvider && isMetaMask) {
      activate(walletName)

      return
    }

    window.open('https://metamask.io/', '_blank')
  }

  const imageNode = (
    <Image src={metaMaskIcon} width={25} height={25} alt="MetaMask" />
  )

  if (isTally) {
    return (
      <Tooltip content={messages.tallyError}>
        <ListItem
          title={title}
          leftNode={imageNode}
          disabled
          onClick={handleClick}
        />
      </Tooltip>
    )
  }

  return (
    <ListItem
      title={title}
      leftNode={imageNode}
      onClick={handleClick}
    />
  )
}


export default React.memo(MetaMaskLink)
