import supportedNetworks from 'config/helpers/supportedNetworks'
import { AbstractConnectorArguments } from 'modules/custom-connectors/types'

import AbstractConnector from './AbstractConnector'


const toHex = (digit: number | string) => `0x${Number(digit).toString(16)}`

const fromHex = (digit: number | string) => parseInt(String(digit), 16)

const getErrorCode = (error: any) => error?.data?.originalError?.code || error?.code

const formatChainId = (chainId: string) => {
  const stringChainId = String(chainId)

  return /x/.test(stringChainId) ? fromHex(stringChainId) : Number(stringChainId)
}

class InjectedConnector extends AbstractConnector {

  private chainId: number | null = null
  private isListenersAdded: boolean = false

  constructor(props: AbstractConnectorArguments) {
    super(props)

    this.handleClose = this.handleClose.bind(this)
    this.handleChainChanged = this.handleChainChanged.bind(this)
    this.handleAccountsChanged = this.handleAccountsChanged.bind(this)
  }

  private handleClose() {
    this.emitDeactivate()
  }

  private handleChainChanged(chainId: string) {
    this.emitUpdate({
      chainId: formatChainId(chainId),
    })
  }

  private handleAccountsChanged(accounts: string[]) {
    if (!accounts.length) {
      this.emitDeactivate()
    }
    else {
      this.emitUpdate({
        account: accounts[0],
      })
    }
  }

  async getProvider(): Promise<EthereumProvider> {
    if (window.ethereum) {
      // wallet link uses WalletLinkProvider for window.ethereum
      // @ts-ignore
      return Promise.resolve(window.ethereum)
    }

    const error = new Error('No Ethereum provider was found on window.ethereum')

    return Promise.reject(error)
  }

  private async addListeners() {
    const provider = await this.getProvider()

    if (typeof provider.on === 'function' && !this.isListenersAdded) {
      provider.on('close', this.handleClose)
      provider.on('chainChanged', this.handleChainChanged)
      provider.on('accountsChanged', this.handleAccountsChanged)

      this.isListenersAdded = true
    }
  }

  async activate() {
    const provider = await this.getProvider()

    await this.addListeners()

    if (provider.isMetaMask) {
      provider.autoRefreshOnNetworkChange = false
    }

    try {
      const account = await this.getAccount()

      return {
        account,
        provider,
      }
    }
    catch (error: any) {
      if (getErrorCode(error) === 4001) {
        throw new Error('The user rejected the request')
      }
      else {
        return Promise.reject(error)
      }
    }
  }

  async changeChainId(chainId: number): Promise<void> {
    const hexadecimalChainId = toHex(chainId)
    const provider = await this.getProvider()

    await this.addListeners()

    if (provider) {
      try {
        const waitForChain = () => (
          new Promise<void>((resolve) => {
            provider.once('chainChanged', () => {
              if (provider.chainId === hexadecimalChainId) {
                resolve()
              }
              else {
                resolve(waitForChain())
              }
            })
          })
        )

        return provider.request({
          method: 'wallet_switchEthereumChain',
          params: [ { chainId: hexadecimalChainId } ],
        })
          .then(() => {
            // Wait for provider chain update
            if (provider.chainId !== hexadecimalChainId) {
              return waitForChain()
            }
          })
      }
      catch (error: any) {
        // DApp browser returns error.data.originalError
        const errorCode = error?.data?.originalError?.code || error?.code
        const isUnrecognizedChain = errorCode === 4902
        const network = Object.values(supportedNetworks).find((supportedNetwork) => (
          supportedNetwork.chainId === chainId
        ))

        if (isUnrecognizedChain && network) {
          return provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                blockExplorerUrls: [ network.blockExplorerUrls ],
                nativeCurrency: network.nativeCurrency,
                chainId: network.hexadecimalChainId,
                rpcUrls: [ network.url ],
                chainName: network.name,
              },
            ],
          })
            .then(() => this.changeChainId(chainId))
        }

        return Promise.reject(error)
      }
    }
    else {
      return Promise.reject('Provider is not exist')
    }
  }

  private async sendRequest(params: string | Record<string, string>) {
    const provider = await this.getProvider()

    // @ts-ignore
    // window.provider = provide
    const data = await provider.send(params)

    return data?.result || data
  }

  async getChainId(): Promise<number> {
    const provider = await this.getProvider()

    // @ts-ignore
    const isTrustWallet = window?.trustwallet

    let chainId = isTrustWallet
      ? await this.sendRequest({ method: 'eth_chainId' })
      : await this.sendRequest('eth_chainId')

    if (!chainId) {
      chainId = await this.sendRequest('net_version')
    }

    if (!chainId) {
      chainId = await this.sendRequest({ method: 'net_version' })
    }

    if (!chainId) {
      if (provider.isDapper) {
        const netVersion = provider.cachedResults.net_version
        chainId = netVersion?.result || netVersion
      }
      else {
        chainId = provider.chainId || provider.netVersion || provider.networkVersion || provider._chainId
      }
    }

    this.chainId = formatChainId(chainId)

    return this.chainId
  }

  async getAccount(): Promise<null | string> {
    const provider = await this.getProvider()

    let accounts

    try {
      accounts = await this.sendRequest('eth_accounts')
    }
    catch (error: any) {
      if (getErrorCode(error) === 4001) {
        return Promise.reject(error)
      }
    }

    if (!accounts?.length) {
      accounts = await provider.enable().then((data) => data?.result || data)
    }

    if (!accounts?.length) {
      accounts = await this.sendRequest({ method: 'eth_accounts' })
    }

    return accounts?.[0]
  }

  async deactivate() {
    const provider = await this.getProvider()

    if (provider?.removeListener && this.isListenersAdded) {
      provider.removeListener('close', this.handleClose)
      provider.removeListener('chainChanged', this.handleChainChanged)
      provider.removeListener('accountsChanged', this.handleAccountsChanged)

      this.isListenersAdded = false
    }
  }

  async isAuthorized() {
    try {
      await this.getProvider()
    }
    catch {
      return false
    }

    try {
      const accounts = await this.sendRequest('eth_accounts')

      return Boolean(accounts?.length)
    }
    catch (error) {
      return Promise.reject(error)
    }
  }
}


export default InjectedConnector
