/* eslint-disable max-len */
import { WETH9 } from '@uniswap/sdk-core'
import constants from 'helpers/constants'


const chainId = 5

// ATTN Addresses must be in check sum format!

export default {
  api: {
    rest: 'https://stage-api.stakewise.io',
    mainSubgraph: 'https://api.thegraph.com/subgraphs/name/stakewise/stakewise-perm-goerli',
    uniswapSubgraph: '',
  },
  network: {
    chainId,
    isMain: false,
    id: 'harbour_goerli',
    name: 'Harbour Goerli',
    hexadecimalChainId: '0x5',
    blockExplorerUrls: 'https://goerli.etherscan.io',
    url: 'https://goerli.infura.io/v3/84842078b09946638c03157f83405213',
    nativeCurrency: {
      symbol: constants.tokens.eth,
      name: 'Ethereum',
      decimals: 18,
    },
  },
  pages: {
    etherscan: 'https://goerli.etherscan.io/tx',
    uniWethToStakedEthPoolNewPosition: '',
    uniSwiseToStakedEthPoolNewPosition: '',
    uniRewardEthToStakedEthPoolNewPosition: '',
    uniEthToSwisePoolNewPosition: '',
  },
  settings: {
    minimalAPR: 2.5,
    maintainerFee: 800,
    rewardUniswapFee: 0,
  },
  addresses: {
    tokens: {
      default: {
        swise: '0x0e2497aACec2755d831E4AFDEA25B4ef1B823855',
        staked: '0xa5c65F2D71f9c82e31a380e1dadb680976492fc5',
        reward: '0xbA9aD2A3Ef7A372900644aBe9D82eCD3Fa8CF8dD',
        deposit: WETH9[chainId].address,
        alternativeDeposit: '',
      },
      special: {
        dai: '',
        steth: '',
      },
    },
    base: {
      pool: '0x896634387c415eFb1d51da80aDf3D90616281949',
      oracles: '0x4E9CA30186E829D7712ADFEEE491c0c6C46E1AED',
      multicall: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
      merkleDrop: '',
      whiteListManager: '0x176Bf5626C6e9Cd82a13CD69997fA58c633fcF7B',
      merkleDistributor: '0x366CB066ee45Fd98f7F228Fc3e0ff76A4801D523',
      vestingEscrowFactory: '',
      soloPaymentsRecipient: '',
    },
    pools: {
      uniswap: {
        stakedTokenNativeToken: '',
        stakedTokenRewardToken: '',
        stakedTokenSwiseToken: '',
        ethSwiseToken: '',
      },
      curve: {
        stakedTokenRewardToken: '',
        gaugeStakedTokenRewardToken: '',
        stakedTokenDepositToken: '',
        gaugeStakedTokenDepositToken: '',
        stakedTokenLidoStakedToken: '',
        gaugeStakedTokenLidoStakedToken: '',
      },
    },
    helpers: {
      uniswap: {
        quoter:  '',
        router:  '',
        positionManager: '',
      },
    },
  },
  tokens: {
    nativeToken: constants.tokens.eth,
    depositToken: constants.tokens.eth,
    rewardToken: constants.tokens['reth-h'],
    stakedToken: constants.tokens['seth-h'],
    alternativeDepositToken: constants.tokens.eth,
  },
} as const
