import React, { useRef, useEffect } from 'react'
import cx from 'classnames'

import Text from 'components/ui/Text/Text'
import Box from 'components/layout/Box/Box'
import type { TextProps } from 'components/ui'

import useCountdown from './util/useCountdown'
import type { Input as InputType } from './util/useCountdown'

import messages from './messages'
import s from './Countdown.module.scss'


export type CountdownProps = {
  initialTime: number
  withIcon?: boolean
  size?: TextProps['size']
  align?: TextProps['align']
  color?: TextProps['color']
  timeOptions?: InputType['timeOptions']
  expiredText?: string | Intl.Message
  dataTestId?: string
  expiredCallback?: (resetTime: (value: number) => void) => void
}

const Countdown: React.FC<CountdownProps> = (props) => {
  const {
    align,
    dataTestId,
    timeOptions,
    initialTime,
    expiredText,

    size = 12,
    withIcon = true,
    color = 'dark-gray',

    expiredCallback,
  } = props

  const timerRef = useRef<HTMLDivElement>(null)

  const { isExpired, setTime } = useCountdown({
    initialTime,
    timeOptions,
    nodeRef: timerRef,
  })

  useEffect(() => {
    if (isExpired && typeof expiredCallback === 'function') {
      expiredCallback(setTime)
    }
  }, [ isExpired, expiredCallback, setTime ])

  const message = isExpired ? (expiredText || messages.expired) : ' '

  const containerClassName = cx(s.container, {
    [s.withIcon]: withIcon,
    [s.expired]: isExpired,
  })

  return (
    <Box className={containerClassName} pr={8} alignRight relative>
      <Text
        className={s.text}
        message={message}
        ref={timerRef}
        color={color}
        align={align}
        size={size}
        dataTestId={dataTestId}
      />
    </Box>
  )
}


export default React.memo(Countdown)
