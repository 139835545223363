import { InjectedConnector } from 'custom-connectors'
import supportedChainIds from 'config/helpers/supportedChainIds'

import initTally from './tally/init'
import initMetaMask from './metaMask/init'
import initDAppBrowser from './dAppBrowser/init'


const initInjected: InitConnectors.CreateConnectorHandler<WalletIds> = () => {
  const connector = new InjectedConnector({
    supportedChainIds,
  })

  const createWalletsHandlers = [ initMetaMask, initTally, initDAppBrowser ]

  return createWalletsHandlers.reduce((result, initWallet) => {
    // @ts-ignore
    const walletData = initWallet(connector)

    return {
      ...result,
      [walletData.id]: walletData,
    }
  }, {})
}


export default initInjected
