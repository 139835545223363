import { useCallback } from 'react'
import { openNotification } from 'notifications'
import { parseEther } from '@ethersproject/units'
import { useConfig, useStore, useActions } from 'hooks'
import { getters, commonMessages, constants } from 'helpers'

import messages from './messages'


type Input = {
  closeModal: () => void
  destroy: () => void
}

type Output = {
  canClaim: boolean
  onButtonClick: () => void
}

const storeSelector = (store: Store) => ({
  proof: store.account.balances.swiseDrop.proof,
  amount: store.account.balances.swiseDrop.amount,
  swiseDropIndex: store.account.balances.swiseDrop.index,
})

const useClaim = (values: Input): Output => {
  const { destroy, closeModal } = values

  const actions = useActions()
  const { swiseDropIndex, proof, amount } = useStore(storeSelector)
  const { config, contracts, library, address, activeWallet } = useConfig()

  const canClaim = activeWallet !== constants.walletNames.monitorAddress

  const handleClick = useCallback(async () => {
    if (library && contracts && address && canClaim && contracts.base.merkleDropContract) {
      closeModal()

      let txHash = ''
      const amountValue = parseEther(amount)
      const signer = library.getUncheckedSigner(address)
      const signedContract = contracts.base.merkleDropContract.connect(signer)

      let timer = setTimeout(() => {
        openNotification({
          type: 'info',
          text: commonMessages.notifications.waitingClaim,
        })
      }, 10000)

      try {
        const estimatedGas = await signedContract.estimateGas.claim(swiseDropIndex, address, amountValue, proof)
        const { hash } = await signedContract.claim(swiseDropIndex, address, amountValue, proof, {
          gasLimit: getters.getGasMargin(estimatedGas),
        })

        txHash = hash

        actions.ui.setBottomLoaderTransaction(`${config.pages.etherscan}/${txHash}`)
      }
      catch (error) {
        clearTimeout(timer)
        actions.ui.resetBottomLoader()
        openNotification({
          type: 'error',
          text: messages.errorNotification,
        })

        return
      }

      library.waitForTransaction(txHash)
        .then(() => {
          destroy()
          clearTimeout(timer)
          actions.ui.resetBottomLoader()
          actions.account.setSwiseDrop({ amount: '0' })
          openNotification({
            type: 'success',
            text: messages.successNotification,
          })
        })
    }
  }, [ contracts, config, amount, address, canClaim ])

  return {
    canClaim,
    onButtonClick: handleClick,
  }
}


export default useClaim
