import React from 'react'
import { Field, useFieldState } from 'formular'
import cx from 'classnames'
import { useIntl } from 'intl'

import Flex from 'components/layout/Flex/Flex'
import Icon from 'components/ui/icons/Icon/Icon'

import s from './Switch.module.scss'


export type SwitchProps = {
  className?: string
  field: Field<boolean>
  onIconName?: string
  offIconName?: string
  dataTestId?: string
  ariaLabel: Intl.Message
  onChange?: (value: boolean) => void
}

const Switch: React.FC<SwitchProps> = (props) => {
  const { field, className, onIconName, offIconName, ariaLabel, dataTestId, onChange } = props

  const intl = useIntl()

  const { value } = useFieldState<boolean>(field)

  const handleClick = () => {
    field.set(!value)

    if (typeof onChange === 'function') {
      onChange(!value)
    }
  }

  const rootClassName = cx(s.switch, className, {
    [s.active]: value,
  })

  const iconName = value ? onIconName : offIconName
  const iconColor = value ? 'info' : 'white'

  return (
    <button
      className={rootClassName}
      role="switch"
      tabIndex={0}
      aria-label={intl.formatMessage(ariaLabel)}
      aria-checked={value}
      data-testid={dataTestId}
      onClick={handleClick}
    >
      <Flex className={s.control} align="center" justify="center">
        {
          typeof iconName === 'string' && (
            // ATTN The key is needed to re-render the SVG styles!
            <Icon key={iconName} name={iconName} color={iconColor} size={14} />
          )
        }
      </Flex>
    </button>
  )
}


export default React.memo(Switch)
