import React from 'react'
import { useDevice } from 'device'

import Text from 'components/ui/Text/Text'

import s from './Tab.module.scss'


type TabProps = {
  id: string
  title: Intl.Message | string
  isActive?: boolean
  dataTestId?: string
  onClick: (id: string) => void
}

const Tab: React.FC<TabProps> = (props) => {
  const { id, title, isActive, dataTestId, onClick } = props

  const { isMobile } = useDevice()

  return (
    <button
      className={s.container}
      role="tab"
      data-testid={dataTestId || `tab: ${id}`}
      aria-selected={isActive}
      onClick={() => onClick(id)}
    >
      <Text
        className={s.text}
        size={isMobile ? 16 : 20}
        message={title}
        align="center"
        color={isActive ? 'white' : 'default'}
      />
    </button>
  )
}


export default React.memo(Tab)
