export default {
  copiedToClipboard: {
    en: 'Copied to clipboard',
    ru: 'Скопировано в буфер обмена',
    fr: 'Copié dans le presse-papier',
    es: 'Copiado al portapapeles',
    pt: 'Copiado para a área de transferência',
    de: 'In die Zwischenablage kopiert',
    zh: '复制到剪贴板',
  },
}
