import request from 'request-module'
import { analytics } from 'helpers'
import { AxiosError } from 'axios'
import { formatEther } from '@ethersproject/units'


type ApiResponse = {
  index: string
  amount: string
  proof: string[]
}

const fetchAccountSwiseDrop = async (account: string, config: Config): Promise<InitialData.Account.RestData['swiseDrop']> => {
  try {
    const response = await request.post<ApiResponse>({
      url: `https://merkle-drop.stakewise.workers.dev/`,
      data: {
        chainId: config.network.chainId,
        account,
      },
    })

    return {
      index: response?.index || '',
      proof: response?.proof || [ '' ],
      amount: formatEther(response?.amount || '0'),
    }
  }
  catch (error) {
    if ((error as AxiosError)?.response?.status !== 404 && IS_PROD && !IS_PREVIEW) {
      analytics.sentry.exception('REST API: merkle-drop.stakewise.workers.dev', error as Error)
    }

    return Promise.reject(error)
  }
}


export default fetchAccountSwiseDrop
