export default {
  link: {
    en: 'Stay Notified',
    ru: 'Получать Уведомления',
    fr: 'Rester informé',
    es: 'Mantenerse Notificado',
    pt: 'Fique notificado',
    de: 'Bleibe Informiert',
    zh: '接受通知',
  },
}
