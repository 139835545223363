import { useEffect } from 'react'
import { useActions, useConfig } from 'hooks'
import { requests, analytics } from 'helpers'
import { openNotification } from 'notifications'

import getFormattedResult from './getters/getFormattedResult'
import fetchAccountRestData from './fetching/fetchAccountRestData'

import messages from './messages'


const useAccount = () => {
  const actions = useActions()
  const { config, address, contracts } = useConfig()

  useEffect(() => {
    if (address) {
      actions.ui.setInitialRequestLoading(true)

      Promise.all([
        requests.multicallUserBalances({ contracts, address }),
        fetchAccountRestData(address, config),
        requests.fetchWhitelistedAccounts({ contracts, address }),
      ])
        .then(async (result) => {
          const account = await getFormattedResult(result)

          actions.account.setInitialData(account)
        })
        .catch((error) => {
          openNotification({
            type: 'error',
            text: messages.error,
          })

          analytics.sentry.exception('Fetch account state error', error)
        })
        .finally(() => {
          actions.ui.setInitialRequestLoading(false)
        })
    }
    else {
      actions.account.resetData()
    }
  }, [ config, address, actions, contracts ])
}


export default useAccount
