import { RefObject, useCallback, useEffect } from 'react'
import { useDevice } from 'device'
import { useConfig, useEventListener } from 'hooks'


type UseStickyProps = {
  fixedClassName: string
  rootContainerRef: RefObject<HTMLDivElement>
}

const useSticky = ({ fixedClassName, rootContainerRef }: UseStickyProps) => {
  const { isDesktop } = useDevice()
  const { isMainnet } = useConfig()

  const handler = useCallback(() => {
    if (!rootContainerRef.current) {
      return
    }

    if (!isDesktop) {
      rootContainerRef.current.classList.remove(fixedClassName)
      rootContainerRef.current.style.height = ''
    }
    else {
      const withBanner = document.body.classList.contains('withBanner')
      const offset = withBanner ? 56 : 0
      const scrollY = window.scrollY

      if (scrollY > offset) {
        rootContainerRef.current.classList.add(fixedClassName)
      }
      else {
        const originalHeight = window.innerHeight - offset - 20

        rootContainerRef.current.style.height = `${originalHeight + scrollY}px`
        rootContainerRef.current.classList.remove(fixedClassName)
      }
    }
  }, [ fixedClassName, rootContainerRef, isDesktop ])

  useEventListener('scroll', handler)
  useEventListener('resize', handler)

  useEffect(() => {
    handler()
  }, [ handler, isMainnet ])
}


export default useSticky
