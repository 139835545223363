import React from 'react'
import Link from 'next/link'
import { analytics } from 'helpers'
import { useRouter } from 'next/router'

import Row from './Row/Row'
import type { RowProps } from './Row/Row'


export type Item = Pick<RowProps, 'text' | 'iconName'> & {
  href: string
}

type ListProps = {
  items: Array<Item>
}

const List: React.FC<ListProps> = ({ items }) => {
  const { pathname } = useRouter()

  return (
    <>
      {
        items.map(({ text, href, iconName }, index) => {
          const isActive = pathname === href
          const isAnotherDomain = /^http/.test(href)

          const item = (
            <Row
              text={text}
              iconName={iconName}
              isActive={isActive}
            />
          )

          const handleClick = () => {
            analytics.statistics.sendEvent('left menu link', { title: text.en })
          }

          if (isAnotherDomain) {
            return (
              <a
                key={index}
                href={href}
                role="menuitem"
                target="_blank"
                rel="noreferrer nofollow"
                data-testid={`main menu link: ${text.en}`}
                onClick={handleClick}
              >
                {item}
              </a>
            )
          }

          return (
            <Link key={index} href={href} passHref>
              <a role="menuitem" onClick={handleClick}>{item}</a>
            </Link>
          )
        })
      }
    </>
  )
}


export default React.memo(List)
