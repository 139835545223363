import convertStringToHash from 'helpers/modifiers/convertStringToHash'


const protectAnalyticsData = <T>(data: T): T => {
  const { recipient, address }: { recipient?: string, address?: string } = data || {}

  return {
    ...data,
    ...(recipient && { recipient: convertStringToHash(recipient) }),
    ...(address && { address: convertStringToHash(address) }),
  }
}


export default protectAnalyticsData

