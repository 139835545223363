import supportedNetworks from './supportedNetworks'


type Urls = { [chainId in ChainIds]: string }

const urls = Object.values(supportedNetworks)
  .reduce<Urls>((acc, { chainId, url }) => {
    acc[chainId] = url

    return acc
  }, {} as Urls)


export default urls
