import { constants, actions } from 'helpers'

import messages from './messages'


const getSpecialErrors: InitConnectors.Data['getSpecialErrors'] = (error) => {
  const errorMessage = (error as Error)?.message

  if (/unsupported chain id/i.test(errorMessage)) {
    actions.resetWalletConnect()
    return messages.errors.unsupportedNetwork
  }
  else if (/user rejected the request/i.test(errorMessage)) {
    actions.resetWalletConnect()
    return messages.errors.authorize
  }

  return null
}

const initZenGo = (connector: AbstractConnector): InitConnectors.Data => ({
  connector,
  name: messages.name,
  id: constants.walletNames.zenGo,
  activationMessage: messages.activationMessage,
  getSpecialErrors,
})


export default initZenGo
