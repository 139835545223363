import * as Sentry from '@sentry/nextjs'
import { store } from 'redux/store'

import protectAnalyticsData from './utils/protectAnalyticsData'


const _wrapMethod = (method: Function) => {
  if (!IS_PROD && !IS_PREVIEW) {
    return
  }

  method()
}

const context = (name: string, data: { [key: string]: any }) => _wrapMethod(() => {
  Sentry.setContext(name, protectAnalyticsData(data))
})

type breadcrumbInput = {
  category?: 'action'
  message: string
  data?: any
}

const breadcrumb = (values: breadcrumbInput) => _wrapMethod(() => {
  const { message, data, category = 'action' } = values

  Sentry.addBreadcrumb({
    level: 'info',
    category,
    message,
    data: protectAnalyticsData(data),
  })
})

const exception = (text: string, error?: Error, extra?: Record<string, any>) => {
  const isE2E = typeof window !== 'undefined' && window.isE2E

  if (isE2E || !IS_PROD && !IS_PREVIEW) {
    console.error(text, error, extra)

    return
  }

  Sentry.captureException(new Error(text), {
    extra: {
      ...extra,
      error,
      store: store.getState(),
    },
  })
}


export default {
  context,
  exception,
  breadcrumb,
}
