import { openNotification } from 'notifications'
import supportedNetworks from 'config/helpers/supportedNetworks'
import type WalletConnectProviderType from '@walletconnect/ethereum-provider'
import WalletConnectProvider, { OPTIONAL_EVENTS, OPTIONAL_METHODS } from '@walletconnect/ethereum-provider'

import { WalletLinkConnectorArguments } from 'modules/custom-connectors/types'

import AbstractConnector from './AbstractConnector'

import messages from './messages'


class WalletLinkConnector extends AbstractConnector {

  chainIds: number[]
  config: WalletLinkConnectorArguments
  provider?: WalletConnectProviderType

  private isListenersAdded: boolean

  constructor(props: WalletLinkConnectorArguments) {
    const { supportedChainIds, config } = props

    super({ supportedChainIds })

    this.config = config
    this.isListenersAdded = false
    this.chainIds = supportedChainIds

    this.init()
    this.handleClose = this.handleClose.bind(this)
    this.handleChainChanged = this.handleChainChanged.bind(this)
    this.handleAccountsChanged = this.handleAccountsChanged.bind(this)
  }

  private handleClose() {
    this.emitDeactivate()
  }

  private handleChainChanged(chainId: string) {
    // Walletconnect can send these values when switching networks ¯\_(ツ)_/¯
    if (chainId !== '0xNaN') {
      this.emitUpdate({
        chainId: parseInt(chainId),
      })
    }
  }

  private handleAccountsChanged(accounts: string[]) {
    if (!accounts.length) {
      this.emitDeactivate()
    }
    else {
      this.emitUpdate({
        account: accounts[0],
      })
    }
  }

  private async addListeners() {
    const provider = await this.getProvider()

    if (typeof provider.on === 'function' && !this.isListenersAdded) {
      provider.on('disconnect', this.handleClose)
      provider.on('chainChanged', this.handleChainChanged)
      provider.on('accountsChanged', this.handleAccountsChanged)

      this.isListenersAdded = true
    }
  }

  async init() {
    if (!this.provider) {
      this.provider = await WalletConnectProvider.init({
        ...this.config,
        optionalEvents: OPTIONAL_EVENTS,
        optionalMethods: OPTIONAL_METHODS,
      })
    }

    return this.provider
  }

  async getProvider() {
    if (!this.provider) {
      const provider = await this.init()

      return provider
    }

    return this.provider
  }

  async activate(config: Config) {
    const provider = await this.getProvider()

    if (provider.session) {
      await this.deactivate()
    }

    if (!provider.session) {
      await provider.connect({
        ...this.config,
        chains: [ config.network.chainId ],
        optionalEvents: OPTIONAL_EVENTS,
        optionalMethods: OPTIONAL_METHODS,
        optionalChains: this.chainIds.filter((chain) => chain !== config.network.chainId),
      })
    }

    let isResolved = false

    const accounts: string[] = await new Promise((resolve, reject) => {
      const userRejectError = 'The user rejected the request'

      provider.on('disconnect', () => {
        // Check provider has not been enabled to prevent this event callback from being called in the future
        if (!isResolved) {
          reject(userRejectError)
        }
      })

      provider.enable()
        .then((accounts) => resolve(accounts))
        .catch((error) => {
          if (error.message === 'User closed modal') {
            reject(userRejectError)
          }
          else {
            reject(error)
          }
          this.deactivate()
        })
        .finally(() => {
          isResolved = true
        })
    })

    await this.addListeners()

    return {
      account: accounts[0],
      provider,
    }
  }

  async changeChainId(chainId: number) {
    const provider = await this.getProvider()

    if (!provider.session) {
      return
    }

    const { methods, chains } = provider.session.namespaces.eip155

    const canSwitchChain = methods.includes('wallet_switchEthereumChain')

    const isSupportedChain = chains && chains
      .map((value) => parseInt(value.split(':')[1] || ''))
      .includes(chainId)

    if (!canSwitchChain) {
      openNotification({
        type: 'error',
        text: messages.switchError,
      })

      throw new Error("Wallet unsupport switch")
    }

    if (!isSupportedChain) {
      openNotification({
        type: 'error',
        text: messages.chainError,
      })

      throw new Error("Wallet unsupport chain")
    }

    const network = Object.values(supportedNetworks).find((supportedNetwork) => (
      supportedNetwork.chainId === chainId
    ))

    if (provider && network) {
      const waitForChain = () => (
        new Promise<void>((resolve) => {
          provider.once('chainChanged', () => {
            if (provider.chainId === chainId) {
              resolve()
            }
            else {
              resolve(waitForChain())
            }
          })
        })
      )

      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [
          { chainId: network.hexadecimalChainId },
        ],
      })

      // Wait for provider chain update
      if (provider.chainId !== chainId) {
        return waitForChain()
      }
    }
  }

  async getChainId() {
    const provider = await this.getProvider()

    return provider.chainId
  }

  async getAccount() {
    const provider = await this.getProvider()

    return provider.accounts?.[0]
  }

  async deactivate() {
    const provider = await this.getProvider()

    if (typeof provider.removeListener === 'function' && this.isListenersAdded) {
      provider.removeListener('disconnect', this.handleClose)
      provider.removeListener('chainChanged', this.handleChainChanged)
      provider.removeListener('accountsChanged', this.handleAccountsChanged)

      this.isListenersAdded = false

      return provider.disconnect()
    }
  }
}


export default WalletLinkConnector
