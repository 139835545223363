import { EventEmitter } from 'events'

import { AbstractConnectorArguments, ConnectorUpdate } from './types'


const events = {
  error: 'AbstractConnectorError',
  update: 'AbstractConnectorUpdate',
  deactivate: 'AbstractConnectorDeactivate',
}

class AbstractConnector extends EventEmitter {

  private supportedChainIds?: number[]

  constructor({ supportedChainIds }: AbstractConnectorArguments = {}) {
    super()

    this.supportedChainIds = supportedChainIds
  }

  protected emitUpdate(update: ConnectorUpdate) {
    console.log(`Emitting '${events.update}' with payload`, update)
    this.emit(events.update, update)
  }

  protected emitError(error: Error) {
    console.log(`Emitting '${events.error}' with payload`, error)
    this.emit(events.error, error)
  }

  protected emitDeactivate() {
    console.log(`Emitting '${events.deactivate}'`)
    this.emit(events.deactivate)
  }
}


export default AbstractConnector
