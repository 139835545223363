const getGnosisData = (): GetNodeOperators.Data => {
  const operators = {
    'Finoa': [ '0xfe26832d3580e0ade4813f9e60e7c17b45e92cba' ],
    'StakeWise Labs': [ '0xf37c8f35fc820354b402054699610c098559ae44' ],
    'CryptoManufaktur': [ '0x59ecf48345a221e0731e785ed79ed40d0a94e2a5' ],
  }

  const australia = [
    `${operators['StakeWise Labs'][0]}-1`,
    `${operators['StakeWise Labs'][0]}-2`,
    `${operators['StakeWise Labs'][0]}-3`,
  ]

  const asia = [
    `${operators['Finoa']}-1`,
    `${operators['Finoa']}-2`,
  ]

  const europe = [] as Array<string>

  const northAmerica = [
    `${operators['CryptoManufaktur'][0]}-1`,
    `${operators['CryptoManufaktur'][0]}-2`,
    `${operators['CryptoManufaktur'][0]}-3`,
  ]

  return {
    operators,
    asia,
    europe,
    australia,
    northAmerica,
  }
}


export default getGnosisData
