import harbourMainnet from './harbourMainnet'
import harbourGoerli from './harbourGoerli'
import mainnet from './mainnet'
import goerli from './goerli'
import gnosis from './gnosis'


export default {
  harbourMainnet,
  mainnet,
  gnosis,

  // testnets
  goerli,
  harbourGoerli,
}
