import { requests } from 'helpers'

import fetchAccountSwiseDrop from './fetchAccountSwiseDrop'
import fetchAccountSettings from './fetchAccountSettings'


const fetchAccountRestData = (address: string, config: Config): Promise<InitialData.Account.RestData> => {
  let result = {} as InitialData.Account.RestData

  const wrapPromise = <T extends unknown>(promise: Promise<T>, propName: string, defaultValue: T) => promise
    .then((response) => {
      result = { ...result, [propName]: response }
    })
    .catch(() => {
      result = { ...result, [propName]: defaultValue }
    })

  return new Promise((resolve) => {
    const swiseDropPromise = wrapPromise(
      fetchAccountSwiseDrop(address, config),
      'swiseDrop',
      {
        index: '',
        amount: '0',
        proof: [ '' ],
      }
    )

    const soloValidatorsPromise = wrapPromise(
      requests.fetchSoloValidators(address, config),
      'soloValidators',
      []
    )

    const userPromise = wrapPromise(
      fetchAccountSettings(address, config),
      'user',
      {
        newsletter: false,
        currency: 'USD',
        email: null,
        isRegistered: false,
      }
    )

    Promise.allSettled([
      soloValidatorsPromise,
      swiseDropPromise,
      userPromise,
    ]).then(() => {
      resolve(result)
    })
  })
}


export default fetchAccountRestData
