import React from 'react'
import cx from 'classnames'
import Image from 'next/image'
import { constants, modifiers } from 'helpers'
import { useStore, useConfig, useFiatValues } from 'hooks'

import { Text, Icon, CountUp } from 'components/ui'
import { Box, Flex } from 'components/layout'
import { Tooltip } from 'components/feedback'

import s from './NativeTokenBalance.module.scss'


type MainTokenBalanceProps = {
  isCompact?: boolean
  buyNativeToken?: (() => void) | null
}

const storeSelector = (store: Store) => ({
  currencySymbol: store.account.currencySymbol,
  nativeTokenBalance: store.account.balances.nativeTokenBalance,
  isInitialRequestLoading: store.ui.initialRequestLoading,
})

const NativeTokenBalance: React.FC<MainTokenBalanceProps> = (props) => {
  const { isCompact, buyNativeToken } = props

  const {
    currencySymbol,
    nativeTokenBalance,
    isInitialRequestLoading,
  } = useStore(storeSelector)

  const { config, isMainnet } = useConfig()
  const nativeToken = config.tokens.nativeToken

  const { fiatBalance } = useFiatValues({
    fiatBalance: {
      value: nativeTokenBalance,
      token: nativeToken,
    },
  })

  const isNotEmpty = Number(fiatBalance.value)
  const image = constants.tokensImages[nativeToken]

  const formattedBalance = parseFloat(modifiers.formatTokenValue({
    withSeparator: false,
    value: nativeTokenBalance,
  }))

  const containerClassName = cx(s.container, {
    [s.loading]: isInitialRequestLoading,
    [s.compact]: isCompact,
  })

  const content = (
    <Box
      className={containerClassName}
      radius={8}
      noWrapper
      ph={12}
    >
      <Flex justify="start">
        {
          Boolean(image) && (
            <Box className={s.imageContainer} mr={8}>
              <Image
                src={image}
                height={16}
                width={16}
                alt=""
              />
            </Box>
          )
        }
        <Text
          className={s.text}
          color={isCompact ? 'black' : 'default'}
          size={isCompact ? 14 : 16}
          tag="span"
          dataTestId="header-token-balance"
        >
          <CountUp
            end={formattedBalance}
            suffix={` ${nativeToken}`}
            decimals={formattedBalance ? 4 : 2}
            duration={isCompact ? 0 : 1.5}
            separator=","
          />
        </Text>
        {
          !isNotEmpty && isMainnet && typeof buyNativeToken === 'function' && (
            <button
              className={s.buyButton}
              type="button"
              onClick={buyNativeToken}
            >
              <Icon
                name="fa-solid fa-plus"
                color="white"
                size={14}
              />
            </button>
          )
        }
      </Flex>
    </Box>
  )

  if (isNotEmpty) {
    return (
      <Tooltip content={`${fiatBalance.formattedValue} ${currencySymbol}`} center>
        {content}
      </Tooltip>
    )
  }

  return content
}


export default React.memo(NativeTokenBalance)
