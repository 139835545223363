import { createSlice, PayloadAction } from '@reduxjs/toolkit'


export type NodeOperator = {
  name: string
  validators: number
}

export const currencies = {
  USD: 0,
  EUR: 0,
  GBP: 0,
} as const

export type CurrenciesObject = Record<keyof typeof currencies, number>

export interface SystemState {
  isPoolPaused: boolean
  oraclesNextUpdate: number
  totalStakingAmount: string
  validators: {
    stakingAPR: number
    protocolFee: number
    maintainerFee: number
  }
  nodeOperators: {
    asia: NodeOperator[]
    europe: NodeOperator[]
    australia: NodeOperator[]
    northAmerica: NodeOperator[]
  }
  fiatPrices: {
    GNO: CurrenciesObject
    ETH: CurrenciesObject
    DAI: CurrenciesObject
    mGNO: CurrenciesObject
    SWISE: CurrenciesObject
    isCalculated: boolean
  }
  isFetched: boolean
}

export const initialState: SystemState = {
  isPoolPaused: false,
  oraclesNextUpdate: 0,
  totalStakingAmount: '0',
  nodeOperators: {
    asia: [],
    europe: [],
    australia: [],
    northAmerica: [],
  },
  validators: {
    stakingAPR: 0,
    protocolFee: 0,
    maintainerFee: 0,
  },
  fiatPrices: {
    GNO: currencies,
    ETH: currencies,
    DAI: currencies,
    mGNO: currencies,
    SWISE: currencies,
    isCalculated: false,
  },
  isFetched: false,
}

export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setInitialData: (state, action: PayloadAction<SystemState>) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    setTotalStakingAmount: (state, action: PayloadAction<SystemState['totalStakingAmount']>) => {
      state.totalStakingAmount = action.payload
    },
    resetData: () => initialState,
  },
})


export const systemMethods = systemSlice.actions

export default systemSlice.reducer
