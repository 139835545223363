import React, { forwardRef } from 'react'
import { createPortal } from 'react-dom'


type TooltipContentProps = {
  children: React.ReactNode
  className: string
}

const TooltipContent = forwardRef<HTMLDivElement, TooltipContentProps>((props, ref) => {
  const { className, children } = props

  return createPortal(
    <div ref={ref} className={className}>
      {children}
    </div>,
    document.getElementById('tooltips') as Element
  )
})

TooltipContent.displayName = 'TooltipContent'


export default TooltipContent
