export default {
  waiting: {
    en: 'Waiting for transfer confirmation',
    ru: 'Ожидаем подтверждения перевода',
    fr: 'Attente de la confirmation du transfert',
    es: 'Esperando la confirmación de la transferencia',
    pt: 'Aguardando confirmação da transferência',
    de: 'Warten auf die Transaktionsbestätigung',
    zh: '等待交易確認',
  },
}
