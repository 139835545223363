export default {
  connect: {
    en: 'Connect wallet',
    ru: 'Подключить кошелёк',
    fr: 'Connecter un portefeuille',
    es: 'Conectar cartera',
    pt: 'Conectar carteira',
    de: 'Wallet verbinden',
    zh: '連接錢包',
  },
  close: {
    en: 'Close menu',
    ru: 'Закрыть меню',
    fr: 'Ferme le menu',
    es: 'Cerrar el menú',
    pt: 'Fechar menu',
    de: 'Menü schließen',
    zh: '關閉選單',
  },
  mobile: {
    connect: {
      en: 'Connect',
      ru: 'Подключить',
      fr: 'Connecter',
      es: 'Conectar',
      pt: 'Conectar',
      de: 'Verbinden',
      zh: '連接',
    },
    close: {
      en: 'Close',
      ru: 'Закрыть',
      fr: 'Fermer',
      es: 'Cerrar',
      pt: 'Fechar',
      de: 'Schließen',
      zh: '關閉',
    },
  },
}
