import React from 'react'
import { modalWrapper } from 'modal'

import { Box } from 'components/layout'
import { Modal } from 'components/feedback'
import { Text, Tabs } from 'components/ui'

import useTokensTabs from './util/useTokensTabs'
import SendingForm from './SendingForm/SendingForm'

import messages from './messages'


export const [ SendTokensModal, openSendTokensModal ] = (
  modalWrapper(UNIQUE_FILE_ID, (props) => {
    const { closeModal } = props

    const tabs = useTokensTabs()

    return (
      <Modal analyticsName="send tokens" closeModal={closeModal}>
        <Box pv={16}>
          <Text
            message={messages.title}
            color="default"
            align="center"
            size={24}
          />
          <Box mt={40}>
            <Tabs>
              {
                tabs.map(({ token, balance, whiteListedOnly }) => (
                  <Tabs.Content key={token} id={token} title={token}>
                    <SendingForm
                      token={token}
                      balance={balance}
                      whiteListedOnly={whiteListedOnly}
                      closeModal={closeModal}
                    />
                  </Tabs.Content>
                ))
              }
            </Tabs>
          </Box>
        </Box>
      </Modal>
    )
  })
)
