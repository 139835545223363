import { Token, Ether } from '@uniswap/sdk-core'
import constants from '../constants'


type Output = {
  uniStakedToken: Token
  uniRewardToken: Token
  uniSwiseToken: Token
  uniWrappedEthToken: Token
}

const savedTokens: { [chainId: number]: Output } = {}

const getUniTokens = (config: Config): Output => {
  // For E2E we need to provide original chain id,
  // otherwise Ether.onChain(chainId) will throw an error
  // @ts-ignore
  const chainId = config.network.originalChainId || config.network.chainId

  if (savedTokens[chainId]) {
    return savedTokens[chainId]
  }

  const uniWrappedEthToken = Ether.onChain(chainId).wrapped

  const uniStakedToken = new Token(
    chainId,
    config.addresses.tokens.default.staked,
    18,
    constants.tokens.seth2,
    'StakeWise Staked ETH2'
  )

  const uniRewardToken = new Token(
    chainId,
    config.addresses.tokens.default.reward,
    18,
    constants.tokens.reth2,
    'StakeWise Reward ETH2'
  )

  const uniSwiseToken = new Token(
    chainId,
    config.addresses.tokens.default.swise,
    18,
    constants.tokens.swise,
    'StakeWise Token'
  )

  savedTokens[chainId] = {
    uniWrappedEthToken,
    uniStakedToken,
    uniRewardToken,
    uniSwiseToken,
  }

  return savedTokens[chainId]
}


export default getUniTokens
