import { RefObject } from 'react'
import { Field } from 'formular'
import { BigNumber } from '@ethersproject/bignumber'
import { isAddress } from '@ethersproject/address'
import { parseEther } from '@ethersproject/units'

import messages from './messages'


// ATTN if you add new validator, then add unit test for it!

// Regular expressions
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line
const ensAddressRegex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/

// Helpers
const isEmpty = (value: any): boolean => (
  typeof value === 'undefined'
  || value === null
  || value === ''
  || /^\s+$/.test(value)
)

const hasSufficientBalance = (balance: BigNumber, value: string): boolean => {
  if (BigNumber.isBigNumber(balance) && !isEmpty(value)) {
    try {
      const formattedValue = parseEther(value)

      return balance.gte(BigNumber.from(formattedValue))
    }
    catch (error) {
      console.error(error)
      return false
    }
  }

  return false
}

const isValidNumberWithDot = (value: string) => (
  !isEmpty(value) && !isNaN(Number(value))
)

// Validators
const required = (value: any) => {
  if (isEmpty(value)) {
    return messages.required
  }
}

const ethAddress = (value: string) => {
  if (!isAddress(value)) {
    return messages.ethAddress
  }
}

const ethOrEnsAddress = (value: string) => {
  if (!isAddress(value) && !ensAddressRegex.test(value)) {
    return messages.ethOrEnsAddress
  }
}

const email = (value: string) => {
  if (!emailRegex.test(value)) {
    return messages.email
  }
}

const numberWithDot = (value: string) => {
  if (!isValidNumberWithDot(value)) {
    return messages.invalidNumberWithDot
  }
}

const sufficientBalance = (balance: BigNumber) => (value: string) => {
  if (!hasSufficientBalance(balance, value)) {
    return messages.insufficientBalance
  }
}

const exclude = (values: string[]) => (value: string) => {
  if (values.includes(value)) {
    return messages.exclude
  }
}

const greaterThanZero = (value: string) => {
  if (Number(value) <= 0) {
    return messages.greaterThanZero
  }
}

const date = (value: string) => {
  if (!/^\d\d\/\d\d\/\d\d\d\d$/.test(value)) {
    return messages.invalidDate
  }
}

type FormFields = Record<string, Field<string>>

type CompareDateProps = {
  moreThan?: string
  lessThan?: string
}

const compareDate = ({ moreThan, lessThan }: CompareDateProps) => (value: string, fields: FormFields) => {
  const fieldName = moreThan || lessThan
  const valueToCompare = fields[fieldName as string]?.state.value

  if (value && valueToCompare) {
    const date = new Date(value)
    const compareDate = new Date(valueToCompare)
    const compareMore = Boolean(moreThan)

    const isError = compareMore
      ? date <= compareDate
      : date >= compareDate

    if (isError) {
      const errorMessage = compareMore ? messages.mustBeMore : messages.mustBeLess

      return { ...errorMessage, values: { valueToCompare } }
    }
  }
}

type Validator = (value: string, values: Record<string, Field<string>>) => Intl.Message | void

const refValidator = (ref: RefObject<Validator>): Validator => (value, values) => (
  ref.current?.(value, values)
)

export default {
  sufficientBalance,
  ethOrEnsAddress,
  greaterThanZero,
  numberWithDot,
  refValidator,
  compareDate,
  ethAddress,
  required,
  exclude,
  email,
  date,
}
