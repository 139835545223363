import createCollectOperator from './createCollectOperator'


// There are cases where we only have 1 address, which needs to be prorated across continents.
//
// config example:
//
// multipurpose: [
//   {
//     id: `${operators['Blockdaemon'][0]}-0`,
//     continent: 'asia',
//     percent: 20, // part of validotors count
//   },
//   {
//     id: `${operators['Blockdaemon'][0]}-0`,
//     continent: 'europe',
//     percent: 40, // part of validotors count
//   },
// ]

type Input = Pick<GetNodeOperators.Params, 'operatorName' | 'multipurpose' | 'validatorsCount' | 'result'>

const multiCollectOperator = (values: Input) => {
  const { multipurpose, operatorName, result, validatorsCount } = values

  Object.values(multipurpose).map(({ continent, percent }) => {
    const value = (Number(validatorsCount) / 100 * percent).toFixed(0)

    const collectOperator = createCollectOperator({
      validatorsCount: value,
      operatorName,
      result,
    })

    collectOperator(continent)
  })
}


export default multiCollectOperator
