import { useEffect } from 'react'

import { supportedNetworks } from '../helpers'


const classes = {
  [supportedNetworks.harbourMainnet.id]: 'harbourMainnet',
  [supportedNetworks.harbourGoerli.id]: 'testnet',
  [supportedNetworks.mainnet.id]: 'mainnet',
  [supportedNetworks.goerli.id]: 'testnet',
  [supportedNetworks.gnosis.id]: 'gnosis',
} as const


const useNetworkClassName = (networkId: ConfigProvider.Context['networkId']) => {
  useEffect(() => {
    const bodyClass = classes[networkId as keyof typeof classes]

    if (bodyClass) {
      document.body.classList.add(bodyClass)

      Object.values(classes).forEach((className) => {
        if (className !== bodyClass) {
          document.body.classList.remove(className)
        }
      })
    }
  }, [ networkId ])
}


export default useNetworkClassName
