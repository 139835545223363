export default {
  text: {
    en: `
      We collect data from your activities on the site for analytics to improve our service.
      You can turn off sending data at any time on the page -
    `,
    ru: `
      Мы собираем данные ваших действий на сайте для аналитики, чтобы сделать наш сервис лучше.
      Отключить отправку данных вы можете в любой момент на странице - 
    `,
    fr: `
      We collect data from your activities on the site for analytics to improve our service.
      You can turn off sending data at any time on the page -
    `,
    es: `
      We collect data from your activities on the site for analytics to improve our service.
      You can turn off sending data at any time on the page -
    `,
    pt: `
      We collect data from your activities on the site for analytics to improve our service.
      You can turn off sending data at any time on the page -
    `,
    de: `
      We collect data from your activities on the site for analytics to improve our service.
      You can turn off sending data at any time on the page -
    `,
    zh: `
      We collect data from your activities on the site for analytics to improve our service.
      You can turn off sending data at any time on the page -
    `,
  },
}
