import React, { useRef } from 'react'

import Box from 'components/layout/Box/Box'
import Grid from 'components/layout/Grid/Grid'
import type { GridProps } from 'components/layout'

import Tab from './Tab/Tab'
import useTabs from './util/useTabs'
import Content from './Content/Content'
import type { TabsContentProps } from './Content/Content'

import s from './Tabs.module.scss'


/**
 * How to use it:
 *
   <Tabs>
    <Tabs.Content id={ID} title={TAB_NAME}>
      {CONTENT}
    </Tabs.Content>
    <Tabs.Content id={ID} title={TAB_NAME}>
      {CONTENT}
    </Tabs.Content>
    ...
  </Tabs>
 */

export type TabsProps = {
  children: Array<React.ReactElement<TabsContentProps>>
  className?: string
  defaultActiveTab?: string
  onChange?: (id: string) => void
}

type TabsComponent = React.FC<TabsProps> & {
  Content: React.FC<TabsContentProps>
}

const Tabs: TabsComponent = (props) => {
  const { children, className, defaultActiveTab, onChange } = props

  const tabsContainer = useRef<HTMLDivElement>(null)
  const floatingBackground = useRef<HTMLDivElement>(null)

  const { tabsCount, content, activeTab, handleClick } = useTabs({
    children, defaultActiveTab, onChange, tabsContainer, floatingBackground,
  })

  const tabs = (
    <Box className={s.tabsContainer} ref={tabsContainer} relative>
      <Grid columns={tabsCount as GridProps['columns']} align="center">
        {
          React.Children.map(children, (child: React.ReactElement<TabsContentProps>) => {
            if (child.type !== Content) {
              console.error('Tabs error: You need to wrap all children in <Tabs.Content> wrapper with "id" and "title" props')

              return null
            }

            const { id, title, dataTestId } = child.props
            const isActive = id === activeTab

            return (
              <Tab
                key={id}
                id={id}
                title={title}
                isActive={isActive}
                dataTestId={dataTestId}
                onClick={handleClick}
              />
            )
          })
        }
      </Grid>
      <div
        ref={floatingBackground}
        className={s.background}
      />
    </Box>
  )

  return (
    <div className={className}>
      {tabs}
      <Box mt={32}>
        {content}
      </Box>
    </div>
  )
}

Tabs.Content = Content


export default Tabs
