export default {
  you: {
    en: 'You',
    ru: 'Вы',
    fr: 'Vous',
    es: 'Usted',
    pt: 'Você',
    de: 'Du',
    zh: '您',
  },
  contract: {
    en: '{token} Contract',
    ru: '{token} Контракт',
    fr: '{token} Contract',
    es: 'Contrato de {token}',
    pt: '{token} Contrato',
    de: '{token} Contract',
    zh: '{token} 合約',
  },
}
