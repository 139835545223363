import React, { useMemo } from 'react'
import cx from 'classnames'

import Box from 'components/layout/Box/Box'
import type { BoxProps } from 'components/layout'

import s from './Bone.module.scss'


type BoneProps = Pick<BoxProps, 'radius'> & {
  className?: string
  w?: number // width rem
  h?: number // height rem
  pw?: number // percent width
  ph?: number // percent height
}

const Bone: React.FC<BoneProps> = (props) => {
  const {
    className,
    w: width, h: height, radius,
    pw: percentWidth, ph: percentHeight,
  } = props

  const style = useMemo(() => {
    const style: React.CSSProperties = {}

    if (width) {
      style.width = style.minWidth = `${width}rem`
    }

    if (percentWidth) {
      style.width = style.minWidth = `${percentWidth}%`
    }

    if (height) {
      style.height = style.minHeight = `${height}rem`
    }

    if (percentHeight) {
      style.height = style.minHeight = `${percentHeight}%`
    }

    return style
  }, [ width, height, percentWidth, percentHeight ])

  return (
    <Box
      className={cx(s.bone, className)}
      overflow="hidden"
      radius={radius}
      style={style}
      relative
    />
  )
}


export default React.memo(Bone)
