export default {
  notification: {
    en: `
      Congratulations on being part of the Early Adopters Campaign!
      You can claim your tokens by clicking on your SWISE balance.
    `,
    ru: `
      Поздравляем! Вы стали участником Кампании Early Adopters!
      Вы можете получить свои токены, нажав на свой баланс SWISE.
    `,
    fr: `
      Félicitations d'avoir participé à la campagne des premiers utilisateurs!
      Vous pouvez réclamer vos jetons en cliquant sur votre solde de SWISE.
    `,
    es: `
      Felicidades por formar parte de la campaña para los primeros usuarios!
      Puedes reclamar tus tokens haciendo clicke en tu balance de SWISE.
    `,
    pt: `
      Parabéns por fazer parte da Campanha Early Adopters!
       Você pode reclamar seus tokens clicando no seu saldo SWISE.
    `,
    de: `
      Glückwunsch zur Teilnahme am Early Adopters Programm!
      Du kannst Deine Tokens abholen, indem Du auf den SWISE Kontostand klickst.
`,
    zh: `
      恭喜成為早期質押者!
      您可以藉由點擊您的 SWISE 餘額索取此代幣.
    `,
  },
}
