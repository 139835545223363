import { constants } from 'helpers'

import messages from './messages'


const getSpecialErrors: InitConnectors.Data['getSpecialErrors'] = (error) => {
  const errorMessage = typeof error === 'string' ? error : error.message

  if (/no ethereum provider/i.test(errorMessage)) {
    return messages.errors.noProvider
  }

  if (/user rejected the request/i.test(errorMessage)) {
    return messages.errors.authorize
  }

  return null
}

const initMetaMask = (connector: AbstractConnector): InitConnectors.Data => ({
  connector,
  name: messages.name,
  id: constants.walletNames.metaMask,
  activationMessage: messages.activationMessage,
  getSpecialErrors,
})


export default initMetaMask
