export default {
  label: {
    en: 'Menu',
    ru: 'Меню',
    fr: 'Menu',
    es: 'Menú',
    pt: 'Menu',
    de: 'Menü',
    zh: '選單',
  },
}
