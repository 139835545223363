import { useMemo } from 'react'
import { constants } from 'helpers'
import { useStore, useConfig } from 'hooks'


type Output = Array<{
  balance: string
  whiteListedOnly: boolean
  token: Config['tokens']['rewardToken'] | Config['tokens']['stakedToken'] | Extract<Tokens, 'SWISE'>
}>

const storeSelector = (store: Store) => ({
  stakedTokenBalance: store.account.balances.stakedTokenBalance,
  rewardTokenBalance: store.account.balances.rewardTokenBalance,
  swiseTokenBalance: store.account.balances.swiseTokenBalance,
})

const useTokensTabs = (): Output => {
  const { config } = useConfig()
  const { rewardTokenBalance, stakedTokenBalance, swiseTokenBalance } = useStore(storeSelector)

  return useMemo(() => [
    {
      token: config.tokens.stakedToken,
      balance: stakedTokenBalance,
      whiteListedOnly: true,
    },
    {
      token: config.tokens.rewardToken,
      balance: rewardTokenBalance,
      whiteListedOnly: true,
    },
    {
      token: constants.tokens.swise,
      balance: swiseTokenBalance,
      whiteListedOnly: false,
    },
  ], [ config, rewardTokenBalance, stakedTokenBalance, swiseTokenBalance ])
}


export default useTokensTabs
