import { getConfig, supportedNetworks } from '../helpers'


// We use this mock to build SSR in development mode.
// This speeds it up, as we don't have to do anything unnecessary.

const getMockContext = () => ({
  config: getConfig(supportedNetworks.mainnet.id),

  address: null,
  chainId: supportedNetworks.mainnet.chainId,
  networkId: supportedNetworks.mainnet.id,
  accountName: null,
  activeWallet: null,
  canChangeChain: false,
  autoConnectChecked: false,

  library: {} as ConfigProvider.Context['library'],
  connector: {} as ConfigProvider.Context['connector'],
  contracts: {} as ConfigProvider.Context['contracts'],
  connectors: {} as ConfigProvider.Context['connectors'],

  isGnosis: false,
  isGoerli: false,
  isMainnet: true,
  isHarbour: false,
  isHarbourGoerli: false,
  isHarbourMainnet: false,

  activate: () => Promise.resolve(),
  deactivate: () => Promise.resolve(),
  changeChain: () => Promise.resolve(),
})


export default getMockContext
