import React, { forwardRef } from 'react'
import cx from 'classnames'

import Icon from 'components/ui/icons/Icon/Icon'
import { IconProps } from 'components/ui'

import s from './QuestionIcon.module.scss'


export type QuestionIconProps = {
  className?: string
  withHover?: boolean
  size?: IconProps['size']
  background: 'gray' | 'blue' | 'default' | 'white' | 'danger-states' | 'warning'
}

const QuestionIcon = forwardRef<HTMLDivElement, QuestionIconProps>((props, ref) => {
  const { className, background, withHover, size = 16 } = props

  const containerClassName = cx(className, s.container, {
    [s.withHover]: withHover,
    [s[`background-${background}`]]: background,
  })

  return (
    <div className={containerClassName} ref={ref}>
      <Icon name="fas fa-question-circle" color="white" size={size} />
    </div>
  )
})

QuestionIcon.displayName = 'QuestionIcon'


export default React.memo(QuestionIcon)
