import { configureStore } from '@reduxjs/toolkit'

import account from './account'
import system from './system'
import farms from './farms'
import ui from './ui'


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const store = configureStore({
  reducer: {
    account,
    system,
    farms,
    ui,
  },
})
