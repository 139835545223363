import { useState, useEffect, useCallback } from 'react'
import localStorage from 'local-storage'
import { constants } from 'helpers'
import { useDevice } from 'device'


type Output = {
  isOpened: boolean
  isDeviceCalculated: boolean
  onClick: () => void
}

const useMenu = (): Output => {
  const { isDesktop, isCalculated } = useDevice()
  const [ isOpened, setOpened ] = useState<boolean>(false)

  useEffect(() => {
    if (!isCalculated) {
      return
    }

    if (!isDesktop) {
      setOpened(false)
      document.body.classList.remove('main-menu-open')

      return
    }

    const savedStatus = localStorage.getItem<boolean>(constants.localStorageNames.savedOpenedMenu)

    if (isDesktop && savedStatus !== null) {
      setOpened(savedStatus)

      if (savedStatus) {
        document.body.classList.add('main-menu-open')
      }
      else {
        document.body.classList.remove('main-menu-open')
      }
    }
  }, [ isDesktop, isCalculated ])

  const handleClick = useCallback(() => {
    setOpened((prev) => {
      if (prev) {
        localStorage.setItem(constants.localStorageNames.savedOpenedMenu, false)
        document.body.classList.remove('main-menu-open')
      }
      else {
        localStorage.setItem(constants.localStorageNames.savedOpenedMenu, true)
        document.body.classList.add('main-menu-open')
      }

      return !prev
    })
  }, [])

  return {
    isOpened,
    onClick: handleClick,
    isDeviceCalculated: isCalculated,
  }
}


export default useMenu
