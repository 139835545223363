import React, { ReactElement } from 'react'
import { Form, useFormState } from 'formular'


type FormState = {
  values: Record<string, any>
  errors: Record<string, string> | null
  isValid: boolean
  isChanged: boolean
  isValidating: boolean
  isSubmitting: boolean
  isSubmitted: boolean
}

type FieldsStateProps = {
  children: (state: FormState) => ReactElement | null
  form: Form<FormState['values']>
}

const FormState: React.FC<FieldsStateProps> = (props) => {
  const { children, form } = props

  const state = useFormState(form)

  return children(state)
}


export default React.memo(FormState)
