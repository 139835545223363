import getNetworkType from './getNetworkType'


type Input = {
  config: Config
  fiatDeposit: number
  poolAddress: string
  positions: Store['farms']['pools'][string]['positions']
}

type Output = {
  apr: number
  isPartially: boolean
  isParticipating: boolean
}

const getPositionsData = (values: Input): Output => {
  const { positions, config, fiatDeposit, poolAddress } = values

  if (!positions.length) {
    return {
      apr: 0,
      isPartially: false,
      isParticipating: false,
    }
  }

  const { isGnosis } = getNetworkType(config)

  if (isGnosis) {
    // Gnosis has no positions, so we collect all data on the deposit in one position
    return {
      apr: positions[0].APR,
      isPartially: false,
      isParticipating: true,
    }
  }

  const isSwisePool = [
    config.addresses.pools.uniswap.stakedTokenSwiseToken,
    config.addresses.pools.uniswap.ethSwiseToken as string,
  ].includes(poolAddress)

  const { totalFiatProfit, positionsInRange } = positions.reduce((acc, position) => {
    const { fiatDeposit, APR, inRange, inFullRange } = position

    const isActive = isSwisePool ? inFullRange : inRange
    const fiatDepositProfit = APR * (fiatDeposit / 100)

    acc.totalFiatProfit = acc.totalFiatProfit + fiatDepositProfit

    if (isActive) {
      acc.positionsInRange += 1
    }

    return acc
  }, {
    totalFiatProfit: 0,
    positionsInRange: 0,
  })

  const apr = fiatDeposit ? totalFiatProfit / (fiatDeposit / 100) : 0
  const isParticipating = positions.length ? positionsInRange === positions.length : false
  const isPartially = positionsInRange > 0

  return { apr, isPartially, isParticipating }
}


export default getPositionsData
