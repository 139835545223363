import { useActions } from 'hooks'
import commonMessages from 'helpers/messages'
import { openNotification } from 'notifications'


type Input = {
  config: Config
  sentText?: Intl.Message
  txHash: string | undefined
  library: Library | undefined
  actions: ReturnType<typeof useActions>
  withConfirmedNotification?: boolean
  callback?: () => void
}

// TODO - use this helper for other places where we handle transactions
const handleTransaction = (values: Input) => {
  const { txHash, config, sentText, actions, library, withConfirmedNotification = true, callback } = values

  actions.ui.resetBottomLoader()

  if (!txHash || !library) {
    return Promise.reject()
  }

  actions.ui.setBottomLoaderTransaction(`${config.pages.etherscan}/${txHash}`)

  if (sentText) {
    openNotification({
      type: 'success',
      text: sentText,
    })
  }

  return library.waitForTransaction(txHash)
    .then(() => {
      if (typeof callback === 'function') {
        callback()
      }

      actions.ui.resetBottomLoader()

      if (withConfirmedNotification) {
        openNotification({
          type: 'success',
          text: commonMessages.notifications.success,
        })
      }
    })
}


export default handleTransaction
