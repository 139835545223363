export default {
  locale: 'locale',
  currency: 'currency',
  themeColor: 'themeColor',
  walletName: 'walletName',
  zengoBanner: 'zengoBanner',
  savedNetwork: 'savedNetwork',
  savedOpenedMenu: 'savedOpenedMenu',
  savedReadOnlyAddress: 'savedReadOnlyAddress',
  rewardsUpdateTime: 'rewardsUpdateTime',
  depositAddTokensShown: 'depositAddTokensShown',
  ledgerSelectedAccount: 'ledgerSelectedAccount',
  depositAddTokensShownGnosis: 'depositAddTokensShownGnosis',
  isPreventAnalytics: 'isPreventAnalytics',

  // If any query breaks, we can show the user the last successful ones, which we save each time a query succeeds
  mocks: {
    fiatValues: 'mockFiatValues',
    swisePrice: 'mockSwisePrice',
    validatorsAPR: 'mockValidatorsAPR',
    totalStakingAmount: 'mockTotalStakingAmount',
  },
}
