import { ExternalProvider, Web3Provider } from '@ethersproject/providers'
import { getConnectors } from 'connectors'
import { createContracts } from 'contracts'

import { getConfig, supportedNetworks } from '../helpers'


const getInitialContext = (queryNetworkId?: NetworkIds | null): ConfigProvider.State => {
  const networkId = queryNetworkId || supportedNetworks.mainnet.id
  const config = getConfig(networkId)
  const connectors = getConnectors(config)
  const connector = connectors.monitorAddress.connector
  const chainId = config.network.chainId as ChainIds

  if (networkId !== supportedNetworks.mainnet.id) {
    connector.changeChainId?.(chainId)
  }

  const library = new Web3Provider(connector as ExternalProvider)
  const contracts = createContracts(library, config)

  return {
    config,
    chainId,
    networkId,

    library,
    connector,
    contracts,
    connectors,

    address: null,
    accountName: null,
    activeWallet: null,
    autoConnectChecked: false,
  }
}


export default getInitialContext
