import React from 'react'
import { useDevice } from 'device'
import { constants } from 'helpers'

import { Text, Icon } from 'components/ui'
import { Box, Flex } from 'components/layout'

import s from './ListItem.module.scss'


export type ListItemProps = {
  hash: {
    text: string
    link: string
  }
  sender: Intl.Message | string
  recipient: Intl.Message | string
  amount: {
    value: string
    token: Tokens
    isExpenses?: boolean
  }
}

const ListItem: React.FC<ListItemProps> = (props) => {
  const { sender, recipient, amount, hash } = props

  const { isMobile } = useDevice()

  return (
    <Flex className={s.container}>
      <Box className={s.box} p={12}>
        <Text
          message={sender}
          color="default"
          align="center"
          size={12}
        />
      </Box>
      <Box className={s.box} p={12}>
        <Text
          message={recipient}
          color="default"
          align="center"
          size={12}
        />
      </Box>
      <Box className={s.box} p={12}>
        <Text
          color={amount?.isExpenses ? 'danger' : 'green-states'}
          message={`${amount?.value} ${amount?.token}`}
          align="center"
          size={12}
        />
      </Box>
      <div className={s.hash}>
        <a
          href={hash?.link}
          rel="noopener noreferrer"
          target="_blank"
        >
          {
            isMobile ? (
              <Icon
                name="fa-light fa-up-right-from-square"
                color="info"
                size={14}
              />
            ) : (
              <Text
                message={hash?.text}
                className={s.link}
                color="info"
                align="center"
                size={12}
              />
            )
          }
        </a>
      </div>
    </Flex>
  )
}


export default React.memo(ListItem)
