import { useMemo, useCallback } from 'react'
import { modifiers, getters } from 'helpers'
import { useSelector as useStore } from 'react-redux'


type Input<T extends string> = Record<T, {
  token: Tokens
  value: string
}>

type Output<T extends string> = Record<T, {
  value: number
  formattedValue: string
}>

const storeSelector = (store: Store) => ({
  currency: store.account.currency,
  fiatPrices: store.system.fiatPrices,
})

const useFiatValues = <T extends string>(values: Input<T>): Output<T> => {
  const { fiatPrices, currency } = useStore(storeSelector)

  const getFiatValue = useCallback((params: Input<T>[T]) => {
    const { token, value } = params

    const result = getters.getFiatValue({ token, value, fiatPrices, currency })

    const formattedResult = modifiers.formatTokenValue({
      value: String(result),
      max: 2,
    })

    return {
      value: Number(result),
      formattedValue: formattedResult,
    }
  }, [ fiatPrices, currency ])

  return useMemo(() => (
    Object.keys(values).reduce<Output<T>>((acc, key) => {
      const params = values[key as keyof typeof values]
      const result = getFiatValue(params)

      acc[key as keyof typeof values] = result

      return acc
    }, {} as Output<T>)
  ), [ values, getFiatValue ])
}


export default useFiatValues
