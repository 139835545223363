import request from 'request-module'
import cacheStorage from 'cache-storage'


type Cache = { [key: string]: string[] }

const cacheTime = 1000 * 60 * 5
const cache = cacheStorage.get(UNIQUE_FILE_ID)
cache.setData<Cache>({})

const setCache = (key: string, value: string[]) => {
  const cachedData = cache.getData<Cache>() || {}
  const hasMemoryLeaks = Object.keys(cachedData).length > 10

  if (hasMemoryLeaks) {
    cache.setData({ [key]: value })

    return
  }

  cache.setData({
    ...cachedData,
    [key]: value,
  }, cacheTime)
}

// TODO Temporarily remove this logic from the application + remove cache
const fetchActivationTimes = (indexes: number[], config: Config): Promise<string[]> => {
  const cacheKey = indexes.join(config.network.chainId.toString())
  const cachedData = cache.getData<Cache>() || {}

  if (cachedData[cacheKey]) {
    return Promise.resolve(cachedData[cacheKey])
  }

  return request.post<{ activation_times: string[] }>({
    url: `${config.api.rest}/activation-times/`,
    data: {
      network: config.network.id,
      validator_indexes: indexes,
    },
  })
    .then((response) => {
      const data = response.activation_times

      setCache(cacheKey, data)

      return data
    })
}


export default fetchActivationTimes
