import React, { useState } from 'react'
import { Field, useFieldState } from 'formular'
import { useIntl } from 'intl'
import cx from 'classnames'

import Text from 'components/ui/Text/Text'
import Box from 'components/layout/Box/Box'
import Flex from 'components/layout/Flex/Flex'
import Icon from 'components/ui/icons/Icon/Icon'

import masks from './util/masks'

import s from './Input.module.scss'


const sizes = [ 38, 50 ] as const

type InputSize = typeof sizes[number]

export type InputProps = {
  className?: string
  field: Field<string>
  size?: InputSize
  mask?: keyof typeof masks
  pattern?: string
  disabled?: boolean
  dataTestId?: string
  withRightIcon?: boolean
  validateChange?: boolean
  leftNode?: React.ReactNode
  placeholder?: Intl.Message | string
  onChange?: (value: string) => void
}

const Input: React.FC<InputProps> = (props) => {
  const {
    className, field, mask, pattern, disabled, placeholder, leftNode,
    dataTestId, size = 38, withRightIcon = true, validateChange, onChange,
  } = props

  const intl = useIntl()
  const { value, error } = useFieldState(field)
  const [ isSuccess, setSuccess ] = useState(false)

  const handleErrors = () => {
    field.validate().then((errors: object) => {
      const successStatus = Boolean(value && !errors && !field.state.error)

      setSuccess(successStatus)
    })
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) {
      return
    }

    let value = event.target.value
    const applyMask = mask ? masks[mask] : undefined

    if (value) {
      if (typeof applyMask === 'function') {
        value = applyMask(value)
      }
      else if (pattern) {
        const isValidPattern = new RegExp(pattern, 'g').test(value)

        if (!isValidPattern) {
          return
        }
      }
    }

    field.set(value)

    if (error || validateChange) {
      handleErrors()
    }

    if (typeof onChange === 'function') {
      onChange(value)
    }
  }

  const isErrored = Boolean(error)

  const rootClassName = cx(s.root, className, {
    [s.errored]: isErrored,
    [s.success]: isSuccess,
    [s.disabled]: disabled,
    [s.withRightIcon]: withRightIcon,
    [s[`size-${size}`]]: size,
  })

  const placeholderText = typeof placeholder === 'object'
    ? intl.formatMessage(placeholder)
    : placeholder

  return (
    <>
      <Flex className={rootClassName} justify="start">
        {
          Boolean(leftNode) && leftNode
        }
        <input
          className={s.input}
          value={value}
          disabled={disabled}
          placeholder={placeholderText}
          data-testid={dataTestId}
          onChange={handleChange}
          onBlur={handleErrors}
        />
        {
          withRightIcon && (
            <div className={s.rightIcon}>
              {
                (isErrored && !isSuccess) && (
                  <Icon name="fa-solid fa-xmark" size={16} color="danger-states" />
                )
              }
              {
                (isSuccess && !isErrored) && (
                  <Icon name="fa-solid fa-check" size={16} color="success" />
                )
              }
            </div>
          )
        }
      </Flex>
      {
        isErrored && (
          <Box mt={8} noWrapper>
            <Text
              message={error}
              color="danger"
              size={12}
              dataTestId={dataTestId ? `${dataTestId}-validation` : ''}
            />
          </Box>
        )
      }
    </>
  )
}


export default React.memo(Input)
