import { useCallback, useEffect, useMemo } from 'react'
import { useField, useFieldState } from 'formular'
import { useRouter } from 'next/router'
import { analytics } from 'helpers'
import { useIntl } from 'intl'

import enImage from '../images/en.svg'
import deImage from '../images/de.svg'
import frImage from '../images/fr.svg'
import esImage from '../images/es.svg'
import ptImage from '../images/pt.svg'
import zhImage from '../images/zh.svg'
import ruImage from '../images/ru.svg'


const options = [
  {
    title: 'English',
    value: 'en',
    image: enImage,
  },
  {
    title: 'Deutsch',
    value: 'de',
    image: deImage,
  },
  {
    title: 'French',
    value: 'fr',
    image: frImage,
  },
  {
    title: 'Español',
    value: 'es',
    image: esImage,
  },
  {
    title: 'Português',
    value: 'pt',
    image: ptImage,
  },
  {
    title: '繁體中文',
    value: 'zh',
    image: zhImage,
  },
  {
    title: 'Русский',
    value: 'ru',
    image: ruImage,
  },
]

const useLanguage = () => {
  const intl = useIntl()
  const { locales } = useRouter()

  const languageField = useField({
    value: intl.locale,
  })

  const { value } = useFieldState(languageField)

  useEffect(() => {
    const handleChange = (value: string) => {
      intl.setLocale(value)
    }

    languageField.on('change', handleChange)

    return () => {
      languageField.off('change', handleChange)
    }
  }, [ languageField, intl ])

  const filteredOptions = useMemo(() => {
    return options.filter(({ value }) => locales?.includes(value))
  }, [ locales ])

  const [ selectedOption, languageOptions ]  = useMemo(() => [
    filteredOptions.find((option) => option.value === value),
    filteredOptions.filter((option) => option.value !== value),
  ], [ filteredOptions, value ])

  const handleChange = useCallback((value) => {
    analytics.statistics.sendEvent('update language', {
      oldLanguage: languageField.state.value,
      language: value,
    })

    languageField.set(value)
  }, [])

  return {
    languageOptions,
    selectedOption,
    handleChange,
  }
}


export default useLanguage
