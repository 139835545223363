import React from 'react'
import { parseEther } from '@ethersproject/units'
import { modifiers, commonMessages } from 'helpers'

import { Box } from 'components/layout'
import { Input } from 'components/inputs'
import { Text, Icon } from 'components/ui'

import useSendingForm from './util/useSendingForm'
import ConfirmButton from './ConfirmButton/ConfirmButton'

import messages from './messages'


export type SendingFormProps = {
  balance: string
  whiteListedOnly: boolean
  token: Config['tokens']['rewardToken'] | Config['tokens']['stakedToken'] | Extract<Tokens, 'SWISE'>
  closeModal: () => void
}

const SendingForm: React.FC<SendingFormProps> = (props) => {
  const { token, balance, whiteListedOnly, closeModal } = props

  const { form, isLoading, isReadOnlyWallet, onSubmit } = useSendingForm({
    balance: parseEther(balance),
    whiteListedOnly,
    closeModal,
    token,
  })

  const formattedBalance = modifiers.formatTokenValue({ value: balance })

  const balanceMessage = {
    ...messages.balance,
    values: { balance: `${formattedBalance} ${token}` },
  }

  return (
    <Box pt={24}>
      <Text
        message={balanceMessage}
        color="dark-gray"
        align="center"
        size={16}
      />
      <Box mt={24}>
        <Input
          field={form.fields.amount}
          size={50}
          validateChange
          pattern="^[0-9]+\.?[0-9]{0,18}$"
          dataTestId="send tokens modal amount input"
          placeholder={messages.amount}
          leftNode={(
            <Box pv={8} pl={16}>
              <Icon
                name="fa-light fa-coins"
                color="default"
                size={16}
              />
            </Box>
          )}
        />
      </Box>
      <Box mt={16}>
        <Input
          field={form.fields.recipient}
          size={50}
          validateChange
          dataTestId="send tokens modal recipient input"
          placeholder={messages.recipient}
          leftNode={(
            <Box pv={8} pl={16}>
              <Icon
                name="fa-light fa-user"
                color="default"
                size={16}
              />
            </Box>
          )}
        />
      </Box>
      <Box mt={40} alignCenter>
        {
          isReadOnlyWallet && (
            <Box mb={24} noWrapper>
              <Text
                message={commonMessages.disabledMessages.readOnly}
                color="danger"
                size={16}
              />
            </Box>
          )
        }
        <ConfirmButton
          form={form}
          loading={isLoading}
          isReadOnlyWallet={isReadOnlyWallet}
          onClick={onSubmit}
        />
      </Box>
    </Box>
  )
}


export default React.memo(SendingForm)
