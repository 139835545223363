export default {
  vestings: {
    en: 'Vestings',
    ru: 'Вестинги',
    fr: 'Vestings',
    es: 'Adquisiciones',
    pt: 'Aquisições',
    de: 'Ansprüche',
    zh: '選擇權',
  },
  addTokens: {
    en: 'Add Tokens',
    ru: 'Добавить Токены',
    fr: 'Ajouter les jetons',
    es: 'Agregar Tokens',
    pt: 'Adicionar tokens',
    de: 'Tokens hinzufügen',
    zh: '增加代幣',
  },
  transactions: {
    en: 'Transactions',
    ru: 'Транзакции',
    fr: 'Transactions',
    es: 'Transacciones',
    pt: 'Transações',
    de: 'Transaktionen',
    zh: '交易',
  },
  activations: {
    en: 'Activations',
    ru: 'Активации',
    fr: 'Activations',
    es: 'Activaciones',
    pt: 'Ativações',
    de: 'Aktivierungen',
    zh: '啟用',
  },
  disconnect: {
    en: 'Disconnect',
    ru: 'Отключить',
    fr: 'Déconnexion',
    es: 'Desconectar',
    pt: 'Desconectar',
    de: 'Verbindung trennen',
    zh: '斷線',
  },
  switchAccount: {
    en: 'Switch Account',
    ru: 'Переключить Аккаунт',
    fr: 'Changer de compte',
    es: 'Cambiar Cuenta',
    pt: 'Mudar de conta',
    de: 'Konto wechseln',
    zh: '切換帳戶',
  },
  sendTokens: {
    en: 'Send Tokens',
    ru: 'Отправить токены',
    fr: 'Envoyer des jetons',
    es: 'Enviar Tokens',
    pt: 'Enviar tokens',
    de: 'Sende Tokens',
    zh: '發送代幣',
  },
  buyNativeToken: {
    en: 'Buy {nativeToken}',
    ru: 'Купить {nativeToken}',
    fr: 'Buy {nativeToken}',
    es: 'Buy {nativeToken}',
    pt: 'Buy {nativeToken}',
    de: 'Buy {nativeToken}',
    zh: 'Buy {nativeToken}',
  },
}
