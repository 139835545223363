import { useStore } from 'hooks'

import type { Item } from '../List/List'

import messages from './messages'


const _getItems = (hasSoloValidators: boolean): Item[] => {
  const result = [
    {
      text: messages.stake,
      href: '/',
      iconName: 'fa-light fa-rocket',
    },
    {
      text: messages.farm,
      href: '/farm',
      iconName: 'fa-light fa-tractor',
    },
  ]

  if (hasSoloValidators) {
    result.push(
      {
        text: messages.solo,
        href: '/solo',
        iconName: 'fa-light fa-user',
      }
    )
  }

  result.push(
    {
      text: messages.forum,
      iconName: 'fa-light fa-comments',
      href: 'https://forum.stakewise.io',
    },
    {
      text: messages.vote,
      iconName: 'fa-light fa-gavel',
      href: 'https://vote.stakewise.io',
    },
    {
      text: messages.docs,
      href: 'https://docs.stakewise.io',
      iconName: 'fa-light fa-file-magnifying-glass',
    }
  )

  return result
}

const storeSelector = (store: Store) => ({
  hasSoloValidators: Boolean(store.account.soloValidators.list.length),
})

const useContent = () => {
  const { hasSoloValidators } = useStore(storeSelector)

  return _getItems(hasSoloValidators)
}


export default useContent
