import { analytics } from 'helpers'
import React, { ErrorInfo } from 'react'

import ErrorContent from './ErrorContent/ErrorContent'


class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    analytics.sentry.exception('UI was crashed', error, { info: errorInfo })
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props

    if (hasError) {
      return (
        <ErrorContent />
      )
    }

    return children
  }
}


export default ErrorBoundary
