export default {
  required: {
    en: 'This field is required',
    ru: 'Это обязательное поле',
    fr: 'Ce champ est requis',
    es: 'Este campo es requerido',
    pt: 'Este campo é obrigatório',
    de: 'Dieses Feld ist erforderlich',
    zh: '此欄位為必填欄位',
  },
  email: {
    en: 'Valid email address required',
    ru: 'Необходимо ввести корректный email',
    fr: 'Une adresse mail valide est requise',
    es: 'Se requiere un correo válido',
    pt: 'Endereço de e-mail válido obrigatório',
    de: 'Gültige Email Adresse erforderlich',
    zh: '必須為有效的電子郵件地址',
  },
  ethAddress: {
    en: 'Valid Ethereum address required',
    ru: 'Необходимо ввести корректный Ethereum адрес',
    fr: 'Une adresse Ethereum valide est requise',
    es: 'Se requiere una dirección de Ethereum válida',
    pt: 'Endereço Ethereum válido necessário',
    de: 'Gültige Ethereum Adresse erforderlich',
    zh: '必須為有效的 Ethereum 地址',
  },
  ethOrEnsAddress: {
    en: 'Valid Ethereum or ENS address required',
    ru: 'Необходимо ввести корректный Ethereum или ENS адрес',
    fr: 'Une adresse Ethereum ou ENS valide est requise',
    es: 'Se requiere una dirección de Ethereum o ENS válidos',
    pt: 'Endereço Ethereum ou ENS válido necessário',
    de: 'Gültige Ethereum oder END Adresse erforderlich',
    zh: '必須為有效的 Ethereum 或 ENS 域名',
  },
  insufficientBalance: {
    en: 'Insufficient balance on selected wallet',
    ru: 'Недостаточно средств на выбранном кошельке',
    fr: 'Solde insuffisant dans le portefeuille sélectionné',
    es: 'Balance insuficiente en la cartera seleccionada',
    pt: 'Saldo insuficiente na carteira selecionada',
    de: 'Unzureichender Kontostand in der ausgewählten Wallet',
    zh: '所選錢包的餘額不足',
  },
  invalidNumberWithDot: {
    en: 'Must be a number with dot as a decimal point',
    ru: 'Необходимо ввести число с точкой в качестве разделителя',
    fr: 'Ceci doit être un nombre avec un point décimal',
    es: 'El valor debe ser un número con un punto como separador decimal',
    pt: 'Deve ser um número com ponto como ponto decimal',
    de: 'Muss eine Nummer mit einem Punkt als Dezimaltrenner sein',
    zh: '必須為含有小數點的數字',
  },
  exclude: {
    en: 'This value does not fit',
    ru: 'Не подходящее значение',
    fr: 'Cette valeur ne correspond pas',
    es: 'Este valor no encaja',
    pt: 'Este valor não se encaixa',
    de: 'Dieser Wert passt nicht',
    zh: '此值不適合',
  },
  greaterThanZero: {
    en: 'Must be greater than 0',
    ru: 'Необходимо ввести число больше чем 0',
    fr: 'Ceci doit être supérieur à 0',
    es: 'Debe ser mayor a 0',
    pt: 'Deve ser maior que 0',
    de: 'Muss größer als 0 sein',
    zh: '必須大於零',
  },
  invalidDate: {
    en: 'Must be valid date',
    ru: 'Необходимо ввести корректную дату',
    fr: 'Ceci doit être une date valide',
    es: 'La fecha debe ser válida',
    pt: 'Deve ser uma data válida',
    de: 'Muss ein gültiges Datum sein',
    zh: '必須為有效的日期',
  },
  mustBeLess: {
    en: 'Must be less than {valueToCompare}',
    ru: 'Необходимо ввести меньше чем {valueToCompare}',
    fr: 'Ceci doit être inférieur à {valueToCompare}',
    es: 'El valor debe ser menor a {valueToCompare}',
    pt: 'Deve ser menor que {valueToCompare}',
    de: 'Muss weniger als {valueToCompare} sein',
    zh: '必須小於 {valueToCompare}',
  },
  mustBeMore: {
    en: 'Must be more than {valueToCompare}',
    ru: 'Необходимо ввести больше чем {valueToCompare}',
    fr: 'Ceci doit être supérieur à {valueToCompare}',
    es: 'El valor debe ser mayor a {valueToCompare}',
    pt: 'Deve ser maior que {valueToCompare}',
    de: 'Muss mehr als {valueToCompare} sein',
    zh: '必須大於 {valueToCompare}',
  },
}
