import { constants } from 'helpers'
import supportedNetworks from 'config/helpers/supportedNetworks'

import { InjectedConnector } from 'custom-connectors'

import messages from './messages'


const getSpecialErrors: InitConnectors.Data['getSpecialErrors'] = (error) => {
  const errorMessage = typeof error === 'string' ? error : error.message

  if (/no ethereum provider/i.test(errorMessage)) {
    return messages.errors.noProvider
  }

  if (/user rejected the request/i.test(errorMessage)) {
    return messages.errors.authorize
  }

  return null
}

const connector = new InjectedConnector({
  supportedChainIds: [ supportedNetworks.mainnet.chainId ],
})

const initTally = (): InitConnectors.Data => ({
  // @ts-ignore
  connector,
  name: messages.name,
  id: constants.walletNames.tally,
  activationMessage: messages.activationMessage,
  getSpecialErrors,
})


export default initTally
