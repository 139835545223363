import React from 'react'
import { modalWrapper } from 'modal'

import { Box } from 'components/layout'
import { Modal } from 'components/feedback'
import { Text, Tabs, Icon, QrReader } from 'components/ui'
import { Input, Button } from 'components/inputs'

import useMonitorAddress from './util/useMonitorAddress'

import messages from './messages'
import s from './MonitorAddressModal.module.scss'


export const [ MonitorAddressModal, openMonitorAddressModal ] = (
  modalWrapper(UNIQUE_FILE_ID, (props) => {
    const { closeModal } = props

    const { field, cameraError, onSetAddress, onSetCameraError } = useMonitorAddress(closeModal)

    return (
      <Modal analyticsName="monitor address connect" closeModal={closeModal}>
        <Box pv={16}>
          <Text
            message={messages.title}
            color="default"
            align="center"
            size={24}
          />
          <Box className={s.content} mt={32} center>
            <Tabs onChange={() => onSetCameraError('')}>
              <Tabs.Content id="input" title={messages.tabs.address}>
                <Input
                  field={field}
                  size={50}
                  placeholder={messages.inputPlaceholder}
                  leftNode={(
                    <Box pv={8} pl={16}>
                      <Icon
                        name="fa-brands fa-ethereum"
                        color="dark-gray"
                        size={16}
                      />
                    </Box>
                  )}
                />
                <Box mt={16} alignCenter>
                  <Button
                    message={messages.buttonTitle}
                    // ATTN don't send onClick event to onSetAddress method
                    onClick={() => onSetAddress()}
                  />
                </Box>
              </Tabs.Content>
              <Tabs.Content id="qr" title={messages.tabs.qr}>
                {cameraError ? (
                  <Text
                    message={cameraError}
                    color="danger"
                    align="center"
                    size={16}
                  />
                ) : (
                  <QrReader
                    onScan={onSetAddress}
                    onError={() => onSetCameraError(messages.cameraError)}
                  />
                )}
              </Tabs.Content>
            </Tabs>
          </Box>
          <Box mt={32} noWrapper>
            <Text
              message={messages.note}
              color="dark-gray"
              align="center"
              size={16}
            />
          </Box>
        </Box>
      </Modal>
    )
  })
)
