import React from 'react'
import Image from 'next/image'
import { useConfig } from 'hooks'
import { constants } from 'helpers'

import { Tooltip } from 'components/feedback'

import ListItem from '../components/ListItem/ListItem'

import messages from './messages'
import tallyIcon from 'public/static/images/logos/tally-logo.png'


const walletName = constants.walletNames.tally

const TallyLink: React.FC = () => {
  const { connectors, activate } = useConfig()

  // @ts-ignore
  const isTally = typeof window !== 'undefined' && window?.ethereum?.isTally
  const isMetaMask = typeof window !== 'undefined' && window?.ethereum?.isMetaMask
  const hasInjectedProvider = Boolean(typeof window !== 'undefined' && (window?.web3 || window?.ethereum))

  const title = connectors[walletName].name

  const handleClick = () => {
    if (hasInjectedProvider && isTally) {
      activate(walletName)

      return
    }

    window.open('https://tally.cash/community-edition', '_blank')
  }

  const imageNode = (
    <Image src={tallyIcon} width={25} height={25} alt="Tally" />
  )

  if (isMetaMask) {
    return (
      <Tooltip content={messages.metaMaskError}>
        <ListItem
          title={title}
          leftNode={imageNode}
          disabled
          onClick={handleClick}
        />
      </Tooltip>
    )
  }

  return (
    <ListItem
      title={title}
      leftNode={imageNode}
      onClick={handleClick}
    />
  )
}


export default React.memo(TallyLink)
