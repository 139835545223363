/* eslint-disable max-len */
import { WETH9 } from '@uniswap/sdk-core'
import constants from 'helpers/constants'


const chainId = 1
const isTheGraph = process.env.NEXT_PUBLIC_HARBOUR_MAINNET_RUN_THEGRAPH === 'true'

// ATTN Addresses must be in check sum format!

export default {
  api: {
    rest: IS_PROD
      ? 'https://api.stakewise.io'
      : 'https://stage-api.stakewise.io',
    mainSubgraph: isTheGraph
      ? 'https://api.thegraph.com/subgraphs/name/stakewise/stakewise-harbour-mainnet'
      : 'https://graph.stakewise.io/subgraphs/name/stakewise/stakewise-harbour-mainnet',
    uniswapSubgraph: '',
  },
  network: {
    chainId,
    isMain: false,
    id: 'harbour_mainnet',
    name: 'Harbour',
    hexadecimalChainId: '0x1',
    blockExplorerUrls: 'https://etherscan.io',
    url: process.env.NEXT_PUBLIC_MAINNET_NETWORK_URL || '',
    nativeCurrency: {
      symbol: constants.tokens.eth,
      name: 'Ethereum',
      decimals: 18,
    },
  },
  pages: {
    etherscan: 'https://etherscan.io/tx',
    uniWethToStakedEthPoolNewPosition: '',
    uniSwiseToStakedEthPoolNewPosition: '',
    uniRewardEthToStakedEthPoolNewPosition: '',
    uniEthToSwisePoolNewPosition: '',
  },
  settings: {
    minimalAPR: 2.5,
    maintainerFee: 800,
    rewardUniswapFee: 0,
  },
  addresses: {
    tokens: {
      default: {
        swise: '0x48C3399719B582dD63eB5AADf12A40B4C3f52FA2',
        staked: '0x65077fA7Df8e38e135bd4052ac243F603729892d',
        reward: '0xCBE26dbC91B05C160050167107154780F36CeAAB',
        deposit: WETH9[chainId].address,
        alternativeDeposit: '',
      },
      special: {
        dai: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
        steth: '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84',
      },
    },
    base: {
      pool: '0xeA6b7151b138c274eD8d4D61328352545eF2D4b7',
      oracles: '0x16c0020fC507C675eA8A3A817416adA3D95c661b',
      multicall: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
      merkleDrop: '',
      whiteListManager: '0x57a9cbED053f37EB67d6f5932b1F2f9Afbe347F3',
      merkleDistributor: '0x07E8291591eaC73Dd93b079e3E68e171094bA9e1',
      vestingEscrowFactory: '0x7B910cc3D4B42FEFF056218bD56d7700E4ea7dD5',
      soloPaymentsRecipient: '',
    },
    pools: {
      uniswap: {
        stakedTokenNativeToken: '',
        stakedTokenRewardToken: '',
        stakedTokenSwiseToken: '',
        ethSwiseToken: '',
      },
      curve: {
        stakedTokenRewardToken: '',
        gaugeStakedTokenRewardToken: '',
        stakedTokenDepositToken: '',
        gaugeStakedTokenDepositToken: '',
        stakedTokenLidoStakedToken: '',
        gaugeStakedTokenLidoStakedToken: '',
      },
    },
    helpers: {
      uniswap: {
        quoter:  '',
        router:  '',
        positionManager: '',
      },
    },
  },
  tokens: {
    nativeToken: constants.tokens.eth,
    depositToken: constants.tokens.eth,
    rewardToken: constants.tokens['reth-h'],
    stakedToken: constants.tokens['seth-h'],
    alternativeDepositToken: constants.tokens.eth,
  },
} as const
