import EventAggregator from 'event-aggregator'


const events = new EventAggregator()

let notifications: Notifications.Notification[] = []
let notificationId = 1

const subscribe: Notifications.Subscribe = (event, handler) => {
  events.subscribe(event, handler)
}

const unsubscribe: Notifications.Unsubscribe = (event, handler) => {
  events.unsubscribe(event, handler)
}

const openNotification: Notifications.OpenNotification = (props) => {
  const notification = { id: ++notificationId, props }
  notifications = [ ...notifications, notification ]

  events.dispatch('open', notification, notifications)
}

const closeNotification: Notifications.CloseNotification = (id) => {
  const notification = notifications.find((notification) => notification.id === id)

  if (notification) {
    notifications = notifications.filter((notification) => notification.id !== id)

    events.dispatch('close', notification, notifications)
  }
}

const closeAllNotifications = (): void => {
  notifications.forEach((notification) => {
    closeNotification(notification.id)
  })
}


export {
  subscribe,
  unsubscribe,
  openNotification,
  closeNotification,
  closeAllNotifications,
}
