export default {
  name: {
    en: 'Monitor Wallet',
    ru: 'Посмотреть Кошелёк',
    fr: 'Surveillez le portefeuille',
    es: 'Monitor Wallet',
    pt: 'Monitorar carteira',
    de: 'Wallet Adresse beobachten',
    zh: 'Monitor 錢包',
  },
  activationMessage: {
    en: 'Scan QR code or enter your wallet address',
    ru: 'Отсканируйте QR-код или введите адрес Вашего кошелька',
    fr: 'Scannez le QR code ou entrez l\'adresse de votre portefeuille',
    es: 'Escanee el código QR o introduzca su dirección de cartera',
    pt: 'Digitalize o código QR ou insira o endereço da sua carteira',
    de: 'QR Code scannen oder Wallet Adresse eingeben',
    zh: '掃描 QR Code 或輸入您的錢包地址',
  },
}
