import axios from 'axios'
import { constants } from 'helpers'
import localStorage from 'local-storage'
import { ExternalProvider } from '@ethersproject/providers'

import AbstractConnector from './AbstractConnector'


type Props = { urls: Record<number, string>, defaultChainId: number }
type Callback = (error: any, response: any) => void
type RequestParams = { id?: string, method: string, params?: any[] }

class ReadOnlyConnector extends AbstractConnector implements ExternalProvider {

  url: string
  urls: Record<number, string>
  chainId: number
  account?: string
  private isLogsEnabled: boolean

  constructor({ urls, defaultChainId }: Props) {
    super()

    this.url = urls[defaultChainId]
    this.urls = urls
    this.chainId = defaultChainId
    this.isLogsEnabled = localStorage.getItem(constants.queryNames.logReadOnlyConnector) || false
  }

  async changeChainId(chainId: number) {
    this.url = this.urls[chainId]
    this.chainId = chainId
  }

  async activate() {
    return {
      chainId: this.chainId,
      account: this.account,
    }
  }

  async deactivate() {
    this.account = undefined
  }

  async getProvider() {
    return this
  }

  async getChainId() {
    return this.chainId
  }

  async getAccount() {
    return this.account as string
  }

  setAccount(account?: string) {
    this.account = account
  }

  request({ method, params }: RequestParams) {
    return axios(this.url, {
      method: 'POST',
      data: JSON.stringify({
        jsonrpc: '2.0',
        id: 1,
        method: method,
        params: params,
      }),
    })
      .then((response) => {
        if (this.isLogsEnabled) {
          console.log({
            request: { method, params },
            response: response.data?.result,
          })
        }

        return response.data?.result
      })
  }

  async sendAsync(requestParams: RequestParams, callback: Callback) {
    return this.request(requestParams)
      .then((result) => (
        callback(null, {
          id: requestParams.id,
          jsonrpc: "2.0",
          result,
        })
      ))
      .catch((error) => callback(error, null))
  }
}


export default ReadOnlyConnector
