import addNumberSeparator from './addNumberSeparator'


const formatFiatValue = (value: number): string => {
  const [ integer, remainder ] = String(value).split('.')

  const formattedInteger = addNumberSeparator(integer)

  if (!remainder) {
    return `${formattedInteger}.00`
  }
  else if (remainder.length === 1) {
    return `${formattedInteger}.${remainder}0`
  }
  else {
    return `${formattedInteger}.${remainder.slice(0, 2)}`
  }
}


export default formatFiatValue
