/* eslint-disable max-len */
import { WETH9 } from '@uniswap/sdk-core'
import constants from 'helpers/constants'


const chainId = 5

// ATTN Addresses must be in check sum format!

export default {
  api: {
    rest: 'https://stage-api.stakewise.io',
    mainSubgraph: 'https://api.thegraph.com/subgraphs/name/stakewise/stakewise-goerli',
    uniswapSubgraph: 'https://api.thegraph.com/subgraphs/name/stakewise/uniswap-v3-goerli',
  },
  network: {
    chainId,
    id: 'goerli',
    isMain: true,
    name: 'Goerli Testnet',
    hexadecimalChainId: '0x5',
    blockExplorerUrls: 'https://goerli.etherscan.io',
    url: 'https://goerli.infura.io/v3/84842078b09946638c03157f83405213',
    nativeCurrency: {
      symbol: constants.tokens.eth,
      name: 'Ethereum',
      decimals: 18,
    },
  },
  pages: {
    etherscan: 'https://goerli.etherscan.io/tx',
    uniWethToStakedEthPoolNewPosition: 'https://app.uniswap.org/#/add/ETH/0x221D9812823DBAb0F1fB40b0D294D9875980Ac19/3000',
    uniSwiseToStakedEthPoolNewPosition: 'https://app.uniswap.org/#/add/0x221D9812823DBAb0F1fB40b0D294D9875980Ac19/0x0e2497aACec2755d831E4AFDEA25B4ef1B823855/3000',
    uniRewardEthToStakedEthPoolNewPosition: 'https://app.uniswap.org/#/add/0x221D9812823DBAb0F1fB40b0D294D9875980Ac19/0x826f88d423440c305D9096cC1581Ae751eFCAfB0/3000',
    uniEthToSwisePoolNewPosition: '',
  },
  settings: {
    minimalAPR: 3.5,
    maintainerFee: 1000,
    rewardUniswapFee: 3000,
  },
  addresses: {
    tokens: {
      default: {
        swise: '0x0e2497aACec2755d831E4AFDEA25B4ef1B823855',
        staked: '0x221D9812823DBAb0F1fB40b0D294D9875980Ac19',
        reward: '0x826f88d423440c305D9096cC1581Ae751eFCAfB0',
        deposit: WETH9[chainId].address,
        alternativeDeposit: '',
      },
      special: {
        dai: '0x111122AB2CB36Ebb5068a7de2a891AB98b0eBA2D',
        steth: '',
      },
    },
    base: {
      pool: '0x8c1EfEcFb5c4F1099AB0460b5659342943764Df7',
      oracles: '0x06b0C9476315634dCc59AA3F3f7d5Df6feCbAa90',
      multicall: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
      merkleDrop: '0xFc3513E92799F0169e5f14F354d0097E4b790498',
      whiteListManager: '',
      merkleDistributor: '0x6Ef0172b79131C66c7012Db3545D637B116feb12',
      vestingEscrowFactory: '0xbA91cdD484893c1f8F75DB55733ccaDcd0fE5f59',
      soloPaymentsRecipient: '0x1867c96601bc5fE24F685d112314B8F3Fe228D5A',
    },
    pools: {
      uniswap: {
        stakedTokenNativeToken: '0x6e43b882B7Fc455a56577Ac523eC95E5188c6E51',
        stakedTokenRewardToken: '0x384057FAd2B21C1051224b441367c0c6AdBa685C',
        stakedTokenSwiseToken: '0x55e7453CD669812BE8e4267e9af95d40d78B7107',
        ethSwiseToken: '',
      },
      curve: {
        stakedTokenRewardToken: '',
        gaugeStakedTokenRewardToken: '',
        stakedTokenDepositToken: '',
        gaugeStakedTokenDepositToken: '',
        stakedTokenLidoStakedToken: '',
        gaugeStakedTokenLidoStakedToken: '',
      },
    },
    helpers: {
      uniswap: {
        quoter:  '0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6',
        router:  '0xE592427A0AEce92De3Edee1F18E0157C05861564',
        positionManager: '0xC36442b4a4522E871399CD717aBDD847Ab11FE88',
      },
    },
  },
  tokens: {
    nativeToken: constants.tokens.eth,
    depositToken: constants.tokens.eth,
    rewardToken: constants.tokens.reth2,
    stakedToken: constants.tokens.seth2,
    alternativeDepositToken: constants.tokens.eth,
  },
} as const
