import React, { useMemo } from 'react'

import IntlContext from './IntlContext'
import createIntl from './createIntl'
import useLocale from './useLocale'


const IntlProvider: React.FC = (props) => {
  const { children } = props

  const { locale, setLocale } = useLocale()

  const intl = useMemo(() => createIntl({ locale, setLocale }), [ locale, setLocale ])

  return (
    <IntlContext.Provider value={intl}>
      {children}
    </IntlContext.Provider>
  )
}


export default IntlProvider
