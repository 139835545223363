import { useEffect } from 'react'
import { constants } from 'helpers'
import localStorage from 'local-storage'


type UseStorageUpdateProps = Pick<
  ConfigProvider.State,
  'config' | 'address' | 'activeWallet' | 'autoConnectChecked'
>

const useStorageUpdate = ({ config, address, activeWallet, autoConnectChecked }: UseStorageUpdateProps) => {
  // Update localStorage network
  useEffect(() => {
    if (autoConnectChecked) {
      localStorage.setItem<LocalStorageData.SavedNetwork>(constants.localStorageNames.savedNetwork, {
        id: config.network.id,
        chainId: config.network.chainId as ChainIds,
      })
    }
  }, [ config, autoConnectChecked ])

  // Update localStorage wallet name
  useEffect(() => {
    if (autoConnectChecked) {
      if (activeWallet) {
        const isCustomBrowsers = [
          constants.walletNames.walletConnect,
          constants.walletNames.dAppBrowser,
          constants.walletNames.ledgerLive,
          constants.walletNames.gnosis,
          // @ts-ignore
        ].includes(activeWallet)

        if (!isCustomBrowsers) {
          localStorage.setItem(constants.localStorageNames.walletName, activeWallet)
        }
      }
      else {
        localStorage.removeItem(constants.localStorageNames.walletName)
      }
    }
  }, [ activeWallet, autoConnectChecked ])

  // Update localStorage readonly address
  useEffect(() => {
    const isMonitorAddress = activeWallet === constants.walletNames.monitorAddress

    if (isMonitorAddress && address) {
      localStorage.setItem(constants.localStorageNames.savedReadOnlyAddress, address)
    }
    else {
      localStorage.removeItem(constants.localStorageNames.savedReadOnlyAddress)
    }
  }, [ address, activeWallet ])
}


export default useStorageUpdate
