import React from 'react'
import { useDevice } from 'device'

import { Text } from 'components/ui'
import { Box, Flex } from 'components/layout'

import messages from './messages'
import s from './Header.module.scss'


const titles = [
  messages.sender,
  messages.recipient,
  messages.amount,
]

const Header: React.FC = () => {
  const { isMobile } = useDevice()

  return (
    <Flex>
      {
        titles.map((message, index) => (
          <Box key={index} className={s.box} p={12} alignCenter>
            <Text
              message={message}
              color="dark-gray"
              size={14}
              uppercase
            />
          </Box>
        ))
      }
      <Box className={s.hash} alignCenter>
        {
          !isMobile && (
            <Text
              message={messages.txHash}
              color="dark-gray"
              size={14}
              uppercase
            />
          )
        }
      </Box>
    </Flex>
  )
}


export default React.memo(Header)
