export default {
  errorNotification: {
    en: 'Failed to claim SWISE. Please try again.',
    ru: 'Не удалось получить SWISE. Пожалуйста, попробуйте еще раз.',
    fr: 'Echec de la réclamation. Merci de réessayer',
    es: 'Ha habido un error reclamando SWISE. Intente de nuevo.',
    pt: 'Falha ao reclamar SWISE. Por favor, tente novamente',
    de: 'Abholen von SWISE fehlgeschlagen. Bitte nochmal probieren',
    zh: '索取 SWISE 失敗。請重新嘗試一次。',
  },
  successNotification: {
    en: 'Congratulations on receiving your SWISE tokens!',
    ru: 'Поздравляем с получением токенов SWISE!',
    fr: 'Félicitations, vous avez reçu vos jetons SWISE!',
    es: 'Felicidades por reclamar sus tokens SWISE!',
    pt: 'Parabéns por receber seus tokens SWISE!',
    de: 'Glückwunsch, Du hast Deine SWISE Tokens erhalten!',
    zh: '恭喜您獲得 SWISE 代幣!',
  },
}
