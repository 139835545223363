import React from 'react'
import cx from 'classnames'
import Image from 'next/image'

import { Box, Flex } from 'components/layout'
import { Dropdown, Icon, Text } from 'components/ui'

import s from './Select.module.scss'


type Option = {
  title: string
  value: string
  icon?: string
  image?: StaticImage
}

type SelectProps = {
  label?: string
  iconSize: number
  options: Option[]
  className?: string
  dataTestId?: string
  selectedOption: Option
  onChange: (value: string) => void
}

const Select: React.FC<SelectProps> = (props) => {
  const { className, selectedOption, iconSize, options, label, dataTestId, onChange } = props

  const { title, image, icon } = selectedOption

  return (
    <div className={cx(s.wrapper, className)} aria-label={label}>
      <Dropdown
        className={s.wFull}
        buttonClassName={cx(s.block, s.wFull)}
        direction="top"
        dataTestId={dataTestId}
        controlNode={(isOpened) => (
          <Box className={s.container} relative noWrapper>
            <Flex align="center" justify="start">
              {
                Boolean(image) && (
                  <Image
                    className={s.image}
                    height={iconSize}
                    width={iconSize}
                    src={image as StaticImage}
                    alt=""
                  />
                )
              }
              {
                Boolean(icon) && (
                  <Flex className={s.selectedIconContainet} align="center" justify="center">
                    <Icon
                      className={s.selectedIcon}
                      name={icon as string}
                      size={16}
                    />
                  </Flex>
                )
              }
              <Box className={s.select} ph={8} noWrapper>
                <Text
                  message={title}
                  size={12}
                  align="left"
                />
              </Box>
              <div className={cx(s.rightIcon, isOpened ? 'fa-rotate-180' : null)}>
                <Icon name="fa-light fa-chevron-down" size={16} />
              </div>
            </Flex>
          </Box>
        )}
      >
        {
          options.map(({ title, value, image, icon }, index) => (
            <button
              key={index}
              className={cx(s.option, s.wFull)}
              data-testid={`${dataTestId}: ${value}`}
              onClick={() => onChange(value)}
            >
              <Box p={8} noWrapper>
                <Flex align="center" justify="start">
                  {
                    Boolean(image) && (
                      <Image
                        className={s.image}
                        height={iconSize}
                        width={iconSize}
                        src={image as StaticImage}
                        alt=""
                      />
                    )
                  }
                  {
                    Boolean(icon) && (
                      <Flex className={s.iconContainet} align="center" justify="center">
                        <Icon
                          name={icon as string}
                          color="white"
                          size={16}
                        />
                      </Flex>
                    )
                  }
                  <Box pl={8} noWrapper>
                    <Text
                      message={title}
                      color="black"
                      align="left"
                      size={12}
                    />
                  </Box>
                </Flex>
              </Box>
            </button>
          ))
        }
      </Dropdown>
    </div>
  )
}


export default React.memo(Select)
