import React from 'react'
import cx from 'classnames'
import { analytics } from 'helpers'

import Text from 'components/ui/Text/Text'
import type { TextProps } from 'components/ui'

import s from './Button.module.scss'


export const sizes = [ 'sm', 'md' ] as const
export const colors = [ 'blue', 'orange', 'purple' ] as const

type Size = typeof sizes[number]
type Color = typeof colors[number]

export type ButtonProps = {
  size?: Size
  color?: Color
  loading?: boolean
  className?: string
  disabled?: boolean
  uppercase?: boolean
  fullWidth?: boolean
  dataTestId?: string
  type?: 'submit' | 'button'
  fullWidthOnMobile?: boolean
  analyticsEventName?: string
  message: TextProps['message']
  analyticsEventParams?: { [key: string]: string }
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const Button: React.FC<ButtonProps> = (props) => {
  const {
    className, size = 'md', color = 'blue', disabled, loading, dataTestId,
    message, fullWidth, fullWidthOnMobile, uppercase, type = 'button',
    analyticsEventName, analyticsEventParams, onClick,
  } = props

  const buttonClassName = cx(s.button, className, 'button', {
    [s[`size-${size}`]]: true,
    [s[`color-${color}`]]: color,
    [s.loading]: loading,
    [s.uppercase]: uppercase,
    [s.fullWidth]: fullWidth,
    [s.disabled]: disabled || loading,
    [s.fullWidthOnMobile]: fullWidthOnMobile,
  })

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (disabled || loading) {
      return
    }

    if (typeof onClick === 'function') {
      onClick(event)
    }

    if (analyticsEventName) {
      analytics.statistics.sendEvent(analyticsEventName, analyticsEventParams)
    }
  }

  return (
    <button
      className={buttonClassName}
      data-testid={dataTestId}
      type={type}
      onClick={handleClick}
    >
      <Text
        className={s.text}
        message={message}
        color="white"
        tag="span"
      />
    </button>
  )
}


export default React.memo(Button)
