import { constants } from 'helpers'

import supportedNetworkIds from './supportedNetworkIds'


const getQueryNetworkId = () => {
  if (typeof window !== 'undefined') {
    const queryNetworkId = new URL(window.location.href).searchParams.get(constants.queryNames.networkId)

    if (queryNetworkId && supportedNetworkIds.includes(queryNetworkId as NetworkIds)) {
      return queryNetworkId as NetworkIds
    }
  }

  return null
}


export default getQueryNetworkId
