import harbourMainnet from '../data/harbourMainnet'
import harbourGoerli from '../data/harbourGoerli'
import mainnet from '../data/mainnet'
import goerli from '../data/goerli'
import gnosis from '../data/gnosis'


const supportedNetworks = {
  harbourMainnet: harbourMainnet.network,
  harbourGoerli: harbourGoerli.network,
  mainnet: mainnet.network,
  goerli: goerli.network,
  gnosis: gnosis.network,
} as const


export default supportedNetworks
