import { constants } from 'helpers'
import { SafeAppConnector } from 'custom-connectors'
import supportedChainIds from 'config/helpers/supportedChainIds'

import messages from './messages'


const getSpecialErrors: InitConnectors.Data['getSpecialErrors'] = (error) => {
  const errorMessage = (error as Error)?.message

  if (/unsupported chain id/i.test(errorMessage)) {
    return messages.errors.unsupportedNetwork
  }

  return null
}

const initGnosis: InitConnectors.CreateConnectorHandler<WalletIds> = () => {
  const connector = new SafeAppConnector({
    supportedChainIds,
  })

  return {
    [constants.walletNames.gnosis]: {
      connector,
      name: messages.name,
      activationMessage: messages.activationMessage,
      getSpecialErrors,
    },
  }
}


export default initGnosis
