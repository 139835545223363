import { useRef } from 'react'
import { useIntl } from 'intl'


const useIntlRef = () => {
  const intl = useIntl()

  const intlRef = useRef(intl)
  intlRef.current = intl

  return intlRef
}


export default useIntlRef
