export default {
  selected: {
    en: 'Selected',
    ru: 'Выбранo',
    fr: 'Sélectionné',
    es: 'Seleccionado',
    pt: 'Selecionado',
    de: 'Ausgewählt',
    zh: '已選取',
  },
  stake: {
    en: 'Stake',
    ru: 'Застейкать',
    fr: 'Stakez',
    es: 'Hacer stake',
    pt: 'Stake',
    de: 'Stake',
    zh: '質押',
  },
  reinvest: {
    en: 'Reinvest',
    ru: 'Реинвестировать',
    fr: 'Réinvestissez',
    es: 'Reinvertir',
    pt: 'Reinvestir',
    de: 'Reinvestieren',
    zh: '再投入',
  },
  withdraw: {
    // TODO - need a translation
    en: 'Withdraw',
    ru: 'Вывести',
  },
  notifications: {
    sent: {
      // TODO - need a translation
      en: 'The transaction has been sent. Please wait for it to confirm.',
    },
    waitingClaim: {
      en: 'Waiting for claim confirmation',
      ru: 'Ожидаем подтверждения получения',
      fr: 'Attente de la confirmation de réclamation',
      es: 'Esperando que se confirme la petición',
      pt: 'Aguardando confirmação da reclamação',
      de: 'Warten auf die Claim Bestätigung',
      zh: '等待認領確認',
    },
    failed: {
      en: 'Failed to send the transaction. Please try again.',
      ru: 'Не удалось отправить транзакцию. Пожалуйста, попробуйте снова.',
      fr: 'Impossible d\'envoyer la transaction. Merci de réessayer.',
      es: 'La envío de la transacción ha fallado. Por favor intente de nuevo.',
      pt: 'Falha ao enviar a transação. Por favor, tente novamente.',
      de: 'Das Senden der Transaktion ist fehlgeschlagen. Bitte versuche es erneut.',
      zh: '交易發送失敗 請重新嘗試。',
    },
    success: {
      en: 'Transaction has been confirmed',
      ru: 'Транзакция подтверждена',
      fr: 'La transaction a été confirmée',
      es: 'La transacción se ha confirmado',
      pt: 'A transação foi confirmada',
      de: 'Transaktion wurde bestätigt',
      zh: '交易已被確認',
    },
  },
  buttons: {
    confirm: {
      en: 'Confirm',
      ru: 'Подтвердить',
      fr: 'Confirmer',
      es: 'Confirmar',
      pt: 'Confirmar',
      de: 'Bestätigen',
      zh: '確認',
    },
    approve: {
      en: 'Approve {token}',
      ru: 'Одобрить {token}',
      fr: 'Approuvez {token}',
      es: 'Aprobar {token}',
      pt: 'Aprovar {token}',
      de: 'Autorisiere {token}',
      zh: '允許 {token}',
    },
  },
  disabledMessages: {
    notConnectedWallet: {
      en: 'Wallet must be connected',
      ru: 'Необходимо подключить кошелёк',
      fr: 'Le portefeuille doit être connecté',
      es: 'La cartera debe estar conectada',
      pt: 'A carteira deve estar conectada',
      de: 'Wallet muss verbunden sein',
      zh: '錢包必須是連接狀態',
    },
    readOnly: {
      en: 'Read only mode',
      ru: 'Режим просмотра',
      fr: 'Mode lecture seule',
      es: 'Modo de solo lectura',
      pt: 'Modo somente de leitura',
      de: 'Nur-Lese-Modus',
      zh: '唯讀模式',
    },
    notWhitelisted: {
      en: 'Not whitelisted address',
      ru: 'Адресс не внесен в белый список',
      fr: 'Adresse absente de la liste blanche',
      es: 'La dirección no está en el whitelist',
      pt: 'Endereço não permitido',
      de: 'Keine Whitelist Adresse',
      zh: '非白名單地址',
    },
  },
  // TODO - need a translation
  settings: {
    en: 'Settings',
    ru: 'Настройки',
    fr: 'Settings',
    es: 'Settings',
    pt: 'Settings',
    de: 'Settings',
    zh: 'Settings',
  },
}
