import supportedNetworks from 'config/helpers/supportedNetworks'


const getNetworkType = (config: Config) => ({
  isGnosis: config.network.id === supportedNetworks.gnosis.id,
  isGoerli: config.network.id === supportedNetworks.goerli.id,
  isMainnet: config.network.id === supportedNetworks.mainnet.id,
  isHarbourGoerli: config.network.id === supportedNetworks.harbourGoerli.id,
  isHarbourMainnet: config.network.id === supportedNetworks.harbourMainnet.id,
  isHarbour: config.network.id === supportedNetworks.harbourGoerli.id || config.network.id === supportedNetworks.harbourMainnet.id,
})


export default getNetworkType
