export default {
  noItems: {
    en: 'No transactions',
    ru: 'Нет транзакций',
    fr: 'Pas de transaction',
    es: 'No hay transacciones',
    pt: 'Nenhuma transação',
    de: 'Keine Transaktionen',
    zh: '沒有交易記錄',
  },
}
