import { supportedNetworks } from 'config'

import getGnosisData from './getGnosisData'
import getGoerliData from './getGoerliData'
import getMainnetData from './getMainnetData'
import getHarbourGoerliData from './getHarbourGoerliData'
import getHarbourMainnetData from './getHarbourMainnetData'


const operatorsOfNetwork = {
  [supportedNetworks.harbourMainnet.id]: getHarbourMainnetData(),
  [supportedNetworks.harbourGoerli.id]: getHarbourGoerliData(),
  [supportedNetworks.mainnet.id]: getMainnetData(),
  [supportedNetworks.goerli.id]: getGoerliData(),
  [supportedNetworks.gnosis.id]: getGnosisData(),
} as const


export default operatorsOfNetwork
