import axios from 'axios'
import { print } from 'graphql'
import type { AxiosPromise } from 'axios'
import type { TypedDocumentNode } from '@graphql-typed-document-node/core'


axios.defaults.headers['Accept'] = 'application/json'
axios.defaults.headers['Content-Type'] = 'application/json'

// --- GET ---
type GetInput = {
  url: string
  params?: { [key: string]: string | number }
}

const get = <T>(values: GetInput): AxiosPromise<T> => {
  const { url, params } = values

  return axios.get(url, {
    params,
  })
}

// --- POST ---
type PostInput = {
  url: string
  data?: any
  params?: any
}

const submit = (method: 'post' | 'put') => async <T>(values: PostInput): Promise<T> => {
  const { url, data, params } = values

  try {
    const response = await axios[method]<T>(url, data, { params })

    return response.data
  }
  catch (error) {
    return Promise.reject(error)
  }
}

const post = submit('post')

const put = submit('put')

// --- GQL ---
const gql = async <
  TData = any, TVariables = Record<string, any>
>(url: string, query: TypedDocumentNode<TData, TVariables>, variables?: TVariables): Promise<TData> => {
  const result = await axios.post(url, {
    query: print(query),
    variables,
  })

  const errors = result?.data?.errors

  if (errors) {
    return Promise.reject(errors)
  }

  return result.data.data as TData
}


export default {
  gql,
  get,
  put,
  post,
}
