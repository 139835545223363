export default {
  monitorAddress: 'monitorAddress',
  walletConnect: 'walletConnect',
  dAppBrowser: 'dAppBrowser',
  ledgerLive: 'ledgerLive',
  coinbase: 'coinbase',
  metaMask: 'metaMask',
  gnosis: 'gnosis',
  portis: 'portis',
  ledger: 'ledger',
  tally: 'tally',
  zenGo: 'zenGo',
} as const
