import React, { useMemo, useState } from 'react'
import { openNotification } from 'notifications'
import { constants } from 'helpers'
import { useConfig } from 'hooks'
import cx from 'classnames'

import { Box, Flex } from 'components/layout'
import { Text, RoundIcon } from 'components/ui'

import messages from './messages'
import s from './Token.module.scss'


type TokenProps = {
  className?: string
  type: 'staked' | 'reward' | 'swise'
}

const Token: React.FC<TokenProps> = (props) => {
  const { className, type } = props

  const { config } = useConfig()
  const [ isLoading, setLoading ] = useState(false)

  const { symbol, address } = useMemo(() => {
    const params = {
      staked: {
        symbol: config.tokens.stakedToken,
        address: config.addresses.tokens.default.staked,
      },
      reward: {
        symbol: config.tokens.rewardToken,
        address: config.addresses.tokens.default.reward,
      },
      swise: {
        symbol: constants.tokens.swise,
        address: config.addresses.tokens.default.swise,
      },
    }

    return params[type]
  }, [ config, type ])

  const handleClick = () => {
    if (address && typeof window?.ethereum?.request === 'function') {
      openNotification({
        type: 'info',
        text: messages.notifications.waiting,
      })

      setLoading(true)

      // TODO - This method not working correctly. I created issue and wait result
      // https://github.com/MetaMask/metamask-extension/issues/12416
      window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          // @ts-ignore
          type: 'ERC20',
          options: {
            symbol,
            address,
            decimals: 18,
            image: `${config.pages.website}/static/images/currencies/${symbol.toLowerCase()}.png`,
          },
        },
      })
        .then((result) => {
          const hasResult = Boolean(result)
          const notificationType = hasResult ? 'success' : 'error'
          const notificationText = hasResult
            ? { ...messages.notifications.success, values: { symbol } }
            : { ...messages.notifications.success, values: { symbol } }

            openNotification({
              type: notificationType,
              text: notificationText,
            })
        })
        .catch(() => {
          openNotification({
            type: 'error',
            text: messages.notifications.error.communicating,
          })
        })
        .finally(() => setLoading(false))
    }
  }

  const containerClassName = cx(s.container, className, {
    [s.loading]: isLoading,
  })

  return (
    <Flex align="start" justify="center">
      <Box
        className={containerClassName}
        pv={4}
        pl={4}
        pr={32}
        relative
        noWrapper
        overflow="hidden"
      >
        <Flex justify="center" dataTestId={`add tokens modal button: ${symbol}`} onClick={handleClick}>
            <RoundIcon className={s.icon} token={symbol} size={52} />
            <Box ml={16}>
              <Text
                message={{ ...messages.text, values: { symbol } }}
                className={s.text}
                color="default"
                size={16}
                html
              />
            </Box>
        </Flex>
      </Box>
    </Flex>
  )
}


export default React.memo(Token)
