import request from 'request-module'
import localStorage from 'local-storage'
import { analytics, constants } from 'helpers'
import { BigNumber } from '@ethersproject/bignumber'


type ApiResponse = {
  apr: string
  price: string
}

const fetchSwisePrice = async (config: Config): Promise<InitialData.System.RestData['swiseUsdRateBN']> => {
  const localStorageName = `${constants.localStorageNames.mocks.swisePrice}-${config.network.id}`

  try {
    const response = await request.get<ApiResponse>({
      url: `${config.api.rest}/swise-stats/`,
      params: {
        network: config.network.id,
      },
    })

    const { price = 0 } = response?.data || {}

    const result = (Number(price) * 100_000_000).toFixed(0)

    if (Number(price) > 0) {
      localStorage.setItem(localStorageName, result)
    }

    return BigNumber.from(result)
  }
  catch (error) {
    analytics.sentry.exception('REST API: swise-stats', error as Error)

    try {
      const savedData = localStorage.getItem<string>(localStorageName)

      if (savedData) {
        return BigNumber.from(savedData)
      }
    }
    catch {
      localStorage.removeItem(localStorageName)
    }

    return Promise.reject(error)
  }
}

export default fetchSwisePrice
