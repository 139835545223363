import React from 'react'
import cx from 'classnames'

import Box from 'components/layout/Box/Box'

import s from './SmallLoader.module.scss'


type SmallLoaderProps = {
  className?: string
}

const SmallLoader: React.FC<SmallLoaderProps> = ({ className }) => (
  <Box className={cx(s.loader, className)} relative />
)


export default SmallLoader
