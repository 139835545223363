import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'

import BottomLoader from 'compositions/BottomLoader/BottomLoader'
import Notifications from 'compositions/Notifications/Notifications'

import Header from './Header/Header'
import Banner from './Banner/Banner'
import Footer from './Footer/Footer'
import ErrorBoundary from './ErrorBoundary/ErrorBoundary'

import {
  useAnalytics,
  useQueryParams,
  useInitialRequests,
  useAutoFetchBalances,
} from './util'

import s from './AppLayout.module.scss'


const AppLayout: React.FC = ({ children }) => {
  useAnalytics()
  useQueryParams()
  useInitialRequests()
  useAutoFetchBalances()

  const { isCalculated } = useDevice()

  const animationClassName = cx(s.animation, {
    [s.start]: isCalculated,
  })

  const containerClassName = cx(s.container, {
    [s.show]: isCalculated,
  })

  return (
    <>
      <Banner />
      <div className={containerClassName}>
        <Header />
        <main className={s.content}>
          <div className={animationClassName}>
            <ErrorBoundary>
              {children}
            </ErrorBoundary>
          </div>
        </main>
        <Footer />
        <Notifications />
        <BottomLoader />
      </div>
    </>
  )
}


export default AppLayout
