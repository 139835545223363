import { Erc20Abi } from 'contracts'
import { parseEther } from '@ethersproject/units'


type Input = {
  amount: string
  sender: string
  recipient: string
  tokenContract: Erc20Abi
  wrongAmount?: string
}

const allowance = async (values: Input) => {
  const { amount, sender, recipient, tokenContract, wrongAmount } = values

  const allowance = await tokenContract.allowance(sender, recipient)
  const amountBN = parseEther(amount)
  const isNeedApprove = allowance.lt(amountBN)

  if (wrongAmount) {
    const isWrongAllowance = allowance.gt(parseEther(wrongAmount))

    return isNeedApprove || isWrongAllowance
  }

  return isNeedApprove
}


export default allowance
