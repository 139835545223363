export default {
  days: {
    en: 'd',
    ru: 'д',
    fr: 'j',
    es: 'd',
    pt: 'd',
    de: 'd',
    zh: '天',
  },
  hours: {
    en: 'h',
    ru: 'ч',
    fr: 'h',
    es: 'h',
    pt: 'h',
    de: 'h',
    zh: '小時',
  },
  minutes: {
    en: 'm',
    ru: 'м',
    fr: 'm',
    es: 'm',
    pt: 'm',
    de: 'm',
    zh: '分',
  },
  seconds: {
    en: 's',
    ru: 'с',
    fr: 's',
    es: 's',
    pt: 's',
    de: 's',
    zh: '秒',
  },
}
