import React from 'react'
import cx from 'classnames'
import { useIntl } from 'intl'

import messages from './messages'
import s from './ActionIcon.module.scss'


type ActionIconProps = {
  id: string
  controlsId: string
  isOpened: boolean
  dataTestId?: string
  onClick: () => void
}

const ActionIcon: React.FC<ActionIconProps> = (props) => {
  const { id, controlsId, isOpened, dataTestId, onClick } = props

  const intl = useIntl()

  const buttonClassName = cx(s.button, {
    [s.opened]: isOpened,
  })

  return (
    <button
      className={buttonClassName}
      id={id}
      type="button"
      aria-label={intl.formatMessage(messages.label)}
      aria-expanded={isOpened}
      aria-haspopup
      aria-controls={controlsId}
      data-testid={dataTestId}
      onClick={onClick}
    >
      <div className={cx(s.bar, s.bar1)} />
      <div className={cx(s.bar, s.bar2)} />
      <div className={cx(s.bar, s.bar3)} />
      <div className={cx(s.bar, s.bar4)} />
      <div className={cx(s.bar, s.bar5)} />
      <div className={cx(s.bar, s.bar6)} />
    </button>
  )
}


export default React.memo(ActionIcon)
