export default {
  amount: {
    en: 'Tokens amount',
    ru: 'Количество токенов',
    fr: 'Montant de jeton',
    es: 'Cantidad de tokens',
    pt: 'Valor dos tokens',
    de: 'Token Menge',
    zh: '代幣數量',
  },
  recipient: {
    en: 'Recipient address',
    ru: 'Адрес получателя',
    fr: 'Adresse du destinataire',
    es: 'Dirección de recepción',
    pt: 'Endereço do destinatário',
    de: 'Empfängeradresse',
    zh: '接收地址',
  },
  balance: {
    en: 'Your balance: {balance}',
    ru: 'Ваш баланс: {balance}',
    fr: 'Votre balance: {balance}',
    es: 'Su balance: {balance}',
    pt: 'Seu saldo da conta: {balance}',
    de: 'Dein Kontostand: {balance}',
    zh: '您的餘額: {balance}',
  },
}
