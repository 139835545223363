import { constants } from 'helpers'
import supportedNetworks from 'config/helpers/supportedNetworks'

import { PortisConnector } from 'custom-connectors'

import messages from './messages'


const getSpecialErrors: InitConnectors.Data['getSpecialErrors'] = (error) => {
  const errorMessage = (error as Error)?.message

  if (/unsupported chain id/i.test(errorMessage)) {
    return messages.errors.unsupportedNetwork
  }
  else if (error === 'User denied login.') {
    return messages.errors.authorize
  }

  return null
}

const initPortis: InitConnectors.CreateConnectorHandler<WalletIds> = () => {
  const connector = new PortisConnector({
    dAppId: IS_PREVIEW ? '7d49159d-30b8-4ac4-92cd-71643acffa72' : '3ae575d0-0e45-4842-9f5b-67cf79a6cec9',
    supportedChainIds: [
      supportedNetworks.mainnet.chainId, // ATTN should be first
      supportedNetworks.goerli.chainId,
      supportedNetworks.gnosis.chainId,
    ],
  })

  return {
    [constants.walletNames.portis]: {
      connector,
      name: messages.name,
      activationMessage: messages.activationMessage,
      getSpecialErrors,
    },
  }
}


export default initPortis
