type Procedure = (...args: any[]) => void
type Output<F extends Procedure> = ((this: ThisParameterType<F>, ...args: Parameters<F>) => void) & { flush(): void }

function throttle<F extends Procedure>(func: F, timeout: number): Output<F> {
  let isThrottled: boolean
  let timeoutId: NodeJS.Timeout
  let savedArgs: any
  let context: any

  function flush() {
    clearTimeout(timeoutId)
  }

  function wrapper(this: ThisParameterType<F>, ...args: Parameters<F>): void {
    if (isThrottled) {
      savedArgs = args
      context = this

      return
    }

    func.apply(this, args)
    isThrottled = true

    timeoutId = setTimeout(() => {
      isThrottled = false

      if (savedArgs) {
        wrapper.apply(context, savedArgs)
        savedArgs = null
        context = null
      }
    }, timeout)
  }

  wrapper.flush = flush

  return wrapper
}


export default throttle
