export default {
  name: {
    en: 'Portis',
    ru: 'Portis',
    fr: 'Portis',
    es: 'Portis',
    pt: 'Portis',
    de: 'Portis',
    zh: 'Portis',
  },
  activationMessage: {
    en: 'Waiting for authorization to be completed in Portis',
    ru: 'Ожидаем завершения авторизации в Portis',
    fr: 'Attente de l\'autorisation dans Portis',
    es: 'Esperando que la autorización sea efectuada en Portis',
    pt: 'Aguardando a conclusão da autorização no Portis',
    de: 'Warte auf die Autorisierung in Portis',
    zh: '等待 Portis 允許存取',
  },
  errors: {
    unsupportedNetwork: {
      en: 'Portis is connected to an unsupported network',
      ru: 'Portis подключен к не поддерживаемой сети',
      fr: 'Portis est connecté à un réseau non supporté',
      es: 'Portis está conectado a una red no soportada',
      pt: 'Portis está conectado a uma rede não suportada',
      de: 'Portis ist mit einem nicht unterstützten Netzwerk verbunden',
      zh: 'Portis 連接到一個不支援的網路',
    },
    authorize: {
      en: 'Please authorize access to your Portis account',
      ru: 'Пожалуйста, разрешите доступ к Вашему аккаунту Portis',
      fr: 'Merci d\'autoriser l\'accès à votre compte Portis',
      es: 'Favor permitir acceso a su cuenta de Portis',
      pt: 'Por favor autorize o acesso à sua conta Portis',
      de: 'Bitte autorisiere den Zugriff auf Dein Portis Konto',
      zh: '請允許存取您的 Portis 錢包',
    },
  },
}
