import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import { useConfig } from 'hooks'

import { Box, Flex } from 'components/layout'
import Network from 'compositions/Network/Network'

import Menu from './Menu/Menu'
import Title from './Title/Title'
import Connect from './Connect/Connect'
import Settings from './Settings/Settings'

import s from './Header.module.scss'


const Header: React.FC = () => {
  const { address, activeWallet, autoConnectChecked } = useConfig()
  const { isMobile, isTablet, isDesktop } = useDevice()

  const boxPh = (isMobile || isTablet) ? 16 : 32

  return (
    <header className={s.showContent}>
      <Box className={s.content} ph={boxPh} noWrapper>
        <Flex>
          <Box pv={16} noWrapper>
            <Flex justify="start">
              <Menu />
              <Box ml={isDesktop ? 64 : 32}>
                <Title />
              </Box>
            </Flex>
          </Box>
          <Flex
            align="center"
            justify="between"
            className={cx({
              [s.showContent]: autoConnectChecked,
              [s.container]: !autoConnectChecked,
            })}
          >
            {
              isDesktop && (
                <Box mr={activeWallet ? undefined : 16}>
                  <Network />
                </Box>
              )
            }
            {
              activeWallet ? (
                address && (
                  <Settings />
                )
              ) : (
                <Connect />
              )
            }
          </Flex>
        </Flex>
        {
          Boolean(autoConnectChecked && (isTablet || isMobile)) && (
            <Box mb={4} noWrapper>
              <Flex justify="center">
                <Network />
              </Flex>
            </Box>
          )
        }
      </Box>
    </header>
  )
}


export default React.memo(Header)
