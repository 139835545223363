import { useState, useRef, useEffect } from 'react'
import cacheStorage from './CacheStorage'


type Output<T> = [ T | null, <D extends T>(data: D, time?: number) => void, boolean ]

const useCacheListner = <T = any>(cacheId: string): Output<T> => {
  const lastCacheId = useRef<string>()
  const cache = cacheStorage.get(cacheId)
  const [ data, setData ] = useState<T | null>(cache.getData())

  useEffect(() => {
    const handler = () => setData(cache.getData())

    if (lastCacheId.current && lastCacheId.current !== cacheId) {
      handler()
    }

    lastCacheId.current = cacheId

    cache.addListener(handler)

    return () => cache.removeListener(handler)
  }, [ cacheId ])

  return [ data, cache.setData.bind(cache), cache.isChanged ]
}


export default useCacheListner
