import setPosition from './setPosition'
import type { Input as SetPositionInput } from './setPosition'


type Input = SetPositionInput & {
  styles: {
    readonly [key: string]: string
  }
}

const createTooltip = ({ trigger, tooltip, styles, center }: Input) => {
  let isVisible = false

  const show = () => {
    isVisible = true
    tooltip.removeAttribute('style')
    tooltip.classList.add(styles.visible)

    setPosition({ tooltip, trigger, center })

    document.addEventListener('touchstart', hide, { passive: true, capture: true })
    window.addEventListener('scroll', hide)
    window.addEventListener('blur', hide)
  }

  const hide = () => {
    isVisible = false

    // Need to block hide() flow if user moved cursor on tooltip
    setTimeout(() => {
      if (!isVisible) {
        tooltip.classList.remove(styles.visible)

        document.removeEventListener('touchstart', hide)
        window.removeEventListener('scroll', hide)
        window.removeEventListener('blur', hide)
      }
    }, 64)
  }

  tooltip.addEventListener('touchstart', show, { passive: true })
  tooltip.addEventListener('mouseenter', show)
  tooltip.addEventListener('mouseleave', hide)
  tooltip.addEventListener('blur', hide)

  trigger.addEventListener('touchstart', show, { passive: true })
  trigger.addEventListener('mouseenter', show)
  trigger.addEventListener('mouseleave', hide)
  trigger.addEventListener('blur', hide)

  // unmount callback for useEffect
  return () => {
    tooltip.removeEventListener('touchstart', show)
    tooltip.removeEventListener('mouseenter', show)
    tooltip.removeEventListener('mouseleave', hide)
    tooltip.removeEventListener('blur', hide)

    trigger.removeEventListener('touchstart', show)
    trigger.removeEventListener('mouseenter', show)
    trigger.removeEventListener('mouseleave', hide)
    trigger.removeEventListener('blur', hide)

    document.removeEventListener('touchstart', hide)
    window.removeEventListener('blur', hide)
    window.removeEventListener('scroll', hide)
  }
}


export default createTooltip
