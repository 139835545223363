export default {
  title: {
    en: 'Read-only',
    ru: 'Режим просмотра',
    fr: 'Lecture seule',
    es: 'Solo lectura',
    pt: 'Somente leitura',
    de: 'Nur-Lesen',
    zh: '唯讀',
  },
  tabs: {
    address: {
      en: 'Address',
      ru: 'Адрес',
      fr: 'Adresse',
      es: 'Dirección',
      pt: 'Endereço',
      de: 'Adresse',
      zh: '地址',
    },
    qr: {
      en: 'QR code',
      ru: 'QR код',
      fr: 'QR code',
      es: 'Código QR',
      pt: 'Código QR',
      de: 'QR Code',
      zh: 'QR code',
    },
  },
  inputPlaceholder: {
    en: 'Wallet address or ENS name',
    ru: 'Адрес кошелька или ENS имя',
    fr: 'Adresse du portefeuille ou nom ENS',
    es: 'Dirección de cartera o ENS',
    pt: 'Endereço da carteira ou nome ENS',
    de: 'Wallet Adresse oder ENS Name',
    zh: '錢包地址或ENS域名',
  },
  buttonTitle: {
    en: 'Connect',
    ru: 'Подключить',
    fr: 'Connecter',
    es: 'Conectar',
    pt: 'Conectar',
    de: 'Verbinden',
    zh: '連接',
  },
  note: {
    en: 'Scan QR code or enter Your wallet address',
    ru: 'Отсканируйте QR код или введите адрес Вашего кошелька',
    fr: 'Scannez le QR code ou entrer votre l\'adresse du portefeuille',
    es: 'Escanee su código QR o introduzca su dirección de cartera',
    pt: 'Digitalize o código QR ou insira o endereço da sua carteira',
    de: 'QR Code scannen oder Wallet Adresse eingeben',
    zh: '掃描QR code或輸入您的錢包地址',
  },
  cameraError: {
    en: 'Having trouble to access the camera',
    ru: 'Проблемы с подключением к камере',
    fr: 'Problème pour accéder à la caméra',
    es: 'Ha ocurrido un error accesando la cámara',
    pt: 'Problemas para acessar a câmera',
    de: 'Probleme beim Zugriff auf die Kamera',
    zh: '無法存取攝影鏡頭',
  },
  customEnsError: {
    en: 'Please enter a valid ENS name',
    ru: 'Пожалуйста, введите корректное ENS имя',
    fr: 'Merci d\'entrer une adresse ENS valide',
    es: 'Favor introducir un ENS válido',
    pt: 'Por favor insira um nome ENS válido',
    de: 'Bitte gib einen gültigen ENS Namen ein',
    zh: '請輸入有效的ENS域名',
  },
}
