export default {
  terms: {
    en: 'Terms & Conditions',
    ru: 'Условия Использования',
    fr: 'Termes et conditions',
    es: 'Términos y condiciones',
    pt: 'Termos e Condições',
    de: 'Teilnahme und Geschäftsbedingungen',
    zh: '合約條款 & 條件',
  },
}
