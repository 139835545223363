import { constants } from 'helpers'

import supportedNetworkIds from './supportedNetworkIds'


const filterChainIdByNetworkId = (json: Record<string, unknown>): Record<NetworkIds, number> => {
  const result = {} as Record<NetworkIds, number>

  Object.keys(json).forEach((networkId) => {
    if (supportedNetworkIds.includes(networkId as NetworkIds)) {
      const chainId = json[networkId]

      if (chainId && typeof chainId === 'number') {
        result[networkId as NetworkIds] = chainId
      }
    }
  })

  return result
}

const getChainIdByNetworkIdE2E = (): Record<NetworkIds, number> => {
  try {
    if (typeof window !== 'undefined' && (!IS_PROD || IS_PREVIEW)) {
      const value = new URL(window.location.href).searchParams.get(constants.queryNames.e2e)

      if (value) {
        const json = JSON.parse(value)

        if (json && typeof json === 'object') {
          const chainIdByNetworkIdE2E = filterChainIdByNetworkId(json)

          if (Object.keys(chainIdByNetworkIdE2E).length) {
            // Set window.isE2E to block sentry and analytics events
            window.isE2E = true
          }

          return chainIdByNetworkIdE2E
        }
      }
    }
  }
  catch {}

  return {} as Record<NetworkIds, number>
}


export default getChainIdByNetworkIdE2E
