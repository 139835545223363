type Input = Pick<GetNodeOperators.Params, 'operatorName' | 'validatorsCount' | 'result'>

const createCollectOperator = (values: Input) => (continent: GetNodeOperators.Params['continent']) => {
  const { operatorName, result, validatorsCount } = values

  const validators = Number(validatorsCount)

  if (result[continent][operatorName]) {
    result[continent][operatorName].validators += validators
  }
  else {
    result[continent][operatorName] = {
      name: operatorName,
      validators,
    }
  }
}


export default createCollectOperator
