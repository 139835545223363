import { RefObject, useEffect, useState, useRef, useCallback } from 'react'
import { getters } from 'helpers'
import { useIntl } from 'intl'


export type Input = {
  nodeRef?: RefObject<HTMLElement>
  initialTime: number
  message?: Intl.Message
  timeOptions?: Parameters<typeof getters.getTime>[0]['options']
}

type Output = {
  isExpired: boolean
  setTime: (value: number) => void
}

const useCountdown = (values: Input): Output => {
  const { nodeRef, initialTime, message, timeOptions } = values

  const intl = useIntl()
  const timeLeft = useRef<number>()
  const timer = useRef<NodeJS.Timer>()
  const tickTime = timeOptions?.withSeconds === false ? 60000 : 1000

  const [ isExpired, setExpired ] = useState(false)

  const intlRef = useRef(intl)
  intlRef.current = intl

  const handleTick = useCallback(() => {
    if (typeof timeLeft.current === 'number') {
      timeLeft.current -= tickTime

      if (timer.current && timeLeft.current <= 0) {
        clearInterval(timer.current)
        setExpired(true)

        return
      }
    }

    if (timeLeft.current && nodeRef?.current) {
      const time = getters.getTime({
        options: timeOptions,
        time: timeLeft.current as number,
        intl: intlRef.current,
      })

      if (message) {
        nodeRef.current.innerHTML = intlRef.current.formatMessage(message, { time })
      }
      else {
        nodeRef.current.innerHTML = time
      }
    }
  }, [ message, nodeRef, tickTime, timeOptions ])

  const handleStartTimer = useCallback((time: number) => {
    if (timer.current) {
      clearInterval(timer.current)
    }

    setExpired(false)
    timeLeft.current = time
    timer.current = setInterval(handleTick, tickTime)
    handleTick()
  }, [ tickTime, handleTick ])

  useEffect(() => {
    if (!nodeRef || !nodeRef.current) {
      // You can not send a ref link if the timer is expired
      return
    }

    handleStartTimer(initialTime)

    return () => {
      if (timer.current) {
        clearInterval(timer.current)
      }
    }
  }, [ initialTime ])

  return {
    isExpired,
    setTime: handleStartTimer,
  }
}


export default useCountdown
