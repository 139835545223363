import React from 'react'
import cx from 'classnames'

import Box from 'components/layout/Box/Box'
import Flex from 'components/layout/Flex/Flex'
import Icon from 'components/ui/icons/Icon/Icon'

import s from './ArrowButton.module.scss'


export type ArrowButtonProps = {
  iconName: string
  disabled: boolean
  onClick: () => void
}

const ArrowButton: React.FC<ArrowButtonProps> = (props) => {
  const { iconName, disabled, onClick } = props

  const containerClassName = cx(s.container, {
    [s.disabled]: disabled,
  })

  return (
    <button type="button" onClick={onClick}>
      <Box className={containerClassName} mh={4} radius={4} noWrapper>
        <Flex justify="center">
          <Icon
            name={iconName}
            color="default"
            size={14}
          />
        </Flex>
      </Box>
    </button>
  )
}


export default React.memo(ArrowButton)
