import React, { useEffect, useMemo } from 'react'
import { Html5QrcodeScanner } from 'html5-qrcode'
import type { Html5QrcodeCameraScanConfig } from 'html5-qrcode'

import s from './QrReader.module.scss'


type QrReaderProps = {
  onScan: (result: string) => void
  onError: (result: string) => void
}

const qrcodeRegionId = 'reader'

const QrReader: React.FC<QrReaderProps> = (props) => {
  const { onScan, onError } = props

  const config: Html5QrcodeCameraScanConfig = useMemo(() => ({
    fps: 10,
    qrbox : { width: 300, height: 250 },
    disableFlip: false,
    qrCodeSuccessCallback: onScan,
  }), [ onScan ])

  useEffect(() => {
    if (typeof onScan !== 'function') {
      throw "onScan is required callback."
    }
    const html5QrcodeScanner = new Html5QrcodeScanner(qrcodeRegionId, config, false)
    html5QrcodeScanner.render(onScan, onError)

    return () => {
      html5QrcodeScanner.clear().catch(error => {
        console.error("Failed to clear html5QrcodeScanner. ", error)
      })
    }
  }, [ config, onError, onScan ])

  return (
    <div className={s.container} id={qrcodeRegionId} />
  )
}


export default React.memo(QrReader)
