import fetchSwisePrice from './fetchSwisePrice'
import fetchValidatorsAPR from './fetchValidatorsAPR'


const fetchSystemRestData = async (config: Config): Promise<InitialData.System.RestData> => {
  const [ validatorsAPR, swiseUsdRateBN ] = await Promise.all([
    fetchValidatorsAPR(config),
    fetchSwisePrice(config),
  ])

  return {
    validatorsAPR,
    swiseUsdRateBN,
  }
}


export default fetchSystemRestData
