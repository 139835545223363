import React from 'react'
import cx from 'classnames'

import { Icon } from 'components/ui'

import s from './ActionIcon.module.scss'


type ActionIconProps = {
  isActive: boolean
}

const ActionIcon: React.FC<ActionIconProps> = (props) => {
  const { isActive } = props

  const iconName = isActive ? 'fa-light fa-xmark' : 'fa-light fa-gear'

  const className = cx(s.icon, {
    [s.active]: isActive,
  })

  return (
    <div className={className}>
      <Icon key={iconName} name={iconName} size={20} />
    </div>
  )
}


export default React.memo(ActionIcon)
