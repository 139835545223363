import React, { useRef, useEffect } from 'react'
import cx from 'classnames'

import s from './Overlay.module.scss'


export type OverlayProps = {
  className?: string
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

const Overlay: React.FC<OverlayProps> = (props) => {
  const { children, className, onClick } = props

  const overlayRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (overlayRef.current) {
        overlayRef.current.classList.add(s.mounted)
      }
    }, 400)

    return () => {
      clearTimeout(timer)
    }
  })

  return (
    <div className={cx(s.overlay, className)} ref={overlayRef} onClick={onClick}>
      {children}
    </div>
  )
}


export default Overlay
