import React, { forwardRef } from 'react'
import { Message } from 'intl'
import cx from 'classnames'

import s from './Text.module.scss'


export const sizes = [ 10, 12, 14, 16, 18, 20, 24, 28, 32, 40, 50 ] as const

export const colors = [
  'info',
  'white',
  'black',
  'danger',
  'inverse',
  'default',
  'warning',
  'dark-gray',
  'green-states',
] as const

export type Size = typeof sizes[number]
export type Color = typeof colors[number]

export type TextProps = {
  size?: Size
  color?: Color
  html?: boolean
  light?: boolean
  noWrap?: boolean
  semibold?: boolean
  className?: string
  uppercase?: boolean
  dataTestId?: string
  children?: React.ReactNode
  tag?: keyof React.ReactHTML
  message?: Intl.Message | string
  align?: 'left' | 'center' | 'right'
}

const Text = forwardRef<HTMLElement, TextProps>((props, ref) => {
  const {
    className, children, message, dataTestId, tag = 'div', size = 16,
    light, semibold, align, uppercase, color, html, noWrap,
  } = props

  if (size && !sizes.includes(size)) {
    console.error(`Wrong size "${size}" passed to Text component`)
  }

  const rootClassName = cx(className,  {
    [s[`size-${size}`]]: size,
    [s[`align-${align}`]]: align,
    [s[`color-${color}`]]: color,
    [s.noWrap]: noWrap,
    [s.light]: light,
    [s.semibold]: semibold,
    [s.uppercase]: uppercase,
  })

  if (children) {
    return React.createElement(tag, {
      className: rootClassName,
      ref,
      'data-testid': dataTestId,
    }, children)
  }

  if (message) {
    return (
      <Message
        className={rootClassName}
        dataTestId={dataTestId}
        value={message}
        html={html}
        tag={tag}
        ref={ref}
      />
    )
  }

  return null
})

Text.displayName = 'Text'


export default Text
