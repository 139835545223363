import React, { MouseEvent } from 'react'
import { Field, useFieldState } from 'formular'
import cx from 'classnames'

import Box from 'components/layout/Box/Box'
import Flex from 'components/layout/Flex/Flex'
import Icon from 'components/ui/icons/Icon/Icon'

import s from './Checkbox.module.scss'


export type CheckboxProps = {
  className?: string
  disabled?: boolean
  field: Field<any>
  dataTestId?: string
  withPreventDefault?: boolean
}

const Checkbox: React.FC<CheckboxProps> = (props) => {
  const { className, disabled, children, field, dataTestId, withPreventDefault = true } = props

  const { value } = useFieldState(field)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (withPreventDefault) {
      event.preventDefault()
    }

    event.stopPropagation()

    field.set(!value)
  }

  const rootClassName = cx(s.root, className, {
    [s.checked]: Boolean(value),
    [s.disabled]: Boolean(disabled),
  })

  return (
    <button
      className={rootClassName}
      role="checkbox"
      disabled={disabled}
      aria-checked={value}
      data-testid={dataTestId}
      onClick={(event) => handleClick(event)}
    >
      <Flex justify="center">
        <Flex className={s.iconContainer} justify="center">
          <Icon
            className={s.icon}
            name="fa-solid fa-check"
            color="white"
            size={14}
          />
        </Flex>
        {
          Boolean(children) && (
            <Box ml={8}>
              {children}
            </Box>
          )
        }
      </Flex>
    </button>
  )
}


export default Checkbox
