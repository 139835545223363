import React, { useMemo, useState, useCallback, useEffect } from 'react'

import type { TabsProps } from '../Tabs'
import type { TabsContentProps } from '../Content/Content'


type Input = Pick<TabsProps, 'children' | 'defaultActiveTab' | 'onChange'> & {
  tabsContainer: React.RefObject<HTMLDivElement>
  floatingBackground: React.RefObject<HTMLDivElement>
}

type Output = {
  tabsCount: number
  content: React.ReactNode
  activeTab: string
  handleClick: (id: string) => void
}

type SetFloatingBackgroundPositionInput = Pick<Input, 'floatingBackground'> & {
  tabsCount: Output['tabsCount']
  activeTabIndex: number
}

const setFloatingBackgroundPosition = (values: SetFloatingBackgroundPositionInput) => {
  const { floatingBackground, tabsCount, activeTabIndex } = values

  if (floatingBackground?.current) {
    const containerWidth = 100
    const backgroundWidth = Math.abs(containerWidth / tabsCount)
    const leftPosition = activeTabIndex * backgroundWidth

    floatingBackground.current.style.width = `${backgroundWidth}%`
    floatingBackground.current.style.left = `${leftPosition}%`
  }
}

const useTabs = (values: Input): Output => {
  const { children, defaultActiveTab, tabsContainer, floatingBackground, onChange } = values

  const availableTabIds = useMemo<string[]>(() => (
    React.Children.map(children, (child: React.ReactElement<TabsContentProps>) => child.props.id)
  ), [ children ])

  const [ activeTab, setActiveTab ] = useState(defaultActiveTab || availableTabIds[0])
  const activeTabIndex = availableTabIds.indexOf(activeTab)
  const tabsCount = availableTabIds.length

  useEffect(() => {
    setFloatingBackgroundPosition({ floatingBackground, tabsCount, activeTabIndex })
  }, [ activeTabIndex, tabsCount, floatingBackground ])

  const handleClick = useCallback((id) => {
    if (activeTab === id) {
      return
    }

    setActiveTab(id)

    if (typeof onChange === 'function') {
      onChange(id)
    }
  }, [ activeTab, setActiveTab, onChange ])

  return {
    content: children[activeTabIndex],
    tabsCount,
    activeTab,
    handleClick,
  }
}


export default useTabs
