export default {
  staking: {
    en: 'Staking',
    ru: 'Стейкинг',
    fr: 'Staking',
    es: 'Staking',
    pt: 'Staking',
    de: 'Staking',
    zh: '質押',
  },
  farms: {
    en: 'Farms',
    ru: 'Фермы',
    fr: 'Farms',
    es: 'Farms',
    pt: 'Fazenda',
    de: 'Farming',
    zh: '農場',
  },
  solo: {
    en: 'Solo',
    ru: 'Соло',
    fr: 'Solo',
    es: 'Solo',
    pt: 'Só',
    de: 'Solo',
    zh: '單獨',
  },
  payments: {
    en: 'Payments',
    ru: 'Платежи',
    fr: 'Paiements',
    es: 'Pagos',
    pt: 'Pagamentos',
    de: 'Zahlungen',
    zh: '付款',
  },
  vesting: {
    en: 'Vesting',
    ru: 'Вестинг',
    fr: 'Vesting',
    es: 'Consolidación',
    pt: 'Aquisições',
    de: 'Ansprüche',
    zh: '選擇權',
  },
  questionIconLabel: {
    en: 'Open documentation',
    ru: 'Открыть документацию',
    fr: 'Voir la documentation',
    es: 'Abrir documentación',
    pt: 'Documentação aberta',
    de: 'Dokumentation öffnen',
    zh: '開啟文件',
  },
  updateValidators: {
    en: 'Update validators',
    ru: 'Обновить валидаторы',
    fr: 'Mettre à jour les validateurs',
    es: 'Actualizar validadores',
    pt: 'Atualizar validadores',
    de: 'Validatoren aktualisieren',
    zh: '更新验证者',
  },
  faq: {
    en: 'FAQ',
    ru: 'FAQ',
    fr: 'FAQ',
    es: 'FAQ',
    pt: 'FAQ',
    de: 'FAQ',
    zh: 'FAQ',
  },
}
