import React from 'react'

import ConfigContext from './ConfigContext'
import useConfigContext from './util/useConfigContext'


const ConfigProvider: React.FC = ({ children }) => {
  const context = useConfigContext()

  return (
    <ConfigContext.Provider value={context}>
      {children}
    </ConfigContext.Provider>
  )
}


export default ConfigProvider
