export default {
  name: {
    en: 'WalletConnect',
    ru: 'WalletConnect',
    fr: 'WalletConnect',
    es: 'WalletConnect',
    pt: 'WalletConnect',
    de: 'WalletConnect',
    zh: 'WalletConnect',
  },
  activationMessage: {
    en: 'Waiting for QR code to be scanned by WalletConnect application',
    ru: 'Ожидаем сканирования QR-кода приложением WalletConnect',
    fr: 'Attente du scan du QR Code par l\'application WalletConnect',
    es: 'Esperando que el código QR sea escaneado por el app de WalletConnect',
    pt: 'Aguardando a leitura do código QR pelo aplicativo WalletConnect',
    de: 'Warte auf das Scannen des QR Codes in der WalletConnect App',
    zh: '等待 WalletConnect 應用程式掃描 QR Code',
  },
  errors: {
    unsupportedNetwork: {
      en: 'WalletConnect is connected to an unsupported network',
      ru: 'WalletConnect подключен к не поддерживаемой сети',
      fr: 'WalletConnect est connecté à un réseau non supporté',
      es: 'WalletConnect está conectado a una red no soportada',
      pt: 'WalletConnect está conectado a uma rede não suportada',
      de: 'WalletConnect ist mit einem nicht unterstützten Netzwerk verbunden',
      zh: 'WalletConnect 連接到一個不支援的網路',
    },
    authorize: {
      en: 'Please authorize access to your WalletConnect account',
      ru: 'Пожалуйста, разрешите доступ к Вашему аккаунту WalletConnect',
      fr: 'Merci d\'autoriser l\'accès à votre compte WalletConnect',
      es: 'Favor permitir acceso a su cuenta de WalletConnect',
      pt: 'Por favor autorize o acesso à sua conta WalletConnect',
      de: 'Bitte autorisiere den Zugriff auf Dein WalletConnect Konto',
      zh: '請允許存取您的 WalletConnect',
    },
  },
}
