import { parseEther } from '@ethersproject/units'
import { Percent } from '@uniswap/sdk-core'


export default {
  blocksInDay: 6646,
  secondsInBlock: 13,
  blocksInYear: 2427458,
  amount0: parseEther('0'),
  amount1: parseEther('1'),
  amount10: parseEther('10'),
  amount32: parseEther('32'),
  emptyAddress: '0x0000000000000000000000000000000000000000',

  // Ticks must be in full range for SWISE-sETH2 pool
  fullRangeTickMax: 887220,
  fullRangeTickMin: -887220,
  uniswapDefaultSlippage: new Percent(50, 10_000), // .50%
}
