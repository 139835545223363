import React from 'react'
import cx from 'classnames'

import { Text } from 'components/ui'
import { Box, Flex } from 'components/layout'

import s from './ListItem.module.scss'


type ListItemProps = {
  className?: string
  title: Intl.Message
  disabled?: boolean
  leftNode?: React.ReactNode
  onClick?: () => void
}

const ListItem: React.FC<ListItemProps> = (props) => {
  const { className, title, leftNode, disabled, onClick } = props

  const handleClick = () => {
    if (!disabled && typeof onClick === 'function') {
      onClick()
    }
  }

  const buttonClassName = cx(s.item, className, {
    [s.disabled]: disabled,
  })

  return (
    <Box
      className={buttonClassName}
      dataTestId={`connect link: ${title.en}`}
      pv={16}
      ph={8}
      onClick={handleClick}
    >
      <Flex justify="start">
        {leftNode}
        <Box pl={8} noWrapper>
          <Text message={title} size={12} color="black" />
        </Box>
      </Flex>
    </Box>
  )
}


export default ListItem
