const deepFreeze = <T extends Record<string, any>>(obj: T): T => {
  Object.keys(obj)
    .forEach((prop) => {
      const isObject = typeof obj[prop] === 'object'
      const isFrozen = Object.isFrozen(obj[prop])

      if (isObject && !isFrozen) {
        deepFreeze(obj[prop])
      }
    })

  return Object.freeze(obj)
}


export default deepFreeze
