import { useCallback } from 'react'
import { openNotification } from 'notifications'

import messages from './messages'


const useCopyToClipboard = () => {
  const fallbackCopyToClipboard = useCallback((text) => {
    const textArea = document.createElement('textarea')

    textArea.value = text

    // Avoid scrolling to bottom
    textArea.style.top = '0'
    textArea.style.left = '0'
    textArea.style.position = 'fixed'

    document.body.appendChild(textArea)

    textArea.focus()
    textArea.select()

    let isCopied

    try {
      isCopied = document.execCommand('copy')
    }
    catch (error) {
      console.error('Unable to copy', error)
      isCopied = false
    }

    document.body.removeChild(textArea)

    return isCopied
  }, [])

  return useCallback(async (text) => {
    if (!navigator.clipboard) {
      return fallbackCopyToClipboard(text)
    }

    await navigator.clipboard.writeText(text)

    openNotification({
      type: 'success',
      text: messages.copiedToClipboard,
    })

    return true
  }, [ fallbackCopyToClipboard ])
}


export default useCopyToClipboard
