import { parseEther } from '@ethersproject/units'


const getIsInputTokenAmountValid = (value: string): boolean => {
  if (typeof value !== 'string') {
    return false
  }

  const number = Number(value)

  if (isNaN(number)) {
    return false
  }

  if (number && number >= 1e-18) {
    try {
      return Boolean(parseEther(value))
    }
    catch (_) {
      return false
    }
  }

  return false
}


export default getIsInputTokenAmountValid
