import React from 'react'
import dynamic from 'next/dynamic'
import { useDevice } from 'device'

import { Box, Flex } from 'components/layout'

import Links from './Links/Links'
import CurrencySelect from './CurrencySelect/CurrencySelect'
import LanguageSelect from './LanguageSelect/LanguageSelect'

import s from './Footer.module.scss'


const SwitchTheme = dynamic(
  () => import('./SwitchTheme/SwitchTheme'),
  { ssr: false }
)

const Footer: React.FC = () => {
  const { isMobile } = useDevice()

  return (
    <footer className={s.footer}>
      <Flex align={isMobile ? 'end' : 'center'}>
        <Flex
          align={isMobile ? 'start' : 'center'}
          direction={isMobile ? 'column' : 'row'}
        >
          <SwitchTheme />
          <Box
            ml={isMobile ? undefined : 8}
            mt={isMobile ? 8 : undefined}
            hideEmpty
          >
            <CurrencySelect />
          </Box>
          <Box
            ml={isMobile ? undefined : 8}
            mt={isMobile ? 8 : undefined}
            hideEmpty
          >
            <LanguageSelect />
          </Box>
        </Flex>
        <Links />
      </Flex>
    </footer>
  )
}


export default React.memo(Footer)
