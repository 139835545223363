import { useMemo } from 'react'
import * as ui from 'redux/ui'
import * as farms from 'redux/farms'
import * as system from 'redux/system'
import * as account from 'redux/account'


const useInitialState = () => useMemo(() => ({
  ui: ui.initialState,
  farms: farms.initialState,
  system: system.initialState,
  account: account.initialState,
}), [])


export default useInitialState
