import React, { useEffect } from 'react'
import { useDevice } from 'device'

import { Box, Flex } from 'components/layout'
import { Icon, Text } from 'components/ui'

import messages from './messages'
import s from './Banner.module.scss'


const Banner: React.FC = () => {
  const { isMobile } = useDevice()

  useEffect(() => {
    document.body?.classList.add('withBanner')

    return () => {
      document.body?.classList.remove('withBanner')
    }
  }, [])

  return (
    <Box
      className={s.banner}
      p={isMobile ? 8 : 16}
    >
      <a
        className={s.link}
        href="https://stakewise.medium.com/announcing-the-launch-of-stakewise-v3-55effc24dbe4"
        rel="noreferrer nofollow"
        target="_blank"
      >
        <Flex justify="center" align="center">
          <Text
            message={messages.title}
            align="center"
            color="black"
            tag="span"
            size={14}
            html
          />
          <Icon
            containerClassName={s.icon}
            name="fa-solid fa-arrow-right"
            color="black"
            size={16}
          />
        </Flex>
      </a>
    </Box>
  )
}


export default React.memo(Banner)
