import constants from 'helpers/constants'


const isTheGraph = process.env.NEXT_PUBLIC_GNOSIS_RUN_THEGRAPH === 'true'

// ATTN Addresses must be in check sum format!

export default {
  api: {
    rest: IS_PROD
      ? 'https://api.stakewise.io'
      : 'https://stage-api.stakewise.io',
    mainSubgraph: isTheGraph
      ? 'https://gateway-arbitrum.network.thegraph.com/api/007fa8d2e10d5b2394388e4ed945d8ab/subgraphs/id/7L4CAkFkJsPRsM65iB88aW9JchKEibhBM4rkcyAhh1Ff'
      : 'https://graph-gno.stakewise.io/subgraphs/name/stakewise/stakewise',
    uniswapSubgraph: '',
  },
  network: {
    id: 'gnosis',
    chainId: 100,
    isMain: true,
    name: 'Gnosis Chain',
    hexadecimalChainId: '0x64', // https://www.xdaichain.com/for-users/wallets/metamask/metamask-setup
    url: process.env.NEXT_PUBLIC_GNOSIS_NETWORK_URL || '',
    blockExplorerUrls: 'https://blockscout.com/xdai/mainnet',
    nativeCurrency: {
      symbol: constants.tokens.xdai,
      name: constants.tokens.xdai,
      decimals: 18,
    },
  },
  pages: {
    etherscan: 'https://blockscout.com/xdai/mainnet/tx',
    uniWethToStakedEthPoolNewPosition: '',
    uniSwiseToStakedEthPoolNewPosition: '',
    uniRewardEthToStakedEthPoolNewPosition: '',
    uniEthToSwisePoolNewPosition: '',
  },
  settings: {
    minimalAPR: 8,
    rewardUniswapFee: 0,
    maintainerFee: 1000,
  },
  addresses: {
    tokens: {
      default: {
        swise: '0xfdA94F056346d2320d4B5E468D6Ad099b2277746',
        staked: '0xA4eF9Da5BA71Cc0D2e5E877a910A37eC43420445',
        reward: '0x6aC78efae880282396a335CA2F79863A1e6831D4',
        deposit: '0x9C58BAcC331c9aa871AFD802DB6379a98e80CEdb',
        alternativeDeposit: '0x722fc4DAABFEaff81b97894fC623f91814a1BF68',
      },
      special: {
        dai: '',
        steth: '',
      },
    },
    base: {
      pool: '0x2f99472b727e15EECf9B9eFF9F7481B85d3b4444',
      oracles: '0xa6D123620Ea004cc5158b0ec260E934bd45C78c1',
      multicall: '0xb5b692a88BDFc81ca69dcB1d924f59f0413A602a',
      merkleDrop: '',
      whiteListManager: '',
      merkleDistributor: '0x7dc30953CE236665d032329F6a922d67F0a33a2B',
      vestingEscrowFactory: '',
      soloPaymentsRecipient: '',
    },
    pools: {
      uniswap: {
        stakedTokenNativeToken: '',
        stakedTokenRewardToken: '',
        stakedTokenSwiseToken: '',
        ethSwiseToken: '',
      },
      curve: {
        stakedTokenRewardToken: '0x5D7309a01B727d6769153fCB1dF5587858d53B9C',
        gaugeStakedTokenRewardToken: '0x9509A9D5C55793858FE8b1C00a99e012a7AF4aaB',
        stakedTokenDepositToken: '0xBdF4488Dcf7165788D438b62B4C8A333879B7078',
        gaugeStakedTokenDepositToken: '0x2686d5E477d1AaA58BF8cE598fA95d97985c7Fb1',
        stakedTokenLidoStakedToken: '',
        gaugeStakedTokenLidoStakedToken: '',
      },
    },
    helpers: {
      uniswap: {
        quoter:  '',
        router:  '',
        positionManager: '',
      },
    },
  },
  tokens: {
    nativeToken: constants.tokens.xdai,
    rewardToken: constants.tokens.rgno,
    stakedToken: constants.tokens.sgno,
    depositToken: constants.tokens.gno,
    alternativeDepositToken: constants.tokens.mgno,
  },
} as const
