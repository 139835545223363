export default {
  stake: {
    en: 'Stake',
    ru: 'Стейкинг',
    fr: 'Stake',
    es: 'Hacer staking',
    pt: 'Stake',
    de: 'Stake',
    zh: '質押',
  },
  farm: {
    en: 'Farm',
    ru: 'Фермы',
    fr: 'Farm',
    es: 'Farmear',
    pt: 'Fazenda',
    de: 'Farmen',
    zh: '農場',
  },
  solo: {
    en: 'Solo',
    ru: 'Соло',
    fr: 'Solo',
    es: 'Solo',
    pt: 'Só',
    de: 'Solo',
    zh: '單獨',
  },
  payments: {
    en: 'Payments',
    ru: 'Платежи',
    fr: 'Paiements',
    es: 'Pagos',
    pt: 'Pagamentos',
    de: 'Zahlungen',
    zh: '付款',
  },
  forum: {
    en: 'Forum',
    ru: 'Форум',
    fr: 'Forum',
    es: 'Foro',
    pt: 'Fórum',
    de: 'Forum',
    zh: '論壇',
  },
  vote: {
    en: 'Vote',
    ru: 'Голосование',
    fr: 'Vote',
    es: 'Votar',
    pt: 'Vote',
    de: 'Abstimmung',
    zh: '投票',
  },
  docs: {
    en: 'Docs',
    ru: 'Документация',
    fr: 'Docs',
    es: 'Docs',
    pt: 'Documentos',
    de: 'Anleitung',
    zh: '文件',
  },
}
