
import { useCallback, useEffect } from 'react'
import { constants } from 'helpers'

import getSavedContext from './getSavedContext'
import { supportedNetworks } from '../helpers'


type UseAutoConnectProps = {
  activate: ConfigProvider.Context['activate']
  contextRef: Record<'current', ConfigProvider.State>
  setContext: (param: Partial<ConfigProvider.Context>) => void
}

const useAutoConnect = ({ activate, contextRef, setContext }: UseAutoConnectProps) => {
  const handleAutoConnect = useCallback(async (savedContext: ConfigProvider.State) => {
    const { networkId, activeWallet } = savedContext

    const hasInjectedProvider = Boolean(window.web3 || window.ethereum)
    const connectors = contextRef.current.connectors

    const isLedger = activeWallet === constants.walletNames.ledger
    const isDAppBrowser = window.device.isMobile && hasInjectedProvider
    const isGnosisSafe = await connectors.gnosis?.connector?.isSafeApp?.()
    const isLedgerLive = await connectors.ledgerLive?.connector?.isLedgerLive?.()

    // ATTN: The order in this code is important!
    if (isLedgerLive) {
      activate(constants.walletNames.ledgerLive, networkId)
    }
    else if (isGnosisSafe) {
      const networks: Record<number, NetworkIds> = {
        [supportedNetworks.gnosis.chainId]: supportedNetworks.gnosis.id,
      }

      // @ts-ignore
      const { chainId } = await connectors.gnosis.connector.getSafeInfo()

      if (networks[chainId]) {
        // another network may be selected
        activate(constants.walletNames.gnosis, networks[chainId])

        return
      }

      activate(constants.walletNames.gnosis, networkId)
    }
    else if (isDAppBrowser) {
      activate(constants.walletNames.dAppBrowser, networkId)
    }
    else if (isLedger) {
      activate(constants.walletNames.ledger, networkId)
    }
    else if (activeWallet === constants.walletNames.monitorAddress) {
      setContext(savedContext)
    }
    else if (activeWallet) {
      activate(activeWallet, networkId)
    }
    else {
      setContext(savedContext)
    }
  }, [ activate, contextRef, setContext ])

  useEffect(() => {
    const savedContext = getSavedContext()

    handleAutoConnect(savedContext)
  }, [ setContext, handleAutoConnect ])
}


export default useAutoConnect
