import { useEffect, useCallback } from 'react'
import { LedgerPathTypes } from 'custom-connectors'
import { useObjectState, useConfig } from 'hooks'
import { openNotification } from 'notifications'
import localStorage from 'local-storage'
import { constants } from 'helpers'

import messages from '../messages'


type Output = {
  address: string | null | undefined
  accounts: string[]
  isFetching: boolean
  nextPathType: PathItem
  onSelectAddress: (address: string) => void
  onChangePathType: (pathType: LedgerPathTypes) => void
}

type PathItem = {
  cache: string[]
  type: LedgerPathTypes
  title: Intl.Message
}

const livePathData: PathItem = {
  cache: [],
  type: LedgerPathTypes.LIVE,
  title: messages.types.live,
}

const legacyPathData: PathItem = {
  cache: [],
  type: LedgerPathTypes.LEGACY,
  title: messages.types.legacy,
}

const bip44PathData: PathItem = {
  cache: [],
  type: LedgerPathTypes.BIP44,
  title: messages.types.bip44,
}

const paths = [ livePathData, legacyPathData, bip44PathData ]

const findPath = (type: LedgerPathTypes): PathItem => paths.find((item) => item.type === type) as PathItem

const findNextPath = (type: LedgerPathTypes): PathItem => {
  const index = paths.findIndex((item) => item.type === type)
  const nextPath = paths[index + 1]

  return nextPath || paths[0]
}

const useSwitchAccount = (closeModal: () => void): Output => {
  const { address, connector } = useConfig()

  const initialPathType = connector?.pathType as LedgerPathTypes
  const currentPathItem = findPath(initialPathType)

  const [ { accounts, pathType, isFetching }, setState ] = useObjectState<{
    pathType: LedgerPathTypes
    accounts: Output['accounts']
    isFetching: Output['isFetching']
  }>({
    pathType: initialPathType,
    accounts: currentPathItem.cache,
    isFetching: !Boolean(currentPathItem.cache.length),
  })

  const fetchAddresses = (pathType: LedgerPathTypes) => {
    const pathItem = findPath(pathType)

    if (pathItem.cache.length) {
      setState({
        pathType,
        isFetching: false,
        accounts: pathItem.cache,
      })

      return
    }

    if (connector?.getAccounts) {
      connector.getAccounts({
        from: 0,
        limit: 10,
      })
        .then((accounts: Output['accounts']) => {
          pathItem.cache = accounts

          setState({
            accounts,
            pathType,
            isFetching: false,
          })
        })
    }
  }

  const handleChangePathType = (pathType: LedgerPathTypes) => {
    if (connector?.setPathType) {
      setState({ isFetching: true })

      connector.setPathType(pathType)
        .then(() => {
          fetchAddresses(pathType)
        })
    }
  }

  useEffect(() => {
    fetchAddresses(initialPathType)
  }, [])

  const handleSelectAddress = useCallback((address: string) => {
    const index = accounts.indexOf(address)

    if (index !== -1 && connector?.setActiveAccount) {
      connector?.setActiveAccount(index)
        .then(() => {
          localStorage.setItem(constants.localStorageNames.ledgerSelectedAccount, {
            index,
            pathType,
          })

          openNotification({
            type: 'success',
            text: messages.successNotification,
          })
        })
    }

    closeModal()
  }, [ accounts, pathType ])

  return {
    address,
    accounts,
    isFetching,
    nextPathType: findNextPath(pathType),
    onSelectAddress: handleSelectAddress,
    onChangePathType: handleChangePathType,
  }
}


export default useSwitchAccount
