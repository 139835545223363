import React, { forwardRef } from 'react'
import cx from 'classnames'

import s from './Flex.module.scss'


export type FlexProps = {
  children?: React.ReactNode
  className?: string
  id?: string
  wrap?: boolean
  direction?: 'column' | 'row' | 'row-reverse' | 'column-reverse'
  align?: 'center' | 'start' | 'end' | 'baseline' | 'stretch'
  justify?: 'between' | 'center' | 'start' | 'end' | 'around'
  inline?: boolean
  fullHeight?: boolean
  style?: React.CSSProperties
  dataTestId?: string
  onClick?: () => void
}

const Flex = forwardRef<HTMLDivElement, FlexProps>((props, ref) => {
  const {
    id,
    wrap,
    style,
    inline,
    children,
    className,
    fullHeight,
    dataTestId,
    align = 'center',
    direction = 'row',
    justify = 'between',
    onClick,
  } = props

  const rowClassName = cx(className, {
    [s.row]: !inline,
    [s.inline]: inline,
    [s.wrap]: wrap,
    [s.fullHeight]: fullHeight,
    [s[`align-${align}`]]: align,
    [s[`justify-${justify}`]]: justify,
    [s[`direction-${direction}`]]: direction,
  })

  return (
    <div
      className={rowClassName}
      id={id}
      ref={ref}
      style={style}
      data-testid={dataTestId}
      onClick={onClick}
    >
      {children}
    </div>
  )
})

Flex.displayName = 'Flex'


export default Flex
