import React, { forwardRef } from 'react'
import cx from 'classnames'

import s from './Box.module.scss'


const allowedKeys = [ 'm', 'mv', 'mh', 'mt', 'mr', 'mb', 'ml', 'p', 'pv', 'ph', 'pt', 'pr', 'pb', 'pl' ] as const
const allowedSizes = [ 4, 8, 12, 16, 20, 24, 32, 40, 48, 56, 64, 72, 80 ] as const
const allowedRadius = [ 4, 8, 12 ] as const

type Key = typeof allowedKeys[number]
export type Size = typeof allowedSizes[number]
export type SizeProps = { [ key in Key ]?: Size }

const getClasses = (keys: SizeProps): string[] => {
  const result: string[] = []

  const newKeys = Object.keys(keys).reduce((acc, key) => {
    const value = keys[key as Key]

    if (key === 'mv') {
      acc.mt = value
      acc.mb = value
    }
    else if (key === 'mh') {
      acc.ml = value
      acc.mr = value
    }
    else if (key === 'pv') {
      acc.pt = value
      acc.pb = value
    }
    else if (key === 'ph') {
      acc.pl = value
      acc.pr = value
    }
    else {
      acc[key as Key] = value
    }

    return acc
  }, {} as SizeProps)

  Object.keys(newKeys).forEach((key) => {
    let value = newKeys[key as Key]

    if (value && !allowedSizes.includes(value)) {
      console.error(`Wrong size "${value}" passed to Box component`)
    }

    result.push(s[`${key}_${value}`])
  })

  return result
}

export interface BoxProps {
  m?: Size
  mv?: Size // margin vertical
  mh?: Size // margin horizontal
  mt?: Size
  mr?: Size
  mb?: Size
  ml?: Size

  p?: Size
  pv?: Size // padding vertical
  ph?: Size // padding horizontal
  pt?: Size
  pr?: Size
  pb?: Size
  pl?: Size

  id?: string
  tag?: string
  noWrapper?: boolean // ATTN child should have a className prop
  dataTestId?: string
  hideEmpty?: boolean
  children?: React.ReactNode
  style?: React.CSSProperties
  onClick?: () => void

  center?: boolean
  inline?: boolean
  className?: string
  relative?: boolean
  overflow?: 'hidden'
  fullWidth?: boolean
  alignLeft?: boolean
  fullHeight?: boolean
  alignRight?: boolean
  lineHeight0?: boolean
  alignCenter?: boolean
  radius?: typeof allowedRadius[number]
}

const Box = forwardRef<HTMLElement, BoxProps>((props, ref) => {
  const {
    children, id, className, alignCenter, alignRight, radius, overflow, hideEmpty, dataTestId, fullWidth,
    lineHeight0, fullHeight, alignLeft, center, inline, style, relative, tag = 'div', noWrapper, onClick,
    ...keys
  } = props

  const rootClassName = cx(className, ...getClasses(keys), {
    [s.center]: center,
    [s.inline]: inline,
    [s.relative]: relative,
    [s.alignLeft]: alignLeft,
    [s.hideEmpty]: hideEmpty,
    [s.fullWidth]: fullWidth,
    [s.alignRight]: alignRight,
    [s.fullHeight]: fullHeight,
    [s.lineHeight0]: lineHeight0,
    [s.alignCenter]: alignCenter,
    [s[`radius-${radius}`]]: radius,
    [s[`overflow-${overflow}`]]: overflow,
  })

  if (noWrapper && React.isValidElement(children)) {
    return React.cloneElement(children, {
      // @ts-ignore
      className: cx(children.props.className, rootClassName),
    })
  }

  return React.createElement(tag, {
    id,
    ref,
    style,
    className: rootClassName,
    'data-testid': dataTestId,
    onClick,
  }, children)
})

Box.displayName = 'Box'


export default Box
